import React, { useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Skeleton } from "@mui/material";

const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    zIndex: "10",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: '#09D1C7',
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

const ViewCategory = (data) => {
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const history = useHistory();
  const [isImageLoading, setIsImageLoading] = useState(true);
  return (
    <div
      key={`category${data.id}`}
      className="categorySlider"
      onClick={() =>
        history.push(
          `/products?${data.name == "Dog Grooming" || data.name == "Fishes" || data.name == "Rabbits/Hamster/Guinea pig"? "sid" : "pid"}=${btoa(
            data.id
          )}`
        )
      }
    >
      <img
        src={data.image}
        style={{
          display: isImageLoading ? "none" : "block",
        }}
        alt=""
        onLoad={() => setIsImageLoading(false)}
      />
      {isImageLoading && (
        <Skeleton
          variant="circular"
          width={mobileMatches ? 140 : 75}
          height={mobileMatches ? 140 : 75}
        />
      )}
      {!isImageLoading && <h4>{data.name}</h4>}
      {isImageLoading && <Skeleton variant="text" width={70} />}
    </div>
  );
};

function Category({ isLoading, categoriesData }) {
  const slider = useRef();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    centerPadding: "60px",
    arrows: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const categoryList =
    categoriesData?.data?.map((category, index) => ({
      ...category,
      id: category.id,
      link: category.link,
      image: category.category_image
        ? `${categoriesData.image_link}/${category.category_image}`
        : "https://dummyimage.com/150x150",
      name: category.heading_h1,
    })) || [];

  return (
    <section className="section" style={{ margin: "0" }}>
      <div className="section-inner">
        <div className="sectionHeader">
          <h3>Shop By Categories</h3>
        </div>
        {/* <div
          style={{
            borderBottom: "1px solid #e5e5e5",
            width: "100%",
            height: "0",
            marginBottom: "0.5rem",
          }}
        /> */}
        {mobileMatches ? (
          <div className="sliderrr">
            <Slider
              style={{ width: "100%" }}
              ref={(c) => (slider.current = c)}
              {...settings}
            >
              {categoryList.map((category) =>
                ViewCategory(category, isLoading)
              )}
            </Slider>
            {/* {mobileMatches && (
                <> */}
            <CustomIconButton
              className="arrowLeft"
              variant="contained"
              color="primary"
              onClick={() => slider.current.slickPrev()}
            >
              <ArrowBackIosNewIcon />
            </CustomIconButton>
            <CustomIconButton
              className="arrowRight"
              variant="contained"
              color="primary"
              onClick={() => slider.current.slickNext()}
            >
              <ArrowForwardIosIcon />
            </CustomIconButton>
            {/* </>
              )} */}
          </div>
        ) : (
          <Box sx={{ width: "100%", ml: 0, mr: 0 }} className="mobile-sliderr">
            {categoryList.map((category) => ViewCategory(category, isLoading))}
          </Box>
        )}
      </div>
    </section>
  );
}

export default Category;
