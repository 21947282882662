import { baseService } from "./baseService";

export default async function fetchProductPage(data) {
  try {
    if (data.category_id) {
      const res = await baseService.get(
        `fetch-all-products?page=${data.page}&category_id=${data.category_id}&option_id=${data.option_id}&sale_price_from=${data.priceRange[0]}&sale_price_to=${data.priceRange[1]}&brandId=${data.brandIds}`
      );
      return res.data;
    } else if (data.sub_category_id) {
      const res = await baseService.get(
        `fetch-all-products?page=${data.page}&sub_category_id=${data.sub_category_id}&option_id=${data.option_id}&sale_price_from=${data.priceRange[0]}&sale_price_to=${data.priceRange[1]}&brandId=${data.brandIds}`
      );
      return res.data;
    } else if (data.p_category_id) {
      const res = await baseService.get(
        `fetch-all-products?page=${data.page}&p_category_id=${data.p_category_id}&option_id=${data.option_id}&sale_price_from=${data.priceRange[0]}&sale_price_to=${data.priceRange[1]}&brandId=${data.brandIds}`
      );
      return res.data;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function fetchProductsByBrands(data) {
  try {
    console.log(
      `https://api.pettofit.com/api/v1/get-product-by-brand?brandId=${data.brandId}&page=${data.page}&option_id=${data.option_id}&sale_price_from=${data.priceRange[0]}&sale_price_to=${data.priceRange[1]}`
    );
    const res = await baseService.get(
      `get-product-by-brand?brandId=${data.brandId}&page=${data.page}&option_id=${data.option_id}&sale_price_from=${data.priceRange[0]}&sale_price_to=${data.priceRange[1]}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchCatalogForBrand(id) {
  try {
    const res = await baseService.get(`get-filters?brand_id=${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
