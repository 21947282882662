import React from "react";
import "./Review.css";
import StarIcon from "@material-ui/icons/Star";

function Review({ data }) {
  return data ? (
    <div
      className="review"
      style={{
        maxHeight: 365,
        overflow: "scroll",
      }}
    >
      {data
        // .filter((v, i) => i < 3)
        .map((d, i) => (
          <div className="reviewBox">
            <p className="review__header">
              <span>
                {d.rating} <StarIcon className="star" />
              </span>
              {d.review_title}
            </p>
            <p className="review__desc">{d.review_text}</p>
            <p className="review__footer">
              <span>
                {d.name} | {new Date(d.updated_at).toLocaleDateString()}
              </span>
            </p>
          </div>
        ))}
    </div>
  ) : (
    ""
  );
}

export default Review;
