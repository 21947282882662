import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    zIndex: "10",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: 'lightgray',
    padding: "5px",
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

function ShopByBrands({ brands }) {
  const slider = useRef();
  const history = useHistory();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [settings, setSettings] = useState({
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    speed: 1500,
    slidesToShow: Math.min(brands.data.length, 6),
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    //   cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(brands.data.length, 5),
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(brands.data.length, 3),
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(brands.data.length, 2),
          slidesToScroll: 1,
        },
      },
    ],
  });

  const brandList =
    brands?.data?.map((brand, index) => ({
      id: brand.id,
      link: brand.link,
      name: brand.name,
      image: `${brands?.image_link}/${brand.logo}`,
    })) || [];

  const showBrand = (data) => {
    return (
      <div
        key={`brand${data.id}`}
        className="brandSlider"
        onClick={() => history.push("/shop-by-brand/" + btoa(data.id))}
      >
        <img src={data.image} alt="" className="brand-slider-image" />
      </div>
    );
  };
  return (
    <div className="section">
      <section className="section-inner shopByBrands">
        <div className="sectionHeader">
          <h3>Shop By Brands</h3>
          {/*<Button
            variant="contained"
            size="medium"
            style={{
              backgroundColor: "#09d1c7",
              marginRight: "8px",
              padding: "4px 11px",
            }}
          >
            <Link
              to={`/brands`}
              style={{ textDecoration: "none", color: "white" }}
            >
              View All{" "}
            </Link>
          </Button>*/}
        </div>
        {/* <div
          style={{
            borderBottom: "1px solid #e5e5e5",
            width: "100%",
            height: "0",
            marginBottom: "0.5rem",
          }}
        /> */}
        <div className="sliderrr shop-by-brand-slider">
          <Slider
            style={{ marginTop: "10px" }}
            ref={(c) => (slider.current = c)}
            {...settings}
          >
            {brandList.map((brand) => showBrand(brand))}
          </Slider>
          {mobileMatches && (
            <>
              <CustomIconButton
                className="arrowLeft"
                variant="contained"
                color="primary"
                onClick={() => {
                  setSettings((prevState) => {
                    return {
                      ...prevState,
                      speed: Math.max(750, prevState.speed - 200),
                      autoplaySpeed: Math.max(
                        1000,
                        prevState.autoplaySpeed - 200
                      ),
                    };
                  });
                  slider.current.slickPrev();
                }}
              >
                <ArrowBackIosNewIcon />
              </CustomIconButton>
              <CustomIconButton
                className="arrowRight"
                variant="contained"
                color="primary"
                onClick={() => {
                  setSettings((prevState) => {
                    return {
                      ...prevState,
                      speed: Math.max(750, prevState.speed - 200),
                      autoplaySpeed: Math.max(
                        1000,
                        prevState.autoplaySpeed - 200
                      ),
                    };
                  });
                  slider.current.slickNext();
                }}
              >
                <ArrowForwardIosIcon />
              </CustomIconButton>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

export default ShopByBrands;
