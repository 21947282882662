import React, { useRef } from "react";
import "./Testimonial.css";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    zIndex: "10",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: 'lightgray',
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

function Testimonial({ isLoading, testimonial }) {
  const slider = useRef();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  var settings = {
    // className: "center",
    // centerMode: true,
    // centerPadding: "60px",
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviewList =
    testimonial?.data.map((review) => ({
      id: review.id,
      name: review.name,
      place: "India",
      rating: review.rating,
      review: review.text,
    })) || [];

  const handlePrevSlide = () => {
    slider.current.slickPrev();
  };

  const handleNextSlide = () => {
    slider.current.slickNext();
  };
  console.log(testimonial);
  // })) || [];
  const viewReview = (data) => {
    return (
      <div key={`testimonial${data.id}`} className="reviewBox">
        <div className="reviewIconBox">
          <FormatQuoteIcon style={{ fontSize: "3rem" }} />
        </div>
        <div className="testimonial__ratingContainer">
          {[1, 2, 3, 4, 5].map((rating) =>
            rating <= data.rating ? (
              <StarIcon key={`star${rating}`} className="ratingIcon" />
            ) : (
              <StarBorderIcon key={`star${rating}`} className="ratingIcon" />
            )
          )}
        </div>
        <p
          className="review"
          dangerouslySetInnerHTML={{ __html: data.review }}
        ></p>
        <p className="name">{data.name}</p>
        <p className="place">{data.place}</p>
      </div>
    );
  };
  return (
    <section className="testimonial">
      <div className="section">
        <div className="testimonial__header">
          <h2>Take a look what customer says</h2>
        </div>
        <div className="sliderrr">
          <Slider
            style={{ width: "100%" }}
            ref={(c) => (slider.current = c)}
            {...settings}
          >
            {reviewList.map((review) => viewReview(review))}
          </Slider>
          {mobileMatches && (
            <>
              <CustomIconButton
                className="arrowLeft"
                variant="contained"
                color="primary"
                onClick={handlePrevSlide}
              >
                <ArrowBackIosNewIcon />
              </CustomIconButton>
              <CustomIconButton
                className="arrowRight"
                variant="contained"
                color="primary"
                onClick={handleNextSlide}
              >
                <ArrowForwardIosIcon />
              </CustomIconButton>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
