import React, { useEffect, useState } from "react";
import "./MyOrder.css";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { fetchOrders, fetchUser } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Stack } from "@mui/material";
import { fetchOrderDetail, fetchOrderList } from "../../../api";
import axios from "axios";
import { Helmet } from "react-helmet";

const orderStages = {
  3: "Payment Successful",
  4: "Dispatching Soon",
  5: "Deliverey Failed",
  6: "Cancelled By Customer",
  7: "Cancled By Seller",
  8: "Deliverey Address Changed",
  9: "Shipping Intiated",
  10: "Payment Refunded",
  11: "Payment Failed",
  18: "Order Return Requested",
  19: "Order Returned",
  20: "Order Devlivered",
};

function MyOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orders, user } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = React.useState(true);
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [data, setData] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  console.log(orders);

  const makeImages = (productImageLink, image) => {
    return productImageLink + "/" + image;
  };

  useEffect(() => {
    (async () => {
      const res = await fetchOrderList(user.id);
      console.log("this is order", res);

      setIsLoading(false);
      setData(res.data);
      console.log("this is data");
      console.log(data);
    })();
  }, [user]);

  return (
    <div className="myOrder section">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <Breadcrumbs
        sx={{ ml: mobileMatches ? 0 : 1 }}
        aria-label="breadcrumb"
        className="breadcum"
      >
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/orders">
          My Order
        </Link>
      </Breadcrumbs>
      <Grid sx={{ p: mobileMatches ? 0 : 1 }} container spacing={2}>
        <Grid item md={3} xs={12}>
          <Paper className="myOrder__menu" elevation={3}>
            <ul>
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/orders" className="active">
                  My Order
                </Link>
              </li>
              <li>
                <Link to="/appointment">My Appointment</Link>
              </li>
              <li>
                <Link to="/reviews">My Reviews & Rating</Link>
              </li>
              <li>
                <Link to="/tickets">Tickets</Link>
              </li>
            </ul>
          </Paper>
        </Grid>
        <Grid item md={9} xs={12}>
          {/* <h3 className="pageTitle">My Order</h3> */}
          {isLoading && (
            <Stack spacing={2}>
              <Skeleton variant="rect" height={200} />
              <Skeleton variant="rect" height={200} />
              <Skeleton variant="rect" height={200} />
              <Skeleton variant="rect" height={200} />
              <Skeleton variant="rect" height={200} />
              <Skeleton variant="rect" height={200} />
              <Skeleton variant="rect" height={200} />
            </Stack>
          )}
          {data.length === 0 ? (
            <h4 className="emptyCart">No Orders Placed</h4>
          ) : (
            data.map((order) =>
              order.bagItems.length > 0 ? (
                <Paper
                  className="myOrder__container"
                  elevation={3}
                  onClick={() =>
                    history.push(`./order-detail/${order.order_id}`)
                  }
                >
                  {order.bagItems.map((item, index) => {
                    return (
                      <div key={index} className="container">
                        <div className="content">
                          <div className="myOrder__productDetail">
                            <img
                              src={makeImages(
                                order.image_link,
                                item.item_photo
                              )}
                              alt=""
                            />
                            <div>
                              <h4>{item.product_title}</h4>
                              <p>Quantity: {item.quantity}</p>
                              <h4 className="price">
                                <FontAwesomeIcon
                                  icon={faRupeeSign}
                                  className="icon"
                                />{" "}
                                {item.price}
                              </h4>
                              <p>Shipping Status: {item.shipping_status}</p>
                            </div>
                          </div>
                          {/*  */}
                          <div className="myOrder__deliverySummary">
                            <h4>{item.order_date}</h4>
                            <p>{orderStages[order.status]}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Paper>
              ) : (
                <></>
              )
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default MyOrder;
