import React, { useState, useRef, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import VideocamIcon from "@mui/icons-material/Videocam";
import ForumIcon from "@mui/icons-material/Forum";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import UpdateIcon from "@mui/icons-material/Update";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Popper from "@material-ui/core/Popper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Backdrop } from "@mui/material";
import VideoCallWidget from "../../popups/VideoCallWidget";
import ChatWidget from "../../popups/ChatWidget";
import PrescriptionEditor from "../../popups/PrescriptionEditor";
import DateTimePicker from "../../popups/DateTmePicker";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "./index.css";
import PersonIcon from "@material-ui/icons/Person";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDoctorAppointments } from "../../../api";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { fetchUser } from "../../../store";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import PetReport from "../../popups/petReport";
import axios from "axios";
import { Helmet } from "react-helmet";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#08D0C7",
    color: theme.palette.common.white,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "5px",
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "13px",
  },
  menu: {
    minHeight: "auto",
  },
  menuText: {
    fontSize: "0.8em",
  },
  menuIcon: {
    color: "#08D0C7",
    fontSize: "0.8em",
  },
}));

export default function DoctorDashboard() {
  const { user } = useSelector((state) => state.menu);
  const [profileOpen, setProfileOpen] = useState(false);
  const anchorRef = useRef(null);
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [pagee, setPagee] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [backdropType, setBackdropType] = useState("");
  const open = Boolean(anchorEl);
  const [appointmentNumber, setAppointmentNumber] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [chatAccessToken, setChatAccessToken] = useState("");
  const [chatChannelSid, setChatChannelSid] = useState("");
  const [prescription, setPrescription] = useState("");
  const [petInfo, setPetInfo] = useState({});
  const [appointmentsFromApi, setAppointmentsFromApi] = useState([]);
  const [row, setRow] = useState({});
  const [metaInfo, setMetaInfo] = useState({});
  const [counter, setCounter] = useState(3540);
  const [unreadMessages, setUnreadMessages] = useState(-1);

  const countUnreadMessages = (messageCount) => {
    setUnreadMessages(messageCount);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPagee(newPage);
  };

  const reduceCounter = () => {
    setTimeout(() => setCounter(counter - 1), 1000);
  };

  let historyback = useHistory();
  const goToPreviousPath = () => {
    historyback.goBack();
  };

  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  useEffect(() => {
    if (user?.role_type) {
      if (user.role_type !== "10") history.push("/");
      (async () => {
        const appointmentsData = await getDoctorAppointments(user.id);
        if (!appointmentsData.error) {
          const appointmentList = appointmentsData.data
            .map((a) => ({
              ...a,
              tim: a.assign_datetime
                ? a.assign_datetime.split(" ")[1]
                : a.req_datetime.split(" ")[1],
              dat: a.assign_datetime
                ? new Date(a.assign_datetime.split(" ")[0])
                    .toString()
                    .split(" ")
                    .slice(1, 4)
                    .join("-")
                : new Date(a.req_datetime.split(" ")[0])
                    .toString()
                    .split(" ")
                    .slice(1, 4)
                    .join("-"),
              prob: a.name,
            }))
            .reverse();
          console.log("appointmentList", appointmentList);
          setAppointmentsFromApi(appointmentList);
          setRows(appointmentList);
        }
      })();
    }
  }, [user?.role_type]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPagee(0);
  };
  return (
    <>
      <div className="vet-dashboard">
        <Helmet>
          <title>{metaInfo.meta_title}</title>
        </Helmet>
        <div className="sidebar">
          <div
            style={{
              width: "170px",
              height: "160px",
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
              position: "fixed",
              top: "60px",
            }}
          >
            <img src={require("../../../image/logo-sm.png").default} alt="" />
            <img
              src={require("../../../image/logo-text-sm.png").default}
              alt=""
            />
          </div>
          <div style={{ width: "90%" }}>
            <h3>
              <center>Appointments</center>
            </h3>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Select Timeline</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setRows(
                        appointmentsFromApi.filter(
                          (a) => new Date(a.assign_datetime) > new Date()
                        )
                      );
                    }}
                  >
                    Upcoming Appointments
                  </p>
                  <div
                    style={{
                      height: "0px",
                      borderBottom: "1px solid grey",
                    }}
                  ></div>
                  <p
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setRows(
                        appointmentsFromApi.filter(
                          (a) => new Date(a.assign_datetime) < new Date()
                        )
                      );
                    }}
                  >
                    Attended Appointments
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="main-content">
          <div className="header">
            <h2 style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackRoundedIcon
                className="backto"
                onClick={goToPreviousPath}
                style={{ marginRight: "10px" }}
              />
              Dashboard
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>Dr {user.name}</h3>
              <PersonIcon style={{ paddingLeft: "10px" }} />
              {/* </IconButton> */}
            </div>
            <Popper
              open={profileOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: "1" }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => setProfileOpen((open) => !open)}
                    >
                      <Box>
                        <Stack
                          sx={{ p: 1, maxWidth: "200px" }}
                          direction="column"
                          alignItems="center"
                        >
                          <Avatar />
                          <h4 style={{ margin: "0px", textAlign: "center" }}>
                            {user?.name}
                          </h4>
                        </Stack>
                        <Divider />
                        <MenuList
                          autoFocusItem={profileOpen}
                          id="menu-list-grow"
                        >
                          <MenuItem onClick={() => handleClose("profile")}>
                            Profile
                          </MenuItem>
                          <MenuItem>Logout</MenuItem>
                        </MenuList>
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <div className="content">
            <div className="appointment-card-container">
              <div className="appointment-card">
                <h3 style={{ color: "white" }}>
                  <center>Total Appointments</center>
                </h3>
                <h1 style={{ color: "white" }}>
                  <center>{rows.length || 0}</center>
                </h1>
              </div>
              <div
                className="appointment-card"
                style={{
                  background: "#D2FCFB",
                }}
              >
                <h3>
                  <center>Upcomming Appointments</center>
                </h3>
                <h1>
                  <center>
                    {
                      rows?.filter(
                        (row) => new Date(row.assign_datetime) > new Date() || 0
                      ).length
                    }
                  </center>
                </h1>
              </div>
              <div
                className="appointment-card"
                style={{
                  background: "#FCD2D3",
                }}
              >
                <h3>
                  <center>Cancelled Appointments</center>
                </h3>
                <h1>
                  <center>
                    {rows.filter((row) => row.status === "Cancelled").length}
                  </center>
                </h1>
              </div>
            </div>
            <div
              style={{
                width: "86%",
                padding: "0 7%",
              }}
            >
              <Grid item lg={10} xs={11}>
                <Paper sx={{ p: 0 }} className="" elevation={3}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={{ width: 80 }} align="center">
                            Time
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: 100 }} align="center">
                            Date
                          </StyledTableCell>
                          <StyledTableCell>Pet Parent Name</StyledTableCell>
                          <StyledTableCell
                            sx={{ width: mobileMatches ? 160 : "" }}
                            align="center"
                          >
                            {mobileMatches ? "Action" : ""}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .slice(
                            pagee * rowsPerPage,
                            pagee * rowsPerPage + rowsPerPage
                          )
                          .map((row, i) => {
                            return (
                              <StyledTableRow key={i}>
                                <StyledTableCell align="center">
                                  {row.tim}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.dat}
                                </StyledTableCell>
                                <StyledTableCell>{row.prob}</StyledTableCell>
                                <StyledTableCell align="center">
                                  {mobileMatches ? (
                                    <div
                                      style={{ width: "30%", display: "flex" }}
                                    >
                                      <Tooltip title="Video Call with Customer">
                                        <IconButton
                                          onClick={() => {
                                            if (row.assign_status !== 1)
                                              return toast.info(
                                                "The appointment session has not been confirmed yet!"
                                              );
                                            if (
                                              new Date() <
                                              new Date(row.assign_datetime)
                                            )
                                              return toast.info(
                                                "The appointment session has not started yet"
                                              );
                                            if (
                                              (new Date() -
                                                new Date(row.assign_datetime)) /
                                                60000 >=
                                              30
                                            )
                                              return toast.info(
                                                "The appointment session has expired!"
                                              );
                                            setAppointmentNumber(
                                              row.appointment_number
                                            );
                                            setCustomerId(row.customer_id);
                                            setBackdropOpen(true);
                                            setBackdropType("video");
                                          }}
                                          size="small"
                                        >
                                          <VideocamIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          "Chat with Customer " + unreadMessages
                                        }
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            console.log(row);
                                            if (row.assign_status !== 1)
                                              return toast.info(
                                                "The appointment session has not been confirmed yet!"
                                              );
                                            if (
                                              (new Date() -
                                                new Date(row.assign_datetime)) /
                                                600000 >=
                                              1008
                                            )
                                              return toast.info(
                                                "Session has expired!"
                                              );
                                            setBackdropOpen(true);
                                            setChatAccessToken(row.token);
                                            setChatChannelSid(row.channel?.sid);
                                            setBackdropType("chat");
                                          }}
                                          size="small"
                                        >
                                          <ForumIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Prescribe">
                                        <IconButton
                                          onClick={() => {
                                            setBackdropOpen(true);
                                            setAppointmentNumber(
                                              row.appointment_number
                                            );
                                            setCustomerId(row.customer_id);
                                            setBackdropType("prescription");
                                            setPrescription(
                                              row.reports &&
                                                row.reports.includes(".pdf")
                                                ? row.reports
                                                : null
                                            );
                                          }}
                                          size="small"
                                        >
                                          <CloudDownloadIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Reports">
                                        <IconButton
                                          onClick={() => {
                                            setBackdropOpen(true);
                                            setBackdropType("report");
                                            setPetInfo(
                                              row.pets && row.pets.length
                                                ? row.pets[0]
                                                : {}
                                            );
                                          }}
                                          size="small"
                                        >
                                          <AssignmentIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    <>
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls="long-menu"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(e) => {
                                          setRow(row);
                                          handleClick(e);
                                        }}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    </>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() => {
                              console.log(row);
                              if (row.assign_status !== 1)
                                return toast.info(
                                  "The appointment session has not been confirmed yet!"
                                );
                              if (new Date() < new Date(row.assign_datetime))
                                return toast.info(
                                  "The appointment session has not started yet"
                                );
                              if (
                                (new Date() - new Date(row.assign_datetime)) /
                                  60000 >=
                                30
                              )
                                return toast.info(
                                  "The appointment session has expired!"
                                );
                              setAppointmentNumber(row.appointment_number);
                              setCustomerId(row.customer_id);
                              setBackdropOpen(true);
                              setBackdropType("video");
                              handleClose();
                            }}
                            className={classes.menu}
                          >
                            <VideocamIcon className={classes.menuIcon} /> &nbsp;{" "}
                            <span className={classes.menuText}>Video Call</span>
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              if (row.assign_status !== 1)
                                return toast.info(
                                  "The appointment session has not been confirmed yet!"
                                );
                              if (
                                (new Date() - new Date(row.assign_datetime)) /
                                  600000 >=
                                1008
                              )
                                return toast.info("Session has expired!");
                              setBackdropOpen(true);
                              setChatAccessToken(row.token);
                              setChatChannelSid(row.channel?.sid);
                              setBackdropType("chat");
                              handleClose();
                            }}
                            className={classes.menu}
                          >
                            <ForumIcon className={classes.menuIcon} /> &nbsp;{" "}
                            <span className={classes.menuText}>
                              Chat with Customer
                            </span>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setBackdropOpen(true);
                              setAppointmentNumber(row.appointment_number);
                              setCustomerId(row.customer_id);
                              setBackdropType("prescription");
                              handleClose();
                            }}
                            className={classes.menu}
                          >
                            <CloudDownloadIcon className={classes.menuIcon} />{" "}
                            &nbsp;{" "}
                            <span className={classes.menuText}>Prescribe</span>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setBackdropOpen(true);
                              setBackdropType("report");
                              setPetInfo(
                                row.pets && row.pets.length ? row.pets[0] : {}
                              );
                            }}
                            className={classes.menu}
                          >
                            <AssignmentIcon className={classes.menuIcon} />{" "}
                            &nbsp;{" "}
                            <span className={classes.menuText}>Reports</span>
                          </MenuItem>
                        </Menu>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={pagee}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        {backdropType === "video" && (
          <VideoCallWidget
            isCustomer={false}
            appointmentNumber={appointmentNumber}
            customerId={customerId}
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
            counter={counter}
            reduceCounter={reduceCounter}
          />
        )}
        {backdropType === "chat" && (
          <ChatWidget
            token={chatAccessToken}
            channelSid={chatChannelSid}
            closeBackdrop={() => {
              setBackdropType("");
              setBackdropOpen(false);
            }}
            setUnreadMessages={countUnreadMessages}
          />
        )}
        {backdropType === "prescription" && (
          <PrescriptionEditor
            editable={true}
            appointmentNumber={appointmentNumber}
            customerId={customerId}
            prescription={prescription}
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
          />
        )}
        {backdropType === "reschedule" && (
          <DateTimePicker
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
          />
        )}
        {backdropType === "report" && (
          <PetReport
            petInfo={petInfo}
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
          />
        )}
      </Backdrop>
    </>
  );
}
