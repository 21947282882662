import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Menu from '@mui/material/Menu';
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { fetchCancelForm, fetchReview } from "../../../api";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useHistory } from "react-router";
import { fetchOrderStatus } from "../../../api";
import { red } from "@material-ui/core/colors";

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const steps = ["Order Placed", "Order Dispatched", "Out for Delivery"];

const orderStages = {
  3: "Payment Successful",
  4: "Dispatching Soon",
  5: "Deliverey Failed",
  6: "Cancelled By Customer",
  7: "Cancled By Seller",
  8: "Deliverey Address Changed",
  9: "Shipping Intiated",
  10: "Payment Refunded",
  11: "Payment Failed",
  18: "Order Return Requested",
  19: "Order Returned",
  20: "Order Devlivered",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const names = [
  "Bought it from other place",
  "Wrong delivery location",
  "Over priced",
  "Want to change shipping address",
  "Item not required anymore"
];

function getStyles(name, theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular,
  };
}

const BootstrapButton1 = withStyles({
  root: {
    color: "#09D1C7",
    fontWeight: "bold",
    width: "160px",
    marginBottom: "10px",
    fontFamily: ["Arial"].join(","),
    "&:active": {
      boxShadow: "none",
    },
  },
})(Button);

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    width: "160px",
    marginBottom: "10px",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const BootstrapButton3 = withStyles({
  root: {
    border: "1px solid",
    color: "#000",
    width: "160px",
    marginBottom: "10px",
    //   backgroundColor: '#09D1C7',
    borderColor: "#000",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      borderColor: "#000",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: "#000",
    },
  },
})(Button);

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const useStyles = makeStyles((theme) => ({
  // textField:{
  //     marginBottom: '10px',
  // },
  blur: {
    position: "relative",
    backdropFilter: "blur(10px)",
  },
}));

function ProductDetail({
  order,
  imageLink,
  shippedStatus,
  currentOrderStatus,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [orderDetail, setOrderDetail] = React.useState(order);
  const [sub_order, setSub_Order] = React.useState("");
  const [product, setProduct] = React.useState("");
  const [productDetails, setProductDetails] = useState({});
  const { user } = useSelector((state) => state.menu);
  const [openTicket, setOpenTicket] = React.useState(false);
  const [trackingUrl, setTrackingUrl] = React.useState("");
  const [cancelling, setCancelling] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  console.log(user.id);
  useEffect(() => {
    (async () => {
      if (order.order_id) {
        const res = await fetchOrderStatus(order.order_id, user.id);
        setTrackingUrl(res.data.pop()?.tracking_data?.track_url);
        setStatus(res.data);
      }
    })();
  }, [order.order_id]);

  const handleClickOpenTicket = () => {
    setOpenTicket(true);
  };

  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = async () => {
    const data = {
      userId: user.id,
      order_id: order.order_id,
      cancel_reason: reason,
      sub_order_id: sub_order,
    };
    console.log(data);
    const res = await fetchCancelForm(data);
    console.log(res.error);
    if (res.error) {
      toast.error(res.message);
    } else {
      toast.info("order cancelled successfully");
      history.goBack();
    }
  };

  const handleReview = async () => {
    const data = {
      product_id: product,
      review_title: title,
      review_text: description,
      userId: user.id,
      rating: value,
      type: "0",
    };
    const res = await fetchReview(data);
    if (res.error) {
      toast.error(res.message);
    } else {
      toast.info("reviewed  successfully");
      history.goBack();
    }
  };

  const theme = useTheme();
  const history = useHistory();
  const [openFeedbackForm, setOpenFeedbackForm] = useState(false);
  const [openFilledFeedbackForm, setOpenFilledFeedbackForm] = useState(false);
  const [openReturnForm, setOpenReturnForm] = useState(false);
  const [openCancelForm, setOpenCancelForm] = useState(false);
  const [reason, setReason] = useState("Reason");

  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);

  console.log('reason',reason);
  
  return (
    <>
      {order?.bagItems?.map((detail) => (
        <div className="content orderDe">
          <div className="myOrder__productDetail">
            <img src={imageLink + "/" + detail.item_photo} alt="" />

            <div>
              <h4
                style={{
                  marginTop: "10px",
                  maxWidth: "200px",
                  minWidth: "200px",
                }}
              >
                {detail.product_title}
              </h4>
              <p style={{ marginTop: "5px" }}>Quantity: {detail.quantity}</p>
              <h4 className="price " style={{ marginTop: "5px" }}>
                <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                {detail.price}
              </h4>
            </div>
          </div>
          <div className="stepper">
            <Button variant="contained" style={{ marginBottom: "10px" }}>
              <a
                href={trackingUrl}
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <strong>Track Package Status</strong>
              </a>
            </Button>
            {shippedStatus && (
              <Button variant="contained" style={{ marginBottom: "10px" }}>
                <a
                  href={detail.invoice_url}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <strong>
                    &nbsp;&nbsp;&nbsp;Download Invoice&nbsp;&nbsp;&nbsp;
                  </strong>
                </a>
              </Button>
            )}
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              >
                Order Status
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  OK
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </div>
          <div className="myOrder__deliverySummary">
            {/* {!(
              currentOrderStatus === 6 ||
              currentOrderStatus === 7 ||
              currentOrderStatus === 9 ||
              currentOrderStatus === 10 ||
              currentOrderStatus === 11 ||
              currentOrderStatus === 20
            ) ? (
              <BootstrapButton2
                onClick={() => {
                  setSub_Order(detail.sub_order_id);
                  console.log("this is suorderid");
                  console.log(detail);
                  setOpenCancelForm(true);
                }}
              >
                Cancel Item
              </BootstrapButton2>
            ) : (
              <BootstrapButton2>
                {orderStages[currentOrderStatus]}
              </BootstrapButton2>
            )} */}
            {shippedStatus &&
              (detail.reviews_ratings ? (
                <BootstrapButton1
                  onClick={() => {
                    setOpenFeedbackForm(true);
                    setProduct(detail.product_id);
                    setTitle(detail.reviews_ratings.review_title);
                    setDescription(detail.reviews_ratings.review_text);
                    setValue(detail.reviews_ratings.rating);
                  }}
                >
                  <StarIcon /> Edit Rating
                </BootstrapButton1>
              ) : (
                <BootstrapButton1
                  onClick={() => {
                    setOpenFeedbackForm(true);
                    setProduct(detail.product_id);
                  }}
                >
                  <StarIcon /> Rate & Review
                </BootstrapButton1>
              ))}

            {/* <BootstrapButton1
              onClick={() => {
                setOpenFeedbackForm(true);
                setProduct(detail.product_id);
              }}
            >
              <StarIcon /> Rate & Review
            </BootstrapButton1> */}

            {/* <BootstrapButton1 onClick={handleClickOpenTicket}>
              <ConfirmationNumberIcon />
              Raise Ticket
            </BootstrapButton1>
            <Dialog
              open={openTicket}
              onClose={handleCloseTicket}
              fullWidth={true}
              maxWidth="sm"
            >
              <DialogTitle>Raise Ticket form</DialogTitle>
              <DialogContent>
                <h3 className="reviewTitle">Raise a Ticket for this product</h3>
                <CssTextField
                  style={{ width: "100%", marginBottom: "10px" }}
                  label="Title"
                  size="small"
                  // onChange={(event) => setTitle(event.target.value)}
                />
                <CssTextField
                  style={{ width: "100%" }}
                  label="Description"
                  multiline
                  rows={4}
                  size="small"
                  // onChange={(event) => setDescription(event.target.value)}
                />
                <br />
                <br />
                <label htmlFor="contained-button-file">
                  <input
                    accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf"
                    id="outlined-size-small"
                    type="file"
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      setProductDetails({
                        ...productDetails,
                        attachment: e.target.files[0],
                      });
                    }}
                  />
                </label>
              </DialogContent>
              <DialogActions
                style={{ display: "flex", alignItems: "baseline" }}
              >
                <Button onClick={handleCloseTicket}>Cancel</Button>
                <Button
                  onClick={handleCloseTicket}
                  variant="contained"
                  className="modal_btn"
                  onClick={handleReview}
                  style={{ backgroundColor: "#09D1C7", color: "white" }}
                >
                  Raise Ticket
                </Button>
              </DialogActions>
            </Dialog> */}
          </div>

          <Dialog
            open={openFeedbackForm}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="sm"
            keepMounted
            onClose={() => setOpenFeedbackForm(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Rate & Review Form"}</DialogTitle>
            <DialogContent>
              <h3 className="reviewTitle">Review this product</h3>
              <CssTextField
                style={{ width: "100%", marginBottom: "10px" }}
                label="Title"
                size="small"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
              <CssTextField
                style={{ width: "100%" }}
                label="Description"
                multiline
                rows={4}
                size="small"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
              <h3 className="reviewTitle">Rate this product</h3>
              <div className="reviewRateBox">
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={0.5}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                {value !== null && (
                  <Box sx={{ ml: 2 }}>
                    {labels[hover !== -1 ? hover : value]}
                  </Box>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                className="modal_btn"
                onClick={() => setOpenFeedbackForm(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="modal_btn"
                onClick={handleReview}
                style={{ backgroundColor: "#09D1C7", color: "white" }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openFilledFeedbackForm}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="sm"
            keepMounted
            onClose={() => setOpenFilledFeedbackForm(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Your Review"}</DialogTitle>
            <DialogContent>
              <h3 className="reviewTitle">Review Title</h3>
              <div className="reviewRateBox">
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={0.5}
                  readOnly
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                {value !== null && (
                  <Box sx={{ ml: 2 }}>
                    {labels[hover !== -1 ? hover : value]}
                  </Box>
                )}
              </div>
              <p>
                Magna aliquip dolore nulla fugiat ullamco proident excepteur. Ut
                aute dolor fugiat et Lorem exercitation elit mollit non elit ad
                sit. Pariatur proident elit ullamco duis. Commodo id aute ea ad.
                Velit duis voluptate consectetur consectetur tempor velit ex.
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenFilledFeedbackForm(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openReturnForm}
            TransitionComponent={Transition}
            fullWidth={false}
            maxWidth="sm"
            keepMounted
            onClose={() => setOpenReturnForm(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Return Form"}</DialogTitle>
            <DialogContent>
              <FormControl sx={{ m: 1, width: 300 }}>

              <div>
                <Button
                  id="demo-customized-button"
                  aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {reason}
                </Button>
                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                >
                  {names.map((name) => (
                    <MenuItem onClick={handleCloseMenu} disableRipple>
                    {name}
                  </MenuItem>
                  ))}
                </Menu>
              </div>
                {/* <InputLabel id="demo-multiple-name-label">Reason</InputLabel>
                <Menu
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  input={<OutlinedInput label="Reason" />}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
              <h5 style={{ fontWeight: "500", marginBottom: "10px" }}>
                Need Help?
              </h5>
              <p style={{ margin: "0px" }}>Write us at hello@pettofit.com</p>
              {/* <TextareaAutosize style={{width: '99%'}} minRows={4} placeholder="Description" /> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenReturnForm(false)}>Cancel</Button>
              <Button>Replace</Button>
              <Button>Return</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openCancelForm}
            TransitionComponent={Transition}
            fullWidth={false}
            maxWidth="sm"
            keepMounted
            onClose={() => setOpenCancelForm(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Cancel Form"}</DialogTitle>
            <DialogContent>
              <FormControl sx={{ m: 1, width: 300 }}>
              <div>
                <Button
                  id="demo-customized-button"
                  aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={{minWidth:'300px',backgroundColor:'white',border:'1px solid #d9d9d9',borderRadius:'4px'}}
                >
                  {reason}
                </Button>
                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                >
                  {names.map((name) => (
                    <MenuItem onClick={()=>{setReason(name);handleCloseMenu();}} disableRipple>
                    {name}
                  </MenuItem>
                  ))}
                  <div style={{paddingLeft:'15px'}}>
                  <TextField id="other-reason" label="Other" onChange={(e1)=>{setReason(e1.target.value)}} variant="outlined" />
                  </div>
                </Menu>
              </div>
              </FormControl>
              <h5 style={{ fontWeight: "500", marginBottom: "10px" }}>
                Need Help?
              </h5>
              <p style={{ margin: "0px" }}>Write us at hello@pettofit.com</p>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={() => setOpenCancelForm(false)}>Cancel</Button> */}
              <Button
                // variant="outlined"
                variant="contained"
                className="modal_btn"
                disabled={cancelling}
                onClick={async () => {
                  setCancelling(true);
                  await handleCancel();
                  setCancelling(false);
                }}
              >
                Cancel Order
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ))}
    </>
  );
}

export default ProductDetail;
