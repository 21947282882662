import React, { useState, useEffect } from "react";
import "./Brands.css";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { Helmet } from "react-helmet";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Brands() {
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  const history = useHistory();
  return (
    <div className="brands section">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <Box sx={{ flexGrow: 1, p: 1 }}>
        <h2>All Brands</h2>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          {Array.from(Array(16)).map((_, index) => (
            <Grid item xs={4} sm={2} md={2} key={index}>
              <Item
                className="brandContainer"
                onClick={() => history.push("/")}
              >
                <img
                  className="brandImage"
                  src={
                    "https://1000logos.net/wp-content/uploads/2020/09/Pedigree-Logo.jpg"
                  }
                  alt=""
                />
                <h4 className="brandName">Brand Name</h4>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* <Grid container spacing={3}>
            <Grid item md={6} xs={12} component={Paper}>
                    <img src={'https://1000logos.net/wp-content/uploads/2020/09/Pedigree-Logo.jpg'} alt="" />
                    <h4>Brand Name</h4>
            </Grid>
            <Grid item md={6} component={Paper}>
                    <img src={'https://1000logos.net/wp-content/uploads/2020/09/Pedigree-Logo.jpg'} alt="" />
                    <h4>Brand Name</h4>
            </Grid>
            </Grid> */}
    </div>
  );
}

export default Brands;
