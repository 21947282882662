import React, { useState, useEffect } from "react";
import Chat, { Bubble, useMessages } from "@chatui/core";
import "@chatui/core/dist/index.css";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { headers } from "../api";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function TicketChat() {
  const { messages, appendMsg } = useMessages([]);
  const history = useHistory();
  const { id } = useParams();
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  useEffect(() => {
    if (!id) history.push("/tickets");
    else {
      fetchTicketMessages();
    }
  }, []);

  const fetchTicketMessages = async () => {
    try {
      const res = await axios.get(
        `https://api.pettofit.com/api/v1/get-ticket-chat?ticket_id=${id}`,
        {
          headers: headers,
        }
      );
      if (res) {
        res.data.data.forEach((m) =>
          appendMsg({
            type: "text",
            position: m.msg_by === "1" ? "right" : "left",
            date: new Date(m.created_at),
            content: { text: m.msg },
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function handleSend(type, val) {
    if (type === "text" && val.trim()) {
      try {
        await axios.post(
          "https://api.pettofit.com/api/v1/add-ticket-chat",
          {
            ticket_id: id,
            msg: val,
          },
          {
            headers: headers,
          }
        );
        appendMsg({
          type: "text",
          content: { text: val },
          position: "right",
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  }

  function renderMessageContent(msg) {
    const { content } = msg;
    console.log(content);
    return (
      <Bubble
        style={{
          backgroundColor: msg.position === "right" ? "#13D3C9" : "#fff",
          color: msg.position === "right" ? "#fff" : "#000",
        }}
        content={content?.text}
      />
    );
  }

  return (
    <div>
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <Chat
        navbar={{ title: id }}
        locale="en-US"
        messages={messages}
        placeholder="Type a message..."
        renderMessageContent={renderMessageContent}
        onSend={handleSend}
      />
    </div>
  );
}
