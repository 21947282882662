import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";
import "./styles.css";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { headers } from "../../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const BootstrapButton3 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      borderColor: "#09D1C7",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: "#09D1C7",
    },
  },
})(Button);

function DateTimePicker({
  closeBackdrop,
  appointmentReason,
  appointmentNumber,
  fetchAppointments,
}) {
  const { user } = useSelector((state) => state.menu);
  const [value, setValue] = React.useState(new Date());
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const [reason, setReason] = useState(appointmentReason);
  const [selectedTime, setSelectedTime] = useState("");
  const timeSlots = Array.from(new Array(24 * 2)).map(
    (_, index) =>
      `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
        index % 2 === 0 ? "00" : "30"
      }`
  );

  const rescheduleAppointment = async () => {
    axios
      .post(
        "https://api.pettofit.com/api/v1/reschudule-appointment",
        {
          userId: user.id,
          problem: reason,
          req_datetime:
            new Date().toISOString().split("T")[0] + " " + selectedTime,
          appointment_type: 1,
          appointment_number: appointmentNumber,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        toast.success("Appointment Rescheduled Successfully");
        closeBackdrop();
        fetchAppointments();
      })
      .catch((err) => {
        toast.error("Something went wrong");
        closeBackdrop();
      });
  };

  return (
    <div
      className="conatiner-date-time-picker"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle>Reschedule Appointment</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: "15px" }}></DialogContentText>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="Date desktop"
              inputFormat="MM/dd/yyyy"
              minDate={new Date()}
              value={value}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
        <br />
        <Autocomplete
          id="disabled-options-demo"
          options={timeSlots}
          onChange={(e) => setSelectedTime(e.target.value)}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField {...params} label="Select Time Slot" />
          )}
        />
        <textarea
          style={{
            width: "100%",
            height: "200px",
            marginTop: "20px",
            border: "1px solid #e5e5e5",
            outline: "none",
            fontSize: "16px",
          }}
          onChange={(e) => setReason(e.target.value)}
        >
          {appointmentReason}
        </textarea>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={closeBackdrop}
          className="modal_btn"
        >
          Cancel
        </Button>
        <BootstrapButton3
          variant="outlined"
          className="modal_btn"
          onClick={rescheduleAppointment}
        >
          Book
        </BootstrapButton3>
      </DialogActions>
    </div>
  );
}

export default DateTimePicker;
