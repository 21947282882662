import { Breadcrumbs, Grid, Paper, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchTicketList } from "../../../api";
import axios from "axios";
import { Helmet } from "react-helmet";

function Tickets() {
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const { user, isLoggedIn } = useSelector((state) => state.menu);
  const [userTickets, setUserTickets] = React.useState([]);
  const history = useHistory();
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);

  const fetchTickets = async () => {
    const res = await fetchTicketList(user.id);
    setUserTickets(res.data);
  };

  useEffect(() => {
    if (isLoggedIn) fetchTickets();
  }, [isLoggedIn]);

  return (
    <>
      <div className="myOrder section">
        <Helmet>
          <title>{metaInfo.meta_title}</title>
        </Helmet>
        <Breadcrumbs
          sx={{ ml: mobileMatches ? 0 : 1 }}
          aria-label="breadcrumb"
          className="breadcum"
        >
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/tickets">
            Tickets
          </Link>
        </Breadcrumbs>
        <Grid sx={{ p: mobileMatches ? 0 : 1 }} container spacing={2}>
          <Grid item md={3} xs={12}>
            <Paper className="myOrder__menu" elevation={3}>
              <ul>
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li>
                  <Link to="/orders">My Order</Link>
                </li>
                <li>
                  <Link to="/appointment">My Appointment</Link>
                </li>
                <li>
                  <Link to="/reviews">My Reviews & Rating</Link>
                </li>
                <li>
                  <Link to="/tickets" className="active">
                    Tickets
                  </Link>
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item md={9} xs={12}>
            {userTickets?.map((ticket) => (
              <Paper
                sx={{ padding: "1rem", cursor: "pointer" }}
                onClick={() => {
                  history.push(`/ticket-chat/${ticket.ticket_number}`);
                }}
                elevation={2}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h3>{ticket.ticket_number}</h3>
                    <p>{ticket.subject}</p>
                    <p>{ticket.meta_description}</p>
                  </div>
                  <img src={ticket.attachment} alt="ticket" />
                </div>
              </Paper>
            ))}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Tickets;
