import React, { useEffect, useRef } from "react";
import "./styles.css";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Send } from "@mui/icons-material/";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Client } from "twilio-chat";
import axios from "axios";
import { fetchTwilioToken } from "../../../api";
import { useSelector } from "react-redux";

function ChatWidget({ closeBackdrop, token, channelSid, setUnreadMessages }) {
  const { user } = useSelector((state) => state.menu);
  const [connecting, setConnecting] = React.useState(true);
  const [messages, setMessages] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [channel, setChannel] = React.useState(null);
  const [client, setClient] = React.useState(null);
  const inputRef = useRef();

  const handleMessageAdd = ({ state }) => {
    setMessages((m) => [...m, state]);
  };

  const connectToTwilioChat = async () => {
    const data = await fetchTwilioToken(user.email);
    const client = new Client(data.token);
    client.on("stateChanged", (state) => {
      if (state === "initialized") {
        setClient(client);
        console.log(channelSid);
        client
          .getChannelBySid(channelSid)
          .then(async (channel) => {
            setConnecting(false);
            channel.on("messageAdded", (message) => {
              handleMessageAdd(message);
            });
            channel.getMessages().then((messages) => {
              console.log(messages);
              setMessages(messages.items.map((message) => message.state));
            });
            setChannel(channel);
            const unreadMessages = await channel.getUnconsumedMessagesCount();
            console.log("unreadMessages", unreadMessages);
            setUnreadMessages(unreadMessages);
          })
          .catch(() => {
            setError(true);
            setConnecting(false);
          });
      }
    });
  };

  useEffect(() => {
    connectToTwilioChat();
  }, []);

  useEffect(() => {
    document
      .querySelector(".chat-body")
      ?.scrollTo(0, document.querySelector(".chat-body").scrollHeight);
  }, [messages]);

  return (
    <div
      className="container-chat"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="chat-header">
        <h1>Chat with vet</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              transform: "rotate(45deg)",
              borderRadius: "50%",
              fontSize: "2rem",
              padding: "0.5rem",
              cursor: "pointer",
            }}
            onClick={closeBackdrop}
          >
            +
          </div>
        </div>
      </div>
      {connecting && (
        <div
          className="chat-body"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "95%",
          }}
        >
          <h3>Connecting...</h3>
        </div>
      )}
      {error && (
        <div
          className="chat-body"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
          }}
        >
          <h3>
            Something is wrong with the server! Please try again in some time.
          </h3>
        </div>
      )}
      {!connecting && !error && (
        <>
          <div className="chat-body">
            <div className="chat-messages">
              {messages.map((message) => (
                <div
                  className={`message ${
                    message.author == user.email
                      ? "local-message"
                      : "remote-message"
                  }`}
                >
                  {message.body}
                </div>
              ))}
            </div>
          </div>
          <div className="chat-footer">
            <Paper
              component="form"
              onSubmit={(e) => {
                if (!channel) return;
                e.preventDefault();
                channel.sendMessage(e.target[0].value);
                e.target[0].value = "";
              }}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                ref={inputRef}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter Message"
                inputProps={{ "aria-label": "Enter Message" }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={(e) => {
                  if (!channel) return;
                  channel.sendMessage(inputRef.current.children[0].value);
                  inputRef.current.children[0].value = "";
                }}
              >
                <Send />
              </IconButton>
            </Paper>
          </div>
        </>
      )}
    </div>
  );
}

export default ChatWidget;
