import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Container } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { fetchOrderDetail } from "../api/index";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function Invoice() {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { user } = useSelector((state) => state.menu);
  const [page, setPage] = React.useState(0);
  const [inv, setInv] = React.useState({});
  const params = useParams();
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getPdf = () => {
    var doc = new jsPDF("p", "mm", "a4");
    // All units are in the set measurement for the document
    // This can be changed to "pt" (points), "mm" (Default), "cm", "in"
    doc.html(document.getElementById("downlodad-inv"), {
      height: "15",
      width: "15",
      callback: function (doc) {
        doc.save();
      },
    });
  };

  useEffect(() => {
    if (user.id)
      (async () => {
        console.clear();
        const res = await fetchOrderDetail(params.id, user.id);
        setInv(res.data);
        console.log(res.data);
      })();
  }, [user.id]);

  // Example data (invoice items)
  const invoiceItems = [
    {
      Qty: 1,
      Price: 84.99,
      Taxrate: "10%",
      Tax: 8.49,
      Total: 93.48,
      currency: "USD",
      Name: "Gaming Headset",
    },
    {
      Qty: 2,
      Price: 99.99,
      Taxrate: "10%",
      Tax: 9.99,
      Total: 109.98,
      currency: "USD",
      Name: "Gaming Controller",
    },
  ];

  const reducer = (acc, value) => acc + value;

  console.log("jisoo", Object.keys(invoiceItems[0]));
  console.log("lisa", invoiceItems.map((item) => item.Name).sort());

  return (
    <div style={{ margin: "1.5%" }}>
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <Container
        maxWidth="md"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "2%" }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="text" onClick={getPdf}>
            <DownloadIcon />
            Download
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../image/logo-sm.png").default}
              alt=""
              style={{ width: "80px" }}
            />
            <img
              src={require("../image/logo-text-sm.png").default}
              alt=""
              style={{ width: "80px" }}
            />
          </div>
          <div>
            <p>
              <strong>Invoice Number</strong> : {inv.invoice_no}
            </p>
          </div>
        </div>
        <div id="downlodad-inv">
          <h2 style={{ textAlign: "center" }}>
            <Divider>Invoice</Divider>
          </h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h3>Sold By :</h3>
              <p>PETTOFIT</p>
            </div>
            <div>
              {/* <h2>Invoice</h2> */}
              <h3>Billing Address :</h3>
              <p>
                {inv.bill_name}
                <br />
                {inv.bill_address}
                <br />
                {inv.bill_city}- {inv.bill_pincode}
                <br />
                {inv.bill_state} {inv.bill_country}
                <br />
                {inv.bill_mobile}
              </p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h3>Shipping Address :</h3>
              <p>
                {inv.ship_address}
                <br />
                {inv.ship_mobile}
                <br />
                {inv.ship_city}
                <br />
                {inv.ship_country}
              </p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>
                <strong>ORDER NO :</strong> {inv.order_id}
              </p>
              <p>
                <strong>ORDER DATE :</strong> {inv.date}
              </p>
            </div>
            <div>
              <p>
                <strong>Place of Supply : </strong>GUJARAT
              </p>
              <p>
                <strong>Place of Delivery : </strong>
                {inv.ship_city}
              </p>
            </div>
          </div>
          <Paper>
            <TableContainer style={{ overflow: "hidden" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">S No</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {inv.bagItems?.map((item, i) => {
                    console.log(item);
                    return (
                      <TableRow key={i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell align="right">
                          {item.product_title}
                        </TableCell>
                        <TableCell align="right">{item.quantity} </TableCell>
                        <TableCell align="right">{item.price}</TableCell>
                        <TableCell align="right">
                          {item.price * item.quantity}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>Shippment:</strong>
                    </TableCell>
                    <TableCell align="right">
                      {inv.total_shipping_amount}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>Tax:</strong>
                    </TableCell>
                    <TableCell align="right">{inv.total_tax}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <strong>Total Bill:</strong>
                    </TableCell>
                    <TableCell align="right">
                      {inv.total_payable_amount}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginRight: "10px",
              }}
            ></div>
            <div
              style={{
                marginLeft: "15px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                marginRight: "10px",
              }}
            >
              <div>
                <h3>For PETTOFIT PRIVATE LIMITED:</h3>
              </div>
              <div>
                <img src="https://dummyimage.com/200x80/000/fff" />
              </div>
              <div>
                <h3>Authorized Signature</h3>
              </div>
            </div>
          </Paper>
          <TableContainer style={{ overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h3>Payment Transsaction Id</h3>
                  </TableCell>
                  <TableCell>
                    <h3>Invoice Date</h3>
                  </TableCell>
                  <TableCell>
                    <h3>Invoice Value</h3>
                  </TableCell>
                  <TableCell>
                    <h3>Mode of Payment</h3>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>{inv.invoice_no}</p>
                  </TableCell>
                  <TableCell>
                    <p>{inv.payment_date}</p>
                  </TableCell>
                  <TableCell>
                    <p>{inv.total_payable_amount}</p>
                  </TableCell>
                  <TableCell>
                    <p>{inv.payment_mode}</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
    </div>
  );
}
