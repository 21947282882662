import React, { useEffect, useState } from "react";
import "./Home.css";
import MainSlider from "./MainSlider";
import Category from "./Category";
import PopularProducts from "./PopularProducts";
import ShopByBrands from "./ShopByBrands";
import CatProducts from "./CatProducts";
import SliderBanner from "./SliderBanner";
import DogProducts from "./DogProducts";
import Testimonial from "../Testimonial";
import DealOfTheDay from "./DealOfTheDay";
import RecentView from "./RecentView";
import Blog from "./Blog";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import fetchHomePage from "../../services/http/homepageService";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Helmet } from "react-helmet";
import axios from "axios";
function Home() {
  const history = useHistory();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(true);
  const [homePage, setHomePage] = useState({});
  const [vetBannerLoaded, setVetBannerLoaded] = useState(false);
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    (async () => {
      const homePage = await fetchHomePage();
      setHomePage(homePage);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);

  return (
    <div
      className="home"
      style={{
        background: "#F9F9Fd",
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      {!isLoading && (
        <>
          <MainSlider slides={homePage?.home_sliders} />
          {!isLoading && (
            <Category
              isLoading={isLoading}
              categoriesData={homePage?.shop_by_category}
            />
          )}
          {!homePage?.dog_products.error && (
            <DogProducts
              isLoading={isLoading}
              products={homePage?.dog_products}
            />
          )}
          {!homePage?.deals_of_the_day.error && (
            <DealOfTheDay products={homePage?.deals_of_the_day} />
          )}
          {!homePage?.vet_banner?.error && (
            <section
              style={{
                paddingTop: 0,
                paddingBottom: 0,
              }}
              className="section banner2"
            >
              <img
                onClick={() => history.push("/vet")}
                onLoad={() => setVetBannerLoaded(true)}
                src={
                  !mobileMatches
                    ? `${homePage?.vet_banner?.mobile_image_link}/${homePage?.vet_banner?.data[0].banner_image}`
                    : `${homePage?.vet_banner?.desktop_image_link}/${homePage?.vet_banner?.data[0].banner_image}`
                }
                alt=""
              />
              {!homePage?.vet_banner?.error && !vetBannerLoaded && (
                <Skeleton variant="rectangular" width={"100%"} height={400} />
              )}
            </section>
          )}
          {!homePage?.cat_products.error && (
            <CatProducts
              isLoading={isLoading}
              products={homePage?.cat_products}
            />
          )}
          {!homePage?.popular_products.error && (
            <PopularProducts
              isLoading={isLoading}
              products={homePage?.popular_products}
            />
          )}

          {!homePage?.shop_by_brands.error && (
            <ShopByBrands brands={homePage?.shop_by_brands} />
          )}
          {!homePage?.testimonials_list.error && (
            <Testimonial isLoading testimonial={homePage?.testimonials_list} />
          )}
          {!homePage?.multi_banners.error && (
            <SliderBanner
              isLoading={isLoading}
              multiBanners={homePage?.multi_banners}
            />
          )}
          <RecentView />
          <Blog isLoading={isLoading} blog={homePage?.blogs_list} />
        </>
      )}
      {isLoading && (
        <>
          <Stack
            spacing={4}
            sx={{
              padding: "20px",
            }}
          >
            {/* main slider */}
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={mobileMatches ? 465 : 200}
            />
            {/* shop by category */}
            {/* text and heading */}
            <Stack spacing={1}>
              <Skeleton
                animation="wave"
                width={150}
                height={40}
                variant="text"
              />
              <Skeleton
                animation="wave"
                width={310}
                height={30}
                variant="text"
              />
            </Stack>
            {/* circular sections */}
            <Stack
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={mobileMatches ? 150 : 60}
                  height={mobileMatches ? 150 : 60}
                />
              </div>
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={mobileMatches ? 150 : 60}
                  height={mobileMatches ? 150 : 60}
                />
              </div>
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={mobileMatches ? 150 : 60}
                  height={mobileMatches ? 150 : 60}
                />
              </div>
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={mobileMatches ? 150 : 60}
                  height={mobileMatches ? 150 : 60}
                />
              </div>
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={mobileMatches ? 150 : 60}
                  height={mobileMatches ? 150 : 60}
                />
              </div>
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={mobileMatches ? 150 : 60}
                  height={mobileMatches ? 150 : 60}
                />
              </div>
            </Stack>
            {/* banner 2 */}
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={mobileMatches ? 550 : 100}
              // height={550}
            />
            {/* popular products */}
            <Skeleton animation="wave" width={240} height={40} variant="text" />
            <Stack
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
            </Stack>
            {/* banner3 */}
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={mobileMatches ? 600 : 120}
              // height={600}
            />
            {/* cat products */}
            <Skeleton animation="wave" width={150} height={40} variant="text" />
            <Stack
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={250} height={250} />
                <Skeleton variant="text" width={140} />
                <Skeleton variant="text" width={70} />
                <Skeleton variant="text" width={35} />
              </div>
            </Stack>
            {/* testimonial */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Skeleton
                animation="wave"
                width={200}
                height={60}
                variant="text"
              />
            </Stack>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div className="testimonial-loader-box">
                <center>
                  <Skeleton variant="rectangular" width={70} height={60} />
                </center>
                <br />
                <Skeleton variant="text" width={500} />
                <Skeleton variant="text" width={500} />
                <Skeleton variant="text" width={500} />
              </div>
              <div className="testimonial-loader-box">
                <center>
                  <Skeleton variant="rectangular" width={70} height={60} />
                </center>
                <br />
                <Skeleton variant="text" width={500} />
                <Skeleton variant="text" width={500} />
                <Skeleton variant="text" width={500} />
              </div>
              <div className="testimonial-loader-box">
                <center>
                  <Skeleton variant="rectangular" width={70} height={60} />
                </center>
                <br />
                <Skeleton variant="text" width={500} />
                <Skeleton variant="text" width={500} />
                <Skeleton variant="text" width={500} />
              </div>
            </Stack>
            {/* Blog section */}
            <Skeleton animation="wave" width={200} height={60} variant="text" />
            <Skeleton animation="wave" width={150} height={40} variant="text" />
            <Stack
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={600} height={320} />
                <br />
                <Skeleton variant="text" width={80} />
                <Skeleton variant="text" width={600} />
                <Skeleton variant="text" width={600} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={600} height={320} />
                <br />
                <Skeleton variant="text" width={80} />
                <Skeleton variant="text" width={600} />
                <Skeleton variant="text" width={600} />
              </div>
              <div className="product-loader-box">
                <Skeleton variant="rectangular" width={600} height={320} />
                <br />
                <Skeleton variant="text" width={80} />
                <Skeleton variant="text" width={600} />
                <Skeleton variant="text" width={600} />
              </div>
            </Stack>
          </Stack>
        </>
      )}
    </div>
  );
}

export default Home;
