import React, { useState } from "react";
import "./Cart.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useHistory, useLocation } from "react-router-dom";
import RecentView from "../home/RecentView";
import CircularProgress from "@mui/material/CircularProgress";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { allCities, findSelectedCity } from "../../resources/allCities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import {
  paymentSuccessApi,
  SaveAddressApi,
  choosePaymentApi,
  deleteAddressApi,
  shipToAddressApi,
} from "../../api";
import logo from "../../image/logo-text-sm.png";
import { fetchItems, fetchAddress, updateCart, menuActions } from "../../store";
import { razor_pay_key } from "../../constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";
import Autocomplete from "react-google-autocomplete";
let initalLoad = true;
toast.configure();
const useStyles = makeStyles({
  thPadding: {
    padding: "5px",
  },
  textField: {
    marginBottom: "10px",
  },
});

const theme1 = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        borderColor: "#000",
        "&:focus": {
          borderColor: "#09D1C7",
        },
      },
    },
  },
});

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
      // transform: 'translate(14px, 12px) scale(1)',
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const CustomIconButton = withStyles({
  root: {
    backgroundColor: "#09D1C7",
    boxShadow: "0 0 10px lightgray",
    fontWeight: "500",
    color: "#fff",
    width: "30px",
    height: "30px",
    fontSize: "30px",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0000",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const BootstrapButton1 = withStyles({
  root: {
    color: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:active": {
      boxShadow: "none",
    },
  },
})(Button);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#D2FCFC",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Checkout(props) {
  const makeDispatch = useDispatch();
  const classes = useStyles();
  const { cart, user, addresses, cartId, productImageLink } = useSelector(
    (state) => state.menu
  );
  const [expanded, setExpanded] = React.useState("panel2");
  const dispatch = useDispatch();
  const history = useHistory();
  const [localstate, setState] = useState("");
  const [country, setCountry] = useState("");
  const [primaryAddr, setPrimaryAddr] = useState("");
  const [secondryAddr, setSecondryAddr] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [OnlinePayment, setOnlinePayment] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(
    addresses.filter((a) => a.is_default)[0]
      ? addresses.filter((a) => a.is_default)[0].id
      : 0
  );
  const [invalidCredintials, setInvalidCredintials] = useState(false);
  const [checkedAddr, setCheckedAddr] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Online");
  const [mobile, setMobile] = useState("");
  const [apartment, setApartment] = useState("");
  const [orderDetails, setOrderDetails] = useState(false);
  const [showPaymentloader, setShowPaymentLoader] = useState(false);
  const [metaInfo, setMetaInfo] = useState({});

  const [address, setAddress] = useState();
  const [addressObj, setAddressObj] = useState();

  const getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5"
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4"
      ],
      country: ["country"]
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: ""
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    return address;
  };

  useEffect(() => {
    const func = async () => {
      console.log('address',address);
      const geocodeObj =
        address &&
        (await geocodeByPlaceId(address.place_id));
      const addressObject =
        geocodeObj && getAddressObject(geocodeObj[0].address_components);
      setAddressObj(addressObject);
      setState('');
      setPinCode('');
    };

    func();
  }, [address]);
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  const makeImages = (productImageLink, image) => {
    return productImageLink + "/" + image;
  };
  useEffect(() => {
    dispatch({
      type: "ACTIVE_PAGE",
      page: {
        page: "checkout",
      },
    });
  }, []);
  const location = useLocation();

  const handleCountry = (event) => {
    setCountry(event.label);
    setState(event.state);
  };

  const CalculateTotalAmount = ({ couponAmount }) => {
    let totalAmount = 0;
    if (cart != undefined && cart.length > 0) {
      for (let k in cart) {
        let amount = parseInt(cart[k].quantity * cart[k].sale_price);
        totalAmount += amount;
      }
    }
    totalAmount -= couponAmount;
    //totalAmount += totalAmount < 899 ? 49 : 0;
    totalAmount += totalAmount < 899 ? 0 : 0;
    totalAmount+= paymentMethod=="COD"?29:0;
    return <>{totalAmount}</>;
  };

  const handlePaymentApi = async () => {
    let data = {
      userId: user.id,
      cartId: cartId,
      payment_option: paymentMethod,
    };
    const payRes = await choosePaymentApi(data);

    if (payRes.error) {
      toast.error(payRes.message, { autoClose: 2000 });
    } else {
      setOrderDetails(payRes.data);
    }
  };

  useEffect(() => {
    if (orderDetails != false && orderDetails != undefined) {
      displayRazorpay();
    }
  }, [orderDetails]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleProceedToPayment = () => {
    if (paymentMethod != undefined && selectedAddress != 0) {
      setShowPaymentLoader(true);
      handlePaymentApi();
    } else {
      if (selectedAddress == 0) {
        toast.error("Please Select an Address", { autoClose: 2000 });
      }
      if (paymentMethod == undefined) {
        toast.error("Please Select an Payment Method", { autoClose: 2000 });
      }
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    setShowPaymentLoader(false);
    if (!res) {
      alert("Please check your internet connection!");
      return;
    }
    const {
      totalPayableAmount,
      totalShippingAmount,
      razor_payment_order_id,
      order_id,
    } = orderDetails;
    let amount = (totalPayableAmount + totalShippingAmount) * 100;
    const options = {
      key: razor_pay_key,
      amount: amount.toString(),
      currency: "INR",
      name: "Pettofit",
      description: "Order products",
      image: { logo },
      order_id: razor_payment_order_id,
      handler: async function (response) {
        let responseData = {
          razorpay_order_id: razor_payment_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
        };
        setShowPaymentLoader(true);
        let res = await paymentSuccessApi(responseData);
        setShowPaymentLoader(false);
        if (!res.error) {
          history.push({
            pathname: "/order-confirmed",
            state: { orderId: order_id, ...res.data },
          });
          fetchItems()(dispatch);
          toast.success("Payment Successful", { autoClose: 2000 });
          return;
        }
        toast.error("Something Went wrong ", { autoClose: 2000 });
      },
      prefill: {
        name: user.name,
        email: user.email,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleAddrDelete = async (data) => {
    let delData = {
      userId: user.id,
      addressId: data.id,
    };
    let res = await deleteAddressApi(delData);
    if (!res) {
      toast.error("Some error in server", { autoClose: 2000 });
    }
    if (!res.error) {
      toast.success(res.message, { autoClose: 2000 });
    }
    dispatch(fetchAddress());
  };
  useEffect(() => {
    if (cart.length < 1) {
      history.push("/");
    }
  }, [cart]);

  const handleSaveAddress = async (event) => {
    event.preventDefault();
    const valid =
      !(localstate === "" && addressObj.province === '') &&
      !(country == "" && addressObj && addressObj.city == '') &&
      mobile != "" &&
      (primaryAddr != "" || secondryAddr != "") &&
      primaryAddr.length >= 5 &&
      firstName != "" &&
      !(pinCode == "" && addressObj.postal_code == '') &&
      parseInt(mobile) &&
      mobile.length == 10;
    if (valid) {
      let addrData = {
        pincode: pinCode || addressObj.postal_code,
        mobile: mobile,
        apartment_address: apartment,
        gst_no: "0232323",
        userId: user.id,
        cartId: cartId,
        name: firstName + lastName,
        address: primaryAddr ? primaryAddr : secondryAddr,
        city: country || addressObj && addressObj.city,
        state: localstate || addressObj.province,
        address_type: "home",
        apartment_address: apartment,
        is_default: "is_default",
      };
      console.log('addrData',addrData);
      let addrApi = await SaveAddressApi(addrData);
      if (addrApi.error) {
        toast.error(addrApi.message, { autoClose: 2000 });
      } else {
        setNewAddress(false);
        toast.success(addrApi.message, { autoClose: 2000 });
      }
      dispatch(fetchAddress());
    } else {
      if (firstName == "") {
        setInvalidCredintials(true);
        setErrorMessage("Please Provide Name");
        return;
      }
      if (primaryAddr == "" || primaryAddr.length < 5) {
        setInvalidCredintials(true);
        toast.error("Please provide a valid  address");
        return;
      }
      if (!(parseInt(mobile) && mobile.length == 10)) {
        setInvalidCredintials(true);
        setErrorMessage("Please Provide a valid phone number");
        return;
      }
      if ((country == "" && addressObj && addressObj.city == '')) {
        setInvalidCredintials(true);
        setErrorMessage("Please Provide a city");
        return;
      }

      if ((localstate == "" && addressObj.province == '')) {
        setInvalidCredintials(true);
        setErrorMessage("Please Provide a state");
        return;
      }

      if ((pinCode == "" && addressObj.postal_code == '')) {
        setInvalidCredintials(true);
        setErrorMessage("Please Provide a pincode");
        return;
      }

      toast.error("Please Submit Valid Information", { autoClose: 2000 });
    }
  };
  const [tabValue, setTabValue] = useState(0);
  const handleAddToCart = async (data) => {
    let item = {
      user_id: user.id,
      id: data.product_id,
      title: data.title,
      rating: data.rating,
      size: data.size,
      variant: 1,
      color: data.color,
      discountPrice: data.product_sale_price,
      actualPrice: data.product_mrp,
      price: data.product_mrp,
      product_image: data.product_image,
      pickedSize: data.pickedSize,
      pickedColor: data.pickedColor,
      product_sku: data.product_sku,
      product_title: data.product_title,
      quantity: 1,
    };
    makeDispatch(menuActions.addTocart(item));
    return;
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [loginForm, setLoginForm] = useState(true);
  const [signInWithOTPFormStatus, setSignInWithOTPFormStatus] = useState(false);
  const [sendOTPStatus, setSendOTPStatus] = useState(false);
  const [forgetPasswordFormStatus, setForgetPasswordFormStatus] =
    useState(false);
  const [sendForgetPasswordStatus, setSendForgetPasswordStatus] =
    useState(false);

  const [newAddress, setNewAddress] = useState(false);

  useEffect(() => {
    setNewAddress(false);
  }, [expanded]);

  const handleUpdateCardIncrement = (product) => {
    console.log("handleUpdateCardIncrement")
    const action = {
      ...product,
      quantity: "1",
    };
    updateCart(action, "increase", dispatch);
  };
  const handleUpdateCartRemove = (product) => {
    if (product.quantity > 0) {
      const action = {
        ...product,
        quantity: "1",
      };
      updateCart(action, "decrease", dispatch);
    } else {
      handleDeleteFromCart(product);
    }
  };

  const handleDeleteFromCart = (product) => {
    dispatch(menuActions.deleteItemFromCart(product));
  };

  const handleShipAddr = async () => {
    let shipData = {
      userId: user.id,
      addressId: selectedAddress,
      cartId: cartId,
    };
    const res = await shipToAddressApi(shipData);
    if (!res.error) {
      toast.success(res.message, { autoClose: 2000 });
      return;
    }
    toast.error("Something went wrong", { autoClose: 2000 });
  };
  useEffect(() => {
    if (!initalLoad) {
      handleShipAddr();
    }
    initalLoad = false;
  }, [selectedAddress]);

  useEffect(() => {
    setSendOTPStatus(false);
    setSignInWithOTPFormStatus(false);
    setForgetPasswordFormStatus(false);
  }, [loginForm]);
  var total = 0;

  if (cart != undefined && cart.length > 0) {
    for (let k in cart) {
      let amount = parseInt(cart[k].quantity * cart[k].sale_price);
      total += amount;
    }
  }
  //const shippingCharges = total < 899 ? 49 : 0;
  const shippingCharges = total < 899 ? 0 : 0;
  var defaultCity = country
    ? allCities.filter((c) => c.label == country)[0]
    : null;
  console.log('apartment',apartment);
  console.log('mobile',mobile);
  return (
    <>
      <div className="cart">
        <Helmet>
          <title>{metaInfo.meta_title}</title>
        </Helmet>
        <div className="section">
          <Breadcrumbs
            sx={{ ml: mobileMatches ? 0 : 1 }}
            aria-label="breadcrumb"
            className="breadcrumb"
          >
            <Link color="inherit" to="/">
              Home
            </Link>
            <Link color="inherit" to="/cart">
              Cart
            </Link>
            <Typography color="textPrimary">Checkout</Typography>
          </Breadcrumbs>
          <Grid sx={{ p: mobileMatches ? 0 : 1 }} container spacing={2}>
            <Grid item md={8} xs={12}>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography>Select Address</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {addresses.map((item, index) => {
                      return (
                        <div key={index}>
                          <ListItem
                            alignItems="flex-start"
                            className="checkoutListItem"
                          >
                            <ListItemText
                              primary={item.name}
                              secondary={
                                <React.Fragment>
                                  {item.address}
                                  <br />
                                  <p>
                                    Pincode: {item.pincode}
                                    <br />
                                    City: {item.city} <br />
                                    State: {item.state} <br />
                                    Country: {item.country}
                                  </p>
                                </React.Fragment>
                              }
                            />
                            {index == 0 ? (
                              <input
                                type="radio"
                                name="addresses"
                                size="small"
                                variant="contained"
                                key={item.id}
                                value={item.id}
                                color="primary"
                                checked={selectedAddress == item.id}
                                onClick={(event) => {
                                  setSelectedAddress(event.target.value);
                                }}
                              />
                            ) : (
                              <input
                                type="radio"
                                name="addresses"
                                size="small"
                                variant="contained"
                                key={item.id}
                                value={item.id}
                                color="primary"
                                checked={selectedAddress == item.id}
                                onClick={(event) => {
                                  setSelectedAddress(event.target.value);
                                }}
                              />
                            )}
                            <DeleteIcon
                              onClick={() => {
                                handleAddrDelete(item);
                              }}
                              className="deleteIcon"
                            />
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    })}
                    <ListItem alignItems="flex-start">
                      {/* <BootstrapButton1>View All</BootstrapButton1> */}
                      {!newAddress && (
                        <BootstrapButton1
                          onClick={() => {
                            setInvalidCredintials(false);
                            setErrorMessage("");
                            setNewAddress(true);
                          }}
                        >
                          New Address
                        </BootstrapButton1>
                      )}
                    </ListItem>
                    {newAddress && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <CssTextField
                              className={classes.textField}
                              onChange={(event) => {
                                setInvalidCredintials(false);
                                setErrorMessage("");
                                setFirstName(event.target.value);
                              }}
                              style={{ width: "100%" }}
                              label="First Name"
                              type="text"
                              id="outlined-size-small"
                              size="small"
                              margin="dense"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <CssTextField
                              className={classes.textField}
                              onChange={(event) => {
                                setInvalidCredintials(false);
                                setErrorMessage("");
                                setLastName(event.target.value);
                              }}
                              style={{ width: "100%" }}
                              label="Last Name"
                              type="text"
                              id="outlined-size-small"
                              size="small"
                              margin="dense"
                            />
                          </Grid>
                        </Grid>
                        {/* <CssTextField
                          className={classes.textField}
                          onChange={(event) => {
                            setInvalidCredintials(false);
                            setErrorMessage("");
                            setPrimaryAddr(event.target.value);
                          }}
                          style={{ width: "100%" }}
                          label="Address"
                          type="text"
                          id="outlined-size-small"
                          size="small"
                          margin="dense"
                        /> */}
                        {/* <GooglePlacesAutocomplete
                          apiKey="AIzaSyDiKmRh2vEg2hiV1ZIVeyNlxPjVegpChvE"
                          selectProps={{
                            isClearable: true,
                            value: address,
                            onChange: (val) => {
                              setPrimaryAddr(val && val.value.structured_formatting.main_text || '')
                              setAddress(val || '');
                            },
                            placeholder:"Address"
                          }}
                          apiOptions={{
                            language: 'en', 
                            region: 'in',
                          }}
                        /> */}
                            <Autocomplete
                            style={{width:'99%',height:'35px'}}
                            apiKey={"AIzaSyDiKmRh2vEg2hiV1ZIVeyNlxPjVegpChvE"}
                            placeholder={"Address"}
                            onPlaceSelected={(place) => {
                              setAddress(place || '');
                              setPrimaryAddr(place && place.formatted_address || '')
                            }}
                            options={{
                              types: ["geocode","establishment"],
                              componentRestrictions: { country: "in" }
                            }}
                          />
                        <CssTextField
                          className={classes.textField}
                          onChange={(event) => {
                            setInvalidCredintials(false);
                            setErrorMessage("");
                            setMobile(event.target.value);
                          }}
                          style={{ width: "100%" }}
                          label="Mobile"
                          type="text"
                          id="outlined-size-small"
                          size="small"
                          margin="dense"
                        />
                        <CssTextField
                          className={classes.textField}
                          onChange={(event) => {
                            setInvalidCredintials(false);
                            setErrorMessage("");
                            setApartment(event.target.value);
                          }}
                          style={{ width: "100%" }}
                          label="Apartment, suite, etc. (Optional)"
                          type="text"
                          id="outlined-size-small"
                          size="small"
                          margin="dense"
                        />
                        <Grid container spacing={2}>
                          <Grid item md={4} xs={12}>
                            <ThemeProvider theme={theme1}>
                              <FormControl
                                // variant="standard"
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "4px",
                                }}
                                sx={{ m: 1, minWidth: "100%", margin: "0px" }}
                              >
                                <Select
                                  // value={addressObj && addressObj.city ? addressObj.city : null}
                                  value={findSelectedCity(addressObj && addressObj.city)}
                                  onChange={handleCountry}
                                  options={allCities}
                                  placeholder="City"
                                />
                              </FormControl>
                            </ThemeProvider>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <CssTextField
                              className={classes.textField}
                              // value={localstate}
                              value={localstate || addressObj && addressObj.province}
                              style={{ width: "100%" }}
                              // label="State"
                              placeholder="State"
                              type="text"
                              id="outlined-size-small"
                              size="small"
                              margin="dense"
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <CssTextField
                              onChange={(event) => {
                                setInvalidCredintials(false);
                                setErrorMessage("");
                                setPinCode(event.target.value);
                              }}
                              value={pinCode || addressObj && addressObj.postal_code}
                              className={classes.textField}
                              style={{ width: "100%" }}
                              // label="Pin Code"
                              placeholder='Pin Code'
                              type="text"
                              id="outlined-size-small"
                              size="small"
                              margin="dense"
                            />
                          </Grid>
                        </Grid>
                        {invalidCredintials ? (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {errorMessage}{" "}
                          </p>
                        ) : (
                          <></>
                        )}
                        <BootstrapButton2
                          variant="contained"
                          onClick={handleSaveAddress}
                          color="primary"
                        >
                          Save and Deliver Here
                        </BootstrapButton2>
                      </>
                    )}
                    <ListItem></ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography>Product Review</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {cart.length === 0 ? (
                    <h4 className="emptyCart">No Item in basket</h4>
                  ) : mobileMatches ? (
                    <TableContainer
                      style={{ marginBottom: "20px" }}
                      component={Paper}
                    >
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              Product
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Quantity
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Price
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cart.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                  <div className="productContainer">
                                    <img
                                      src={
                                        productImageLink + "/" + row.item_photo
                                      }
                                      alt=""
                                    />
                                    <div className="detail">
                                      <h4>{row.product_title}</h4>
                                      <div className="selection">
                                        {row.pickedSize &&
                                          row.pickedSize !== undefined &&
                                          row.pickedSize !== "" &&
                                          row.pickedSize != "null" && (
                                            <>
                                              SIZE
                                              <div className="sizeBox">
                                                {row.pickedSize}
                                              </div>
                                            </>
                                          )}
                                        {row.pickedColor != undefined &&
                                          row.pickedColor.code.trim().length >
                                            0 && (
                                            <>
                                              COLOR
                                              <div
                                                className="colorBox"
                                                style={{
                                                  backgroundColor:
                                                    row.pickedColor.code,
                                                }}
                                              ></div>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <div className="qtyContainer">
                                    <CustomIconButton
                                      onClick={() =>
                                        handleUpdateCartRemove(row)
                                      }
                                    >
                                      <span className={classes.paddingButton}>
                                        -
                                      </span>
                                    </CustomIconButton>
                                    <h4>{row.quantity}</h4>
                                    <CustomIconButton
                                      onClick={() =>
                                        handleUpdateCardIncrement(row)
                                      }
                                    >
                                      +
                                    </CustomIconButton>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <div className="priceContainer">
                                    {row.sale_price != row.price && (
                                      <p className="actualPrice">
                                        <FontAwesomeIcon
                                          icon={faRupeeSign}
                                          className="icon"
                                        />{" "}
                                        {row.price}
                                      </p>
                                    )}
                                    <p className="discountedPrice">
                                      <FontAwesomeIcon
                                        icon={faRupeeSign}
                                        className="icon"
                                      />{" "}
                                      {row.sale_price}
                                    </p>
                                    <p className="discountRate">
                                      {!isNaN(
                                        (
                                          ((row.price - row.sale_price) * 100) /
                                          row.price
                                        ).toFixed(0)
                                      ) &&
                                      +(
                                        ((row.price - row.sale_price) * 100) /
                                        row.price
                                      ).toFixed(0) > 0
                                        ? "(" +
                                          (
                                            ((row.price - row.sale_price) *
                                              100) /
                                            row.price
                                          ).toFixed(0) +
                                          "% OFF)"
                                        : ""}
                                    </p>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <DeleteIcon
                                    onClick={() => {
                                      handleDeleteFromCart(row);
                                    }}
                                    className="deleteIcon"
                                  />
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="mobileCratContainer">
                      {cart.map((row) => (
                        <div className="card" key={row.id}>
                          <div className="productContainer">
                            <img
                              src={makeImages(
                                productImageLink,
                                row.product_image
                              )}
                              alt=""
                            />
                            <div className="detail">
                              <h4>{row.title}</h4>
                              <div className="selection">
                                {row.pickedSize &&
                                  row.pickedSize !== undefined &&
                                  row.pickedSize !== "" &&
                                  row.pickedSize != "null" && (
                                    <>
                                      SIZE
                                      <div className="sizeBox">
                                        {row.pickedSize}
                                      </div>
                                    </>
                                  )}
                                {row.pickedColor !== undefined &&
                                  row.pickedColor.length > 0 && (
                                    <>
                                      COLOR
                                      <div
                                        className="colorBox"
                                        style={{
                                          backgroundColor: row.pickedColor,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                          <DeleteIcon className="deleteIcon" />
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Price
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Quantity
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Total
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="priceContainer">
                                      {row.price != row.sale_price && (
                                        <p className="actualPrice">
                                          <FontAwesomeIcon
                                            icon={faRupeeSign}
                                            className="icon"
                                          />{" "}
                                          {row.price}
                                        </p>
                                      )}
                                      <p className="discountedPrice">
                                        <FontAwesomeIcon
                                          icon={faRupeeSign}
                                          className="icon"
                                        />{" "}
                                        {row.sale_price}
                                      </p>
                                      <p className="discountRate">
                                        {!isNaN(
                                          (
                                            ((row.price - row.sale_price) *
                                              100) /
                                            row.price
                                          ).toFixed(0)
                                        ) &&
                                        +(
                                          ((row.price - row.sale_price) * 100) /
                                          row.price
                                        ).toFixed(0) > 0
                                          ? "(" +
                                            (
                                              ((row.price - row.sale_price) *
                                                100) /
                                              row.price
                                            ).toFixed(0) +
                                            "% OFF)"
                                          : ""}
                                      </p>
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="qtyContainer">
                                      <CustomIconButton>-</CustomIconButton>
                                      <h4>{row.quantity}</h4>
                                      <CustomIconButton>+</CustomIconButton>
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="priceContainer">
                                      <p className="discountedPrice">
                                        <FontAwesomeIcon
                                          icon={faRupeeSign}
                                          className="icon"
                                        />{" "}
                                        {row.sale_price * row.quantity}
                                      </p>
                                    </div>
                                  </StyledTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      ))}
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography>Payment Option</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <ListItem alignItems="flex-start">
                      <ListItemText primary="Online Payment" />
                      <input
                        type="radio"
                        size="small"
                        variant="contained"
                        value="Online"
                        checked={paymentMethod == 'Online' ? true:false}
                        onChange={(event) =>
                          setPaymentMethod(event.target.value)
                        }
                        name="onlinePayment"
                        color="primary"
                      />
                      
                    </ListItem>
                    <Divider />
                  </List>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <ListItem alignItems="flex-start">
                      <ListItemText primary="COD Payment" secondary={cart.reduce(
                          (total, cartPrice) =>
                            total +
                            parseInt(cartPrice.quantity) *
                              parseInt(cartPrice.sale_price),
                          0
                        )>3000?<Typography type="body2" style={{ color: '#ff0000' }}>COD is applicable only below 3000</Typography>:""} />
                        <input
                        type="radio"
                        size="small"
                        variant="contained"
                        value="COD"
                        disabled={cart.reduce(
                          (total, cartPrice) =>
                            total +
                            parseInt(cartPrice.quantity) *
                              parseInt(cartPrice.sale_price),
                          0
                        )>3000}
                        checked={paymentMethod == 'COD' ? true : false}
                        onChange={(event) =>
                          setPaymentMethod(event.target.value)
                        }
                        name="COD"
                        color="primary"
                      />
                    </ListItem>
                    <Divider />
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
                disabled
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Other Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Nunc vitae orci ultricies, auctor nunc in, volutpat nisl.
                    Integer sit amet egestas eros, vitae egestas augue. Duis vel
                    est augue.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item md={4} xs={12} className="cartSummary">
              <div className="container">
                <div className="content">
                  <p>Price</p>
                  <h5>
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {cart.reduce(
                      (total, cartPrice) =>
                        total +
                        parseInt(cartPrice.quantity) *
                          parseInt(cartPrice.sale_price),
                      0
                    )}
                  </h5>
                </div>
                <div className="content">
                  <p>Shipping Charges</p>
                  <h5 className="green">
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {shippingCharges}
                  </h5>
                </div>
                {paymentMethod=="COD"&&
                <div className="content">
                  <p>COD Charges</p>
                  <h5 className="green">
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    29
                  </h5>
                </div>}
                <div className="content">
                  <p>Coupon Discount</p>
                  <h5
                    style={{
                      color: "red",
                    }}
                  >
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {location.state?.couponAmount}
                  </h5>
                </div>
                <Divider />
                <div className="content">
                  <div className="total">
                    <h4>Total Amount</h4>
                    <p>Inclusive of all taxed</p>
                  </div>
                  <h5>
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    <CalculateTotalAmount
                      couponAmount={location.state?.couponAmount}
                    />
                  </h5>
                </div>
              </div>
              <BootstrapButton2 onClick={()=>{if(expanded!="panel4"){setExpanded('panel4')}else{handleProceedToPayment()}}}>
                Proceed to payment
              </BootstrapButton2>
            </Grid>
          </Grid>
        </div>
        <RecentView />
      </div>
      {showPaymentloader && (
        <div
          style={{
            background: "rgba(0,0,0,0.5)",
            position: "fixed",
            width: "100vw",
            height: "100vh",
            zIndex: 999999999,
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fefefe",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
}

export default Checkout;
