import React, { useState, useEffect } from "react";
import "../myOrder/MyOrder.css";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import axios from "axios";
import { Helmet } from "react-helmet";

import Button from "@material-ui/core/Button";
import { myReviews } from "../../../api";

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    marginLeft: "10px",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);
const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};
function MyReviews() {
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [review, setReview] = useState({});
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  const { user } = useSelector((state) => state.menu);

  useEffect(() => {
    (async () => {
      const res = await myReviews(user.id);
      setReview(res.data);
    })();
  }, []);
  return (
    <div className="myOrder section">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <Breadcrumbs
        sx={{ ml: mobileMatches ? 0 : 1 }}
        aria-label="breadcrumb"
        className="breadcum"
      >
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/reviews">
          My Reviews & Rating
        </Link>
      </Breadcrumbs>
      <Grid sx={{ p: mobileMatches ? 0 : 1 }} container spacing={2}>
        <Grid item md={3} xs={12}>
          <Paper className="myOrder__menu" elevation={3}>
            <ul>
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/orders">My Order</Link>
              </li>
              <li>
                <Link to="/appointment">My Appointment</Link>
              </li>
              <li>
                <Link to="/reviews" className="active">
                  My Reviews & Rating
                </Link>
              </li>
              <li>
                <Link to="/tickets">Tickets</Link>
              </li>
            </ul>
          </Paper>
        </Grid>
        <Grid item md={9} xs={12}>
          {/* <h3 className="pageTitle">My Order</h3> */}
          <Paper
            className="myOrder__container"
            style={{ cursor: "default" }}
            elevation={3}
          >
            {review.ratings?.map((detail) => (
              <div className="container">
                <div className="content">
                  <div className="myOrder__productDetail">
                    <img
                      src={
                        detail.product.product_image
                          ? review.image_link +
                            "/" +
                            detail.product.product_image
                          : review.image_link +
                            "/" +
                            detail.product.product_variabtion.product_image
                      }
                      alt=""
                    />
                    <div>
                      <p>{detail.product.heading_h1}</p>
                      <h4>{detail.review_title}</h4>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Rating
                          name="hover-feedback"
                          value={detail.rating}
                          precision={0.5}
                          size="small"
                          readOnly
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        <Box sx={{ ml: 2, fontSize: "0.8em" }}>
                          {labels[detail.rating]}
                        </Box>
                      </Box>
                      <p>{detail.review_text}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="container">
              <div className="content">
                <div className="myOrder__productDetail">
                  <img
                    src={require("../../../image/product1.jpeg").default}
                    alt=""
                  />
                </div>
              </div>
            </div> */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default MyReviews;
