import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { StateProvider } from "./StateProvider";
import reducer, { initialState } from "./reducer";
import { Provider } from "react-redux";
import store from "./store";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    if (response.data.error) {
      // toast.error(response.data.message);
    }
    return response;
  },
  (error) => {
    console.error(error);
    if (error.response.data.message && error.response.data.message !== "")
      // toast.error(error.response.data.message);
      return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <Provider store={store}>
        <App />
      </Provider>
    </StateProvider>
    <ToastContainer hideProgressBar />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
