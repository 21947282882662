import React, { useState, useEffect } from "react";
import "../myOrder/MyOrder.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import VideocamIcon from "@mui/icons-material/Videocam";
import ForumIcon from "@mui/icons-material/Forum";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import UpdateIcon from "@mui/icons-material/Update";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Backdrop } from "@mui/material";
import VideoCallWidget from "../../popups/VideoCallWidget";
import ChatWidget from "../../popups/ChatWidget";
import PrescriptionEditor from "../../popups/PrescriptionEditor";
import DateTimePicker from "../../popups/DateTmePicker";
import { getUserAppointments, headers } from "../../../api";
import { toast } from "react-toastify";
import Cancel from "@mui/icons-material/Cancel";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#08D0C7",
    color: theme.palette.common.white,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "5px",
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BootstrapButton3 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:active": {
      boxShadow: "none",
      borderColor: "#09D1C7",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "13px",
  },
  menu: {
    minHeight: "auto",
  },
  menuText: {
    fontSize: "0.8em",
  },
  menuIcon: {
    color: "#08D0C7",
    fontSize: "0.8em",
  },
}));

function MyAppointment() {
  const classes = useStyles();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [pagee, setPagee] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [backdropType, setBackdropType] = useState("");
  const open = Boolean(anchorEl);
  const [rows, setRows] = useState([]);
  const [appointmentNumber, setAppointmentNumber] = useState("");
  const [chatAccessToken, setChatAccessToken] = useState("");
  const [chatChannelSid, setChatChannelSid] = useState("");
  const [prescription, setPrescription] = useState("");
  const [appointmentReason, setAppointmentReason] = useState("");
  const [cancelAppointmentReason, setCancelAppointmentReason] = useState("");
  const { user } = useSelector((state) => state.menu);
  const [row, setRow] = useState({});
  const [metaInfo, setMetaInfo] = useState({});
  const [counter, setCounter] = useState(3540);
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);

  const reduceCounter = () => {
    setTimeout(() => setCounter(counter - 1), 1000);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPagee(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPagee(0);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const cancelAppointment = async () => {
    await axios
      .post(
        "https://api.pettofit.com/api/v1/cancel-appointment",
        {
          appointment_number: appointmentNumber,
          cancel_reason: cancelAppointmentReason,
          userId: user.id,
        },
        {
          headers: headers,
        }
      )
      .then(() => {
        toast.success("Appointment Cancelled");
        setBackdropOpen(false);
        setBackdropType("");
        fetchAppointments();
      })
      .catch(() => {
        toast.success("Something went wrong");
        setBackdropOpen(false);
        setBackdropType("");
      });
  };

  const fetchAppointments = async () => {
    const appointmentsData = await getUserAppointments();
    console.log(appointmentsData);
    if (!appointmentsData.error) {
      setRows(
        appointmentsData.data
          .filter((appointment) => appointment.status !== "Cancelled")
          .map((a) => ({
            ...a,
            tim: a.assign_datetime
              ? a.assign_datetime.split(" ")[1]
              : a.req_datetime.split(" ")[1],
            dat: a.assign_datetime
              ? new Date(a.assign_datetime.split(" ")[0])
                  .toString()
                  .split(" ")
                  .slice(1, 4)
                  .join("-")
              : new Date(a.req_datetime.split(" ")[0])
                  .toString()
                  .split(" ")
                  .slice(1, 4)
                  .join("-"),
            prob: a.problem,
            vet_name: a.vet_name ? a.vet_name : "Not assigned yet",
            vet_degree: a.vet_degree
              ? " (" + a.vet_degree + ")"
              : "Not assigned yet",
          }))
          .reverse()
      );
    }
  };

  return (
    <>
      <div className="myOrder section">
        <Helmet>
          <title>{metaInfo.meta_title}</title>
        </Helmet>
        <Breadcrumbs
          sx={{ ml: mobileMatches ? 0 : 1 }}
          aria-label="breadcrumb"
          className="breadcum"
        >
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/appointment">
            My Appointment
          </Link>
        </Breadcrumbs>
        <Grid sx={{ p: mobileMatches ? 0 : 1 }} container spacing={2}>
          <Grid item md={3} xs={12}>
            <Paper className="myOrder__menu" elevation={3}>
              <ul>
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li>
                  <Link to="/orders">My Order</Link>
                </li>
                <li>
                  <Link to="/appointment" className="active">
                    My Appointment
                  </Link>
                </li>
                <li>
                  <Link to="/reviews">My Reviews & Rating</Link>
                </li>
                <li>
                  <Link to="/tickets">Tickets</Link>
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item md={9} xs={12}>
            {rows.length === 0 ? (
              <h4 className="emptyCart">No Appointments Booked</h4>
            ) : (
              <Paper sx={{ p: 0 }} className="" elevation={3}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ width: 80 }} align="center">
                          Time
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: 100 }} align="center">
                          Date
                        </StyledTableCell>
                        <StyledTableCell>Problem</StyledTableCell>
                        <StyledTableCell>Doctor Details</StyledTableCell>
                        <StyledTableCell
                          sx={{ width: mobileMatches ? 160 : "" }}
                          align="center"
                        >
                          {mobileMatches ? "Action" : ""}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(
                          pagee * rowsPerPage,
                          pagee * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          return (
                            <StyledTableRow key={i}>
                              <StyledTableCell align="center">
                                {row.tim}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.dat}
                              </StyledTableCell>
                              <StyledTableCell>{row.prob}</StyledTableCell>
                              <StyledTableCell>
                                {row.vet_name} <br />
                                {row.vet_degree}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {mobileMatches ? (
                                  <div
                                    style={{ width: "30%", display: "flex" }}
                                  >
                                    <Tooltip title="Video Call with vet">
                                      <IconButton
                                        onClick={() => {
                                          if (row.assign_status !== 1)
                                            return toast.info(
                                              "The appointment session has not been confirmed yet!"
                                            );
                                          if (
                                            new Date() <
                                            new Date(row.assign_datetime)
                                          )
                                            return toast.info(
                                              "The appointment session has not started yet"
                                            );
                                          if (
                                            (new Date() -
                                              new Date(row.assign_datetime)) /
                                              60000 >=
                                            30
                                          )
                                            return toast.info(
                                              "The appointment session has expired, consider rescheduling!"
                                            );
                                          setAppointmentNumber(
                                            row.appointment_number
                                          );
                                          setBackdropOpen(true);
                                          setBackdropType("video");
                                        }}
                                        size="small"
                                      >
                                        <VideocamIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Chat with vet">
                                      <IconButton
                                        onClick={() => {
                                          if (row.assign_status !== 1)
                                            return toast.info(
                                              "The appointment session has not been confirmed yet!"
                                            );
                                          if (
                                            (new Date() -
                                              new Date(row.assign_datetime)) /
                                              600000 >=
                                            1008
                                          )
                                            return toast.info(
                                              "Session has expired, consider booking another appointment!"
                                            );
                                          setBackdropOpen(true);
                                          setChatAccessToken(row.token);
                                          setChatChannelSid(row.channel?.sid);
                                          setBackdropType("chat");
                                        }}
                                        size="small"
                                      >
                                        <ForumIcon />
                                      </IconButton>
                                    </Tooltip>
                                    {row.reports &&
                                      row.reports.length > 0 &&
                                      row.reports.includes(".pdf") && (
                                        <Tooltip title="View Prescription">
                                          <IconButton
                                            onClick={() => {
                                              setBackdropOpen(true);
                                              setPrescription(row.reports);
                                              setBackdropType("prescription");
                                            }}
                                            size="small"
                                          >
                                            <CloudDownloadIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    <Tooltip title="Reschedule Appointment">
                                      <IconButton
                                        onClick={() => {
                                          if (
                                            (new Date(
                                              row.assign_datetime
                                                ? row.assign_datetime
                                                : row.req_datetime
                                            ) -
                                              new Date()) /
                                              60000 <
                                            30
                                          )
                                            return toast.info(
                                              "Unable to reschedule this appointment!"
                                            );
                                          setBackdropOpen(true);
                                          setAppointmentReason(row.problem);
                                          setAppointmentNumber(
                                            row.appointment_number
                                          );
                                          setBackdropType("reschedule");
                                        }}
                                        size="small"
                                      >
                                        <UpdateIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Cancel Appointment">
                                      <IconButton
                                        onClick={() => {
                                          if (
                                            (new Date(
                                              row.assign_datetime
                                                ? row.assign_datetime
                                                : row.req_datetime
                                            ) -
                                              new Date()) /
                                              60000 <
                                            30
                                          )
                                            return toast.info(
                                              "Unable to cancel this appointment!"
                                            );
                                          setBackdropOpen(true);
                                          setAppointmentNumber(
                                            row.appointment_number
                                          );
                                          setBackdropType("cancel");
                                        }}
                                        size="small"
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  <>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls="long-menu"
                                      aria-expanded={open ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={(e) => {
                                        setRow(row);
                                        handleClick(e);
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </>
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            if (row.assign_status !== 1)
                              return toast.info(
                                "The appointment session has not been confirmed yet!"
                              );
                            if (new Date() < new Date(row.assign_datetime))
                              return toast.info(
                                "The appointment session has not started yet"
                              );
                            if (
                              (new Date() - new Date(row.assign_datetime)) /
                                60000 >=
                              30
                            )
                              return toast.info(
                                "The appointment session has expired, consider rescheduling!"
                              );
                            setAppointmentNumber(row.appointment_number);
                            setBackdropOpen(true);
                            setBackdropType("video");
                            handleClose();
                          }}
                          className={classes.menu}
                        >
                          <VideocamIcon className={classes.menuIcon} /> &nbsp;{" "}
                          <span className={classes.menuText}>Video Call</span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (row.assign_status !== 1)
                              return toast.info(
                                "The appointment session has not been confirmed yet!"
                              );
                            if (
                              (new Date() - new Date(row.assign_datetime)) /
                                600000 >=
                              1008
                            )
                              return toast.info(
                                "Session has expired, consider booking another appointment!"
                              );
                            setBackdropOpen(true);
                            setChatAccessToken(row.token);
                            setChatChannelSid(row.channel?.sid);
                            setBackdropType("chat");
                            handleClose();
                          }}
                          className={classes.menu}
                        >
                          <ForumIcon className={classes.menuIcon} /> &nbsp;{" "}
                          <span className={classes.menuText}>
                            Chat with vet
                          </span>
                        </MenuItem>
                        {row.reports && row.reports.includes(".pdf") && (
                          <MenuItem
                            onClick={() => {
                              setBackdropOpen(true);
                              setPrescription(row.prescription);
                              setBackdropType("prescription");
                              handleClose();
                            }}
                            className={classes.menu}
                          >
                            <CloudDownloadIcon className={classes.menuIcon} />{" "}
                            &nbsp;{" "}
                            <span className={classes.menuText}>
                              View Prescription
                            </span>
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            if (
                              (new Date(
                                row.assign_datetime
                                  ? row.assign_datetime
                                  : row.req_datetime
                              ) -
                                new Date()) /
                                60000 <
                              30
                            )
                              return toast.info(
                                "Unable to reschedule this appointment!"
                              );
                            setBackdropOpen(true);
                            setAppointmentReason(row.problem);
                            setAppointmentNumber(row.appointment_number);
                            setBackdropType("reschedule");
                            handleClose();
                          }}
                          className={classes.menu}
                        >
                          <UpdateIcon className={classes.menuIcon} /> &nbsp;{" "}
                          <span className={classes.menuText}>
                            Reschedule Appointment
                          </span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (
                              (new Date(
                                row.assign_datetime
                                  ? row.assign_datetime
                                  : row.req_datetime
                              ) -
                                new Date()) /
                                60000 <
                              30
                            )
                              return toast.info(
                                "Unable to cancel this appointment!"
                              );
                            setBackdropOpen(true);
                            setAppointmentNumber(row.appointment_number);
                            setBackdropType("cancel");
                            handleClose();
                          }}
                          className={classes.menu}
                        >
                          <Cancel className={classes.menuIcon} /> &nbsp;{" "}
                          <span className={classes.menuText}>
                            Cancel Appointment
                          </span>
                        </MenuItem>
                      </Menu>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={pagee}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
        onClick={() => setBackdropOpen(false)}
      >
        {backdropType === "video" && (
          <VideoCallWidget
            isCustomer={true}
            appointmentNumber={appointmentNumber}
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
            counter={counter}
            reduceCounter={reduceCounter}
          />
        )}
        {backdropType === "chat" && (
          <ChatWidget
            token={chatAccessToken}
            channelSid={chatChannelSid}
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
          />
        )}
        {backdropType === "prescription" && (
          <PrescriptionEditor
            prescription={prescription}
            editable={false}
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
          />
        )}
        {backdropType === "reschedule" && (
          <DateTimePicker
            fetchAppointments={fetchAppointments}
            appointmentNumber={appointmentNumber}
            appointmentReason={appointmentReason}
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
          />
        )}
        {backdropType === "cancel" && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              minWidth: "400px",
              width: "50%",
              height: "350px",
              backgroundColor: "#fff",
              color: "#000",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
            onChange={(e) => {
              setCancelAppointmentReason(e.target.value);
            }}
          >
            <h3>Cancel Appointment</h3>
            <textarea
              style={{
                width: "96%",
                height: "200px",
                marginTop: "20px",
                border: "1px solid #e5e5e5",
                outline: "none",
                fontSize: "16px",
                fontFamily: "Arial",
                padding: "5px",
              }}
              placeholder="Enter Reason"
            ></textarea>
            <BootstrapButton3 onClick={cancelAppointment}>
              Submit
            </BootstrapButton3>
          </div>
        )}
      </Backdrop>
    </>
  );
}

export default MyAppointment;
