import React from "react";
import Slider from "@material-ui/core/Slider";
import StarIcon from "@material-ui/icons/Star";

function ReviewSummary({ data }) {
  const fiveStarRatings = data.filter((r) => r.rating == 5).length;
  const fourStarRatings = data.filter((r) => r.rating == 4).length;
  const threeStarRatings = data.filter((r) => r.rating == 3).length;
  const twoStarRatings = data.filter((r) => r.rating == 2).length;
  const oneStarRatings =
    data.length -
    fiveStarRatings -
    fourStarRatings -
    threeStarRatings -
    twoStarRatings;

  return data ? (
    <div className="reviewSummary">
      <div className="content">
        <span>5 </span>
        <StarIcon className="star" />
        <Slider
          style={{ width: "200px", margin: "0px 5px" }}
          disabled
          defaultValue={(fiveStarRatings * 100) / data.length}
          aria-labelledby="disabled-slider"
        />
        <span>{fiveStarRatings}</span>
      </div>
      <div className="content">
        <span>4 </span>
        <StarIcon className="star" />
        <Slider
          style={{ width: "200px", margin: "0px 5px" }}
          disabled
          defaultValue={(fourStarRatings * 100) / data.length}
          aria-labelledby="disabled-slider"
        />
        <span>{fourStarRatings}</span>
      </div>
      <div className="content">
        <span>3 </span>
        <StarIcon className="star" />
        <Slider
          style={{ width: "200px", margin: "0px 5px" }}
          disabled
          defaultValue={(threeStarRatings * 100) / data.length}
          aria-labelledby="disabled-slider"
        />
        <span>{threeStarRatings}</span>
      </div>
      <div className="content">
        <span>2 </span>
        <StarIcon className="star" />
        <Slider
          style={{ width: "200px", margin: "0px 5px" }}
          disabled
          defaultValue={(twoStarRatings * 100) / data.length}
          aria-labelledby="disabled-slider"
        />
        <span>{twoStarRatings}</span>
      </div>
      <div className="content">
        <span>1 </span>
        <StarIcon className="star" />
        <Slider
          style={{ width: "200px", margin: "0px 5px" }}
          disabled
          defaultValue={(oneStarRatings * 100) / data.length}
          aria-labelledby="disabled-slider"
        />
        <span>{oneStarRatings}</span>
      </div>
    </div>
  ) : (
    ""
  );
}

export default ReviewSummary;
