import React, { useState, useEffect } from "react";
import "./cms.css";
import "./style.css";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { privacyPolicy } from "../../api";
import axios from "axios";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  paper: {
    marginTop: "50px",

    marginBottom: "50px",
  },
});

function PrivacyPolicy() {
  // const [policypage, setPolicyPage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [data, setData] = useState([]);

  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/get-page?page_id=2")
      .then((response) => {
        setMetaInfo(response.data.data);
      });
  }, []);

  useEffect(() => {
    (async () => {
      const privacyPage = await privacyPolicy();
      setIsLoading(false);
      setData(privacyPage.data);
    })();
  }, []);
  return (
    <div className="cms section">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <h2 className="stickyHeader header" id="myHeader">
        {data.heading_h1}
      </h2>
      <Paper
        className={classes.paper}
        elevation={3}
        style={{ margin: "15px", zIndex: "2" }}
      >
        <h3>{data.heading_h2}</h3>
        <p dangerouslySetInnerHTML={{ __html: data.page_content }}></p>
      </Paper>
    </div>
  );
}

export default PrivacyPolicy;
