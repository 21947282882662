import React, { useState, useEffect } from "react";
import "./About.css";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { aboutUs } from "../../api";
import axios from "axios";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  paper: {
    marginTop: "50px",
    marginBottom: "50px",
  },
});
function TermsCondtion() {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});

  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/get-page?page_id=7")
      .then((response) => {
        setMetaInfo(response.data.data);
      });
  }, []);

  useEffect(() => {
    (async () => {
      const aboutUsPage = await aboutUs();
      setIsLoading(false);
      setData(aboutUsPage.data);
    })();
  }, []);
  const mobileMatches = useMediaQuery("(min-width:600px)");
  return (
    <section className="about">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <div
        className="about__header"
        style={{ padding: mobileMatches ? "40px" : "10px" }}
      >
        <Typography variant="h2">About Us</Typography>
      </div>
      <div
        className="about__div"
        dangerouslySetInnerHTML={{ __html: data.page_content }}
      ></div>
    </section>
  );
}

export default TermsCondtion;
