import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import "./Careers.css";
import Paper from "@mui/material/Paper";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation, useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const BootstrapButton = withStyles({
  root: {
    border: "1px solid",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

function Careers() {
  const [singleJob, setSingleJob] = useState({});
  const location = useLocation();
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const formData = new FormData();
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  useEffect(() => {
    if (!location.state.job) history.push("/");
    setSingleJob(location.state.job);
    console.log(location.state.job);
  }, []);

  const mobileMatches = useMediaQuery("(min-width:600px)");
  return (
    <section className="careers">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <div
        className="careers__header"
        style={{ padding: mobileMatches ? "40px" : "10px" }}
      >
        <Typography variant="h2">Careers</Typography>
      </div>
      <h1 className="subHeaderr">{singleJob.heading_h1}</h1>
      <div
        className="careers__container"
        style={{ padding: mobileMatches ? "60px" : "5px" }}
      >
        <h2 className="jobTitle">{singleJob.title}</h2>
        <h3>Job Description</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: singleJob.meta_description,
          }}
        ></div>
        <Paper
          sx={{
            width: "400px",
            padding: "20px",
            margin: "auto",
          }}
          elevation={3}
        >
          <div>
            <CssTextField
              margin="dense"
              label="Full Name"
              onChange={(e) => formData.set("full_name", e.target.value)}
            />
            <CssTextField
              margin="dense"
              label="Email"
              onChange={(e) => formData.set("email", e.target.value)}
            />
            <CssTextField
              margin="dense"
              label="Mobile"
              onChange={(e) => formData.set("mobile", e.target.value)}
            />
            <p
              style={{
                marginBottom: "8px",
              }}
            >
              Resume
            </p>
            <input
              style={{
                marginBottom: "10px",
              }}
              onChange={(e) => {
                formData.set("resume", e.target.files[0]);
              }}
              type="file"
              accept=".doc,.docx,.pdf"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BootstrapButton
              size={!mobileMatches ? "small" : "medium"}
              style={{ fontSize: !mobileMatches && "10px" }}
              variant="contained"
              color="primary"
              onClick={async () => {
                formData.set("meta_description", singleJob.job_number);
                const res = await axios.post(
                  "https://api.pettofit.com/api/v1/apply-for-career",
                  formData
                );
                if (!res.data.error) {
                  toast.success("Application sent successfully");
                }
              }}
            >
              Apply
            </BootstrapButton>
          </div>
        </Paper>
      </div>
    </section>
  );
}

export default Careers;
