import React, { useEffect, useState } from "react";
import "./Vet.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Testimonial from "../Testimonial";
import FAQS from "../FAQS";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { GetSubscription, headers } from "../../api";
import { AddSubscription } from "../../api";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { toast } from "react-toastify";
import { verifySubscriptionPayment, getUserSubscriptions } from "../../api";
import Login from "../login/Login";
import { Backdrop } from "@mui/material";
import DateTimePicker from "../popups/DateTmePicker";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import axios from "axios";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { allCities } from "../../resources/allCities";
import { allSpecies } from "../../resources/allSpecies";
import { allGenders } from "../../resources/allGenders";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import SecurityIcon from "@mui/icons-material/Security";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CellWifiIcon from "@mui/icons-material/CellWifi";
import SpatialAudioIcon from "@mui/icons-material/SpatialAudio";
import Select from "react-select";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { razor_pay_key } from "../../constants";
import { Helmet } from "react-helmet";
import Carousel from "react-simply-carousel";
const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
      // transform: 'translate(14px, 12px) scale(1)',
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const theme1 = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        borderColor: "#000",
        "&:focus": {
          borderColor: "#09D1C7",
        },
      },
    },
  },
});

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    width: "90%",
    marginBottom: "30px",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const BootstrapButton3 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      borderColor: "#09D1C7",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: "#09D1C7",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  // textField:{
  //     marginBottom: '10px',
  // },

  blur: {
    position: "relative",
    backdropFilter: "blur(10px)",
  },
}));
const ImagesArray = [
  {
    title: "Efren Reyes",
    desc: 'Known as "The Magician", Efren Reyes is well regarded by many professionals as the greatest all around player of all time.',
    image: "https://i.postimg.cc/RhYnBf5m/er-slider.jpg",
  },
  {
    title: "Ronnie O'Sullivan",
    desc: "Ronald Antonio O'Sullivan is a six-time world champion and is the most successful player in the history of snooker.",
    image: "https://i.postimg.cc/qBGQNc37/ro-slider.jpg",
  },
  {
    title: "Shane Van Boening",
    desc: 'The "South Dakota Kid" is hearing-impaired and uses a hearing aid, but it has not limited his ability.',
    image: "https://i.postimg.cc/cHdMJQKG/svb-slider.jpg",
  },
  {
    title: "Mike Sigel",
    desc: 'Mike Sigel or "Captain Hook" as many like to call him is an American professional pool player with over 108 tournament wins.',
    image: "https://i.postimg.cc/C12h7nZn/ms-1.jpg",
  },
  {
    title: "Willie Mosconi",
    desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
    image: "https://i.postimg.cc/NfzMDVHP/willie-mosconi-slider.jpg",
  },
];
function Vet() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { user, isLoggedIn } = useSelector((state) => state.menu);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [activeMonth, setActiveMonth] = useState(6);
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [datatoDisplay, setDataToDisplay] = useState({});
  const [showPaymentloader, setShowPaymentLoader] = useState(false);
  const [userHasSubscription, setUserHasSubscription] = useState(false);
  const [loginBoxStatus, setLoginBoxStatus] = useState(false);
  const [backdropType, setBackdropType] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [appointmentTimeSlots, setAppointmentTimeSlots] = useState([]);
  const [appointmentDateTime, setAppointmentDateTime] = useState("");
  const [appointmentReason, setAppointmentReason] = useState("");
  const [species, setSpecies] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [textvalue, setTextValue] = React.useState("");
  const [petDetails, setPetDetails] = useState({});
  const [time, setTime] = useState("");
  const [heroBanner, setHeroBanner] = useState("");
  const [gif, setGif] = useState("");
  const [subscriptionImage, setSubscriptionImage] = useState("");
  const [activeSlide, setActiveSlide] = useState(0);
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleChange = (event) => {
    setSpecies(event.label);
  };

  const handleGender = (event) => {
    setGender(event.label);
  };

  const handleCity = (e) => {
    setCity(e.label);
    setState(e.state);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const fetchBanners = async () => {
    const hero = await axios
      .post("https://api.pettofit.com/api/v1/vet-banners", {
        type: 4,
      })
      .then((res) => {
        console.log("hero", res.data);
        return res.data;
      });
    const gif = await axios
      .post("https://api.pettofit.com/api/v1/vet-banners", {
        type: 5,
      })
      .then((res) => res.data);

    const subBanner = await axios
      .post("https://api.pettofit.com/api/v1/vet-banners", {
        type: 6,
      })
      .then((res) => res.data);
    if (!hero.error) {
      setHeroBanner(hero.desktop_image_link + "/" + hero.data[0].banner_image);
    }
    if (!gif.error) {
      setGif(gif.desktop_image_link + "/" + gif.data[0].banner_image);
    }
    if (!subBanner.error) {
      setSubscriptionImage(
        subBanner.desktop_image_link + "/" + subBanner.data[0].banner_image
      );
    }
  };
  useEffect(() => {
    setPetDetails((prevState) => ({
      ...prevState,
      name: user.name,
      email: user.email,
      mobile: user.mobile,
    }));
  }, [user]);
  useEffect(() => {
    fetchBanners();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (isLoggedIn) {
        const timeSlots = await axios.get(
          "https://api.pettofit.com/api/v1/all-appointments",
          {
            headers: headers,
          }
        );
        console.log("timeslots", timeSlots.data.data);
        if (!timeSlots.data?.error)
          setAppointmentTimeSlots(timeSlots.data.data.map((s) => s.time));
        fetchUserSubscription();
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const fetchUserSubscription = async () => {
    const userSubscriptions = await getUserSubscriptions().catch(console.error);
    if (!userSubscriptions) setUserHasSubscription(false);
    else {
      if (
        userSubscriptions.data.some(
          (sub) => sub.is_active === 1 && sub.type === 0
        )
      )
        setUserHasSubscription(true);
      else setUserHasSubscription(false);
    }
  };

  useEffect(() => {
    (async () => {
      const subscription = await GetSubscription();
      setSubscriptionDetail(subscription.data);
      setDataToDisplay(
        subscription.data.filter((s) => parseInt(s.month) === activeMonth)[0]
      );
      setIsLoading(false);
    })();
  }, [isLoggedIn]);

  useEffect(() => {
    (async () => {
      if (appointmentDateTime !== "") {
        if (userHasSubscription) {
          setShowPaymentLoader(true);
          const appointment = await axios.post(
            "https://api.pettofit.com/api/v1/book-appointment",
            {
              userId: user.id,
              problem: appointmentReason,
              req_datetime: appointmentDateTime,
            },
            {
              headers: headers,
            }
          );
          if (appointment.data.error)
            return toast.error(appointment.data.message);
          const appointmentId = appointment.data.data.id;
          const petData = {
            userId: user.id,
            appointment_id: appointmentId,
            ...petDetails,
            pet_gender: gender,
            city: city,
            state: state,
            species_id: species,
            issue_description: textvalue,
          };
          const formData = new FormData();
          Object.keys(petData).forEach((k) => {
            formData.append(k, petData[k]);
          });
          const petDescription = await axios
            .post("https://api.pettofit.com/api/v1/pet/add-pet", formData, {
              headers: headers,
            })
            .catch((res) => res.response);
          if (!petDescription.data.error || petDescription.status === 401)
            toast.success("Appointment booked sucessfully");
          else toast.error("Something went wrong");
          setShowPaymentLoader(false);
          setAppointmentReason("");
          setAppointmentDateTime("");
          setOpen(false);
        } else {
          setShowPaymentLoader(true);
          vetPurchase(1);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDateTime]);

  const vetPurchase = async (type) => {
    if (!isLoggedIn) {
      setLoginBoxStatus(true);
      return;
    }
    if (userHasSubscription && type === 0) {
      toast.success("You already have a subscription");
      return;
    }
    const data = {
      userId: user.id,
      plan_id: type ? 1 : datatoDisplay.id,
      duration: type ? 1 : activeMonth,
      subscribe_from_date: new Date().toISOString().split("T")[0],
      subscribe_to_date: new Date(
        new Date().setDate(new Date().getDate() + activeMonth * 30)
      )
        .toISOString()
        .split("T")[0],
      subscriber_email: user.email,
      type,
    };
    const res = await AddSubscription(data);
    if (!res.error) {
      displayRazorpay(res.data);
      console.log(res);
    } else {
      toast.error(res.message);
    }
  };
  useEffect(() => {
    setDataToDisplay(
      subscriptionDetail?.filter((s) => s.month == activeMonth)[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMonth]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(orderDetails) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    setShowPaymentLoader(false);
    if (!res) {
      alert("Please check your internet connection!");
      return;
    }
    const { amount, transaction_id } = orderDetails;
    const amountInPaise = amount * 100;
    const options = {
      key: razor_pay_key,
      amount: amountInPaise.toString(),
      currency: "INR",
      name: "Pettofit",
      description: "Vet Consultancy",
      order_id: transaction_id,
      handler: async function (response) {
        let responseData = {
          razorpay_order_id: transaction_id,
          razorpay_payment_id: response.razorpay_payment_id,
        };
        setShowPaymentLoader(true);
        let res = await verifySubscriptionPayment(responseData);
        fetchUserSubscription();
        if (!res.error) {
          setShowPaymentLoader(false);
          toast.success("Payment Successful", { autoClose: 2000 });
          fetchUserSubscription();
          if (appointmentDateTime !== "") {
            setShowPaymentLoader(true);
            const appointment = await axios
              .post(
                "https://api.pettofit.com/api/v1/book-appointment",
                {
                  userId: user.id,
                  problem: appointmentReason,
                  req_datetime: appointmentDateTime,
                },
                {
                  headers: headers,
                }
              )
              .catch((res) => res.response);
            if (appointment.data.error) {
              setShowPaymentLoader(false);
              setAppointmentReason("");
              setAppointmentDateTime("");
              setOpen(false);
              return toast.error(appointment.data.message);
            }
            const appointmentId = appointment.data.data.id;
            const petData = {
              userId: user.id,
              appointment_id: appointmentId,
              ...petDetails,
              pet_gender: gender,
              city: city,
              state: state,
              species_id: species,
              issue_description: textvalue,
            };
            const formData = new FormData();
            Object.keys(petData).forEach((k) => {
              formData.append(k, petData[k]);
            });
            const petDescription = await axios
              .post("https://api.pettofit.com/api/v1/pet/add-pet", formData, {
                headers: headers,
              })
              .catch((res) => res.response);
            if (!petDescription.data.error)
              toast.success("Appointment booked sucessfully");
            else toast.error("Something went wrong");
            setShowPaymentLoader(false);
            setAppointmentReason("");
            setAppointmentDateTime("");
            setOpen(false);
          }
          return;
        }
        toast.error("Something Went wrong ", { autoClose: 2000 });
      },
      prefill: {
        name: user.name,
        email: user.email,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleSubmit = (e) => {
    console.log("petDetails", petDetails);
    if (!petDetails.name) {
      toast.error("Pet Parent Name is required");
      return;
    }
    if (!petDetails.email) {
      toast.error("Email is required");
      return;
    }
    if (!petDetails.mobile) {
      toast.error("Mobile is required");
      return;
    }
    if (!petDetails.mobile.length === 10) {
      toast.error("Invalid mobile number");
      return;
    }
    if (!city) {
      toast.error("City is required");
      return;
    }
    if (!species || species.length == 0) {
      toast.error("Species is required");
      return;
    }
    if (!time) {
      toast.error("Time is required");
      return;
    }
    if (!date) {
      toast.error("Date is required");
      return;
    }
    setAppointmentReason(textvalue);

    setAppointmentDateTime(
      new Date(date).getFullYear() +
        "-" +
        (parseInt(new Date(date).getMonth()) + 1) +
        "-" +
        new Date(date).getDate() +
        " " +
        time
    );
  };

  const scheduleSession = async () => {
    if (!isLoggedIn) {
      setLoginBoxStatus(true);
      return;
    }
    setBackdropType("schedule");
    setBackdropOpen(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (!isLoggedIn) {
      setLoginBoxStatus(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  var defaultCity = city ? allCities.filter((c) => c.label == city)[0] : null;
  var defaultSpecies = species
    ? allSpecies.filter((c) => c.label == species)[0]
    : null;
  var defaultGender = gender
    ? allGenders.filter((c) => c.label == gender)[0]
    : null;
  return (
    <>
      <div className="vet">
        <Helmet>
          <title>
            Book Online Vet Consultation at Just Rs 299 | Best Online Veterinary
            Services
          </title>
        </Helmet>
        {!isLoading && (
          <>
            <section className="vetbanner banner section">
              <img
                src="https://api.pettofit.com/vet-banners/mobile/vet-banner-2.png"
                className="vetbannermobilebackground"
                alt=""
              />
              <div className="banner-side">
                <h2>
                  {/* Lorem ipsum dolor sit amet, <span>consetetur</span> */}
                </h2>

                <BootstrapButton3
                  variant="outlined"
                  onClick={handleClickOpen}
                  className="modal_btn vetbannerbutton"
                  style={{ marginBottom: "10px", right: "234px", top: "15px" }}
                >
                  Book an Appointment
                </BootstrapButton3>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Book an Appointment</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      To book an appointment with one of the best vets, please
                      enter some details here. We will try to offer best for
                      your pets.
                    </DialogContentText>
                    <CssTextField
                      className={classes.textField}
                      margin="dense"
                      id="outlined-size-small"
                      label="Pet Parent Name *"
                      type="text"
                      defaultValue={user.name}
                      fullWidth
                      onChange={(e) => {
                        setPetDetails({ ...petDetails, name: e.target.value });
                      }}
                    />
                    <CssTextField
                      className={classes.textField}
                      margin="dense"
                      id="outlined-size-small"
                      label="Pet Name"
                      type="text"
                      fullWidth
                      onChange={(e) => {
                        setPetDetails({
                          ...petDetails,
                          pet_name: e.target.value,
                        });
                      }}
                    />
                    <CssTextField
                      className={classes.textField}
                      margin="dense"
                      id="outlined-size-small"
                      label="Email Address *"
                      defaultValue={user.email}
                      type="email"
                      fullWidth
                      onChange={(e) =>
                        setPetDetails({ ...petDetails, email: e.target.value })
                      }
                    />
                    <CssTextField
                      margin="dense"
                      id="outlined-size-small"
                      label="Phone Number *"
                      type="tel"
                      defaultValue={user.mobile}
                      fullWidth
                      onChange={(e) =>
                        setPetDetails({ ...petDetails, mobile: e.target.value })
                      }
                    />
                    <ThemeProvider theme={theme1}>
                      <FormControl
                        // variant="standard"
                        style={{ marginTop: "8px", marginBottom: "4px" }}
                        sx={{ m: 1, minWidth: "100%", margin: "0px" }}
                      >
                        <Select
                          value={defaultCity}
                          onChange={handleCity}
                          options={allCities}
                          placeholder="City *"
                        />
                      </FormControl>
                    </ThemeProvider>
                    <CssTextField
                      margin="dense"
                      label="State"
                      type="text"
                      fullWidth
                      id="outlined-size-small"
                      value={state}
                    />
                    <ThemeProvider theme={theme1}>
                      <FormControl
                        style={{ marginTop: "8px", marginBottom: "4px" }}
                        sx={{ m: 1, minWidth: "100%", margin: "0px" }}
                      >
                        <Select
                          value={defaultSpecies}
                          onChange={handleChange}
                          options={allSpecies}
                          placeholder="Species *"
                          isSearchable={false}
                        />
                      </FormControl>
                      <FormControl
                        // variant="standard"
                        style={{ marginTop: "8px", marginBottom: "4px" }}
                        sx={{ m: 1, minWidth: "100%", margin: "0px" }}
                      >
                        <Select
                          value={defaultGender}
                          onChange={handleGender}
                          options={allGenders}
                          placeholder="Gender"
                          isSearchable={false}
                        />
                      </FormControl>
                    </ThemeProvider>
                    {/* <CssTextField
                      margin="dense"
                      id="outlined-size-small"
                      label="Age"
                      type="number"
                      fullWidth
                      onChange={(e) =>
                        setPetDetails({ ...petDetails, age: e.target.value })
                      }
                    />
                    <CssTextField
                      margin="dense"
                      id="outlined-size-small"
                      label="Weight"
                      type="number"
                      fullWidth
                      onChange={(e) =>
                        setPetDetails({ ...petDetails, weight: e.target.value })
                      }
                    />
                    <CssTextField
                      margin="dense"
                      id="outlined-size-small"
                      label="Vaccination Status"
                      type="text"
                      fullWidth
                      onChange={(e) =>
                        setPetDetails({
                          ...petDetails,
                          vacination_stage: e.target.value,
                        })
                      }
                    />
                    <br />
                    <br /> */}
                    <LocalizationProvider
                      // style={{ marginTop: "15px !important" }}
                      style={{
                        marginTop: "8px !important",
                        marginBottom: "4px !important",
                      }}
                      dateAdapter={AdapterDateFns}
                    >
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="Date *"
                          value={date}
                          inputFormat="MM/dd/yyyy"
                          minDate={new Date()}
                          onChange={handleDateChange}
                          renderInput={(params) => <CssTextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <Autocomplete
                      style={{ marginTop: "12px", marginBottom: "4px" }}
                      id="disabled-options-demo"
                      options={appointmentTimeSlots}
                      onChange={(e, value) => {
                        setTime(value);
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <CssTextField {...params} label="Select Time Slot *" />
                      )}
                    />
                    <br />
                    <CssTextField
                      id="outlined-size-small"
                      label="Description"
                      multiline
                      maxRows={4}
                      value={textvalue}
                      onChange={handleTextChange}
                      // variant="standard"
                      style={{ width: "100%" }}
                    />
                    <br />
                    <br />
                    <label htmlFor="contained-button-file">
                      <input
                        accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf"
                        id="outlined-size-small"
                        type="file"
                        onChange={(e) => {
                          console.log(e.target.files[0]);
                          setPetDetails({
                            ...petDetails,
                            attachment: e.target.files[0],
                          });
                        }}
                      />
                    </label>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="modal_btn"
                    >
                      Cancel
                    </Button>
                    <BootstrapButton3
                      variant="outlined"
                      className="modal_btn"
                      onClick={handleSubmit}
                    >
                      Book
                    </BootstrapButton3>
                  </DialogActions>
                </Dialog>
              </div>
            </section>
            <section className="feature">
              <div>
                <img
                  src="https://api.pettofit.com/uploads/icons/vets/1.png"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <div>
                <img
                  src="https://api.pettofit.com/uploads/icons/vets/2.png"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <div>
                <img
                  src="https://api.pettofit.com/uploads/icons/vets/3.png"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <div>
                <img
                  src="https://api.pettofit.com/uploads/icons/vets/4.png"
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </div>
              {/* <div>
                <img src={require("../../image/Group877.png").default} alt="" />
                Experienced Vets
              </div> */}
              {/* <div>
                <img src={require("../../image/Group878.png").default} alt="" />
                Video/Audio Calling Facility
              </div> */}
              {/* <div>
                <img src={require("../../image/Group879.png").default} alt="" />
                Chat with Vet
              </div> */}
              {/* <div>
                <img src={require("../../image/Group880.png").default} alt="" />
                On Demand Vet Consultation
              </div> */}
            </section>
            <BootstrapButton3
              variant="outlined"
              onClick={handleClickOpen}
              className="modal_btn vetbannermobilebutton"
              style={{ marginBottom: "10px" }}
            >
              Book an Appointment
            </BootstrapButton3>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Book an Appointment</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To book an appointment with one of the best vets, please enter
                  some details here. We will try to offer best for your pets.
                </DialogContentText>
                <CssTextField
                  className={classes.textField}
                  margin="dense"
                  id="outlined-size-small"
                  label="Pet Parent Name *"
                  type="text"
                  defaultValue={user.name}
                  fullWidth
                  onChange={(e) => {
                    setPetDetails({ ...petDetails, name: e.target.value });
                  }}
                />
                <CssTextField
                  className={classes.textField}
                  margin="dense"
                  id="outlined-size-small"
                  label="Pet Name"
                  type="text"
                  fullWidth
                  onChange={(e) => {
                    setPetDetails({ ...petDetails, pet_name: e.target.value });
                  }}
                />
                <CssTextField
                  className={classes.textField}
                  margin="dense"
                  id="outlined-size-small"
                  label="Email Address *"
                  defaultValue={user.email}
                  type="email"
                  fullWidth
                  onChange={(e) =>
                    setPetDetails({ ...petDetails, email: e.target.value })
                  }
                />
                <CssTextField
                  margin="dense"
                  id="outlined-size-small"
                  label="Phone Number *"
                  defaultValue={user.mobile}
                  type="tel"
                  fullWidth
                  onChange={(e) =>
                    setPetDetails({ ...petDetails, mobile: e.target.value })
                  }
                />
                <ThemeProvider theme={theme1}>
                  <FormControl
                    // variant="standard"
                    style={{ marginTop: "8px", marginBottom: "4px" }}
                    sx={{ m: 1, minWidth: "100%", margin: "0px" }}
                  >
                    <Select
                      options={allCities}
                      placeholder="City *"
                      value={defaultCity}
                      onChange={handleCity}
                    />
                  </FormControl>
                </ThemeProvider>
                <CssTextField
                  margin="dense"
                  label="State"
                  type="text"
                  fullWidth
                  id="outlined-size-small"
                  value={state}
                />
                <ThemeProvider theme={theme1}>
                  <FormControl
                    style={{ marginTop: "8px", marginBottom: "4px" }}
                    sx={{ m: 1, minWidth: "100%", margin: "0px" }}
                  >
                    <Select
                      value={defaultSpecies}
                      onChange={handleChange}
                      options={allSpecies}
                      placeholder="Species *"
                      isSearchable={false}
                    />
                  </FormControl>
                  <FormControl
                    // variant="standard"
                    style={{ marginTop: "8px", marginBottom: "4px" }}
                    sx={{ m: 1, minWidth: "100%", margin: "0px" }}
                  >
                    <Select
                      value={defaultGender}
                      onChange={handleGender}
                      options={allGenders}
                      placeholder="Gender"
                      isSearchable={false}
                    />
                  </FormControl>
                </ThemeProvider>
                {/* <CssTextField
                  margin="dense"
                  id="outlined-size-small"
                  label="Age"
                  type="number"
                  fullWidth
                  onChange={(e) =>
                    setPetDetails({ ...petDetails, age: e.target.value })
                  }
                />
                <CssTextField
                  margin="dense"
                  id="outlined-size-small"
                  label="Weight"
                  type="number"
                  fullWidth
                  onChange={(e) =>
                    setPetDetails({ ...petDetails, weight: e.target.value })
                  }
                />
                <CssTextField
                  margin="dense"
                  id="outlined-size-small"
                  label="Vaccination Status"
                  type="text"
                  fullWidth
                  onChange={(e) =>
                    setPetDetails({
                      ...petDetails,
                      vacination_stage: e.target.value,
                    })
                  }
                />
                <br />
                <br /> */}
                <LocalizationProvider
                  // style={{ marginTop: "15px !important" }}
                  style={{
                    marginTop: "8px !important",
                    marginBottom: "4px !important",
                  }}
                  dateAdapter={AdapterDateFns}
                >
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label="Date *"
                      value={date}
                      inputFormat="MM/dd/yyyy"
                      minDate={new Date()}
                      onChange={handleDateChange}
                      renderInput={(params) => <CssTextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
                <Autocomplete
                  style={{ marginTop: "12px", marginBottom: "4px" }}
                  id="disabled-options-demo"
                  options={appointmentTimeSlots}
                  onChange={(e, value) => {
                    setTime(value);
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <CssTextField {...params} label="Select Time Slot *" />
                  )}
                />
                <br />
                <CssTextField
                  id="outlined-size-small"
                  label="Description"
                  multiline
                  maxRows={4}
                  value={textvalue}
                  onChange={handleTextChange}
                  // variant="standard"
                  style={{ width: "100%" }}
                />
                <br />
                <br />
                <label htmlFor="contained-button-file">
                  <input
                    accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf"
                    id="outlined-size-small"
                    type="file"
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      setPetDetails({
                        ...petDetails,
                        attachment: e.target.files[0],
                      });
                    }}
                  />
                </label>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  className="modal_btn"
                >
                  Cancel
                </Button>
                <BootstrapButton3
                  variant="outlined"
                  className="modal_btn"
                  onClick={handleSubmit}
                >
                  Book
                </BootstrapButton3>
              </DialogActions>
            </Dialog>

            {gif && (
              <section>
                {/* <h1
                style={{
                  textAlign: "center",
                  marginTop: "250px",
                }}
              > */}
                <center>
                  <img src={gif} style={{ width: "30%" }} />
                </center>
                {/* </h1> */}
              </section>
            )}
            {/* <section className="subscription section">
              <div className="box">
                <div className="title">
                  <h2>
                    Pick the best <br /> subscription plan
                  </h2>
                  <p>Get 24X7 online veterinary consulting service</p>
                </div>
              </div>
              <div className="box">
                <div className="subscriptionBox">
                  <img
                    src={require("../../image/banner_top.png").default}
                    alt=""
                  />
                  <h2 className="price">Rs {datatoDisplay?.amount}</h2>
                  <div className="featureBox">
                    <div className="content">
                      <CheckCircleIcon className="icon" />
                      <p>{datatoDisplay?.description}</p>
                    </div>
                  </div>
                  <div className="monthSelector">
                    <div
                      className="activeBox"
                      style={{
                        transform:
                          activeMonth === 1
                            ? "translate(-165%, -50%)"
                            : activeMonth === 6
                            ? "translate(-50%, -50%)"
                            : "translate(65%, -50%)",
                      }}
                    ></div>
                    <div
                      className={`monthName ${activeMonth === 1 && "active"}`}
                      onClick={() => setActiveMonth(1)}
                    >
                      01
                    </div>
                    <div
                      className={`monthName ${activeMonth === 6 && "active"}`}
                      onClick={() => setActiveMonth(6)}
                    >
                      06
                    </div>
                    <div
                      className={`monthName ${activeMonth === 12 && "active"}`}
                      onClick={() => setActiveMonth(12)}
                    >
                      12
                    </div>
                  </div>
                  <h3>MONTH</h3>
                  <BootstrapButton2
                    variant="contained"
                    onClick={() => vetPurchase(0)}
                  >
                    Subscribe now
                  </BootstrapButton2>
                </div>
              </div>
              {mobileMatches && (
                <img className="backgroundImg" src={subscriptionImage} alt="" />
              )}
            </section> */}
            <section>
              <div className="gifImageSuperContainer" elevation={3}>
                <div className="gifImageContainer gifImageContainerBigDevice">
                  <img
                    src="https://api.pettofit.com/uploads/final-1.gif"
                    alt="Ask a vet anything"
                  />
                  <div>
                    <h2>Ask a Vet Anything</h2>
                    <p>
                      Health Issues, Behaviour Issue, Nutrition Guidance. At
                      Pettofit, we believe there is no question too small to ask
                      , weather its Diet or rash or anything you are scared of.
                    </p>
                  </div>
                </div>

                <div className="gifImageContainer gifImageContainerSmallDevice">
                  <div>
                    <h2>Ask a Vet Anything</h2>
                    <p>
                      Health Issues, Behaviour Issue, Nutrition Guidance. At
                      Pettofit, we believe there is no question too small to ask
                      , weather its Diet or rash or anything you are scared of.
                    </p>
                  </div>
                  <img
                    src="https://api.pettofit.com/uploads/final-1.gif"
                    alt="Ask a vet anything"
                  />
                </div>
              </div>
            </section>
            {/* <Testimonial /> */}
            <section className="section vetgraysection">
              <div className="section-inner vetgraysection-inner">
                <div className="sectionHeader vetSectionHeader">
                  <h3>We will help you with</h3>
                </div>
                <div className="vetsectionimages">
                  <div className="vetsectionimage">
                    <img
                      src="https://api.pettofit.com/uploads/icons/vets/health.png"
                      alt="health issues"
                    />
                    <p>Health Issues</p>
                  </div>
                  <div className="vetsectionimage">
                    <img
                      src="https://api.pettofit.com/uploads/icons/vets/behaviour.png"
                      alt="Behavioural issues"
                    />
                    <p>Behavioural Issues</p>
                  </div>
                  <div className="vetsectionimage">
                    <img
                      src="https://api.pettofit.com/uploads/icons/vets/nutrition.png"
                      alt="Nutrition"
                    />
                    <p>Nutrition</p>
                  </div>
                  <div className="vetsectionimage">
                    <img
                      src="https://api.pettofit.com/uploads/icons/vets/training.png"
                      alt="Training related Issues"
                    />
                    <p>Training related Issues</p>
                  </div>
                  <div className="vetsectionimage">
                    <img
                      src="https://api.pettofit.com/uploads/icons/vets/adoption.png"
                      alt="Adoption Guidance"
                    />
                    <p>Adoption Guidance</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="section-inner vetgraysection-inner">
                <div className="sectionHeader vetSectionHeader">
                  <h3>We assure</h3>
                </div>
                <div className="vetsectionimages">
                  <div className="vetsectionimage">
                    <SecurityIcon />
                    <p>Privacy</p>
                    <p className="vetsectionimageexplain">
                      Secure & Confidential session with vet
                    </p>
                  </div>
                  <div className="vetsectionimage">
                    <WorkspacePremiumIcon />
                    <p>Quality</p>
                    <p className="vetsectionimageexplain">
                      Experienced Veterinarian
                    </p>
                  </div>
                  <div className="vetsectionimage">
                    <EmojiEmotionsIcon />
                    <p>Convenience</p>
                    <p className="vetsectionimageexplain">
                      Online Communication Modes
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="section vetgraysection">
              <div className="section-inner vetgraysection-inner">
                <div className="sectionHeader vetSectionHeader">
                  <h3>How does it work</h3>
                </div>
                <div className="vetprocesscontainer">
                  <AccessTimeIcon />
                  <p>
                    Join the session at least 2 mins before the start time-
                    accessible from 'My appointment' on profile section on
                    Pettofit Platform
                  </p>
                </div>
                <div className="vetprocesscontainer">
                  <PostAddIcon />
                  <p>
                    Share the relevant document or information while booking or
                    on chat with the vet beforehand
                  </p>
                </div>
                <div className="vetprocesscontainer">
                  <CellWifiIcon />
                  <p>Ensure Internet connectivity is good</p>
                </div>
                <div className="vetprocesscontainer">
                  <SpatialAudioIcon />
                  <p>Use Earphone to avoid echo issues</p>
                </div>
              </div>
            </section>
            <div>
              <div className="sectionHeader vetSectionHeader">
                <h3>Our veterinarian team</h3>
              </div>
              <Carousel
                activeSlideIndex={activeSlide}
                onRequestChange={setActiveSlide}
                forwardBtnProps={{
                  style: {
                    transform: "rotate(-45deg)",
                    borderWidth: "0 0.4rem 0.4rem 0",
                    height: "3rem",
                    width: "3rem",
                    background: 0,
                    alignSelf: "center",
                    cursor: "pointer",
                    display: window.innerWidth > 600 ? "block" : "none",
                  },
                }}
                backwardBtnProps={{
                  style: {
                    transform: "rotate(135deg)",
                    borderWidth: "0 0.4rem 0.4rem 0",
                    height: "3rem",
                    width: "3rem",
                    background: 0,
                    alignSelf: "center",
                    cursor: "pointer",
                    display: window.innerWidth > 600 ? "block" : "none",
                  },
                }}
                dotsNav={{
                  show: false,
                }}
                itemsToShow={4}
                speed={400}
              >
                {ImagesArray.map((item, index) => (
                  <div>
                    <Card
                      sx={{ minWidth: 300 }}
                      style={{ margin: "10px", borderRadius: "20px" }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="300"
                          image={item.image}
                          alt="green iguana"
                        />
                        <CardContent style={{ minHeight: "150px" }}>
                          <Typography gutterBottom variant="h5" component="div">
                            {item.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.desc}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                ))}
              </Carousel>
            </div>
            <FAQS id={2} />
          </>
        )}
        {isLoading && (
          <>
            <Stack
              spacing={4}
              sx={{
                padding: "20px",
              }}
            >
              {/* main slider */}
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={mobileMatches ? 550 : 290}
              />
              {/* square sections */}
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <div>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={mobileMatches ? 220 : 80}
                    height={mobileMatches ? 190 : 90}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={mobileMatches ? 220 : 80}
                    height={mobileMatches ? 190 : 90}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={mobileMatches ? 220 : 80}
                    height={mobileMatches ? 190 : 90}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={mobileMatches ? 220 : 80}
                    height={mobileMatches ? 190 : 90}
                  />
                </div>
              </Stack>
              {/* text and heading */}
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={mobileMatches ? 270 : 50}
              />
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              ></Stack>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={mobileMatches ? 600 : 640}
                // height={550}
              />

              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <Skeleton
                  animation="wave"
                  width={300}
                  height={50}
                  variant="text"
                />
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={mobileMatches ? 150 : 70}
                    height={mobileMatches ? 150 : 70}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={mobileMatches ? 150 : 70}
                    height={mobileMatches ? 150 : 70}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={mobileMatches ? 150 : 70}
                    height={mobileMatches ? 150 : 70}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={mobileMatches ? 150 : 70}
                    height={mobileMatches ? 150 : 70}
                  />
                </div>
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={mobileMatches ? 150 : 70}
                    height={mobileMatches ? 150 : 70}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={mobileMatches ? 150 : 70}
                    height={mobileMatches ? 150 : 70}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={mobileMatches ? 150 : 70}
                    height={mobileMatches ? 150 : 70}
                  />
                </div>
                <div>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={mobileMatches ? 150 : 70}
                    height={mobileMatches ? 150 : 70}
                  />
                </div>
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  width={200}
                  height={60}
                  variant="text"
                />
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              ></Stack>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={mobileMatches ? 190 : 230}
                // height={550}
              />
            </Stack>
          </>
        )}
      </div>
      {showPaymentloader && (
        <div
          style={{
            background: "rgba(0,0,0,0.5)",
            position: "fixed",
            width: "100vw",
            height: "100vh",
            zIndex: 999999999,
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fefefe",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
      <Login
        setLoginBoxStatus={setLoginBoxStatus}
        loginBoxStatus={loginBoxStatus}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={backdropOpen}
      >
        {backdropType === "schedule" && (
          <DateTimePicker
            setAppointmentDateTime={setAppointmentDateTime}
            slots={appointmentTimeSlots}
            setAppointmentReason={setAppointmentReason}
            closeBackdrop={() => {
              setBackdropOpen(false);
              setBackdropType("");
            }}
          />
        )}
      </Backdrop>
    </>
  );
}

export default Vet;
