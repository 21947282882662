import React, { useRef, useState } from "react";
import "./ProductListing.css";
import Divider from "@material-ui/core/Divider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ProductSlider from "../product/ProductSlider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "@material-ui/core/Modal";
import Grid from "@mui/material/Grid";
import CheckIcon from "@material-ui/icons/Check";
import Stack from "@mui/material/Stack";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import Slide from "@material-ui/core/Slide";
import fetchProductPage from "../../services/http/productpageService";
import { fetchCatalog } from "../../api";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Helmet } from "react-helmet";
import RemoveIcon from "@mui/icons-material/Remove";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let fetchScroll = true;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "0 0 10px lightgray",
  },
  whitespace: {
    whiteSpace: "nowrap",
  },
  checkIcon: {
    color: "#09D1C7",
  },
  checkbox: {
    color: "#09D1C7 !important",
    padding: "1px !important",
    paddingLeft: "10px !important",
    fontSize: "15px !important",
  },
}));

const PrettoSlider = styled(Slider)({
  color: "#09D1C7",
  width: "80%",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 12,
    width: 12,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#09D1C7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

let meta_title = "";
let heading_h1_ls = "";
let content_ls = "";
let banner_image = "";
let mob_banner_image = "";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let _products = [];

function ProductListing() {
  const classes = useStyles();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [sortBy, setSortBy] = useState("Recomended");
  const [showFilterBy, setShowFilterBy] = useState("Categories");
  const [openSortBox, setOpenSortBox] = useState(false);
  const [openFilterBox, setOpenFilterBox] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuLoading, setMenuLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [mobileSelectedFilters, setMobileSelectedFilters] = useState({});
  const [mobileBrandIds, setMobileBrandIds] = useState([]);
  const [productListToRender, setProductListToRender] = useState([]);
  const [catalogsToRender, setCatalogsToRender] = useState([]);
  const timer = useRef(null);
  const [productsFromServer, setProductsFromServer] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [product, setProductPage] = useState({});
  const [metaInfo, setMetaInfo] = useState({});
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalField, setModalField] = useState({});
  const [brandIds, setBrandIds] = useState([]);
  const [openedFilters, setOpenedFilters] = useState([]);

  const subCategoryId = new URLSearchParams(window.location.search).get("sid")
    ? atob(new URLSearchParams(window.location.search).get("sid"))
    : null;

  const categoryId = new URLSearchParams(window.location.search).get("cid")
    ? atob(new URLSearchParams(window.location.search).get("cid"))
    : null;

  const parentId = new URLSearchParams(window.location.search).get("pid")
    ? atob(new URLSearchParams(window.location.search).get("pid"))
    : null;

  const fetchProducts = async (page) => {
    let optionIds = [];
    if (mobileMatches) {
      Object.values(selectedFilters).forEach((valueArray) => {
        if (valueArray.length > 0) {
          optionIds = optionIds.concat(valueArray);
        }
      });
    } else {
      Object.values(mobileSelectedFilters).forEach((valueArray) => {
        if (valueArray.length > 0) {
          optionIds = optionIds.concat(valueArray);
        }
      });
    }
    if (categoryId) {
      const products = await fetchProductPage({
        page: page,
        category_id: categoryId,
        option_id: optionIds.length == 0 ? null : optionIds.join(","),
        priceRange: priceRange,
        brandIds: mobileMatches
          ? brandIds.length > 0
            ? brandIds.join(",")
            : "0"
          : mobileBrandIds.length > 0
          ? mobileBrandIds.join(",")
          : "0",
      });
      return products;
    } else if (subCategoryId) {
      const products = await fetchProductPage({
        page: page,
        sub_category_id: subCategoryId,
        option_id: optionIds.length == 0 ? null : optionIds.join(","),
        priceRange: priceRange,
        brandIds: mobileMatches
        ? brandIds.length > 0
          ? brandIds.join(",")
          : "0"
        : mobileBrandIds.length > 0
        ? mobileBrandIds.join(",")
        : "0",
      });
      return products;
    } else if (parentId) {
      const products = await fetchProductPage({
        page: page,
        p_category_id: parentId,
        option_id: optionIds.length == 0 ? null : optionIds.join(","),
        priceRange: priceRange,
        brandIds: mobileMatches
        ? brandIds.length > 0
          ? brandIds.join(",")
          : "0"
        : mobileBrandIds.length > 0
        ? mobileBrandIds.join(",")
        : "0",
      });
      return products;
    }
  };

  const handleClose = () => {
    setModalField({});
    setOpenModal(false);
  };
  useEffect(() => {
    var fetchingid = categoryId
      ? { cid: categoryId }
      : subCategoryId
      ? { sid: subCategoryId }
      : { pid: parentId };
    (async () => {
      const catalogs = await fetchCatalog(fetchingid);
      if (catalogs && !catalogs.data.error) {
        setCatalogsToRender(catalogs.data);
        setOpenedFilters(catalogs.data.slice(0, 4).map((c) => c.label_name));
      }
    })();
    setPage(1);
  }, [categoryId, subCategoryId, parentId]);

  useEffect(() => {
    (async () => {
      const productPage = await fetchProducts(page);
      _products = productPage.data;
      if (!productPage.has_more) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      meta_title = productPage.meta_title;
      heading_h1_ls = productPage.heading_h1;
      
      content_ls = productPage.content;
      banner_image = productPage.banner_image;
      mob_banner_image = productPage.mob_banner_image;
      if (page > 1) {
        setProductListToRender([...productListToRender, ...productPage.data]);
        setProductsFromServer([...productListToRender, ...productPage.data]);
      } else {
        setProductListToRender(productPage.data);
        setProductsFromServer(productPage.data);
      }
      setMenuLoading(false);
      fetchScroll = true;
    })();
  }, [page, selectedFilters, brandIds]);

  useEffect(() => {
    (async () => {
      const productPage = await fetchProducts(page);
      _products = productPage.data;
      if (!productPage.has_more) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      meta_title = productPage.meta_title;
      heading_h1_ls = productPage.heading_h1;
      
      content_ls = productPage.content;
      banner_image = productPage.banner_image;
      mob_banner_image = productPage.mob_banner_image;
      setProductListToRender(productPage.data);
      setProductsFromServer(productPage.data);
      setMenuLoading(false);
    })();
  }, [categoryId, subCategoryId, parentId]);

  const applySelectedFilters = async () => {
    const productPage = await fetchProducts(page);
    _products = productPage.data;
    if (!productPage.has_more) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
    }
    meta_title = productPage.meta_title;
    heading_h1_ls = productPage.heading_h1;
    content_ls = productPage.content;
    banner_image = productPage.banner_image;
    mob_banner_image = productPage.mob_banner_image;
  
    setProductListToRender(productPage.data);
    setProductsFromServer(productPage.data);
    setMenuLoading(false);
  };

  const scrollToEnd = () => {
    setTimeout(() => {
      if (fetchScroll) {
        setPage((prevState) => {
          fetchScroll = false;
          return prevState + 1;
        });
      }
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener(
      "scroll",
      function () {
        var element = document.getElementById("loadingelement");
        if (
          element &&
          element.getBoundingClientRect().top <= window.innerHeight
        ) {
          scrollToEnd();
        }
      },
      true
    );
  }, []);
  let query = useQuery();

  // useEffect(() => {
  //   if (!isLoading) {
  //     const categoryFilter = atob(query.get("c"));
  //     const filterToSet = {
  //       categories: query.get("c") ? parseInt(categoryFilter) : "",
  //     };
  //     setCatalogsToRender(
  //       catalogs.filter(
  //         (catalog) =>
  //           catalog.cats.id === filterToSet.categories ||
  //           catalog.cats.parent === filterToSet.categories ||
  //           catalog.cats.parentDetails.parent === filterToSet.categories
  //       )
  //     );
  //   }
  // }, [isLoading, query.get("c"), catalogs.length]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     if (
  //       !Object.keys(selectedFilters).some((key) => selectedFilters[key].length)
  //     ) {
  //       setProductListToRender(product);
  //       return;
  //     }
  //     const filteredProducts = product.filter((product) => {
  //       return product?.catalogs?.lables?.some((label) => {
  //         if (selectedFilters[label.label_name]) {
  //           if (
  //             label.options?.some((op) =>
  //               selectedFilters[label.label_name]?.some(
  //                 (s) => s === op.option_name
  //               )
  //             )
  //           ) {
  //             return true;
  //           }
  //           return false;
  //         }
  //       });
  //     });
  //     setProductListToRender(filteredProducts);
  //   }
  // }, [selectedFilters, isLoading]);

  const [brand, setBrand] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    setBrand({ ...brand, [event.target.name]: event.target.checked });
  };

  return (
    <div className="productListing">
      <Helmet>
        <title>{meta_title}</title>
      </Helmet>
      {mobileMatches && (
        <div className="productListing__left">
          <div>
            <h3>Price</h3>
            <PrettoSlider
              valueLabelDisplay="auto"
              getAriaLabel={(index) =>
                index === 0 ? "Minimum price" : "Maximum price"
              }
              defaultValue={[0, 100000]}
              min={0}
              max={100000}
              onChangeCommitted={(_, value) => {
                setPriceRange(value);
              }}
              disableSwap
            />
            <h5
              style={{
                marginTop: 0,
                fontWeight: 500,
                marginBottom: "0",
              }}
            >
              Price range ₹{priceRange[0]} - ₹{priceRange[1]}
            </h5>
            <FormGroup>
              {isMenuLoading &&
                new Array(40).fill(1).map((e, i) => (
                  <FormControlLabel
                    sx={{
                      marginBottom: "8px",
                    }}
                    control={
                      <Checkbox className={classes.checkbox} name="gilad" />
                    }
                    label={
                      <Skeleton
                        variant="text"
                        width={180}
                        height={30}
                        animation="wave"
                      />
                    }
                  />
                ))}
              {!isMenuLoading &&
                catalogsToRender.map((catalog, index) => (
                  <>
                    {catalog.option.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          marginTop: "30px",
                        }}
                      >
                        <h3
                          style={{
                            marginBottom: "10px",
                            fontSize: "18px",
                          }}
                        >
                          {catalog.label_name}
                        </h3>
                        {openedFilters &&
                        openedFilters.length > 0 &&
                        openedFilters.includes(catalog.label_name) ? (
                          <RemoveIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenedFilters((prevState) => {
                                prevState = prevState.filter(
                                  (p) => p != catalog.label_name
                                );
                                return prevState;
                              });
                            }}
                          />
                        ) : (
                          <AddRoundedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenedFilters((prevState) => {
                                return [...prevState, catalog.label_name];
                              });
                            }}
                          />
                        )}
                      </div>
                    )}

                    {openedFilters.includes(catalog.label_name) &&
                      catalog.option.slice(0, 7).map((option) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                className={classes.checkbox}
                                name={option.name}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (catalog.label_name == "Brands") {
                                      setBrandIds((prevState) => {
                                        return [...prevState, option.id];
                                      });
                                    } else {
                                      if (!selectedFilters[catalog.label_name])
                                        setSelectedFilters({
                                          ...selectedFilters,
                                          [catalog.label_name]: [option.id],
                                        });
                                      else
                                        setSelectedFilters({
                                          ...selectedFilters,
                                          [catalog.label_name]: [
                                            ...selectedFilters[
                                              catalog.label_name
                                            ],
                                            option.id,
                                          ],
                                        });
                                    }
                                  } else {
                                    if (catalog.label_name == "Brands") {
                                      setBrandIds((prevState) => {
                                        prevState = prevState.filter(
                                          (p) => p != option.id
                                        );
                                        return prevState;
                                      });
                                    } else {
                                      setSelectedFilters({
                                        ...selectedFilters,
                                        [catalog.label_name]: selectedFilters[
                                          catalog.label_name
                                        ].filter(
                                          (filter) => filter !== option.id
                                        ),
                                      });
                                    }
                                  }
                                  setPage(1);
                                }}
                                checked={
                                  (catalog.label_name == "Brands" &&
                                    brandIds.includes(option.id)) ||
                                  (catalog.label_name != "Brands" &&
                                    selectedFilters[catalog.label_name] &&
                                    selectedFilters[catalog.label_name].some(
                                      (s) => s == option.id
                                    ))
                                }
                              />
                            }
                            className="filter-label"
                            label={option.name}
                          />
                        );
                      })}
                    {catalog.option.length > 7 && (
                      <p
                        className="filterMore"
                        onClick={() => {
                          setOpenModal(true);
                          setModalField(catalog);
                        }}
                      >
                        +{catalog.option.length - 7} more
                      </p>
                    )}
                  </>
                ))}
            </FormGroup>
          </div>
        </div>
      )}
      <div className="productListing__right">
        
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Breadcrumbs className="breadcrumb" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Home
            </Link>
            <Link
              color="inherit"
              to={
                "/products?" +
                (categoryId
                  ? "cid=" +
                    new URLSearchParams(window.location.search).get("cid")
                  : subCategoryId
                  ? "sid=" +
                    new URLSearchParams(window.location.search).get("sid")
                  : "pid=" +
                    new URLSearchParams(window.location.search).get("pid"))
              }
            >
              {productListToRender && productListToRender.length > 0
                ? productListToRender[0].category_name
                : ""}
            </Link>
          </Breadcrumbs>
          {!mobileMatches && (
            <BottomNavigation showLabels sx={{ height: 45 }}>
              <BottomNavigationAction
                label="FILTER"
                icon={<FilterAltIcon />}
                onClick={() => setOpenFilterBox(true)}
              />
            </BottomNavigation>
          )}
        </Stack>
        {mobileMatches ? (
          <img
            className="banner"
            src={banner_image}
            alt=""
          />
        ) : (
          <img
            className="banner"
            src={mob_banner_image}
            alt=""
          />
        )}
        <Grid container spacing={1}>
          {productListToRender.map((product, i) => (
            <Grid
              item
              key={`product${product.id}`}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={6}
            >
              <ProductSlider data={product} />
            </Grid>
          ))}
        </Grid>
        {loadMore && (
          <div id="loadingelement">
            <Grid container spacing={1}>
              {Array(12)
                .fill(0)
                .map((product, i) => (
                  <Grid item key={i} xl={3} lg={3} md={4} sm={6} xs={6}>
                    <Skeleton variant="rectangle" width="100%" height={220} />
                    <Skeleton variant="text" width="100%" height={60} />
                    <Skeleton variant="text" width="100%" height={45} />
                  </Grid>
                ))}
            </Grid>
          </div>
        )}

        <div className="category_content">
            <center><p><h3>{heading_h1_ls}</h3></p></center>
            <p
                style={{ color: "grey", overflow: "auto" }}
                dangerouslySetInnerHTML={{
                  __html: content_ls,
                }}
              />
        </div>
      </div>
      {!mobileMatches && (
        <>
          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              zIndex: "10",
              left: 0,
              right: 0,
            }}
            elevation={3}
          ></Paper>
          <SwipeableDrawer
            anchor={"bottom"}
            open={openSortBox}
            disableSwipeToOpen={true}
            onClose={() => setOpenSortBox(false)}
          >
            <div
              className={classes.fullList}
              role="presentation"
              onClick={() => setOpenSortBox(false)}
              onKeyDown={() => setOpenSortBox(false)}
            >
              <List>
                {[
                  "Recomended",
                  "Top Seller",
                  "New Arrivals",
                  "Price (low to high)",
                  "Price (high to low)",
                  "Discount",
                ].map((text, index) => (
                  <ListItem button key={text} onClick={() => setSortBy(text)}>
                    <ListItemText primary={text} />
                    {text === sortBy && (
                      <ListItemSecondaryAction>
                        <CheckIcon className={classes.checkIcon} />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </div>
          </SwipeableDrawer>

          <SwipeableDrawer
            anchor={"bottom"}
            disableSwipeToOpen={true}
            open={openFilterBox}
            onClose={() => setOpenFilterBox(false)}
            // onOpen={() => setOpenFilterBox(true)}
          >
            <h4 className="filterTitle">Filter</h4>
            <div className="filterBox">
              <div className="leftContainer">
                <div
                  className={classes.fullList}
                  role="presentation"
                  onKeyDown={() => setOpenFilterBox(false)}
                >
                  <List>
                    <ListItem
                      style={{
                        backgroundColor: "Price" === showFilterBy && "#fff",
                      }}
                    >
                      <ListItemText
                        primary={"Price"}
                        onClick={() => setShowFilterBy("Price")}
                      />
                    </ListItem>
                    {catalogsToRender.map((catalog, index) => (
                      <ListItem
                        style={{
                          backgroundColor:
                            catalog.label_name === showFilterBy && "#fff",
                        }}
                        button
                        key={index}
                      >
                        <ListItemText
                          primary={catalog.label_name}
                          onClick={() => setShowFilterBy(catalog.label_name)}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
              <div className="rightContainer">
                {showFilterBy && (
                  <>
                    {showFilterBy === "Price" && (
                      <div
                        style={{
                          width: "97%",
                        }}
                      >
                        <PrettoSlider
                          valueLabelDisplay="auto"
                          getAriaLabel={(index) =>
                            index === 0 ? "Minimum price" : "Maximum price"
                          }
                          defaultValue={[0, 100000]}
                          min={0}
                          max={100000}
                          onChangeCommitted={(_, value) => {
                            setPriceRange(value);
                          }}
                          disableSwap
                        />
                        <h5
                          style={{
                            marginTop: 0,
                            fontWeight: 500,
                            marginBottom: "20px",
                          }}
                        >
                          Price range ₹{priceRange[0]} - ₹{priceRange[1]}
                        </h5>
                      </div>
                    )}
                    <FormGroup>
                      {catalogsToRender
                        ?.find((catalog) => catalog.label_name === showFilterBy)
                        ?.option?.map((option, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              checked={
                                (showFilterBy == "Brands" &&
                                  mobileBrandIds.includes(option.id)) ||
                                (showFilterBy != "Brands" &&
                                  mobileSelectedFilters[showFilterBy] &&
                                  mobileSelectedFilters[showFilterBy].some(
                                    (s) => s == option.id
                                  ))
                              }
                              control={
                                <Checkbox
                                  className={classes.checkbox}
                                  name={option.name}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      if (showFilterBy == "Brands") {
                                        setMobileBrandIds((prevState) => {
                                          return [...prevState, option.id];
                                        });
                                      } else {
                                        if (
                                          !mobileSelectedFilters[showFilterBy]
                                        )
                                          setMobileSelectedFilters({
                                            ...mobileSelectedFilters,
                                            [showFilterBy]: [option.id],
                                          });
                                        else
                                          setMobileSelectedFilters({
                                            ...mobileSelectedFilters,
                                            [showFilterBy]: [
                                              ...mobileSelectedFilters[
                                                showFilterBy
                                              ],
                                              option.id,
                                            ],
                                          });
                                      }
                                    } else {
                                      if (showFilterBy == "Brands") {
                                        setMobileBrandIds((prevState) => {
                                          prevState = prevState.filter(
                                            (p) => p != option.id
                                          );
                                          return prevState;
                                        });
                                      } else {
                                        setMobileSelectedFilters({
                                          ...mobileSelectedFilters,
                                          [showFilterBy]: mobileSelectedFilters[
                                            showFilterBy
                                          ].filter(
                                            (filter) => filter !== option.id
                                          ),
                                        });
                                      }
                                    }
                                  }}
                                />
                              }
                              label={option.name}
                            />
                          );
                        })}
                    </FormGroup>
                  </>
                )}
              </div>
            </div>
            <Paper
              sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
              elevation={3}
            >
              <BottomNavigation showLabels sx={{ height: 45 }}>
                <BottomNavigationAction
                  label="CLOSE"
                  onClick={() => {
                    setOpenFilterBox(false);
                  }}
                />
                <BottomNavigationAction
                  onClick={() => {
                    setOpenFilterBox(false);
                    applySelectedFilters();
                    setPage(1);
                  }}
                  label="APPLY"
                />
              </BottomNavigation>
            </Paper>
          </SwipeableDrawer>
        </>
      )}
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filterModalContainer">
          <div className="filterModalHeader">
            <h3>{modalField.label_name}</h3>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <div className="filterModalBody">
            <Grid container spacing={2}>
              {modalField &&
                modalField.option &&
                modalField.option.map((option) => (
                  <Grid item sm={3} xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.checkbox}
                          name={option.name}
                          onChange={(e) => {
                            if (e.target.checked) {
                              if (modalField.label_name == "Brands") {
                                setBrandIds((prevState) => {
                                  return [...prevState, option.id];
                                });
                              } else {
                                if (!selectedFilters[modalField.label_name])
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    [modalField.label_name]: [option.id],
                                  });
                                else
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    [modalField.label_name]: [
                                      ...selectedFilters[modalField.label_name],
                                      option.id,
                                    ],
                                  });
                              }
                            } else {
                              if (modalField.label_name == "Brands") {
                                setBrandIds((prevState) => {
                                  prevState = prevState.filter(
                                    (p) => p != option.id
                                  );
                                  return prevState;
                                });
                              } else {
                                setSelectedFilters({
                                  ...selectedFilters,
                                  [modalField.label_name]: selectedFilters[
                                    modalField.label_name
                                  ].filter((filter) => filter !== option.id),
                                });
                              }
                            }
                            setPage(1);
                          }}
                          checked={
                            (modalField.label_name == "Brands" &&
                              brandIds.includes(option.id)) ||
                            (modalField.label_name != "Brands" &&
                              selectedFilters[modalField.label_name] &&
                              selectedFilters[modalField.label_name].some(
                                (s) => s == option.id
                              ))
                          }
                        />
                      }
                      className="filter-label"
                      label={option.name}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ProductListing;
