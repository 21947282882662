import React, { useEffect } from "react";
import Quill from "quill";
import "./styles.css";
import "quill/dist/quill.snow.css";
import { Button, TextField, Grid, FormControl } from "@mui/material";
import DividerWithText from "./DividerWithText";
import AddBoxIcon from "@mui/icons-material/AddBox";
import axios from "axios";
import { useSelector } from "react-redux";
import { headers } from "../../../api";
import { toast } from "react-toastify";

function PrescriptionEditor({
  closeBackdrop,
  editable,
  prescription,
  appointmentNumber,
  customerId,
}) {
  // const quillRef = React.createRef();
  const { user } = useSelector((state) => state.menu);
  const quillEditorContainer = React.createRef();
  const [editor, setEditor] = React.useState(null);
  const [medicinesNumber, setMedicinesNumber] = React.useState(1);
  const [data, setData] = React.useState({
    historyOf: "",
    provisionalDiagnosis: "",
    medicines: [],
    generalAdvice: "",
    requiredTest: "",
    nextReview: "",
  });
  const changeData = (label, value) => {
    setData((prevState) => {
      return { ...prevState, [label]: value };
    });
  };
  const changeMedicine = (index, label, value) => {
    var medicines = [...data.medicines];
    medicines[index] = { ...medicines[index], [label]: value };
    setData((prevData) => {
      return { ...prevData, medicines: medicines };
    });
  };
  useEffect(() => {}, []);

  const prescript = async () => {
    data.customer_id = customerId;
    data.appointment_number = appointmentNumber;
    data.vet_id = user.id;
    if (!data.medicines || data.medicines.length == 0) {
      toast.error("Please enter atleast one medicine.");
    } else if (data.provisionalDiagnosis.trim().length == 0) {
      toast.error("Please write a diagnosis.");
    } else {
      let prescriptionResponse = await axios.post(
        "https://api.pettofit.com/api/v1/createPrescription",
        {
          data: data,
        },
        {
          headers: headers,
        }
      );
      console.log("prescriptionResponse", prescriptionResponse);
      closeBackdrop();
      toast.success("Prescription uploaded successfully");
    }
  };

  const addMedicine = () => {
    setMedicinesNumber((prevState) => prevState + 1);
  };
  console.log("prescription", prescription);
  return (
    <div
      className="container-prescription"
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={quillEditorContainer}
    >
      {!prescription ? (
        <div>
          <div className="prescription-header">
            <h1>{editable ? "Create Prescription" : "Prescription"}</h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              {editable && (
                <Button
                  sx={{
                    marginRight: "1rem",
                  }}
                  variant="contained"
                  onClick={() => {
                    prescript();
                  }}
                >
                  Submit
                </Button>
              )}
              <Button onClick={closeBackdrop} color="error" variant="contained">
                Close
              </Button>
            </div>
          </div>
          <div className="prescription-body" ref={quillEditorContainer}>
            <div>
              <Grid container spacing={2}>
                <Grid item xl={6} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      label="History of"
                      variant="outlined"
                      onChange={(e) => changeData("historyOf", e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={6} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      label="Provisional Diagnosis *"
                      variant="outlined"
                      onChange={(e) =>
                        changeData("provisionalDiagnosis", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={12} sm={12}>
                  <DividerWithText>Medicines*</DividerWithText>
                </Grid>
              </Grid>
              {Array(medicinesNumber)
                .fill(0)
                .map((n, index) => {
                  return (
                    <div
                      className="medicines-container"
                      id="medicines-container"
                    >
                      <Grid container spacing={1}>
                        <Grid item xl={3} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              id="outlined-basic"
                              label="Prescribed Medicine"
                              variant="outlined"
                              onChange={(e) => {
                                changeMedicine(
                                  index,
                                  "prescribedMedicine",
                                  e.target.value
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xl={3} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              id="outlined-basic"
                              label="Dose Regimen"
                              variant="outlined"
                              onChange={(e) => {
                                changeMedicine(
                                  index,
                                  "doseRegimen",
                                  e.target.value
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xl={3} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              id="outlined-basic"
                              label="Route of Administration"
                              variant="outlined"
                              onChange={(e) => {
                                changeMedicine(
                                  index,
                                  "routeAdministration",
                                  e.target.value
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xl={3} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              id="outlined-basic"
                              label="Duration"
                              variant="outlined"
                              onChange={(e) => {
                                changeMedicine(
                                  index,
                                  "duration",
                                  e.target.value
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
            </div>
            <div>
              <Grid container spacing={1}>
                <Grid
                  item
                  xl={12}
                  sm={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    startIcon={<AddBoxIcon />}
                    disabled={medicinesNumber >= 10}
                    style={{ margin: "0 auto", cursor: "pointer" }}
                    onClick={() => {
                      if (medicinesNumber <= 10) {
                        addMedicine();
                      }
                    }}
                  >
                    Add another medicine
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container spacing={1}>
                <Grid
                  item
                  xl={12}
                  sm={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-textarea"
                      label="General Advice"
                      placeholder=""
                      multiline
                      onChange={(e) =>
                        changeData("generalAdvice", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={6}
                  sm={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-textarea"
                      label="Required Test"
                      multiline
                      onChange={(e) =>
                        changeData("requiredTest", e.target.value)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={6}
                  sm={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-textarea"
                      label="Next Review"
                      multiline
                      onChange={(e) => changeData("nextReview", e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="prescription-header">
            <h1>Prescription</h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button onClick={closeBackdrop} color="error" variant="contained">
                Close
              </Button>
            </div>
          </div>
          <div className="prescription-body" ref={quillEditorContainer}>
            <embed
              src={prescription}
              type="text/html"
              width="100%"
              height="520"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default PrescriptionEditor;
