import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import "./Careers.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router";
import axios from "axios";
import { Helmet } from "react-helmet";

const BootstrapButton = withStyles({
  root: {
    border: "1px solid",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#03D1C7",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", "Work from Home", "IT Services"),
  createData("Ice cream sandwich", "Work from Home", "Digital"),
];

function Careers() {
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const history = useHistory();
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    fetchCareers();
  }, []);

  const fetchCareers = async () => {
    const response = await axios.get(
      "https://api.pettofit.com/api/v1/career-master"
    );
    if (!response.data.error) {
      setJobs(response.data.data);
    }
  };

  return (
    <section className="careers">
      <Helmet>
        <title>
          Online Pet Clinic | Online Vet Consultation | Veterinary Doctor Online
        </title>
      </Helmet>
      <div
        className="careers__header"
        style={{ padding: mobileMatches ? "40px" : "10px" }}
      >
        <Typography variant="h2">Careers</Typography>
      </div>
      <h1 className="subHeaderr">All Openings</h1>
      <div
        className="careers__container"
        style={{ padding: mobileMatches ? "20px" : "5px" }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 className="currentOpening">
              Current <br />{" "}
              <span style={{ fontSize: mobileMatches ? "1.8em" : "1.5em" }}>
                Opening
              </span>
            </h2>
          </Grid>
          <Grid item md={9} sm={12} xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" size="medium">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Job Title</StyledTableCell>
                    <StyledTableCell align="left">Location</StyledTableCell>
                    <StyledTableCell align="left">Department</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.title}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.location || "Work From Home"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.department || "Digital"}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <BootstrapButton
                          size={!mobileMatches ? "small" : "medium"}
                          style={{ fontSize: !mobileMatches && "10px" }}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            history.push("./career-single", {
                              job: row,
                            })
                          }
                        >
                          Apply Now
                        </BootstrapButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default Careers;
