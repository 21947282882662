import React from "react";
import "./cms.css";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { Helmet } from "react-helmet";
import faqs from "../../resources/faqs";
const useStyles = makeStyles({
  paper: {
    marginTop: "50px",
    marginBottom: "50px",
  },
});

function FAQ() {
  const classes = useStyles();
  const [faq, setFaq] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     const faqPage = await fetchFaq(1);
  //     console.log(faqPage);
  //     setFaq(faqPage.data);
  //   })();
  // }, []); //this api

  return (
    <>
      <Helmet>
        <title>
          Online Pet Consultants | Online Veterinary Consultations | Online Vet
          Care near Me
        </title>
      </Helmet>
      <center>
        <h2>FAQ's</h2>
      </center>
      {faqs.map((e) => {
        return (
          <div className="cms section">
            <Paper
              className={classes.paper}
              elevation={3}
              style={{ margin: "15px" }}
            >
              <h2>{e.section}</h2>
              {e.content.map((f) => {
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: f.question,
                        }}
                      ></div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: f.answer,
                        }}
                      ></div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Paper>
          </div>
        );
      })}
    </>
  );
}

export default FAQ;
