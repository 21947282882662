import React, { useEffect } from "react";

const species = [
  "Dog",
  "Cat",
  "Bird",
  "Rabbit",
  "Hamster",
  "Guinea Pig",
  "Fish",
  "Turtle",
  "Cattle",
  "Reptile",
];
const gender = ["Male", "Female"];

function PetReport({ petInfo, closeBackdrop }) {
  const [infoKeys, setInfoKeys] = React.useState([]);

  useEffect(() => {
    setInfoKeys(Object.keys(petInfo));
  }, []);

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "450px",
        maxWidth: "100%",
        padding: "20px",
        position: "relative",
        color: "black",
        overflowY: "auto",
        maxHeight: "90vh",
        boxSizing: "border-box",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0",
          right: "15px",
          cursor: "pointer",
          transform: "rotate(45deg)",
          fontSize: "30px",
        }}
        onClick={closeBackdrop}
      >
        +
      </div>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Pet Report
      </h2>
      {infoKeys.length === 0 && (
        <h3
          style={{
            textAlign: "center",
            marginTop: "5rem",
            marginBottom: "5rem",
            color: "#808080",
          }}
        >
          No pet info registered
        </h3>
      )}
      {infoKeys.map((key) => {
        return key === "is_active" ||
          key === "attachment" ||
          key === "created_at" ||
          key === "updated_at" ||
          key === "appointment_id" ||
          key === "customer_id" ||
          key === "vet_id" ||
          key === "email" ||
          key === "mobile" ||
          key === "id" ? (
          <></>
        ) : (
          <div
            style={{
              borderBottom: "1px solid #000000",
            }}
          >
            <h3>{key.split("_").join(" ")}</h3>
            <p>
              {key === "species_id" && species[petInfo[key]]}
              {key === "pet_gender" && gender[petInfo[key]]}
              {key !== "species_id" && key !== "pet_gender" && petInfo[key]}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default PetReport;
