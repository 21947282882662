import React, { useEffect, useState } from "react";
import "./Cart.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import RecentView from "../home/RecentView";
import Box from "@mui/material/Box";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  menuActions,
  updateCart,
  fetchItems,
  deleteFromCart,
} from "../../store";
import Login from "../login/Login";
import { ApplyCouponApi, RemoveCouponApi, headers } from "../../api";
import { toast } from "react-toastify";
import axios from "axios";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  thPadding: {
    padding: "5px",
  },
  paddingButton: {
    paddingBottom: "3px !important",
  },
});

const CssTextField = withStyles({
  root: {
    width: "100%",
    // marginBottom: '10px',
    maxWidth: "200px",
    marginRight: "10px",
    "& label": {
      color: "#000",
      transform: "translate(14px, 12px) scale(1)",
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const CustomIconButton = withStyles({
  root: {
    backgroundColor: "#09D1C7",
    boxShadow: "0 0 10px lightgray",
    fontWeight: "500",
    color: "#fff",
    width: "30px",
    height: "30px",
    fontSize: "30px",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#D2FCFC",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function Cart() {
  const classes = useStyles();
  const history = useHistory();
  const {
    cart,
    productImageLink,
    sessionlessCart,
    isLoggedIn,
    user,
    cartDetails,
  } = useSelector((state) => state.menu);
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const [couponApplied, setCouponApplied] = useState(false);
  const [loginBoxStatus, setLoginBoxStatus] = useState(false);
  const [coupon, setCouponCode] = useState("");
  const [couponAmount, setCouponAmount] = useState(0);
  const [metaInfo, setMetaInfo] = useState({});
  const [cartErrors, setCartErrors] = useState(new Array(cart.length).fill(""));

  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);

  useEffect(() => {
    if (cartDetails.coupon_code) {
      setCouponApplied(true);
      setCouponAmount(cartDetails.coupon_amount);
      setCouponCode(cartDetails.coupon_code);
    }
    fetchItems()(dispatch);
  }, []);

  useEffect(() => {
    if (cartDetails.coupon_code) {
      setCouponApplied(true);
      setCouponAmount(cartDetails.coupon_amount);
      setCouponCode(cartDetails.coupon_code);
    }
  }, [cartDetails.coupon_code]);

  useEffect(() => {
    async function checkCouponValidity() {
      let data = {
        cartId: cart[0]?.bagId,
        coupon_code: coupon,
        userId: user.id,
      };
      const couponRes = await ApplyCouponApi(data);
      if (couponRes.error) {
        setCouponApplied(false);
        setCouponCode("");
        setCouponAmount(0);
      }
    }
    checkCouponValidity();
  }, [cart]);
  const handleDeleteFromCart = (product) => {
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].id == product.id) {
        var newCartErrors = [...cartErrors];
        newCartErrors = newCartErrors
          .slice(0, i)
          .concat(cartErrors.slice(i + 1));
        setCartErrors(newCartErrors);
        break;
      }
    }
    deleteFromCart(product, dispatch);
  };

  const proceedCheckout = async () => {
    var cartDetails = [];
    if (isLoggedIn) {
      if (cart != undefined && cart.length > 0) {
        for (var i = 0; i < cart.length; i++) {
          cartDetails.push({
            id: cart[i].id,
            sku: cart[i].product_sku,
            quantity: cart[i].quantity,
            product_id: cart[i].product_id,
            picked_color: cart[i].picked_color,
            picked_size: cart[i].picked_size,
          });
        }
        let checkoutResponseDetails = axios
          .post(
            "https://api.pettofit.com/api/v1/checkoutDetails",
            {
              data: cartDetails,
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (!response.data.error) {
              history.push("/checkout", { couponAmount });
            } else {
              setCartErrors(response.data.error_msg);
            }
          });
      } else {
        toast.error("There are no items in the cart");
      }
    }
  };

  const removeCoupon = async () => {
    let data = {
      cartId: cart[0]?.bagId,
      userId: user.id,
    };
    setCouponApplied(false);
    setCouponAmount(0);
    setTimeout(() => {
      fetchItems()(dispatch);
    }, 200);
    try {
      const couponRes = await RemoveCouponApi(data);
      if (!couponRes.error) {
        setCouponApplied(false);
        setCouponCode("");
        toast.success(couponRes.message);
        fetchItems()(dispatch);
      } else {
        setCouponApplied(false);
        toast.error(couponRes.message);
      }
    } catch (err) {}
  };

  const handleApplyCoupon = async () => {
    if (coupon === "") {
      toast.error("Please fill the coupon first");
      return;
    }
    let data = {
      cartId: cart[0]?.bagId,
      coupon_code: coupon,
      userId: user.id,
    };
    const couponRes = await ApplyCouponApi(data);
    if (!couponRes.error) {
      setCouponApplied(true);
      toast.success(couponRes.message);
      fetchItems()(dispatch);
      setCouponAmount(couponRes.coupan_amount);
    } else {
      setCouponApplied(false);
      toast.error(couponRes.message);
    }
  };

  const updateTotal = () => {
    let total = 0;
    if (isLoggedIn) {
      if (cart != undefined && cart.length > 0) {
        for (let k in cart) {
          let amount =
            parseInt(cart[k].quantity * cart[k].sale_price) +
            parseInt(cart[k].gst_amount) +
            parseInt(cart[k].shipping_amount);
          total += amount;
        }
      }
    } else {
      if (sessionlessCart != undefined && sessionlessCart.length > 0) {
        for (let k in sessionlessCart) {
          let amount = parseInt(
            sessionlessCart[k].quantity * sessionlessCart[k].product_sale_price
          );
          total += amount;
        }
      }
    }
    //total += total < 899 ? 49 : 0;
    total += total < 899 ? 0 : 0;
    return <>{total - couponAmount}</>;
  };

  const handleUpdateCardIncrement = (product) => {
    const action = {
      ...product,
      updatingQuantity: "1",
    };
    updateCart(action, "increase", dispatch);
  };
  const handleUpdateCartRemove = (product) => {
    if (product.quantity > 1) {
      const action = {
        ...product,
        quantity: "1",
      };
      updateCart(action, "decrease", dispatch);
    } else {
      handleDeleteFromCart(product);
    }
  };

  const deleteFromSessionlessCart = (product) => {
    dispatch(menuActions.deleteFromSessionlessCart(product));
  };

  const decrementSessionlessCart = (product) => {
    if (product.quantity > 1) {
      dispatch(menuActions.decrementSessionlessCart(product));
    } else {
      deleteFromSessionlessCart(product);
    }
  };

  const incrementSessionlessCart = (product) => {
    dispatch(menuActions.incrementSessionlessCart(product));
  };
  var total = 0;

  if (isLoggedIn) {
    if (cart != undefined && cart.length > 0) {
      for (let k in cart) {
        let amount = parseInt(cart[k].quantity * cart[k].sale_price);
        total += amount;
      }
    }
  } else {
    if (sessionlessCart != undefined && sessionlessCart.length > 0) {
      for (let k in sessionlessCart) {
        let amount = parseInt(
          sessionlessCart[k].quantity * sessionlessCart[k].product_sale_price
        );
        total += amount;
      }
    }
  }
  //const shippingCharges = total < 899 ? 49 : 0;
  const shippingCharges = total < 899 ? 0 : 0;

  return (
    <div className="cart">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <div className="section">
        <Breadcrumbs sx={{ ml: mobileMatches ? 0 : 1 }} aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Cart</Typography>
        </Breadcrumbs>
        {cart.length === 0 && sessionlessCart.length === 0 ? (
          <h4 className="emptyCart">No Item in basket</h4>
        ) : (
          <Grid sx={{ p: mobileMatches ? 0 : 1 }} container spacing={2}>
            <Grid item md={8} xs={12}>
              {mobileMatches ? (
                <TableContainer
                  style={{ marginBottom: "20px" }}
                  component={Paper}
                >
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          Product
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Quantity
                        </StyledTableCell>
                        <StyledTableCell align="center">Price</StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoggedIn &&
                        cart.map((row, index) => {
                          return (
                            <TableRow key={row.id}>
                              <StyledTableCell component="th" scope="row">
                                <div className="productContainer">
                                  <img
                                    src={
                                      productImageLink + "/" + row.item_photo
                                    }
                                    alt=""
                                  />
                                  <div className="detail">
                                    <h4>{row.product_title}</h4>
                                    <div className="selection">
                                      {row.pickedSize &&
                                        row.pickedSize !== undefined &&
                                        row.pickedSize !== "" &&
                                        row.pickedSize != "null" && (
                                          <>
                                            SIZE:&nbsp;
                                            <div
                                              style={{
                                                backgroundColor: "#72D6CB",
                                                padding: "5px",
                                                borderRadius: "10px",
                                              }}
                                            >
                                              &nbsp;{row.pickedSize}&nbsp;
                                            </div>
                                          </>
                                        )}
                                      {row.pickedColor !== undefined &&
                                        row.pickedColor.length > 0 && (
                                          <>
                                            &nbsp; COLOR
                                            <div
                                              className="colorBox"
                                              style={{
                                                backgroundColor:
                                                  row.pickedColor.code,
                                              }}
                                            ></div>
                                          </>
                                        )}
                                    </div>
                                    {!row.stockstatus && (
                                      <div
                                        className="selection"
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Out of Stock
                                      </div>
                                    )}
                                    {cartErrors[index] &&
                                      cartErrors[index].trim().length > 0 && (
                                        <div
                                          style={{
                                            marginTop: "5px",
                                            fontSize: "13px",
                                            color: "red",
                                          }}
                                        >
                                          {cartErrors[index]}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <div className="qtyContainer">
                                  <CustomIconButton
                                    onClick={() => handleUpdateCartRemove(row)}
                                  >
                                    <span className={classes.paddingButton}>
                                      -
                                    </span>
                                  </CustomIconButton>
                                  <h4>{row.quantity}</h4>
                                  <CustomIconButton
                                    onClick={() => {
                                      handleUpdateCardIncrement(row);
                                    }}
                                  >
                                    +
                                  </CustomIconButton>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <div className="priceContainer">
                                  {!(row.price === row.sale_price) && (
                                    <p className="actualPrice">
                                      <FontAwesomeIcon
                                        icon={faRupeeSign}
                                        className="icon"
                                      />{" "}
                                      {row.price}
                                    </p>
                                  )}
                                  <p className="discountedPrice">
                                    <FontAwesomeIcon
                                      icon={faRupeeSign}
                                      className="icon"
                                    />{" "}
                                    {Math.ceil(row.sale_price)}
                                  </p>
                                  {!(row.price === row.sale_price) && (
                                    <p className="discountRate">
                                      {!isNaN(
                                        (
                                          ((row.price - row.sale_price) * 100) /
                                          row.price
                                        ).toFixed(0)
                                      )
                                        ? (
                                            ((row.price - row.sale_price) *
                                              100) /
                                            row.price
                                          ).toFixed(0) + "% OFF"
                                        : "0% OFF"}
                                    </p>
                                  )}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <DeleteIcon
                                  onClick={() => {
                                    handleDeleteFromCart(row);
                                  }}
                                  className="deleteIcon"
                                />
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                      {!isLoggedIn &&
                        sessionlessCart.map((row) => {
                          return (
                            <TableRow key={`${row.id}-${row.pickedSize}`}>
                              <StyledTableCell component="th" scope="row">
                                <div className="productContainer">
                                  <img
                                    src={
                                      row.variation_image_URL
                                        ? row.variation_image_URL +
                                          "/" +
                                          row.product_image
                                        : productImageLink + "/"+ row.product_image
                                    }
                                    alt=""
                                  />
                                  <div className="detail">
                                    <h4>{row.product_title}</h4>
                                    <div className="selection">
                                      {row.pickedSize &&
                                        row.pickedSize !== undefined &&
                                        row.pickedSize !== "" &&
                                        row.pickedSize != "null" && (
                                          <>
                                            SIZE
                                            <div className="sizeBox">
                                              {row.pickedSize}
                                            </div>
                                          </>
                                        )}
                                      {row.picked_color !== undefined &&
                                        row.picked_color.length > 0 && (
                                          <>
                                            COLOR
                                            <div
                                              className="colorBox"
                                              style={{
                                                backgroundColor:
                                                  row.picked_color,
                                              }}
                                            ></div>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <div className="qtyContainer">
                                  <CustomIconButton
                                    onClick={() =>
                                      decrementSessionlessCart(row)
                                    }
                                  >
                                    <span className={classes.paddingButton}>
                                      -
                                    </span>
                                  </CustomIconButton>
                                  <h4>{row.quantity}</h4>
                                  <CustomIconButton
                                    onClick={() => {
                                      if (row.quantity < row.max_cart_val) {
                                        incrementSessionlessCart(row);
                                      }
                                    }}
                                  >
                                    +
                                  </CustomIconButton>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <div className="priceContainer">
                                  {!(
                                    row.product_sale_price === row.product_mrp
                                  ) && (
                                    <p className="actualPrice">
                                      <FontAwesomeIcon
                                        icon={faRupeeSign}
                                        className="icon"
                                      />{" "}
                                      {row.product_mrp}
                                    </p>
                                  )}
                                  <p className="discountedPrice">
                                    <FontAwesomeIcon
                                      icon={faRupeeSign}
                                      className="icon"
                                    />{" "}
                                    {row.product_sale_price}
                                  </p>
                                  {!(
                                    row.product_sale_price === row.product_mrp
                                  ) && (
                                    <p className="discountRate">
                                      (
                                      {!isNaN(
                                        (
                                          ((row.product_mrp -
                                            row.product_sale_price) *
                                            100) /
                                          (row.price || row.product_mrp)
                                        ).toFixed(0)
                                      )
                                        ? (
                                            ((row.product_mrp -
                                              row.product_sale_price) *
                                              100) /
                                            (row.price || row.product_mrp)
                                          ).toFixed(0) + "% OFF"
                                        : "0% OFF"}
                                      )
                                    </p>
                                  )}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <DeleteIcon
                                  onClick={() => {
                                    deleteFromSessionlessCart(row);
                                  }}
                                  className="deleteIcon"
                                />
                              </StyledTableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box className="mobileCratContainer">
                  {isLoggedIn &&
                    cart.map((row,index) => { 
                      return (
                        <div className="card" key={row.id}>
                          <div className="productContainer">
                            <img
                              src={productImageLink + "/" + row.item_photo}
                              alt=""
                            />
                            <div className="detail">
                              <h4>{row.product_title}</h4>
                              <div className="selection">
                                {row.pickedSize &&
                                  row.pickedSize !== undefined &&
                                  row.pickedSize !== "" &&
                                  row.pickedSize != "null" && (
                                    <>
                                      SIZE
                                      <div className="sizeBox">
                                        {row.pickedSize}
                                      </div>
                                    </>
                                  )}
                                {row.pickedColor !== undefined &&
                                  row.pickedColor.length > 0 && (
                                    <>
                                      COLOR
                                      <div
                                        className="colorBox"
                                        style={{
                                          backgroundColor: row.pickedColor.code,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                          <DeleteIcon
                            className="deleteIcon"
                            onClick={() => {
                              handleDeleteFromCart(row);
                            }}
                          />
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Price
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Quantity
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Total
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="priceContainer">
                                      {row.price > row.sale_price && (
                                        <p className="actualPrice">
                                          <FontAwesomeIcon
                                            icon={faRupeeSign}
                                            className="icon"
                                          />{" "}
                                          {row.price}
                                        </p>
                                      )}
                                      <p className="discountedPrice">
                                        <FontAwesomeIcon
                                          icon={faRupeeSign}
                                          className="icon"
                                        />{" "}
                                        {row.sale_price}
                                      </p>
                                      {row.price > row.sale_price && (
                                        <p className="discountRate">
                                          (
                                          {!isNaN(
                                            (
                                              ((row.price - row.sale_price) *
                                                100) /
                                              row.price
                                            ).toFixed(0)
                                          )
                                            ? (
                                                ((row.price - row.sale_price) *
                                                  100) /
                                                row.price
                                              ).toFixed(0) + "% OFF"
                                            : "0% OFF"}
                                          )
                                        </p>
                                      )}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="qtyContainer">
                                      <CustomIconButton>
                                        <span
                                          onClick={() =>
                                            handleUpdateCartRemove(row)
                                          }
                                          className={classes.paddingButton}
                                        >
                                          -
                                        </span>
                                      </CustomIconButton>
                                      <h4>{row.quantity}</h4>
                                      <CustomIconButton
                                        onClick={() => {
                                          if (row.quantity < row.max_cart_val) {
                                            handleUpdateCardIncrement(row);
                                          }
                                        }}
                                      >
                                        +
                                      </CustomIconButton>
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="priceContainer">
                                      <p className="discountedPrice">
                                        <FontAwesomeIcon
                                          icon={faRupeeSign}
                                          className="icon"
                                        />{" "}
                                        {row.sale_price * row.quantity}
                                      </p>
                                    </div>
                                  </StyledTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {cartErrors[index] &&
                          cartErrors[index].trim().length > 0 && (
                            <div
                              style={{
                                marginTop: "5px",
                                fontSize: "13px",
                                color: "red",
                              }}
                            >
                              {cartErrors[index]}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  {!isLoggedIn &&
                    sessionlessCart.map((row) => {
                      return (
                        <div className="card" key={`${row.id}-${row.pickedSize}`}>
                          <div className="productContainer">
                            <img
                              src={
                                row.variation_image_URL
                                  ? row.variation_image_URL +
                                    "/" +
                                    row.product_image
                                  : productImageLink +"/"+row.product_image
                              }
                              alt=""
                            />
                            <div className="detail">
                              <h4>{row.product_title}</h4>
                              <div className="selection">
                                {row.pickedSize &&
                                  row.pickedSize !== undefined &&
                                  row.pickedSize !== "" &&
                                  row.pickedSize != "null" && (
                                    <>
                                      SIZE
                                      <div className="sizeBox">
                                        {row.pickedSize}
                                      </div>
                                    </>
                                  )}
                                {row.picked_color !== undefined &&
                                  row.picked_color.length > 0 && (
                                    <>
                                      COLOR
                                      <div
                                        className="colorBox"
                                        style={{
                                          backgroundColor: row.picked_color,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                          <DeleteIcon
                            className="deleteIcon"
                            onClick={() => {
                              deleteFromSessionlessCart(row);
                            }}
                          />
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Price
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Quantity
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    Total
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="priceContainer">
                                      {row.product_mrp >
                                        row.product_sale_price && (
                                        <p className="actualPrice">
                                          <FontAwesomeIcon
                                            icon={faRupeeSign}
                                            className="icon"
                                          />{" "}
                                          {row.product_mrp}
                                        </p>
                                      )}
                                      <p className="discountedPrice">
                                        <FontAwesomeIcon
                                          icon={faRupeeSign}
                                          className="icon"
                                        />{" "}
                                        {row.product_sale_price}
                                      </p>
                                      {row.price > row.sale_price && (
                                        <p className="discountRate">
                                          (
                                          {!isNaN(
                                            (
                                              ((row.product_mrp -
                                                row.product_sale_price) *
                                                100) /
                                              row.price
                                            ).toFixed(0)
                                          )
                                            ? (
                                                ((row.product_mrp -
                                                  row.product_sale_price) *
                                                  100) /
                                                row.product_mrp
                                              ).toFixed(0) + "% OFF"
                                            : "0% OFF"}
                                          )
                                        </p>
                                      )}
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="qtyContainer">
                                      <CustomIconButton>
                                        <span
                                          onClick={() =>
                                            decrementSessionlessCart(row)
                                          }
                                          className={classes.paddingButton}
                                        >
                                          -
                                        </span>
                                      </CustomIconButton>
                                      <h4>{row.quantity}</h4>
                                      <CustomIconButton
                                        onClick={() => {
                                          if (row.quantity < row.max_cart_val) {
                                            incrementSessionlessCart(row);
                                          }
                                        }}
                                      >
                                        +
                                      </CustomIconButton>
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.thPadding}
                                    align="center"
                                  >
                                    <div className="priceContainer">
                                      <p className="discountedPrice">
                                        <FontAwesomeIcon
                                          icon={faRupeeSign}
                                          className="icon"
                                        />{" "}
                                        {row.product_sale_price * row.quantity}
                                      </p>
                                    </div>
                                  </StyledTableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      );
                    })}
                </Box>
              )}
              <form>
                {isLoggedIn ? (
                  <>
                    <CssTextField
                      className={classes.margin}
                      label="Enter Coupon Code"
                      type="text"
                      disabled={couponApplied}
                      value={coupon}
                      onChange={(event) => setCouponCode(event.target.value)}
                      variant="outlined"
                    />
                    {!couponApplied && (
                      <BootstrapButton2
                        size={mobileMatches ? "medium" : "small"}
                        onClick={handleApplyCoupon}
                        variant="contained"
                        color="primary"
                      >
                        Apply Coupon
                      </BootstrapButton2>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {isLoggedIn && couponApplied ? (
                  <BootstrapButton2
                    size={mobileMatches ? "medium" : "small"}
                    onClick={removeCoupon}
                    variant="contained"
                    color="primary"
                  >
                    Remove Coupon
                  </BootstrapButton2>
                ) : (
                  <></>
                )}
              </form>
            </Grid>
            <Grid item md={4} xs={12} className="cartSummary">
              <div className="container">
                <div className="content">
                  <p>Price</p>
                  <h5>
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {isLoggedIn
                      ? cart.reduce(
                          (total, cartPrice) =>
                            total +
                            parseInt(cartPrice.quantity) *
                              parseInt(cartPrice.sale_price),
                          0
                        )
                      : sessionlessCart.reduce(
                          (total, cartPrice) =>
                            total +
                            parseInt(cartPrice.quantity) *
                              parseInt(cartPrice.product_sale_price),
                          0
                        )}
                  </h5>
                </div>
                <div className="content">
                  <p>Shipping Charges</p>
                  <h5 className="green">
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {shippingCharges}
                  </h5>
                </div>
                <div className="content">
                  <p>Coupon Discount</p>
                  <h5
                    style={{
                      color: "red",
                    }}
                  >
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {couponAmount}
                  </h5>
                </div>
                <Divider />
                <div className="content">
                  <div className="total">
                    <h4>Total Amount</h4>
                    <p>Inclusive of all taxed</p>
                  </div>
                  <h5>
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {updateTotal()}
                  </h5>
                </div>
              </div>
              {mobileMatches ? (
                <BootstrapButton2
                  onClick={() => {
                    if (!isLoggedIn) {
                      setLoginBoxStatus(true);
                    } else {
                      proceedCheckout();
                    }
                  }}
                  variant="contained"
                  color="primary"
                >
                  Proceed to checkout
                </BootstrapButton2>
              ) : (
                <div className="mobileCheckout">
                  <h4>
                    <span>Total </span>
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {updateTotal()}
                  </h4>
                  <BootstrapButton2
                    onClick={() => {
                      if (!isLoggedIn) {
                        setLoginBoxStatus(true);
                      } else {
                        proceedCheckout()
                      }
                    }}
                  >
                    Proceed to checkout
                  </BootstrapButton2>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <RecentView />
      <Login
        setLoginBoxStatus={setLoginBoxStatus}
        loginBoxStatus={loginBoxStatus}
      />
    </div>
  );
}

export default Cart;
