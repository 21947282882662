import React from "react";
import "./StripeMenu.css";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

class StripeMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Menus: props.data,
      loading: props.loading,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data?.length !== this.props.data?.length) {
      this.setState({
        Menus: this.props.data,
      });
    }
  }

  render() {
    return (
      <header className="globalNav noDropdownTransition">
        <div className="container-lg">
          <ul className="navRoot">
            {this.state.Menus &&
              this.state.Menus.length > 0 &&
              this.state.Menus.map((el, i) => {
                return (
                  <li key={i} className="navSection primary link-primary">
                    {(el.name === "Personalised" ||
                      el.name === "Brands" ||
                      el?.child?.some((c) => c.child?.length > 0)) && (
                      <Link
                        key={i + el.link}
                        to={`/products?sid=${btoa(el.id)}`}
                        className="rootLink item-products hasDropdown colorize"
                        data-dropdown={el.name}
                      >
                        {el.name}
                      </Link>
                    )}
                    {el.name === "Personalised" || el.name === "Small Pets" ? (
                      <div
                        className="dropdownSection"
                        data-dropdown="Personalised"
                      >
                        <div className="dropdownContent">
                          <div className="linkGroup ">
                            <div className="documentationArticles">
                              <div className="documentationArticlesContent">
                                <ul>
                                  {el.child.map((sub, index) => {
                                    return (
                                      <li key={index}>
                                        <Link
                                          to={`/products?sid=${btoa(sub.id)}`}
                                        >
                                          {sub.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : el.name === "Brands" ? (
                      <div className="dropdownSection" data-dropdown="Brands">
                        <div className="dropdownContent">
                          <div className="linkGroup ">
                            <div className="documentationArticles">
                              <div className="documentationArticlesContent">
                                <ul>
                                  {el.child.map((sub, index) => {
                                    return (
                                      <li key={index}>
                                        <Link to="">{sub.name}</Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="dropdownRoot">
                        <div className="dropdownContent">
                          <div className="linkGroup ">
                            <div className="documentationArticles">
                              {el.child?.map((sub, index) => {
                                return sub.child?.length ? (
                                  <div
                                    key={index}
                                    className="documentationArticlesContent"
                                  >
                                    <h4>
                                      <Link
                                        style={{ pointerEvents: "none" }}
                                        to={"/products?sid=" + btoa(sub.id)}
                                      >
                                        {sub.name}
                                      </Link>
                                    </h4>
                                    <ul>
                                      {sub.child.map((sub, index) => {
                                        return (
                                          <li key={index}>
                                            <Link
                                              to={
                                                "/products?sid=" + btoa(sub.id)
                                              }
                                            >
                                              {sub.name}
                                            </Link>
                                            <div style={{ margin: "6px" }} />
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                ) : (
                                  ""
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
            {this.state.Menus.length === 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Skeleton width={350} animation="wave" />
                </div>
              </>
            )}
            {this.state.Menus.length !== 0 && (
              <li className="navSection primary">
                <Link to="/blog" className="rootLink  colorize">
                  Blog
                </Link>
              </li>
            )}
          </ul>
        </div>
      </header>
    );
  }
}

export default StripeMenu;
