import React, { useRef, useState } from "react";
import "./ProductListing.css";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@material-ui/core/Modal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useLocation, useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ProductSlider from "../product/ProductSlider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@mui/material/Grid";
import CheckIcon from "@material-ui/icons/Check";
import Stack from "@mui/material/Stack";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import fetchProductPage, {
  fetchProductsByBrands,
  fetchCatalogForBrand,
} from "../../services/http/productpageService";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import axios from "axios";
import { Helmet } from "react-helmet";

let fetchScroll = true;
let heading_h1_ls = "";
let content_ls = "";
let banner_image = "";
let mob_banner_image = "";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "0 0 10px lightgray",
  },
  whitespace: {
    whiteSpace: "nowrap",
  },
  checkIcon: {
    color: "#09D1C7",
  },
  checkbox: {
    color: "#09D1C7 !important",
    padding: "1px !important",
    paddingLeft: "10px !important",
    fontSize: "15px !important",
  },
}));

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#09D1C7",
      },
      track: {
        color: "#C0FAF7",
      },
      rail: {
        color: "C0FAF7",
      },
    },
  },
});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PrettoSlider = styled(Slider)({
  width: "80%",
  color: "#09D1C7",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 12,
    width: 12,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#09D1C7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

let _products = [];

function ShopByBrand() {
  const classes = useStyles();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [value, setValue] = useState([20, 1537]);
  const [sortBy, setSortBy] = useState("Recomended");
  const [showFilterBy, setShowFilterBy] = useState("Categories");
  const [openSortBox, setOpenSortBox] = useState(false);
  const [openFilterBox, setOpenFilterBox] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [catalogs, setCatalogs] = useState([]);
  const [isMenuLoading, setMenuLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [productListToRender, setProductListToRender] = useState([]);
  const [catalogsToRender, setCatalogsToRender] = useState([]);
  const timer = useRef(null);
  const [productsFromServer, setProductsFromServer] = useState([]);
  const id = useParams().id;
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [product, setProductPage] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalField, setModalField] = useState({});
  const [metaTitle, setMetaTitle] = useState("");
  const [brandIds, setBrandIds] = useState([]);

  const fetchProducts = async (page) => {
    let optionIds = [];
    Object.values(selectedFilters).forEach((valueArray) => {
      if (valueArray.length > 0) {
        optionIds = optionIds.concat(valueArray);
      }
    });
    const products = await fetchProductsByBrands({
      page: page,
      brandId: atob(id),
      option_id: optionIds.length == 0 ? null : optionIds.join(","),
      priceRange: priceRange,
      brandIds: brandIds.length > 0 ? brandIds.join(",") : "0",
    });
    return products;
  };

  useEffect(() => {
    (async () => {
      const catalogs = await fetchCatalogForBrand(atob(id));
      setCatalogsToRender(catalogs.data || []);
      setPage(1);
    })();
  }, [id]);
  useEffect(() => {
    (async () => {
      console.log("page", page);
      if (id) {
        const productPage = await fetchProducts(page);
        _products = productPage.data;
        if (!productPage.has_more) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
        setMetaTitle(productPage.meta_title);
        heading_h1_ls = productPage.heading_h1;
        console.log('fetchProducts ->'+heading_h1_ls);
        content_ls = productPage.content;
        banner_image = productPage.banner_image;
        mob_banner_image = productPage.mob_banner_image;
        if (page > 1) {
          setProductListToRender([...productListToRender, ...productPage.data]);
          setProductsFromServer([...productListToRender, ...productPage.data]);
        } else {
          setProductListToRender(productPage.data);
          setProductsFromServer(productPage.data);
        }
        setIsLoading(false);
        setMenuLoading(false);
      }
      fetchScroll = true;
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      const productPage = await fetchProducts(page);
      _products = productPage.data;
      if (!productPage.has_more) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      heading_h1_ls = productPage.heading_h1;
      console.log('fetchProducts2 ->'+heading_h1_ls);
      content_ls = productPage.content;
      banner_image = productPage.banner_image;
      mob_banner_image = productPage.mob_banner_image;
      setMetaTitle(productPage.meta_title);
      setProductListToRender(productPage.data);
      setProductsFromServer(productPage.data);
      setMenuLoading(false);
    })();
  }, [id, selectedFilters, priceRange, brandIds]);

  useEffect(() => {
    (async () => {
      if (id) {
        const productPage = await fetchProducts(page);
        _products = productPage.data;
        if (!productPage.has_more) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
        heading_h1_ls = productPage.heading_h1;
        console.log('fetchProducts 3 ->'+heading_h1_ls);
        content_ls = productPage.content;
        banner_image = productPage.banner_image;
        mob_banner_image = productPage.mob_banner_image;
        setMetaTitle(productPage.meta_title);
        setProductListToRender([...productListToRender, ...productPage.data]);
        setProductsFromServer([...productsFromServer, ...productPage.data]);
        setIsLoading(false);
        setMenuLoading(false);
      }
    })();
  }, [page]);

  const handleClose = () => {
    setModalField({});
    setOpenModal(false);
  };

  const scrollToEnd = () => {
    setTimeout(function () {
      if (fetchScroll) {
        setPage((prevState) => {
          fetchScroll = false;
          return prevState + 1;
        });
      }
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener(
      "scroll",
      function () {
        var element = document.getElementById("loadingelement");
        if (
          element &&
          element.getBoundingClientRect().top <= window.innerHeight
        ) {
          scrollToEnd();
        }
      },
      true
    );
  }, []);

  // useEffect(() => {
  //   if (!isLoading) {
  //     if (
  //       !Object.keys(selectedFilters).some((key) => selectedFilters[key].length)
  //     ) {
  //       setProductListToRender(productsFromServer);
  //       return;
  //     }
  //     const filteredProducts = productsFromServer.filter((product) => {
  //       return product?.catalogs?.lables?.some((label) => {
  //         console.log(label);
  //         if (selectedFilters[label.label_name]) {
  //           console.log(label.options, selectedFilters[label.label_name]);
  //           if (
  //             label.options?.some((op) =>
  //               selectedFilters[label.label_name]?.some(
  //                 (s) => s === op.option_name
  //               )
  //             )
  //           ) {
  //             return true;
  //           }
  //           return false;
  //         }
  //       });
  //     });
  //     console.log(filteredProducts);
  //     setProductListToRender(filteredProducts);
  //   }
  // }, [selectedFilters, isLoading]);

  const [brand, setBrand] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const handlePrice = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    setBrand({ ...brand, [event.target.name]: event.target.checked });
  };

  return (
    <div className="productListing">
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      {mobileMatches && (
        <div className="productListing__left">
          <div>
            <h3>Price</h3>
            <PrettoSlider
              valueLabelDisplay="auto"
              getAriaLabel={(index) =>
                index === 0 ? "Minimum price" : "Maximum price"
              }
              defaultValue={[0, 100000]}
              min={0}
              max={100000}
              onChangeCommitted={(_, value) => {
                setPriceRange(value);
              }}
              disableSwap
            />
            <h5
              style={{
                marginTop: 0,
                fontWeight: 500,
                marginBottom: "20px",
              }}
            >
              Price range ₹{priceRange[0]} - ₹{priceRange[1]}
            </h5>
            <FormGroup>
              {isMenuLoading &&
                new Array(40).fill(1).map((e, i) => (
                  <FormControlLabel
                    sx={{
                      marginBottom: "8px",
                    }}
                    control={
                      <Checkbox className={classes.checkbox} name="gilad" />
                    }
                    label={
                      <Skeleton
                        variant="text"
                        width={180}
                        height={30}
                        animation="wave"
                      />
                    }
                  />
                ))}
              {!isMenuLoading &&
                catalogsToRender.map((catalog) => (
                  <>
                    {catalog.option.slice(0, 7).length > 0 && (
                      <h3
                        style={{
                          marginTop: "30px",
                          marginBottom: "10px",
                          fontSize: "18px",
                        }}
                      >
                        {catalog.label_name}
                      </h3>
                    )}
                    {catalog.option.map((option) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkbox}
                            name={option.name}
                            onChange={(e) => {
                              if (e.target.checked) {
                                if (catalog.label_name == "Brands") {
                                  setBrandIds((prevState) => {
                                    return [...prevState, option.id];
                                  });
                                } else {
                                  if (!selectedFilters[catalog.label_name])
                                    setSelectedFilters({
                                      ...selectedFilters,
                                      [catalog.label_name]: [option.id],
                                    });
                                  else
                                    setSelectedFilters({
                                      ...selectedFilters,
                                      [catalog.label_name]: [
                                        ...selectedFilters[catalog.label_name],
                                        option.id,
                                      ],
                                    });
                                }
                              } else {
                                if (catalog.label_name == "Brands") {
                                  setBrandIds((prevState) => {
                                    prevState = prevState.filter(
                                      (p) => p != option.id
                                    );
                                    return prevState;
                                  });
                                } else {
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    [catalog.label_name]: selectedFilters[
                                      catalog.label_name
                                    ].filter((filter) => filter !== option.id),
                                  });
                                }
                              }
                              setPage(1);
                            }}
                            checked={
                              (catalog.label_name == "Brands" &&
                                brandIds.includes(option.id)) ||
                              (catalog.label_name != "Brands" &&
                                selectedFilters[catalog.label_name] &&
                                selectedFilters[catalog.label_name].includes(
                                  option.id
                                ))
                            }
                          />
                        }
                        className="filter-label"
                        label={option.name}
                      />
                    ))}
                    {catalog.option.length > 7 && (
                      <p
                        className="filterMore"
                        onClick={() => {
                          setOpenModal(true);
                          setModalField(catalog);
                        }}
                      >
                        +{catalog.option.length - 7} more
                      </p>
                    )}
                  </>
                ))}
            </FormGroup>
          </div>
        </div>
      )}
      <div className="productListing__right">
        
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Breadcrumbs className="breadcrumb" aria-label="breadcrumb">
            <Link color="inherit" to="/">
              Home
            </Link>
            <Link color="inherit" to={"/shop-by-brand/" + id}>
              {productListToRender && productListToRender.length > 0
                ? productListToRender[0].brand_name
                : ""}
            </Link>
          </Breadcrumbs>
          {!mobileMatches && (
            <BottomNavigation showLabels sx={{ height: 45 }}>
              <BottomNavigationAction
                label="FILTER"
                icon={<FilterAltIcon />}
                onClick={() => setOpenFilterBox(true)}
              />
            </BottomNavigation>
          )}
        </Stack>
        {mobileMatches ? (
          <img
            className="banner"
            src={banner_image}
            alt=""
          />
        ) : (
          <img
            className="banner"
            src={mob_banner_image}
            alt=""
          />
        )}
        <Grid container spacing={0}>
          {productListToRender.map((product, i) => (
            <Grid
              item
              key={`product${product.id}`}
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={6}
            >
              <ProductSlider data={product} />
            </Grid>
          ))}
        </Grid>
        {loadMore && (
          <h4 id="loadingelement">
            <Grid container spacing={1}>
              {Array(12)
                .fill(0)
                .map((product, i) => (
                  <Grid item key={i} xl={3} lg={3} md={4} sm={6} xs={6}>
                    <Skeleton variant="rectangle" width="100%" height={220} />
                    <Skeleton variant="text" width="100%" height={60} />
                    <Skeleton variant="text" width="100%" height={45} />
                  </Grid>
                ))}
            </Grid>
          </h4>
        )}

        <div className="brand_content">
            <center><p><h3>{heading_h1_ls}</h3></p></center>
            <p
                style={{ color: "grey", overflow: "auto" }}
                dangerouslySetInnerHTML={{
                  __html: content_ls,
                }}
              />
        </div>
      </div>
      {!mobileMatches && (
        <>
          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              zIndex: "10",
              left: 0,
              right: 0,
            }}
            elevation={3}
          ></Paper>
          <SwipeableDrawer
            anchor={"bottom"}
            open={openSortBox}
            disableSwipeToOpen={true}
            onClose={() => setOpenSortBox(false)}
          >
            <div
              className={classes.fullList}
              role="presentation"
              onClick={() => setOpenSortBox(false)}
              onKeyDown={() => setOpenSortBox(false)}
            >
              <List>
                {[
                  "Recomended",
                  "Top Seller",
                  "New Arrivals",
                  "Price (low to high)",
                  "Price (high to low)",
                  "Discount",
                ].map((text, index) => (
                  <ListItem button key={text} onClick={() => setSortBy(text)}>
                    <ListItemText primary={text} />
                    {text === sortBy && (
                      <ListItemSecondaryAction>
                        <CheckIcon className={classes.checkIcon} />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </div>
          </SwipeableDrawer>

          <SwipeableDrawer
            anchor={"bottom"}
            disableSwipeToOpen={true}
            open={openFilterBox}
            onClose={() => setOpenFilterBox(false)}
            // onOpen={() => setOpenFilterBox(true)}
          >
            <h4 className="filterTitle">Filter</h4>
            <div className="filterBox">
              <div className="leftContainer">
                <div
                  className={classes.fullList}
                  role="presentation"
                  onKeyDown={() => setOpenFilterBox(false)}
                >
                  <List>
                    <ListItem
                      style={{
                        backgroundColor: "Price" === showFilterBy && "#fff",
                      }}
                    >
                      <ListItemText
                        primary={"Price"}
                        onClick={() => setShowFilterBy("Price")}
                      />
                    </ListItem>
                    {catalogsToRender.map((catalog, index) => (
                      <ListItem
                        style={{
                          backgroundColor:
                            catalog.label_name === showFilterBy && "#fff",
                        }}
                        button
                        key={index}
                      >
                        <ListItemText
                          primary={catalog.label_name}
                          onClick={() => setShowFilterBy(catalog.label_name)}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
              <div className="rightContainer">
                {showFilterBy && (
                  <>
                    {showFilterBy === "Price" && (
                      <div
                        style={{
                          width: "97%",
                        }}
                      >
                        <PrettoSlider
                          valueLabelDisplay="auto"
                          getAriaLabel={(index) =>
                            index === 0 ? "Minimum price" : "Maximum price"
                          }
                          defaultValue={[0, 100000]}
                          min={0}
                          max={100000}
                          onChangeCommitted={(_, value) => {
                            setPriceRange(value);
                          }}
                          disableSwap
                        />
                        <h5
                          style={{
                            marginTop: 0,
                            fontWeight: 600,
                            marginBottom: "20px",
                          }}
                        >
                          Price range ₹{priceRange[0]} - ₹{priceRange[1]}
                        </h5>
                      </div>
                    )}
                    <FormGroup>
                      {catalogsToRender
                        ?.find((catalog) => catalog.label_name === showFilterBy)
                        ?.options.map((option, index) => (
                          <FormControlLabel
                            key={index}
                            checked={selectedFilters[showFilterBy]?.includes(
                              option.id
                            )}
                            control={
                              <Checkbox
                                className={classes.checkbox}
                                name={option.name}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (!selectedFilters[showFilterBy])
                                      setSelectedFilters({
                                        ...selectedFilters,
                                        [showFilterBy]: [option.id],
                                      });
                                    else
                                      setSelectedFilters({
                                        ...selectedFilters,
                                        [showFilterBy]: [
                                          ...selectedFilters[showFilterBy],
                                          option.id,
                                        ],
                                      });
                                  } else {
                                    setSelectedFilters({
                                      ...selectedFilters,
                                      [showFilterBy]: selectedFilters[
                                        showFilterBy
                                      ].filter(
                                        (filter) => filter !== option.id
                                      ),
                                    });
                                  }
                                  setPage(1);
                                }}
                              />
                            }
                            label={option.name}
                          />
                        ))}
                    </FormGroup>
                  </>
                )}
              </div>
            </div>
            <Paper
              sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
              elevation={3}
            >
              <BottomNavigation showLabels sx={{ height: 45 }}>
                <BottomNavigationAction
                  label="CLOSE"
                  onClick={() => setOpenFilterBox(false)}
                />
                <BottomNavigationAction label="APPLY" />
              </BottomNavigation>
            </Paper>
          </SwipeableDrawer>
        </>
      )}
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="filterModalContainer">
          <div className="filterModalHeader">
            <h3>{modalField.label_name}</h3>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <div className="filterModalBody">
            <Grid container spacing={2}>
              {modalField &&
                modalField.option &&
                modalField.option.map((option) => (
                  <Grid item sm={3} xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.checkbox}
                          name={option.name}
                          onChange={(e) => {
                            if (e.target.checked) {
                              if (modalField.label_name == "Brands") {
                                setBrandIds((prevState) => {
                                  return [...prevState, option.id];
                                });
                              } else {
                                if (!selectedFilters[modalField.label_name])
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    [modalField.label_name]: [option.id],
                                  });
                                else
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    [modalField.label_name]: [
                                      ...selectedFilters[modalField.label_name],
                                      option.id,
                                    ],
                                  });
                              }
                            } else {
                              if (modalField.label_name == "Brands") {
                                setBrandIds((prevState) => {
                                  prevState = prevState.filter(
                                    (p) => p != option.id
                                  );
                                  return prevState;
                                });
                              } else {
                                setSelectedFilters({
                                  ...selectedFilters,
                                  [modalField.label_name]: selectedFilters[
                                    modalField.label_name
                                  ].filter((filter) => filter !== option.id),
                                });
                              }
                            }
                            setPage(1);
                          }}
                          checked={
                            (modalField.label_name == "Brands" &&
                              brandIds.includes(option.id)) ||
                            (modalField.label_name != "Brands" &&
                              selectedFilters[modalField.label_name] &&
                              selectedFilters[modalField.label_name].includes(
                                option.id
                              ))
                          }
                        />
                      }
                      className="filter-label"
                      label={option.name}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ShopByBrand;
