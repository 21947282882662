import React from "react";
import "./FAQS.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { fetchVetFaq } from "../api";
import { useState } from "react";
import { useEffect } from "react";
import vet_faqs from "../resources/vet_faqs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
  },
  arrow: {
    color: "#03D1C7",
  },
}));

function FAQS({ data, id }) {
  const classes = useStyles();
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    (async () => {
      const faqPage = await fetchVetFaq(id);
      console.log(faqPage);
      setFaq(faqPage.data);
    })();
  }, []);
  return (
    <section className="fqs section">
      <h2>Frequently Asked Questions</h2>
      {/* <Paper elevation={2}>
        {faq.map((a, i) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.arrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{a.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: a.answer,
                }}
              ></div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper> */}
      {vet_faqs.map((e) => {
        return (
          <div className="cms section">
            <Paper
              className={classes.paper}
              elevation={3}
              style={{ margin: "15px" }}
            >
              <h2>{e.section}</h2>
              {e.content.map((f) => {
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: f.question,
                        }}
                      ></div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: f.answer,
                        }}
                      ></div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Paper>
          </div>
        );
      })}
    </section>
  );
}

export default FAQS;
