import React, { useEffect, useRef } from "react";
import Slider from "react-slick";

function ImageSlider({ data, hover, type }) {
  const slider = useRef();
  var settings = {
    dots: true,
    autoplay: false,
    autoplaySpeed: 2000,
    className: "image-slider",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };

  useEffect(() => {
    if (hover) {
      slider.current.slickPlay();
    } else {
      slider.current.slickPause();
    }
  }, [hover]);

  return (
    <Slider
      // style={{ maxWidth: "250px" }}
      ref={(c) => (slider.current = c)}
      {...settings}
    >
      {data.map((img, i) => (
        <img
          // style={{
          //   maxWidth: "250px !important",
          // }}
          key={`image${i}`}
          src={img}
          alt=""
        />
      ))}
    </Slider>
  );
}

export default ImageSlider;
