import React, { useEffect, useState } from "react";
import "./Login.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "@material-ui/core/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LoginWithEmailPassword } from "../../api";
import { RegisterManually } from "../../api";
import { sendOtp } from "../../api";
import { resetPassword } from "../../api"; // imported
import { forgotPassOtp } from "../../api"; // imported
import { verifyforgotPassOtp } from "../../api"; // imported
import { menuActions } from "../../store";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../../constants";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { verifyOtp } from "../../api";
import CircularProgress from "@mui/material/CircularProgress";

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
      // transform: 'translate(14px, 12px) scale(1)',
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const BootstrapButton3 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    //   backgroundColor: '#09D1C7',
    borderColor: "#fff",
    paddingLeft: "20px",
    paddingRight: "20px",
    minWidth: "150px",
    marginLeft: "10px",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      borderColor: "#fff",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: "#fff",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  // textField:{
  //     marginBottom: '10px',
  // },
  blur: {
    position: "relative",
    backdropFilter: "blur(10px)",
  },
}));

let formText = "text here";

function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [loginForm, setLoginForm] = useState(true);
  const [signInWithOTPFormStatus, setSignInWithOTPFormStatus] = useState(false);
  const [sendOTPStatus, setSendOTPStatus] = useState(false);
  const [forgetPasswordFormStatus, setForgetPasswordFormStatus] =
    useState(false);
  const [sendForgetPasswordStatus, setSendForgetPasswordStatus] =
    useState(false);
  const [reqSent, setReqSent] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPass, setSignUpPass] = useState("");
  const [otpPhone, setOtpPhone] = useState("");
  const [resetemail, setResetEmail] = useState("");
  const [fbdata, setFbData] = useState({});
  const [login, setLogin] = useState(false);
  const [picture, setPicture] = useState("");
  const [glogin, setGlogin] = useState(false);
  const [otp, setOtp] = useState("");
  const [resetPasswordFromStatus, setResetPasswordFormStatus] = useState(false);
  const [newresetPassword, setNewResetPassword] = useState("");
  const [confirmResetPassword, setConfirmResetPassword] = useState("");
  const [sendingOtp, setSendingOtp] = useState(false);
  const [verifyingOtp, setVerifyingOtp] = useState(false);

  const handleSendLink = (event) => {
    event.preventDefault();
    setSendForgetPasswordStatus(true);
  };

  const responseGoogle = async (response) => {
    let data = {
      email: response.profileObj.email,
      social_unique_id: response.googleId,
      name: response.profileObj.name,
      login_by: "Google",
    };
    let res = await RegisterManually(data);
    if (res.error) {
      alert(res.message);
    } else {
      dispatch(
        menuActions.setUser({
          user: res.data.user,
          tokenId: res.data.user["social_unique_id"],
        })
      );
      props.setLoginBoxStatus(false);
    }
  };

  const responseGoogleFail = (response) => {
    console.log("Unable to login");
    console.log(response); // if the login fail
  };
  const responseFacebook = async (response) => {
    if (response.status === "unknown") return;
    let data = {
      email: response.email,
      social_unique_id: response.id,
      name: response.name,
      login_by: "Facebook",
    };
    let res = await RegisterManually(data);
    if (res.error) {
      toast.error(res.message);
    } else {
      toast.success("Logged in Successfully", { autoClose: 2000 });
      dispatch(
        menuActions.setUser({
          user: res.data.user,
          tokenId: res.data.user["social_unique_id"],
        })
      );
      props.setLoginBoxStatus(false);
    }
  };

  const otpVerify = async () => {
    const data = {
      mobile: resetemail ? resetemail : otpPhone,
      otp: otp,
    };
    const res = await verifyOtp(data);
    if (res.error) {
      toast.error(res.message);
    } else {
      dispatch(
        menuActions.setUser({
          user: res.data.user,
          tokenId: res.data.user["id"],
        })
      );
      setSendForgetPasswordStatus(false);
      setResetPasswordFormStatus(true);
      props.setLoginBoxStatus(false);
    }
  };

  const verifyforgotPasswordOtp = async () => {
    const data = {
      mobile: resetemail,
      otp: otp,
    };

    console.log(data);
    const res = await verifyforgotPassOtp(data);

    if (res.error) {
      toast.error(res.message);
    } else {
      setResetPasswordFormStatus(true);
    }
  };

  const handleSendOTP = async (event) => {
    event.preventDefault();
    setSendOTPStatus(true);

    const data = {
      mobile: resetemail,
    };

    console.log(data);
    const optResponse = await forgotPassOtp(data);

    // console.log(optResponse.data);
    setOtpPhone(optResponse.data);
    setSendForgetPasswordStatus(true);
  };

  const handleLoginWithEmailAndPassword = async () => {
    const res = await LoginWithEmailPassword(email, password);
    if (res.error) {
      toast.error(res.error);
      return;
    } else {
      const user = {
        ...res.data.user,
        photo: res.data.user.photo
          ? res.data.user_profile_link + "/" + res.data.user.photo
          : "",
      };
      dispatch(
        menuActions.setUser({
          user: user,
          tokenId: res.data.user["id"],
        })
      );
      props.setLoginBoxStatus(false);
    }
  };
  // we will send reset email to resetPassword  function in index.js
  const resetPwd = async () => {
    const data = {
      password: newresetPassword,
      password_confirmation: confirmResetPassword,
      mobile: resetemail,
    };
    console.log(data);
    const resetPasswordResponse = await resetPassword(data);
    if (resetPasswordResponse.error) {
      toast.error(resetPasswordResponse.message);
    } else {
      setSendingOtp(false);
      toast.success("Password Reset Successfully", { autoClose: 2000 });
      setForgetPasswordFormStatus(false);
      setResetPasswordFormStatus(false);
      setNewResetPassword("");
      setConfirmResetPassword("");
    }
  };

  const registerValue = async () => {
    if (phone && phone.length !== 10) {
      return toast.error("Please enter valid phone number");
    }
    const data = {
      //regsitered manually connected with store
      name: name,
      email: signupEmail,
      password: signupPass, //for signup
      mobile: phone,
      password_confirmation: signupPass,
      login_by: "Manual",
    };
    console.log(data);
    const res = await RegisterManually(data);
    if (res.error) {
      toast.error(res.message);
    } else {
      dispatch(
        menuActions.setUser({
          user: res.data.user,
          tokenId: res.data.user["id"],
        })
      );
      props.setLoginBoxStatus(false);
    }
  };

  const otpValue = async () => {
    const data = {
      mobile: otpPhone, //for otp
    };
    console.log(data); //we will log the data
    const otpResponse = await sendOtp(data);
    if (otpResponse.error) {
      toast.error(otpResponse.message);
    } else {
      setSendOTPStatus(true);
      setSignInWithOTPFormStatus(true);
    }
    console.log(otpResponse);
  };

  const viewLoginForm = () => {
    if (
      forgetPasswordFormStatus &&
      !sendForgetPasswordStatus &&
      !resetPasswordFromStatus
    ) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // resetPwd();
            setSendingOtp(true);
            handleSendOTP(e);
          }}
        >
          <h3 className="title">Reset Password</h3>
          <CssTextField
            className={classes.textField}
            label="Email"
            type="tel"
            id="outlined-size-small"
            size="small"
            margin="dense"
            value={resetemail ? resetemail : ""}
            onChange={(event) => {
              setResetEmail(event.target.value);
            }}
            required
          />

          <BootstrapButton2 type="submit">
            {sendingOtp ? "Sending OTP" : "Send OTP"}
          </BootstrapButton2>
          <div className="buttonContainer">
            <Button
              size="small"
              onClick={() => {
                setForgetPasswordFormStatus(false);
                setResetPasswordFormStatus(false);
                setNewResetPassword("");
                setConfirmResetPassword("");
                setSendingOtp(false);
              }}
            >
              Back To Login
            </Button>
          </div>
        </form>
      );
    }
    if (
      forgetPasswordFormStatus &&
      sendForgetPasswordStatus &&
      !resetPasswordFromStatus
    ) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // resetPwd();
            setConfirmResetPassword("");
            verifyforgotPasswordOtp();
          }}
        >
          <div>
            <h3 className="title">OTP!!!</h3>
            <p className="desc">OTP has been sent to {resetemail}</p>
            <CssTextField
              className={classes.textField}
              label="OTP"
              type="tel"
              id="outlined-size-small"
              size="small"
              margin="dense"
              onChange={(event) => setOtp(event.target.value)}
              required
            />
            <BootstrapButton2 type="submit">Verify OTP</BootstrapButton2>
            <div className="iconContainer">
              <BootstrapButton2
                onClick={() => {
                  setForgetPasswordFormStatus(false);
                  setResetPasswordFormStatus(false);
                  setNewResetPassword("");
                  setConfirmResetPassword("");
                  setSendForgetPasswordStatus(false);
                  setSendOTPStatus(false);
                }}
              >
                Back to login
              </BootstrapButton2>
            </div>
          </div>
        </form>
      );
    }
    // resetPasswordFromStatus
    if (forgetPasswordFormStatus && resetPasswordFromStatus) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            resetPwd();
          }}
        >
          <h3 className="title">Enter New Password</h3>
          <CssTextField
            className={classes.textField}
            label="Password"
            type="password"
            id="outlined-size-small"
            size="small"
            margin="dense"
            value={newresetPassword}
            onChange={(event) => setNewResetPassword(event.target.value)}
            required
          />
          <CssTextField
            className={classes.textField}
            label="Confirm New Password"
            type="password"
            id="outlined-size-small"
            size="small"
            margin="dense"
            value={confirmResetPassword}
            onChange={(event) => setConfirmResetPassword(event.target.value)}
            required
          />
          <BootstrapButton2 type="submit">Submit</BootstrapButton2>
          <br />
          <div className="iconContainer">
            <BootstrapButton2
              onClick={() => {
                setForgetPasswordFormStatus(false);
                setResetPasswordFormStatus(false);
                setSendForgetPasswordStatus(false);
                setNewResetPassword("");
                setConfirmResetPassword("");
                setSendOTPStatus(false);
                setEmail("");
                setPassword("");
              }}
            >
              Back to login
            </BootstrapButton2>
          </div>
        </form>
      );
    }

    if (signInWithOTPFormStatus && !sendOTPStatus) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            otpValue();

            //function bnange
          }}
        >
          <h3 className="title">Sign In</h3>
          <CssTextField
            className={classes.textField}
            label="Phone"
            type="tel"
            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            id="outlined-size-small"
            size="small"
            margin="dense"
            value={otpPhone}
            onChange={(event) => setOtpPhone(event.target.value)}
            required
          />

          <BootstrapButton2 type="submit">Send OTP</BootstrapButton2>
          <div className="buttonContainer">
            <Button
              size="small"
              type="text"
              onClick={() => setSignInWithOTPFormStatus(false)}
            >
              Login with Password
            </Button>
          </div>
        </form>
      );
    }

    if (signInWithOTPFormStatus && sendOTPStatus) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            otpVerify();
          }}
        >
          <h3 className="title">Sign In Form</h3>
          <p className="desc">OTP has been sent to {otpPhone}</p>
          <CssTextField
            className={classes.textField}
            label="OTP"
            id="outlined-size-small"
            size="small"
            margin="dense"
            onChange={(event) => setOtp(event.target.value)}
            required
          />

          <BootstrapButton2 type="submit">Submit</BootstrapButton2>
          <div className="buttonContainer">
            <Button size="small" onClick={() => setSendOTPStatus(false)}>
              Change Mobile Number
            </Button>
            <Button
              size="small"
              onClick={() => setSignInWithOTPFormStatus(false)}
            >
              Resend
            </Button>
          </div>
        </form>
      );
    }
    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setReqSent(true);
          await handleLoginWithEmailAndPassword();
          setReqSent(false);
        }}
      >
        <h3 className="title">Sign In Form</h3>
        <CssTextField
          className={classes.textField}
          label="Email"
          type="email"
          id="outlined-size-small"
          size="small"
          margin="dense"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <CssTextField
          className={classes.textField}
          label="Password"
          type="password"
          id="outlined-size-small"
          size="small"
          margin="dense"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          required
        />

        <BootstrapButton2 disabled={reqSent} type="submit">
          {reqSent ? (
            <CircularProgress
              size={20}
              sx={{
                color: "white",
              }}
            />
          ) : (
            "Sign In"
          )}
        </BootstrapButton2>
        <div className="buttonContainer">
          <Button size="small" onClick={() => setSignInWithOTPFormStatus(true)}>
            Login with OTP
          </Button>
          <Button
            size="small"
            onClick={() => {
              setForgetPasswordFormStatus(true);
              setResetPasswordFormStatus(false);
              setSendForgetPasswordStatus(false);
              setNewResetPassword("");
              setConfirmResetPassword("");
            }}
          >
            Forgot Password
          </Button>
        </div>
      </form>
    );
  };

  useEffect(() => {
    setSendOTPStatus(false);
    setSignInWithOTPFormStatus(false);
    setForgetPasswordFormStatus(false);
    setResetPasswordFormStatus(false);
    setNewResetPassword("");
    setConfirmResetPassword("");
  }, [loginForm, props.loginBoxStatus]);

  return (
    <Dialog
      open={props.loginBoxStatus}
      fullWidth={true}
      maxWidth="md"
      onClose={() => props.setLoginBoxStatus(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.blur}
    >
      <div className="login__container">
        <div className="left">
          <img
            className="logo"
            src={require("../../image/logo.png").default}
            alt=""
          />

          {loginForm ? <h3 className="title">Welcome Back !</h3> : <></>}
          <br></br>
          {loginForm ? (
            <BootstrapButton3
              onClick={() => setLoginForm(false)}
              size={mobileMatches ? "medium" : "small"}
            >
              Sign Up
            </BootstrapButton3>
          ) : (
            <BootstrapButton3
              onClick={() => setLoginForm(true)}
              size={mobileMatches ? "medium" : "small"}
            >
              Sign In
            </BootstrapButton3>
          )}
        </div>
        <div className="loginRightContainer paddingZero">
          {loginForm ? (
            viewLoginForm()
          ) : (
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                setReqSent(true);
                await registerValue();
                setReqSent(false);
              }}
            >
              <h3 className="title">CREATE ACCOUNT</h3>
              <CssTextField
                className={classes.textField}
                label="Name"
                type="text"
                id="outlined-size-small"
                size="small"
                margin="dense"
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
              />
              <CssTextField
                className={classes.textField}
                label="Phone"
                type="tel"
                name="phone"
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                id="outlined-size-small"
                size="small"
                margin="dense"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                required
              />
              <CssTextField
                className={classes.textField}
                label="Email"
                type="email"
                id="outlined-size-small"
                size="small"
                margin="dense"
                onChange={(event) => setSignupEmail(event.target.value)}
                required
              />
              <CssTextField
                className={classes.textField}
                label="Password"
                type="password"
                id="outlined-size-small"
                size="small"
                margin="dense"
                onChange={(event) => setSignUpPass(event.target.value)}
                required
              />
              <p className="desc">
                By continuing, you agree to pettofit’s{" "}
                <a href="/terms&condition">Terms of Use </a>and{" "}
                <a href="privacy-policy">Privacy Policy</a>
              </p>
              <BootstrapButton2
                disabled={reqSent}
                type="submit"
                id="submit_button"
              >
                {reqSent ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "white",
                    }}
                  />
                ) : (
                  "Sign Up"
                )}
              </BootstrapButton2>
            </form>
          )}
          <p className="desc">Or Sign up with </p>
          <div className="iconContainer">
            {/* <img src={require("../../image/google.png").default} alt="" /> */}
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              render={(renderProps) => (
                <button
                  className="Btn"
                  style={{
                    border: 0,
                    background: "transparent",
                  }}
                  onClick={renderProps.onClick}
                >
                  <img
                    src={require("../../image/google.png").default}
                    alt=""
                    style={{ width: "50px" }}
                  />
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogleFail}
              cookiePolicy={"single_host_origin"}
            />
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              autoLoad={false}
              fields="name,email,picture"
              scope="public_profile,email"
              cssClass="fbBtn"
              callback={responseFacebook}
              render={(renderProps) => (
                <button
                  className="Btn fa fa-facebook"
                  style={{
                    border: 0,
                    background: "transparent",
                  }}
                  onClick={renderProps.onClick}
                >
                  {" "}
                  <img src={require("../../image/fb.png").default} alt="" />
                </button>
              )}
            />
            {/* <GoogleLogin
              clientId=""
              buttonText="login"
              onSuccess={responseGoogle}
              onFailure={responseGoogleFail}
              style={{
                marginTop: "10px",
              }}
              isSignedIn={true}
            />
            {login && <img src={picture} alt="" />} */}
          </div>
        </div>
        <IconButton
          className="closeButton"
          onClick={() => props.setLoginBoxStatus(false)}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </Dialog>
  );
}

export default Login;
