export const allSpecies = [
  { value: "DOG", label: "DOG" },
  { value: "CAT", label: "CAT" },
  { value: "BIRDS", label: "BIRDS" },
  { value: "RABBITS", label: "RABBITS" },
  { value: "HAMSTER", label: "HAMSTER" },
  { value: "GUIENE PIG", label: "GUIENE" },
  { value: "FISH", label: "FISH" },
  { value: "TURTLES", label: "TURTLES" },
  { value: "CATTLES", label: "CATTLES" },
  { value: "REPTILES", label: "REPTILES" },
];
