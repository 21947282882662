import React, { useEffect, useRef, useState } from "react";
import "./ProductDetail.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useParams, useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ReviewSummary from "./ReviewSummary";
import Review from "./Review";
import Paper from "@mui/material/Paper";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@mui/material/Box";
import SimilarProduct from "./SimilarProduct";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { getProductDetailsApi, checkStock } from "../../api";
import { fetchPincode } from "../../api";
import { toast } from "react-toastify";
import FAQS from "../FAQS";
import { addToCart, fetchItems, menuActions, updateCart } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Skeleton from "@mui/material/Skeleton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Login from "../login/Login";
import CircularProgress from "@mui/material/CircularProgress";
import DiscountIcon from "@mui/icons-material/Discount";
import { Helmet } from "react-helmet";
import axios from "axios";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    backgroundColor: "red",
  },
}));

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
      transform: "translate(14px, 12px) scale(1)",
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    zIndex: "10",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: 'lightgray',
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

const BootstrapButton1 = withStyles({
  root: {
    color: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:active": {
      boxShadow: "none",
    },
  },
})(Button);

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    marginLeft: "10px",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const BootstrapButton3 = withStyles({
  root: {
    border: "1px solid",
    color: "#000",
    //   backgroundColor: '#09D1C7',
    borderColor: "#000",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      borderColor: "#000",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: "#000",
    },
  },
})(Button);

function ImageMagnifier({ image }) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const magnifierHeight = 100;
  const magnifieWidth = 100;
  const zoomLevel = 2;
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);

  return (
    <Box sx={{ position: "relative" }}>
      <img
        style={{ objectFit: "contain", width: "100%" }}
        src={image}
        alt=""
        onMouseEnter={(e) => {
          const elem = e.currentTarget;
          setWidth(elem.getBoundingClientRect().width);
          setHeight(elem.getBoundingClientRect().height);
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();
          const x = e.pageX - left - window.pageXOffset;
          const y = e.pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
        onMouseLeave={() => {
          setShowMagnifier(false);
        }}
      />
      <Box
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",
          pointerEvents: "none",
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: "1",
          border: "1px solid lightgray",
          backgroundColor: "white",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      ></Box>
    </Box>
  );
}
function ProductDetail() {
  const { isLoggedIn, cart, sessionlessCart } = useSelector(
    (state) => state.menu
  );
  const mobileMatches = useMediaQuery("(min-width:700px)");
  const [showSizeChart, setShowSizeChart] = useState(false);
  const slider = useRef();
  const [addToCartReq, setAddToCartReq] = useState(false);
  const focusContainerRef = useRef();
  const [pickedSize, setPickedSize] = useState(null);
  const [shakeStatus, setShakeStatus] = useState(false);
  const [pickedColor, setPickedColor] = useState(null);
  const [windowsOffset, setWindowsOffset] = useState(0);
  const [value, setValue] = React.useState(0);
  const [productDetails, setProductDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [counter, setCounter] = useState(1);
  const dispatch = useDispatch();
  const [selectedVariation, setSelectedVariation] = useState({});
  const [sizeColorMap, setSizeColorMap] = useState({});
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [pincode, setPincode] = useState("");
  const [isPincodeValid, setIsPincodeValid] = useState(false);
  const [pincodeChecked, setPincodeChecked] = useState(false);
  const [loginBoxStatus, setLoginBoxStatus] = useState(false);
  const [metaInfo, setMetaInfo] = useState({});

  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };

  const handlePincode = async () => {
    try {
      const res = await fetchPincode(pincode);
      setIsPincodeValid(!res.data.error);
      setPincodeChecked(true);
    } catch (error) {
      setIsPincodeValid(false);
      setPincodeChecked(true);
    }
  };
  const addProduct = () => {
    setCounter(counter === 10 ? counter : counter + 1);
  };
  const reduceProduct = () => {
    if (counter === 1) {
      return counter;
    } else {
      setCounter(counter - 1);
    }
  };

  useEffect(() => {
    if (productList && productList.images && productList.images.length > 1) {
      var ulelems = document.getElementsByClassName("slick-dots");
      var ulelem =
        ulelems && ulelems[0] ? ulelems[0] : document.createElement("ul");
      var parentElem = null;
      if (ulelem) {
        parentElem = ulelem.parentElement;
        if (parentElem) {
          if (parentElem.style.width != "100%") {
            var wrapper = document.createElement("div");
            wrapper.style.width = "100%";
            wrapper.style.overflowX = "scroll";
            wrapper.style.overflowY = "hidden";
            wrapper.id = "dots-wrapper";
            if (parentElem.contains(ulelem)) {
              parentElem.replaceChild(wrapper, ulelem);
              try {
                if (
                  document.getElementById("dots-wrapper") &&
                  ulelem &&
                  parentElem.contains(wrapper)
                ) {
                  wrapper.appendChild(ulelem);
                }
              } catch (err) {
                console.log("err", err);
              }
            }
          }
        }
      } else {
        parentElem = document.getElementById("dots-wrapper");
        if (parentElem) {
          parentElem.remove();
        }
      }
    }
  });

  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/get-product?slug=" + atob(id))
      .then((response) => {
        setMetaInfo(response.data.data);
      });
  }, []);
  useEffect(() => {
    (async () => {
      const details = await getProductDetailsApi(atob(id));
      setProductDetails(details.data.data);
      setIsLoading(false);
      axios
        .get("https://api.pettofit.com/api/v1/get-product?slug=" + atob(id))
        .then((response) => {
          setMetaInfo(response.data.data);
        });
    })();
  }, [id]);

  useEffect(() => {
    if (pickedSize) {
      setColors(sizeColorMap[pickedSize]);
      setPickedColor(sizeColorMap[pickedSize][0]);
    }
  }, [pickedSize]);

  useEffect(() => {
    setSelectedVariation(
      productDetails?.variation_types?.find(
        (variation) =>
          variation.color_attribute === pickedColor &&
          variation.size_attribute === pickedSize
      )
    );
  }, [pickedColor, pickedSize]);
  useEffect(() => {
    if (productDetails) {
      if (productDetails.product_type === 1) {
        const tempMap = {};
        productDetails.variation_types.forEach((variation) => {
          if (tempMap[variation.size_attribute]) {
            tempMap[variation.size_attribute].push(variation.color_attribute);
          } else {
            tempMap[variation.size_attribute] = [variation.color_attribute];
          }
        });
        setSizeColorMap(tempMap);
        setSelectedVariation(productDetails.variation_types[0]);
        setSizes(Object.keys(tempMap));
        setPickedSize(productDetails.variation_types[0].size_attribute);
        setColors(tempMap[Object.keys(tempMap)[0]]);
      } else {
        setSelectedVariation(productDetails);
      }
    }
  }, [productDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let interval = useRef();

  const handlePrevSlide = () => {
    slider.current.slickPrev();
  };

  const handleNextSlide = () => {
    slider.current.slickNext();
  };
  const handleFocusReview = () => {
    setValue(2);
    focusContainerRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const settings = {
    customPaging: function (i) {
      return (
        <span className="sliderMenu">
          <img
            className="sliderMenu"
            style={{ objectFit: "contain", width: "60px" }}
            src={productList.images[i] || "https://dummyimage.com/50x50"}
            alt=""
          />
        </span>
      );
    },
    dots: true,
    dotsClass: "new-slick-dots slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  var settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };
  const productList = {
    id: productDetails?.id,
    title: productDetails?.product_title,
    rating: productDetails?.rating || 0,
    size: productDetails?.size || [],
    color: productDetails?.color || [],
    discountedPrice: selectedVariation?.product_sale_price,
    actualPrice: selectedVariation?.product_mrp,
    images: [
      productDetails.product_image_URL + "/" + selectedVariation?.product_image,
      ...(selectedVariation
        ? selectedVariation.product_other_images?.length
          ? selectedVariation.product_other_images.map(
              (image) => productDetails.product_other_images_URL + "/" + image
            )
          : []
        : []),
    ],
  };

  useEffect(() => {
    if (shakeStatus) {
      let count = 0;
      interval.current = setInterval(() => {
        if (count < 1) {
          count = count + 1;
        } else {
          setShakeStatus(false);
          clearInterval(interval.current);
        }
      }, 1000);
    }

    return () => clearInterval(interval.current);
  }, [shakeStatus]);

  useEffect(() => {}, [pickedColor]);

  window.onscroll = () => {
    setWindowsOffset(window.pageYOffset);
  };

  const handleAddToCart = async () => {
    if (!isLoggedIn) {
      const checkStockStatus = await checkStock({
        product_id: productDetails.id,
        picked_color: pickedColor,
        picked_size: pickedSize,
      });
      if (!checkStockStatus.data.error)
        if (
          sessionlessCart.some(
            (item) =>
              item.id === productDetails.id &&
              (item.picked_size === pickedSize || item.picked_size === "''") &&
              (item.picked_color === pickedColor || item.picked_color === "''")
          )
        ) {
          dispatch(
            menuActions.incrementSessionlessCart({
              ...productDetails,
              product_id: productDetails.id,
              title: productDetails.title,
              rating: productDetails.rating,
              picked_size: pickedSize || "",
              color: pickedColor || "",
              pickedSize: pickedSize,
              picked_color: pickedColor || "",
              quantity: counter,
              images: selectedVariation?.product_other_images?.length && selectedVariation?.product_other_images.map(
                (v) => productDetails.product_other_images_URL + "/" + v
              ) || [],
              ...selectedVariation,
            })
          );
        } else
          dispatch(
            menuActions.setSessionlessCart({
              ...productDetails,
              product_id: productDetails.id,
              title: productDetails.title,
              rating: productDetails.rating,
              picked_size: pickedSize || "",
              color: pickedColor || "",
              pickedSize: pickedSize,
              picked_color: pickedColor || "",
              quantity: counter,
              images: selectedVariation?.product_other_images?.length && selectedVariation?.product_other_images.map(
                (v) => productDetails.product_other_images_URL + "/" + v
              ) || [],
              ...selectedVariation,
            })
          );

      toast.success("The item has been added to the cart successfully");
      return;
    }
    if (
      cart.some(
        (item) =>
          item.product_id === productDetails.id &&
          (item.picked_size === pickedSize || item.picked_size === "''") &&
          (item.picked_color === pickedColor || item.picked_color === "''")
      )
    ) {
      const productToCart = {
        ...productDetails,
        product_id: productDetails.id,
        picked_size: pickedSize || 0,
        picked_color: pickedColor || 0,
        quantity: 1,
      };
      const res = await updateCart(productToCart, "increase", dispatch);
      fetchItems()(dispatch);
    } else {
      await addToCart(
        {
          ...productDetails,
          quantity: counter,
          product_id: productDetails.id,
          picked_color: pickedColor ? pickedColor : "",
          picked_size: pickedSize ? pickedSize : "null",
        },
        dispatch
      );
      fetchItems()(dispatch);
    }

    toast.success("The item has been added to the cart successfully");
    return;
  };

  return (
    <div
      className="section2 productDetail"
      style={{
        paddingTop: "15px !important",
        margin: "0px !important",
        background: "rgb(249, 249, 253) !important",
      }}
    >
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      {!isLoading && (
        <div
          className="productDetail__detail"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            padding: "25px 10px",
            background: "#fff",
            height: "700px",
          }}
        >
          <ArrowBackRoundedIcon
            className="backArrow"
            onClick={goToPreviousPath}
          />
          <div className="slider__container" style={{ height: "fit-content" }}>
            {mobileMatches ? (
              <div style={{ position: "relative", width: "fit-content" }}>
                <Slider
                  className="Slider_img"
                  ref={(c) => (slider.current = c)}
                  {...settings}
                >
                  {productList.images.map((image) => (
                    <ImageMagnifier image={image} />
                  ))}
                </Slider>
              </div>
            ) : (
              <Slider
                style={{ width: "100%" }}
                ref={(c) => (slider.current = c)}
                {...settings2}
              >
                {productList.images.map((image) => (
                  <div>
                    <img
                      style={{ objectFit: "contain", width: "100%" }}
                      src={image}
                      alt=""
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <div className="detail__container" style={{ marginBottom: "-20px" }}>
            <div>
              <h3 className="title">{productList.title}</h3>
              <div className="ratingContainer" onClick={handleFocusReview}>
                {[1, 2, 3, 4, 5].map((rating) =>
                  rating <= productList.rating ? (
                    <StarIcon key={`star${rating}`} className="ratingIcon" />
                  ) : (
                    <StarBorderIcon
                      key={`star${rating}`}
                      className="ratingIcon"
                    />
                  )
                )}
              </div>
            </div>
            <div>
              <div className="productDetail__rateCard">
                <p className="discountedPrice">
                  <FontAwesomeIcon icon={faRupeeSign} className="icon" />
                  {selectedVariation?.product_sale_price}
                </p>
                {!(
                  selectedVariation?.product_sale_price ===
                  selectedVariation?.product_mrp
                ) && (
                  <p className="actualPrice">
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />
                    {selectedVariation?.product_mrp}
                  </p>
                )}
                {!(productList.actualPrice === productList.discountedPrice) && (
                  <p className="percentageOFF">
                    (
                    {100 -
                      (
                        (productList.discountedPrice * 100) /
                        productList.actualPrice
                      ).toFixed(0) || 0}
                    % OFF)
                  </p>
                )}
              </div>
              <p className="Inc">* All taxes included</p>
              <div className="productOfferContainer">
                <div>
                  <DiscountIcon />
                  Get Flat ₹100 Off On All Orders above ₹1000. Use Code:{" "}
                  <b>NEW100</b>
                </div>
                <div>
                  <DiscountIcon />
                  Get Flat ₹200 Off On All Orders above ₹2000. Use Code:{" "}
                  <b>NEW200</b>
                </div>
              </div>
              <h4>Description</h4>
              <p
                style={{ color: "grey", overflow: "auto" }}
                dangerouslySetInnerHTML={{
                  __html: productDetails?.product_specification,
                }}
              />
            </div>
            <div className="productDetailselection">
              {sizes !== undefined && sizes.length > 0 ? (
                <div
                  className={`productDetail__sizeContainer ${
                    shakeStatus && "shake"
                  }`}
                >
                  {productDetails.product_chart_image &&
                    (productDetails.product_chart_image.includes(".jpg") ||
                      productDetails.product_chart_image.includes(".png")) && (
                      <div className="sizeContainer__header">
                        <h3 style={{ margin: "0px" }}>SELECT SIZE</h3>
                        <BootstrapButton1
                          size="small"
                          onClick={() => setShowSizeChart(true)}
                        >
                          Size Chart
                        </BootstrapButton1>
                      </div>
                    )}
                  <hr style={{ color: "#e2e2e2" }}></hr>
                  <div className="sizeContainer__body">
                    {sizes?.map((size) => (
                      <div
                        key={`size${size}`}
                        style={{
                          backgroundColor: pickedSize === size ? "#B9FFFB" : "",
                        }}
                        onClick={() => setPickedSize(size)}
                        className="sizeBox"
                      >
                        {size}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="productDetail__sizeContainer">
                  <div className="sizeBoxBlank"></div>
                </div>
              )}

              {productList.color !== undefined && productList.color.length > 0 && (
                <div className="colorPicker">
                  <hr style={{ color: "#e2e2e2" }}></hr>
                  <h4>SELECT COLOR</h4>
                  <div className="body">
                    {productList.color.map((color) => (
                      <div
                        key={`color${color.code}`}
                        className={`colorBox ${
                          color.name === pickedColor?.name
                            ? "active"
                            : "picking"
                        }`}
                        onClick={() => setPickedColor(color)}
                        style={{ backgroundColor: `${color.code}` }}
                      ></div>
                    ))}
                  </div>
                  <hr style={{ color: "#e2e2e2" }}></hr>
                </div>
              )}
            </div>
            {colors !== undefined &&
              colors.length > 0 &&
              colors.some((color) => color !== "") && (
                <div className="colorPicker">
                  <h4>SELECT COLOR</h4>
                  <div className="body">
                    {colors.map((color) => (
                      <div
                        key={`color${color}`}
                        className={`colorBox ${
                          color === pickedColor?.name ? "active" : "picking"
                        }`}
                        onClick={() => setPickedColor(color)}
                        style={{ backgroundColor: `${color}` }}
                      ></div>
                    ))}
                  </div>
                </div>
              )}
            <div className="deleverContainer">
              <h4>DELIVER TO</h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePincode();
                }}
              >
                <input
                  type="text"
                  style={{ width: "100%" }}
                  value={pincode}
                  onChange={(event) => {
                    console.log(
                      event.target.value,
                      !isNaN(event.target.value),
                      event.target.value.length <= 6
                    );
                    if (
                      !isNaN(event.target.value) &&
                      event.target.value.length <= 6
                    ) {
                      setPincodeChecked(false);
                      setIsPincodeValid(false);
                      setPincode(event.target.value);
                    }
                  }}
                />
                <BootstrapButton1 type="submit">Check</BootstrapButton1>
              </form>
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: isPincodeValid ? "#09D1C7" : "red",
              }}
            >
              {pincodeChecked &&
                (isPincodeValid
                  ? "Delivery is available for this location"
                  : "Delivery is not available for this location")}
            </div>
            {mobileMatches ? (
              <div>
                <hr style={{ color: "#e2e2e2", width: "100%" }}></hr>
                <Stack
                  spacing={0}
                  direction="row"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <h4 style={{ fontSize: "20px" }}>QTY :</h4>
                  <Button variant="text" onClick={reduceProduct}>
                    -
                  </Button>
                  <p style={{ margin: "10px 20px" }}>{counter}</p>
                  <Button
                    variant="text"
                    onClick={() => {
                      if (counter < selectedVariation.max_cart_val) {
                        addProduct();
                      }
                    }}
                  >
                    +
                  </Button>
                  <BootstrapButton3
                    size="large"
                    style={{
                      color: "#09d1c7",
                      borderColor: "#09b1c7",
                      fontSize: "18px",
                      width: "50%",
                      height: "50px",
                      backgroundColor:
                        addToCartReq ||
                        (selectedVariation &&
                          selectedVariation.out_of_stock == "1")
                          ? "#e6e6e6"
                          : "",
                    }}
                    disabled={
                      addToCartReq ||
                      (selectedVariation &&
                        selectedVariation.out_of_stock == "1")
                    }
                    onClick={async () => {
                      setAddToCartReq(true);
                      await handleAddToCart();
                      setAddToCartReq(false);
                    }}
                  >
                    {selectedVariation && selectedVariation.out_of_stock == "1"
                      ? "Out of Stock"
                      : "Add to Cart"}
                  </BootstrapButton3>
                </Stack>
                <hr style={{ color: "#e2e2e2", width: "100%" }}></hr>
                <div className="buttonContainer">
                    
                  {productDetails.is_returnable === 1 ? ( 
                    <img style={{ width: "100%" }} src="https://api.pettofit.com/uploads/icons/product_details/easy_return.png" alt=""/>
                  ) : (
                    <img style={{ width: "100%" }} src="https://api.pettofit.com/uploads/icons/product_details/no_return.png" alt=""/>
                  )}

                </div>
                <Slide
                  direction="up"
                  in={windowsOffset > 500 ? true : false}
                  mountOnEnter
                  unmountOnExit
                >
                  <Paper elevation={4} className={`stickyBottomBar`}>
                    <div className="d-flex">
                      <img src={productList.images[0]} alt="" />
                      <div>
                        <h4>{productList.title}</h4>
                        <div className="PriceTags">
                          <p>MRP </p>
                          {!(
                            productList.actualPrice ===
                            productList.discountedPrice
                          ) && (
                            <p className="discountedPrice">
                              <FontAwesomeIcon
                                icon={faRupeeSign}
                                className="icon"
                              />{" "}
                              {productList.actualPrice}
                            </p>
                          )}
                          <p className="actualPrice">
                            <FontAwesomeIcon
                              icon={faRupeeSign}
                              className="icon"
                            />{" "}
                            {productList.discountedPrice}
                          </p>
                        </div>
                      </div>
                    </div>
                    {productList.color !== undefined &&
                      productList.color.length > 0 && (
                        <div className="colorPicker">
                          <h4 style={{ fontSize: "0.8em" }}>SELECT COLOR</h4>
                          <div className="body" style={{ marginBottom: "0px" }}>
                            {productList.color.map((color) => (
                              <div
                                key={`color${color.code}`}
                                className={`colorBox ${
                                  color.name === pickedColor?.name
                                    ? "active"
                                    : "picking"
                                }`}
                                onClick={() => setPickedColor(color)}
                                style={{ backgroundColor: `${color.code}` }}
                              ></div>
                            ))}
                          </div>
                        </div>
                      )}
                    {productList.size !== undefined &&
                    productList.size.length > 0 ? (
                      <div
                        className={`productDetail__sizeContainer ${
                          shakeStatus && "shake"
                        }`}
                      >
                        <div className="sizeContainer__header">
                          <h4 style={{ fontSize: "0.8em" }}>SELECT SIZE</h4>
                        </div>
                        <div
                          className="sizeContainer__body"
                          style={{ marginBottom: "0px" }}
                        >
                          {productList.size.map((size) => (
                            <IconButton
                              key={`size${size}`}
                              size="small"
                              style={{
                                backgroundColor:
                                  pickedSize === size ? "#B9FFFB" : "",
                              }}
                              onClick={() => setPickedSize(size)}
                              className="sizeBox"
                            >
                              {size}
                            </IconButton>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="productDetail__sizeContainer">
                        <div className="sizeBoxBlank"></div>
                      </div>
                    )}
                    <div className="d-flex">
                      <BootstrapButton2
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor:
                            addToCartReq ||
                            (selectedVariation &&
                              selectedVariation.out_of_stock == "1")
                              ? "#e6e6e6"
                              : "",
                        }}
                        disabled={
                          addToCartReq ||
                          (selectedVariation &&
                            selectedVariation.out_of_stock == "1")
                        }
                        onClick={async () => {
                          setAddToCartReq(true);
                          await handleAddToCart();
                          setAddToCartReq(false);
                        }}
                      >
                        {selectedVariation &&
                        selectedVariation.out_of_stock == "1"
                          ? "Out of Stock"
                          : "Add to Cart"}
                      </BootstrapButton2>
                    </div>
                  </Paper>
                </Slide>
              </div>
            ) : (
              
              <div className="mobileButtonContainer">
                <div
                  className="buy"
                  onClick={async () => {
                    setAddToCartReq(true);
                    await handleAddToCart();
                    setAddToCartReq(false);
                  }}
                  style={{
                    backgroundColor:
                      addToCartReq ||
                      (selectedVariation &&
                        selectedVariation.out_of_stock == "1")
                        ? "#e6e6e6"
                        : "",
                  }}
                  disabled={
                    addToCartReq ||
                    (selectedVariation && selectedVariation.out_of_stock == "1")
                  }
                >
                  <ShoppingCartIcon />
                  {selectedVariation && selectedVariation.out_of_stock == "1"
                    ? "OUT OF STOCK"
                    : "ADD TO CART"}
                </div>
                {/* <div className="cart">BUY NOW</div> */}
              </div>
            )}
          </div>
         
          <div className="detail__container2">
          { !mobileMatches ? (
            <div className="">
              {productDetails.is_returnable === 1 ? ( 
                  <img style={{ width: "100%" }} src="https://api.pettofit.com/uploads/icons/product_details/easy_return.png" alt=""/>
                ) : (
                  <img style={{ width: "100%" }} src="https://api.pettofit.com/uploads/icons/product_details/no_return.png" alt=""/>
                )}
            </div>
          ):( <div className=""></div>)}

          </div>
          
        </div>
      )}
      {isLoading && (
        <div>
          <Stack
            spacing={4}
            sx={{
              padding: "20px",
            }}
          >
            {/* main slider */}
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={mobileMatches ? 580 : 800}
            />
            {/* detail cards */}
            <Stack
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={mobileMatches ? 170 : 0}
                  height={mobileMatches ? 130 : 0}
                />
              </div>
              <div>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={mobileMatches ? 170 : 0}
                  height={mobileMatches ? 130 : 0}
                />
              </div>
              <div>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={mobileMatches ? 170 : 0}
                  height={mobileMatches ? 130 : 0}
                />
              </div>
              <div>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={mobileMatches ? 170 : 0}
                  height={mobileMatches ? 130 : 0}
                />
              </div>
            </Stack>
            {/* FAQ */}
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={mobileMatches ? 460 : 350}
            />
            {/* banner2 */}
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={mobileMatches ? 240 : 65}
            />
            {/* cat products */}
            <Skeleton animation="wave" width={150} height={40} variant="text" />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={mobileMatches ? 430 : 310}
            />
          </Stack>
        </div>
      )}
      {/* {mobileMatches ? (
        <div>
          <div
            className="featureImageContainer"
            style={{ marginTop: "40px", marginInline: "50px" }}
          >
            <img
              style={{ width: "170px" }}
              src="https://api.pettofit.com/uploads/icons/product_details/1.png"
              alt=""
            />
            <img
              style={{ width: "170px" }}
              src="https://api.pettofit.com/uploads/icons/product_details/2.png"
              alt=""
            />
            {productDetails.is_returnable === "1" ? (
              <img
                style={{ width: "170px" }}
                src="https://api.pettofit.com/uploads/icons/product_details/3.png"
                alt=""
              />
            ) : (
              <img
                style={{ width: "170px" }}
                src="https://api.pettofit.com/uploads/icons/product_details/5.png"
                alt=""
              />
            )}
            <img
              style={{ width: "170px" }}
              src="https://api.pettofit.com/uploads/icons/product_details/6.png"
              alt=""
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="mobileButtonContainer">
            <br></br>
          </div>
          <div
            className="featureImageContainer"
            style={{ marginTop: "40px", marginInline: "50px" }}
          >
            <img
              style={{ width: "100px" }}
              src="https://api.pettofit.com/uploads/icons/product_details/1.png"
              alt=""
            />
            <img
              style={{ width: "100px" }}
              src="https://api.pettofit.com/uploads/icons/product_details/2.png"
              alt=""
            />
            {productDetails.is_returnable === "1" ? (
              <img
                style={{ width: "100px" }}
                src="https://api.pettofit.com/uploads/icons/product_details/3.png"
                alt=""
              />
            ) : (
              <img
                style={{ width: "100px" }}
                src="https://api.pettofit.com/uploads/icons/product_details/5.png"
                alt=""
              />
            )}
            <img
              style={{ width: "100px" }}
              src="https://api.pettofit.com/uploads/icons/product_details/6.png"
              alt=""
            />
          </div>
        </div>
      )} */}
      <div className="productDetail__tabContainer" ref={focusContainerRef}>
        <AppBar position="static">
          <Tabs
            TabIndicatorProps={{ style: { background: "#03D1C7" } }}
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="FEATURES" {...a11yProps(0)} />
            {/* <Tab label="FAQs" {...a11yProps(1)} /> */}
            <Tab label="REVIEW" {...a11yProps(1)} />
            {/* <Tab label="ASK QUESTION" {...a11yProps(3)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div
            className="fetureTab"
            dangerouslySetInnerHTML={{
              __html: productDetails?.product_description,
            }}
          ></div>
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <div className="productDetailTab">
            <FAQS data={productDetails.faqs} />
          </div>
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <div className="reviewTab">
            {/* <h4>Review</h4> */}
            <div className="reviewHeader">
              <div className="reviewMain">
                <h2>
                  {productDetails.reviewsRating?.reduce(
                    (p, c) => p + parseInt(c.rating),
                    0
                  ) / productDetails.reviewsRating?.length
                    ? productDetails.reviewsRating.reduce(
                        (p, c) => p + parseInt(c.rating),
                        0
                      ) / productDetails.reviewsRating.length
                    : 0}
                </h2>{" "}
                <StarIcon className="star" />
              </div>
              <ReviewSummary data={productDetails?.reviewsRating} />
            </div>
            <div>
              <Review data={productDetails?.reviewsRating} />
            </div>
          </div>
        </TabPanel>
      </div>

      {/* <img
        className="staticBanner"
        src={require("../../image/Group871.png").default}
        alt=""
      /> */}
      <SimilarProduct products={productDetails.similar} />
      <Dialog
        open={showSizeChart}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowSizeChart(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Size Chart"}</DialogTitle>
        <DialogContent>
          <img
            src={productDetails.product_chart_image}
            alt=""
            className="size-chart-image"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSizeChart(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Login
        setLoginBoxStatus={setLoginBoxStatus}
        loginBoxStatus={loginBoxStatus}
      />
    </div>
  );
}

export default ProductDetail;
