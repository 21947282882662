const faqs = [
  {
    section: "General Questions",
    content: [
      {
        question: `<p class="faqquestions">Q. What is the Best Size for my Pet?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong> Kindly Refer to Size Chart in Product details Page to Know the best fit. For any Specific query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Do we sell Pets?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>No, we do not sell pets. We only sell pets products and provide Veterinary consultation services.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. What are the modes of Payment we accept?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>We accept Cash on Delivery, Credit Card, Debit Card, Net Banking and UPI.</p>`,
      },
    ],
  },
  {
    section: "Shipping & Order Tracking",
    content: [
      {
        question: `<p class="faqquestions">Q. How can I track my order?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong> Kindly visit Track your order in Order Details Page to track your order. For any Specific query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Is my address serviceable?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>We provide our services in 29k pin codes. You can check it in Deliver to option in Product detail page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How long does an order made take to reach me?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>In metro, It takes usually 1-3 working days to be delivered and In Non-Metro it takes 4-7 working days to be delivered. In Case of Personalised pet portraits product, Kindly check product details page to know the time taken to be delivered.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. What are my shipping Charges?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>For order above ₹899 there is no delivery charges. For Order below ₹899, There is delivery charge of ₹49.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Is Cash on Delivery Chargeable?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Yes, Cash on Delivery is chargeable. There is a extra charge of ₹19 for Cash on delivery.</p>`,
      },
    ],
  },
  {
    section: "Payment",
    content: [
      {
        question: `<p class="faqquestions">Q. What is the Payment Mode for Vet Consultancy?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong> We accept Credit Card, Debit Card, Net Banking and UPI.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. My Payment has been debited but order is not confirmed?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>This is very rare, but in case if it happens Kindly write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> with all the transaction details</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How long does an order made take to reach me?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>In metro, It takes usually 1-3 working days to be delivered and In Non-Metro it takes 4-7 working days to be delivered. In Case of Personalised pet portraits product, Kindly check product details page to know the time taken to be delivered.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. What are my shipping Charges?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>For order above ₹899 there is no delivery charges. For Order below ₹899, There is delivery charge of ₹49.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Is Cash on Delivery Chargeable?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Yes, Cash on Delivery is chargeable. There is a extra charge of ₹19 for Cash on delivery.</p>`,
      },
    ],
  },
  {
    section: "Return, Exchanges & Cancellation",
    content: [
      {
        question: `<p class="faqquestions">Q. Is my product eligible for return/exchange?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong> Kindly Check the Product detail to check if the product is returnable or exchangeable. Not all products are returnable.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How many days after I buy something can I return it?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Kindly Make sure you raise a return/exchange request within 7 days of product delivery.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Delivery Person denies to take the return.</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>This only happens if Product has been used or Price tag has been detached from the product. For any Specific query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How can I raise an exchange request?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>To raise an exchange request, please follow the instructions below:<br />
        <strong>Step 1:</strong> Mention your order ID and send a photograph of the package to <a href="mailto:hello@pettofit.com">hello@pettofit.com</a><br />
        <strong>Step 2:</strong> We will send someone to pick up the product from you at no cost to you*. Please make sure that the product is unused with all tags intact. Do keep the pickup slip safely with you so that we can coordinate with our delivery partners on your product.<br />
        <strong>Step 3:</strong> Once we receive your product at the warehouse and conduct a quality check, we will either offer you an exchange or return our money, in accordance with our policy.
        <br />
        Note that in case the product for exchange is unavailable, we will offer you return your amount and keep you posted on when the item is back in stock.<br />
        *Our Customer Delight representatives are extremely qualified to support you in picking the right size for your pet, so please do reach out to them for a size recommendation before you make your purchase.<br />
        **Note that while the first exchange is free of cost, consequent exchanges may be at a cost borne by the customer.
        </p>`,
      },
      {
        question: `<p class="faqquestions">Q. Can I Cancel My Order?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Cancellations can be made before the product is dispatched. You can make a cancellation on the website or for any Specific query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. What if I receive damaged goods / expired products?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>We keep a very eye on our goods and are careful not to stock or ship products that have passed their expiry date. If damage has occurred in transit, do get in touch with us on <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> within 24 hours of your delivery.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How long does it take to refund?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Depending on your bank, the amount will be credited in your account within 7 working days of placing the request with us.</p>`,
      },
    ],
  },
  {
    section: "Promotion",
    content: [
      {
        question: `<p class="faqquestions">Q. How can I apply a coupon Code?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>You can apply the coupon in cart page. For any Specific query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
    ],
  },
  {
    section: "Veterinary Consultation",
    content: [
      {
        question: `<p class="faqquestions">Q. How can I book Veterinary Consultation?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>To Book consultation, visit Vet Consultation Page on website or click <a href="https://pettofit.com/vet">https://pettofit.com/vet</a> and Click on <strong>Book an Appointment</strong>, select your Time Slot that suits you, Fill the details, Complete the payment and you are done.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How can I attend the scheduled Consultation?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Visit my appointment Page in profile section, click on video call or chat whatever suits you at scheduled time and connect with our experienced vet.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Can I choose the vet I meet with last time I took a consultation?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>If you wish to consult a particular vet, you may write to our customer support prior to your scheduled consultation and make a request.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How can I can reschedule or cancel my appointment?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>You can cancel or reschedule your appointment on My Appointment page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Till what time I can cancel or reschedule my appointment?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>You can cancel or reschedule your appointment till 30mins before the scheduled consultation time.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Will I get refund if I don't turn up for consultation on scheduled time?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>No, we do not refund if you have not attended the appointment scheduled by you. For any Specific request or query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Will I receive prescription from Veterinary Doctor?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>You can view/download your prescription from my appointment page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. My Veterinary Doctor did not turn up?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Our vet team is quite experienced and professional, it rarely happens due to unforeseen conditions. In such case we will either refund your amount or reschedule as per your choice. For any Specific request or query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Are the audio/video calls done during a consultation recorded?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>As per regulatory requirements, all audio and video calls done during an online consultation with the doctor, will be recorded and stored in a secure manner. The video and audio calls are recorded, but will not be shared with you or the doctor. They will only be shared with a competent authority upon explicit request, in case of a medico-legal requirement. They may be reviewed for quality assurance by an in house medical expert at Pettofit.</p>`,
      },
    ],
  },
];

export default faqs;
