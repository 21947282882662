import React, { useState, useEffect } from "react";
import "./MyOrder.css";
import "../../cart/Cart.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  thPadding: {
    padding: "5px",
  },
  confirmIcon: {
    fontSize: "80px !important",
    color: "#03D1C7",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#D2FCFC",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function OrderConfirmed() {
  const classes = useStyles();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const { state } = useLocation();
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  console.log(state);
  return (
    <div className="orderDetail section">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <div className="orderConfirmed">
        <CheckCircleRoundedIcon className={classes.confirmIcon} />
        <h3>Order Confirmed</h3>
        <p>{state.order_id}</p>
      </div>
      <h3
        style={{
          paddingLeft: mobileMatches ? "10px" : "20px",
          fontWeight: "500",
        }}
      >
        Order Detail
      </h3>
      <Paper className="orderDetail__container" elevation={3}>
        <h4 className="title">
          <span>Ordered On: </span>
          {state.payment_date}
        </h4>
        <h4 className="title">
          <span>Order ID: </span>
          {state.order_id}
        </h4>
        <div className="orderDetail__detail">
          <div className="max">
            <h4>{state.bill_address}</h4>
            <p>{state.bill_name}</p>
            <p>{state.bill_city}</p>
            <p>{state.bill_country}</p>
            <p>{state.bill_picode}</p>
          </div>
          <div className="min">
            <h4>Payment Method</h4>
            <p>{state.payment_mode}</p>
            <p>*********3457</p>
          </div>
          <div className="min">
            <h4>Order Summary</h4>
            <table>
              <tbody>
                <tr>
                  <td>Item(s) Subtotal:</td>
                  <th align="left">
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {parseInt(state.total_payable_amount) -
                      (parseInt(state.total_shipping_amount) +
                        parseInt(state.total_tax))}
                  </th>
                </tr>
                <tr>
                  <td>Shipping</td>
                  <th align="left">
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {state.total_shipping_amount}
                  </th>
                </tr>
                <tr>
                  <td>Grand Total</td>
                  <th align="left">
                    <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                    {state.total_payable_amount}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Paper>

      {/* <Paper className="myOrder__container noHover cart" elevation={3}>
        {mobileMatches ? (
          <TableContainer style={{ marginBottom: "20px" }} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Product</StyledTableCell>
                  <StyledTableCell align="center">Quanity</StyledTableCell>
                  <StyledTableCell align="center">Price</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell component="th" scope="row">
                    <div className="productContainer">
                      <img
                        src="https://cdn.shopify.com/s/files/1/0086/0795/7054/products/RealChicken_GlutenFree_688x.progressive.jpg?v=1619133849"
                        alt=""
                      />
                      <div className="detail">
                        <h4>Product Name</h4>
                        <div className="selection">
                          SIZE
                          <div className="sizeBox">S</div>
                          COLOR
                          <div
                            className="colorBox"
                            style={{ backgroundColor: "red" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div className="qtyContainer">
                      <h4>2</h4>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div className="priceContainer">
                      <p className="discountedPrice">
                        <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                        2000
                      </p>
                    </div>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="mobileCratContainer">
            <div className="card">
              <div className="productContainer">
                <img
                  src="https://cdn.shopify.com/s/files/1/0086/0795/7054/products/RealChicken_GlutenFree_688x.progressive.jpg?v=1619133849"
                  alt=""
                />
                <div className="detail">
                  <h4>Product Name</h4>
                  <div className="selection">
                    SIZE
                    <div className="sizeBox">S</div>
                    COLOR
                    <div
                      className="colorBox"
                      style={{ backgroundColor: "red" }}
                    ></div>
                  </div>
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        className={classes.thPadding}
                        align="center"
                      >
                        Price
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.thPadding}
                        align="center"
                      >
                        Quanity
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.thPadding}
                        align="center"
                      >
                        Total
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        className={classes.thPadding}
                        align="center"
                      >
                        <div className="priceContainer">
                          <p className="discountedPrice">
                            <FontAwesomeIcon
                              icon={faRupeeSign}
                              className="icon"
                            />{" "}
                            2000
                          </p>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.thPadding}
                        align="center"
                      >
                        1
                      </StyledTableCell>
                      <StyledTableCell
                        className={classes.thPadding}
                        align="center"
                      >
                        <div className="priceContainer">
                          <p className="discountedPrice">
                            <FontAwesomeIcon
                              icon={faRupeeSign}
                              className="icon"
                            />{" "}
                            2000
                          </p>
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
      </Paper> */}
    </div>
  );
}

export default OrderConfirmed;
