import React, { useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@mui/icons-material/Description";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "./MyOrder.css";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import ProductDetail from "./ProductDetail";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useParams } from "react-router-dom";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CircularProgress from "@mui/material/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { fetchOrderDetail, raiseTicketApi } from "../../../api/index";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Helmet } from "react-helmet";

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
      // transform: 'translate(14px, 12px) scale(1)',
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const BootstrapButton3 = withStyles({
  root: {
    border: "1px solid",
    color: "#000",
    width: "160px",
    marginBottom: "10px",
    //   backgroundColor: '#09D1C7',
    borderColor: "#000",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      borderColor: "#000",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: "#000",
    },
  },
})(Button);

function OrderDetail() {
  const [openTicket, setOpenTicket] = React.useState(false);
  const handleClickOpenTicket = () => {
    setOpenTicket(true);
  };
  const handleCloseTicket = () => {
    setOpenTicket(false);
  };
  const [productDetails, setProductDetails] = useState({});
  const params = useParams();
  const [detail, setDetail] = useState([]);
  const [imageLink, setImageLink] = useState("");
  const { user } = useSelector((state) => state.menu);
  const [loading, setLoading] = useState(true);
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  const raiseTicketForm = new FormData();
  raiseTicketForm.set("userId", user.id);

  useEffect(() => {
    (async () => {
      if (user.id) console.log(user);
      const res = await fetchOrderDetail(params.id, user.id);
      setDetail(res.data);
      raiseTicketForm.set("order_id", res.data.id);
      setImageLink(res.image_link);
      setLoading(false);
    })();
  }, [user.id]);
  const mobileMatches = useMediaQuery("(min-width:600px)");

  const raiseTicket = async () => {
    raiseTicketForm.set("order_id", detail.id);
    const validInput =
      raiseTicketForm.get("subject") && raiseTicketForm.get("meta_description");
    if (!validInput) {
      return toast.error("Please fill all the fields");
    }
    const res = await raiseTicketApi(raiseTicketForm);
    if (res) toast.success("Ticket raised successfully");
    handleCloseTicket();
  };

  return (
    <div className="orderDetail section">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "35vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div>
          <Breadcrumbs
            sx={{ ml: mobileMatches ? 0 : 1 }}
            aria-label="breadcrumb"
            className="breadcum"
          >
            <Link color="inherit" to="/">
              Home
            </Link>
            <Link color="inherit" to="/orders">
              My Order
            </Link>
            <Link color="inherit" to="/orders">
              {detail.order_id}
            </Link>
          </Breadcrumbs>

          <Paper
            sx={{ m: mobileMatches ? 0 : 1 }}
            className="orderDetail__container"
            elevation={3}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h4 className="title">
                  <span>Ordered On: </span> {detail.payment_date}
                </h4>
                <h4 className="title">
                  <span>Order ID: </span>
                  {detail.order_id}
                </h4>
              </div>
              {detail?.order_status === 20 && (
                <div>
                  <Button onClick={handleClickOpenTicket}>
                    <ConfirmationNumberIcon />
                    Raise Ticket
                  </Button>
                  <Dialog
                    open={openTicket}
                    onClose={handleCloseTicket}
                    fullWidth={true}
                    maxWidth="sm"
                  >
                    <DialogTitle>Raise Ticket form</DialogTitle>
                    <DialogContent>
                      <h3 className="reviewTitle">
                        Raise a Ticket for this product
                      </h3>
                      <CssTextField
                        style={{ width: "100%", marginBottom: "10px" }}
                        label="Title"
                        size="small"
                        onChange={(event) =>
                          raiseTicketForm.set("subject", event.target.value)
                        }
                      />
                      <CssTextField
                        style={{ width: "100%" }}
                        label="Description"
                        multiline
                        rows={4}
                        size="small"
                        onChange={(event) =>
                          raiseTicketForm.set(
                            "meta_description",
                            event.target.value
                          )
                        }
                      />
                      <br />
                      <br />
                      <label htmlFor="contained-button-file">
                        <input
                          accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf"
                          id="outlined-size-small"
                          type="file"
                          onChange={(e) => {
                            console.log(e.target.files[0]);
                            raiseTicketForm.set(
                              "attachment",
                              e.target.files[0]
                            );
                          }}
                        />
                      </label>
                    </DialogContent>
                    <DialogActions
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <Button onClick={handleCloseTicket}>Cancel</Button>
                      <Button
                        onClick={raiseTicket}
                        variant="contained"
                        className="modal_btn"
                        style={{ backgroundColor: "#09D1C7", color: "white" }}
                      >
                        Raise Ticket
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            </div>
            <div className="orderDetail__detail">
              <div className="max">
                <h4>{detail.ship_address}</h4>
                <p>{detail.bill_name}</p>
                <p>{detail.ship_city}</p>
                <p>{detail.ship_country}</p>
                <p>{detail.ship_pincode}</p>
              </div>
              <div className="min">
                <h4>Payment Method</h4>
                <p>{detail.payement_type}</p>
                {detail.payment_mode}
              </div>
              <div className="min">
                <h4>Order Summary</h4>
                <table>
                  <tbody>
                    <tr>
                      <td>Item(s) Subtotal:</td>
                      <th align="left">
                        <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                        {parseInt(detail.total_payable_amount) -
                          (parseInt(detail.total_shipping_amount) +
                            parseInt(detail.total_tax))}
                      </th>
                    </tr>
                    <tr>
                      <td>Shipping</td>
                      <th align="left">
                        <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                        {detail.total_shipping_amount}
                      </th>
                    </tr>
                    <tr>
                      <td>tax</td>
                      <th align="left">
                        <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                        {detail.total_tax}
                      </th>
                    </tr>
                    <tr>
                      <td>Grand Total</td>
                      <th align="left">
                        <FontAwesomeIcon icon={faRupeeSign} className="icon" />{" "}
                        {detail.total_payable_amount}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Paper>

          <Paper
            sx={{ m: mobileMatches ? 0 : 1 }}
            className="myOrder__container noHover"
            elevation={3}
          >
            <ProductDetail
              order={detail}
              imageLink={imageLink}
              shippedStatus={detail?.order_status === 20}
              currentOrderStatus={detail?.order_status}
            />
          </Paper>
        </div>
      )}
    </div>
  );
}

export default OrderDetail;
