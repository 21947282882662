import React, { useEffect, useState } from "react";
import "../myOrder/MyOrder.css";
import "./Profile.css";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@mui/material/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@mui/material/Dialog";
import EditIcon from "@mui/icons-material/Edit";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@material-ui/core/Button";
import { menuActions } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { updatePasswordApi, UpdateProfile } from "../../../api";
import { fetchUser } from "../../../store";
import { style } from "@mui/system";
import { baseService } from "../../../services/http/baseService";
import { headers } from "../../../api";
import axios from "axios";
import { Helmet } from "react-helmet";

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        // padding: '10px',
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const CssFormControl = withStyles({
  root: {
    width: "100%",
    "& label": {
      color: "#000",
      transform: "translate(14px, 12px) scale(1)",
    },
    "& label.Mui-focused": {
      color: "#3F51B5",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3F51B5",
      },
    },
  },
})(FormControl);

const BootstrapButton1 = withStyles({
  root: {
    color: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:active": {
      boxShadow: "none",
    },
  },
})(Button);

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

function Profile() {
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [personalEditStatus, setPersonalEditStatus] = useState(false);
  const [emailEditStatus, setEmailEditStatus] = useState(false);
  const [phoneEditStatus, setPhoneEditStatus] = useState(false);
  const [dobEditStatus, setDobEditStatus] = useState(false);
  const [genderEditStatus, setGenderEditStatus] = useState(false);
  const [petNameEditStatus, setPetNameEditStatus] = useState(false);
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);
  const dispatch = useDispatch();
  const [changePasswordFormStatus, setChangePasswordFormStatus] =
    useState(false);
  const { user } = useSelector((state) => state.menu);

  const [editForm, setEditForm] = useState(false);
  //states for the updates
  const [name, updateName] = useState(user.name);

  const [phone, updatePhone] = useState(user.mobile);
  const [dob, updateDob] = useState(user.dob);
  const [gender, updateGender] = useState(user.gender);
  const [password, updatePassword] = useState("");
  const [confirmPassword, confirmUpdatePassword] = useState("");
  const [currentPassword, updatecurrentPassword] = useState(""); //storing the current password
  const [oldPassword, setOldPassword] = useState("");
  const [pet, updatePet] = useState("");
  const [value, setValue] = React.useState("");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const changePassword = async () => {
    const data = {
      userid: user.id,
      oldpassword: oldPassword,
      password: password,
      password_confirmation: confirmPassword,
    };
    const res = await baseService
      .post("/update-password", data, {
        headers,
      })
      .then((res) => res)
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    if (res) {
      setChangePasswordFormStatus(false);
      toast.success("Password changed successfully");
    }
  };

  const changeProfile = async () => {
    const data = {
      userId: user.id,
      name: name ? name : user.name,
      mobile: phone ? phone : user.mobile,
      gender: gender ? gender : user.gender,
      dob: value ? value : user.dob,
      pet_name: pet ? pet : user.pet_name,
    };
    console.clear();
    console.log(data);
    const res = await UpdateProfile(data);
    console.log(res);

    if (res.error) {
      toast.error(res.message);
    } else {
      const profile = await baseService
        .post(
          "/get-customer-profile",
          {
            userId: user.id,
          },
          {
            headers: headers,
          }
        )
        .then((res) => res.data)
        .catch((err) => {
          toast.error(err.response.data.message);
        });
      if (profile) {
        dispatch(
          menuActions.setUser({
            user: {
              ...profile.data,
              token: user.token,
            },
            tokenId: profile.data.id,
          })
        );
      }
      toast.success(res.message);
    }
  };

  useEffect(() => {
    if (user.id) fetchUserProfile();
  }, [user.id]);

  const fetchUserProfile = async () => {
    const profile = await baseService
      .post(
        "/get-customer-profile",
        {
          userId: user.id,
        },
        {
          headers: headers,
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    if (profile) {
      dispatch(
        menuActions.setUser({
          user: {
            ...profile.data,
            token: user.token,
          },
          tokenId: profile.data.id,
        })
      );
    }
  };

  return (
    <div className="myOrder section">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <Breadcrumbs
        sx={{ ml: mobileMatches ? 0 : 1 }}
        aria-label="breadcrumb"
        className="breadcum"
      >
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/profile">
          My Profile
        </Link>
      </Breadcrumbs>
      <Grid sx={{ p: mobileMatches ? 0 : 1 }} container spacing={2}>
        <Grid item md={3} xs={12}>
          <Paper className="myOrder__menu" elevation={3}>
            <ul>
              <li>
                <Link to="/profile" className="active">
                  Profile
                </Link>
              </li>
              <li>
                <Link to="/orders">My Order</Link>
              </li>
              <li>
                <Link to="/appointment">My Appointment</Link>
              </li>
              <li>
                <Link to="/reviews">My Reviews & Rating</Link>
              </li>
              <li>
                <Link to="/tickets">Tickets</Link>
              </li>
            </ul>
          </Paper>
        </Grid>
        <Grid item md={9} xs={12}>
          <Paper className="myOrder__container noHover" elevation={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <input
                type="file"
                id="file"
                style={{
                  display: "none",
                }}
                accept="image/png, image/jpeg"
                onChange={async (e) => {
                  const data = new FormData();
                  data.append("userId", user.id);
                  Object.keys(user).forEach((key) => {
                    data.append(key, user[key]);
                  });
                  data.append("photo", e.target.files[0]);
                  baseService
                    .post("/update-profile", data, {
                      headers: headers,
                    })
                    .then(async () => {
                      const profile = await baseService
                        .post(
                          "/get-customer-profile",
                          {
                            userId: user.id,
                          },
                          {
                            headers: headers,
                          }
                        )
                        .then((res) => res.data)
                        .catch((err) => {
                          toast.error(err.response.data.message);
                        });
                      if (profile) {
                        dispatch(
                          menuActions.setUser({
                            user: {
                              ...profile.data,
                              token: user.token,
                            },
                            tokenId: profile.data.id,
                          })
                        );
                      }
                    })
                    .catch((err) => {
                      toast.error(err.response.data.message);
                    });
                }}
              />
              <img
                style={{
                  borderRadius: "50%",
                  cursor: "pointer",
                  maxWidth: "80px",
                  maxHeight: "80px",
                  width: "80px",
                  height: "80px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("file").click();
                }}
                src={user.photo ? user.photo : "https://via.placeholder.com/80"}
              ></img>
              <h3
                style={{
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("file").click();
                }}
              >
                Change Profile Photo
              </h3>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                changeProfile();
              }}
            >
              <Grid container spacing={1}>
                <Grid item md={6} sm={6} xs={6} className="profilebtn1">
                  <BootstrapButton2
                    size="small"
                    onClick={() => setEditForm(true)}
                  >
                    Edit Profile
                    <EditIcon></EditIcon>
                  </BootstrapButton2>
                  {editForm && (
                    <BootstrapButton2
                      style={{ marginLeft: "10px" }}
                      type="submit"
                      onClick={(event) => {
                        event.preventDefault();
                        changeProfile();
                        setEditForm(false);
                      }}
                    >
                      Save
                    </BootstrapButton2>
                  )}
                </Grid>
                <Grid item md={6} sm={6} xs={6} className="profilebtn2">
                  <BootstrapButton1
                    size="small"
                    style={{ backgroundColor: "#09D1C7", color: "white" }}
                    onClick={() => {
                      setChangePasswordFormStatus(true);
                    }}
                  >
                    Change Password
                  </BootstrapButton1>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <div className="profileContainer">
                    <div style={{ width: "80%" }}>
                      <div className="titleContainer">
                        <h4>Personal Information</h4>
                        {!personalEditStatus && setPersonalEditStatus(true)}
                      </div>
                      <CssTextField
                        label="Customer"
                        placeholder="Customer Name"
                        defaultValue={user.name}
                        size="small"
                        disabled={!editForm}
                        onChange={(event) => updateName(event.target.value)}
                      ></CssTextField>
                      <div className="titleContainer">
                        <h4>Gender</h4>
                        {!genderEditStatus && setGenderEditStatus(true)}
                      </div>
                      <CssFormControl variant="outlined">
                        <InputLabel id="gender">Gender</InputLabel>
                        <Select
                          labelId="gender"
                          label="Gender"
                          defaultValue={user.gender}
                          size="small"
                          disabled={!editForm}
                          onChange={(event) => updateGender(event.target.value)}
                        >
                          {["Male", "Female"].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </CssFormControl>
                      <div className="titleContainer">
                        <h4>Date of Birth</h4>
                        {!dobEditStatus && setDobEditStatus(true)}
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Date Of Birth"
                          inputFormat="MM/dd/yyyy"
                          value={value ? value : new Date(user.dob)}
                          onChange={handleChange}
                          disabled={!editForm}
                          maxDate={new Date()}
                          renderInput={(params) => (
                            <CssTextField {...params} disabled={!editForm} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <div className="profileContainer">
                    <div style={{ width: "80%" }}>
                      <div className="titleContainer">
                        <h4>Email Address</h4>
                        {!emailEditStatus && setEmailEditStatus(true)}
                      </div>
                      <CssTextField
                        label="Email"
                        type="email"
                        defaultValue={user.email}
                        size="small"
                        disabled={true}
                        // onChange={(event) => updateEmail(event.target.value)}
                      />
                      <div className="titleContainer">
                        <h4>Mobile Number</h4>
                        {!phoneEditStatus && setPhoneEditStatus(true)}
                      </div>
                      <CssTextField
                        label="Mobile No."
                        defaultValue={user.mobile}
                        size="small"
                        disabled={!editForm}
                        onChange={(event) => updatePhone(event.target.value)}
                      />
                      <div className="titleContainer">
                        <h4>Pet Name</h4>
                      </div>
                      <CssTextField
                        label="Name"
                        defaultValue={user.pet_name}
                        size="small"
                        disabled={!editForm}
                        onChange={(event) => {
                          updatePet(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>

            <Dialog
              open={changePasswordFormStatus}
              onClose={() => setChangePasswordFormStatus(false)}
            >
              <IconButton
                className="changePasswordFormClose"
                onClick={() => setChangePasswordFormStatus(false)}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle>Change Password</DialogTitle>

              <DialogContent>
                <form
                  className="changePasswordForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    changePassword();
                  }}
                >
                  <CssTextField
                    style={{ marginBottom: "10px" }}
                    type="password"
                    label="Old Password"
                    size="small"
                    onChange={(event) => setOldPassword(event.target.value)}
                  />
                  <CssTextField
                    style={{ marginBottom: "10px" }}
                    type="password"
                    label="New Password"
                    size="small"
                    onChange={(event) => updatePassword(event.target.value)}
                  />
                  <CssTextField
                    style={{ marginBottom: "10px" }}
                    type="password"
                    label="Confirm Password"
                    size="small"
                    onChange={(event) =>
                      confirmUpdatePassword(event.target.value)
                    }
                  />
                  <BootstrapButton2 onClick={changePassword}>
                    Submit
                  </BootstrapButton2>
                </form>
              </DialogContent>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
