export const initialState = {
    basket: [],
    user: null,
    page: null,
};

export const getBasketTotal = (basket) => basket?.reducer((amount, item) => item.price + amount, 0);
// setSubjectTeacherGroup([
//     ...subjectTeacherGroup.slice(0,subjectTeacherGroupIndex),
//     Object.assign({}, subjectTeacherGroup[subjectTeacherGroupIndex], {
//       subjectTeacherNewData: subjectTeacherNewData,
//       subjectTeacherNewUid: tempSelectTeacherFromList,
//     }),
//     ...subjectTeacherGroup.slice(subjectTeacherGroupIndex+1),
//   ]);

function reducer(state, action) {
    switch (action.type) {
        case 'ACTIVE_PAGE':
            return{
                ...state, page: action.page
            }
        case 'SET_USER':
            return {
                ...state, user: action.user
            }
        case 'ADD_TO_BASKET':
            //Logic for adding item to basket
            return {
                ...state, basket: [...state.basket, action.item],
            }
        case 'ADD_QTY':
            //Logic for add quantity for existing item in basket
            return {
                ...state, basket:[
                    ...state.basket.slice(0, action.item.index),
                    Object.assign({}, state.basket[action.item.index], {
                        qty: state.basket[action.item.index].qty+1,
                    }),
                    ...state.basket.slice(action.item.index+1),
                ],
            }
        case 'DEC_QTY':
            //Logic for decrease quantity for existing item in basket
                if(state.basket[action.item.index].qty === 1){
                    //Logic for removing item from basket
                    return {...state, basket: state.basket.splice(action.item.index,1)};
                }else{
                    return {
                    ...state, basket:[
                            ...state.basket.slice(0, action.item.index),
                            Object.assign({}, state.basket[action.item.index], {
                                qty: state.basket[action.item.index].qty-1,
                            }),
                            ...state.basket.slice(action.item.index+1),
                        ],
                    }
                }
        case 'REMOVE_FROM_BASKET': 
            //Logic for removing item from basket

            //we clone the basket
            let newBasket = [...state.basket];

            //we check to see the product exists,
            const index = state.basket.findIndex((basketItem) => basketItem.id === action.id);
            if (index >= 0) {
                //item exists in basket, remove it
                newBasket.splice(index,1);
            }else{
                console.warn(`Cant remove product (id: ${action.id}) as its not found`);
            }

            return {...state, basket: newBasket};

        case 'EMPTY_BASKET': 
            return {
                ...state, basket: []
            }
        default: 
            return state;
        
    }
}

export default reducer;