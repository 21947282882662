import React, { useState, useEffect } from "react";
import "./Reset.css";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Helmet } from "react-helmet";

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    marginTop: "10px",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

function Reset() {
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/fetch-homepage")
      .then((response) => {
        setMetaInfo(response.data.meta_info);
      });
  }, []);

  return (
    <div className="reset">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <Paper className="paper" elevation={3}>
        <form>
          <h3 className="title">Reset Password</h3>
          <TextField
            label="New Password"
            type="password"
            id="outlined-size-small"
            size="small"
            margin="dense"
          />
          <TextField
            label="Retype Password"
            type="password"
            id="outlined-size-small"
            size="small"
            margin="dense"
          />
          <BootstrapButton2
            type="submit"
            onClick={(event) => event.preventDefault()}
          >
            Reset Password
          </BootstrapButton2>
        </form>
      </Paper>
    </div>
  );
}

export default Reset;
