import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from "react-router-dom";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import ProductListing from "./component/productListing/ProductListing";
import ProductDetail from "./component/productDetail/ProductDetail";
import Vet from "./component/vet/Vet";
import Cart from "./component/cart/Cart";
import OrderConfirmed from "./component/myAccount/myOrder/OrderConfirmed";
import Checkout from "./component/cart/Checkout";
import Reset from "./component/reset/Reset";
import Blog from "./component/blog/Blog";
import BlogSingle from "./component/blog/BlogSingle";
import Careers from "./component/careers/Careers";
import CareerSingle from "./component/careers/CareerSingle";
import MyReviews from "./component/myAccount/myReviews/MyReviews";
import TrackPage from "./component/track/TrackPage";
import MyOrder from "./component/myAccount/myOrder/MyOrder";
import OrderDetail from "./component/myAccount/myOrder/OrderDetail";
import MyAppointment from "./component/myAccount/myAppointment/MyAppointment";
import Profile from "./component/myAccount/profile/Profile";
import About from "./component/about/About";
import Brands from "./component/allBrands/Brands";
import ContactUs from "./component/contactUs/ContactUs";
import PrivacyPolicy from "./component/cms/PrivacyPolicy";
import TermsCondition from "./component/cms/TermsCondtion";
import ReturnPolicy from "./component/cms/ReturnPolicy";
import Invoice from "./component/Invoice";

import Home from "./component/home/Home";
import { useSelector } from "react-redux";
import DoctorDashboard from "./component/myAccount/doctorDashboard";
import FAQ from "./component/cms/FAQ";
import TicketChat from "./component/ticketchat";
import ShopByBrand from "./component/shopByBrand/ShopByBrand";
import Tickets from "./component/myAccount/ticket";

const DetailsRedirect = () => {
  const { id } = useParams();
  return <Redirect to={`/detail/${id}`} />;
};

function App() {
  const { isLoggedIn } = useSelector((state) => state.menu);
  const userInLocalStorage = localStorage.getItem("user");

  return (
    <Router>
      <Header />
      <div
        style={{
          minHeight: "calc(100vh - 403px)",
        }}
      >
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/vet-dashboard">
            <DoctorDashboard />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route path="/products">
            <ProductListing />
          </Route>
          <Route exact path="/detail/detail/:id">
            <DetailsRedirect />
          </Route>
          <Route exact path="/detail/:id">
            <ProductDetail />
          </Route>
          <Route exact path="/vet">
            <Vet />
          </Route>
          <Route exact path="/cart">
            <Cart />
          </Route>
          <Route path="/reset">
            <Reset />
          </Route>
          <Route path="/track">
            {isLoggedIn || userInLocalStorage ? (
              <TrackPage />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/tickets">
            <Tickets />
          </Route>
          <Route path="/shop-by-brand/:id">
            <ShopByBrand />
          </Route>
          <Route path="/orders">
            {isLoggedIn || userInLocalStorage ? (
              <MyOrder />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/order-confirmed">
            <OrderConfirmed />
          </Route>
          <Route path="/reviews">
            {isLoggedIn || userInLocalStorage ? (
              <MyReviews />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/order-detail/:id">
            {isLoggedIn || userInLocalStorage ? (
              <OrderDetail />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/appointment">
            {isLoggedIn || userInLocalStorage ? (
              <MyAppointment />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/profile">
            {isLoggedIn || userInLocalStorage ? (
              <Profile />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/checkout">
            {isLoggedIn || userInLocalStorage ? (
              <Checkout />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/blog-single/:id">
            <BlogSingle />
          </Route>
          <Route path="/career">
            <Careers />
          </Route>
          <Route path="/career-single">
            <CareerSingle />
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Route path="/brands">
            <Brands />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms&condition">
            <TermsCondition />
          </Route>
          <Route path="/return-policy">
            <ReturnPolicy />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/invoice/:id">
            <Invoice />
          </Route>
          <Route path="/ticket-chat/:id">
            <TicketChat />
          </Route>
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
