import React, { useRef, useState } from "react";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@mui/material/Skeleton";

const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    zIndex: "10",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: 'lightgray',
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

function SliderBanner({ multiBanners }) {
  const slider = useRef();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [loaded, setLoaded] = useState(false);
  const [bannerloaded, setBannerLoaded] = useState(true);
  var settings = {
    className: "center",
    centerMode: true,
    centerPadding: "200px",
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerPadding: "60px",
        },
      },
    ],
  };
  const bannerList =
    multiBanners?.data?.map((banner, index) => ({
      image: `${multiBanners.desktop_image_link}/${banner.banner_image}`,
      id: index,
      link: "",
    })) || [];

  const handlePrevSlide = () => {
    slider.current.slickPrev();
  };

  const handleNextSlide = () => {
    slider.current.slickNext();
  };
  return (
    <section>
      {!loaded && (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={600}
        />
      )}
      <div className="sliderrr">
        <Slider
          style={{ width: "100%" }}
          ref={(c) => (slider.current = c)}
          {...settings}
        >
          {bannerList.map((banner) => (
            <img
              onLoad={() => setLoaded(true)}
              key={`banner${banner.id}`}
              className="sliderBanner__img"
              src={banner.image}
              alt=""
            />
          ))}
        </Slider>
        {mobileMatches && (
          <>
            <CustomIconButton
              className="arrowLeft"
              variant="contained"
              color="primary"
              onClick={handlePrevSlide}
            >
              <ArrowBackIosNewIcon />
            </CustomIconButton>
            <CustomIconButton
              className="arrowRight"
              variant="contained"
              color="primary"
              onClick={handleNextSlide}
            >
              <ArrowForwardIosIcon />
            </CustomIconButton>
          </>
        )}
      </div>
    </section>
  );
}

export default SliderBanner;
