const vet_faqs = [
  {
    section: "Veterinary Consultation",
    content: [
      {
        question: `<p class="faqquestions">Q. How can I book Veterinary Consultation?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>To Book consultation, visit Vet Consultation Page on website or click <a href="https://pettofit.com/vet">https://pettofit.com/vet</a> and Click on <strong>Book an Appointment</strong>, select your Time Slot that suits you, Fill the details, Complete the payment and you are done.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How can I attend the scheduled Consultation?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Visit my appointment Page in profile section, click on video call or chat whatever suits you at scheduled time and connect with our experienced vet.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Can I choose the vet I meet with last time I took a consultation?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>If you wish to consult a particular vet, you may write to our customer support prior to your scheduled consultation and make a request.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. How can I can reschedule or cancel my appointment?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>You can cancel or reschedule your appointment on My Appointment page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Till what time I can cancel or reschedule my appointment?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>You can cancel or reschedule your appointment till 30mins before the scheduled consultation time.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Will I get refund if I don't turn up for consultation on scheduled time?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>No, we do not refund if you have not attended the appointment scheduled by you. For any Specific request or query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Will I receive prescription from Veterinary Doctor?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>You can view/download your prescription from my appointment page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. My Veterinary Doctor did not turn up?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>Our vet team is quite experienced and professional, it rarely happens due to unforeseen conditions. In such case we will either refund your amount or reschedule as per your choice. For any Specific request or query write us at <a href="mailto:hello@pettofit.com">hello@pettofit.com</a> or visit contact us page.</p>`,
      },
      {
        question: `<p class="faqquestions">Q. Are the audio/video calls done during a consultation recorded?</p>`,
        answer: `<p class="faqanswers"><strong>A.</strong>As per regulatory requirements, all audio and video calls done during an online consultation with the doctor, will be recorded and stored in a secure manner. The video and audio calls are recorded, but will not be shared with you or the doctor. They will only be shared with a competent authority upon explicit request, in case of a medico-legal requirement. They may be reviewed for quality assurance by an in house medical expert at Pettofit.</p>`,
      },
    ],
  },
];

export default vet_faqs;
