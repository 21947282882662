import { styled } from "@mui/material/styles";
import { CameraAlt, Mic, ExitToApp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { connect, createLocalTracks } from "twilio-video";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";

import "./styles.css";
import {
  fetchRoomInfo,
  fetchRoomToken,
} from "../../../services/http/twilioVideoService";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { baseService } from "../../../services/http/baseService";

const VideoControlButton = styled(IconButton)(() => ({
  color: "#fff",
  padding: "12px",
  borderRadius: "50%",
  margin: "10px",
  fontSize: "30px",
  "&:hover": {
    background: "red",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VideoChatWidget({
  closeBackdrop,
  appointmentNumber,
  isCustomer,
  counter,
  reduceCounter,
}) {
  const [useCamera, setUseCamera] = React.useState(true);
  const [useMicrophone, setUseMicrophone] = React.useState(true);
  const [room, setRoom] = React.useState(null);
  const [localAudioStream, setLocalAudioStream] = React.useState(null);
  const [localVideoStream, setLocalVideoStream] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [haveFetchedRoomInfo, setHaveFetchedRoomInfo] = React.useState(false);
  const [roomName, setRoomName] = React.useState("");
  const [token, setToken] = React.useState("");
  const user = useSelector((state) => state.menu.user);
  const [usersInCall, setUsersInCall] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeBackdrop();
  };

  useEffect(() => {
    counter > 0 && reduceCounter();
  }, [counter]);

  useEffect(() => {
    const fetchInfo = async () => {
      const roomInfo = await fetchRoomInfo({
        appointment_number: appointmentNumber,
        userId: user.id,
        type: isCustomer ? "4" : user.role_type,
      });
      if (!roomInfo || roomInfo.error) {
        toast.error("Something went wrong");
        closeBackdrop();
      } else {
        setRoomName(roomInfo.data.room.uniqueName);
        const roomAuthInfo = await fetchRoomToken({
          roomName: roomInfo.data.room.uniqueName,
        });
        if (!roomAuthInfo || roomInfo.error) {
          toast.error("Something went wrong");
          closeBackdrop();
        } else {
          setToken(roomAuthInfo.data.accessToken);
          setHaveFetchedRoomInfo(true);
        }
      }
    };
    fetchInfo();
  }, []);

  useEffect(() => {
    if (roomName !== "" && token !== "") {
      const connectToTwilio = async () => {
        const tracks = await createLocalTracks({
          video: true,
          audio: true,
        })
          .then((t) => {
            return t;
          })
          .catch((err) => {
            handleClickOpen();
          });
        if (!tracks) return;
        const room = await connect(token, {
          name: roomName,
          tracks: tracks,
          audio: true,
          video: { width: 50 },
        }).catch((err) => {
          handleClickOpen();
          tracks.forEach((track) => track.stop());
          closeBackdrop();
        });
        setRoom(room);
        if (room) {
          setUsersInCall((u) => u + 1);
          if (room.participants.length === 0) {
            await baseService.post("/user-notification", {
              userId: user.id,
              appointment_number: appointmentNumber,
              type: user.role_type !== "10" ? 1 : 2,
            });
          }
          room.participants.forEach((participant) => {
            participant.tracks.forEach((publication) => {
              setUsersInCall((u) => u + 1);
              if (publication.kind === "video")
                if (publication.isSubscribed) {
                  document.querySelector(".remote-video").innerHTML = "";
                  document
                    .querySelector(".remote-video")
                    .appendChild(publication.track.attach());
                } else {
                  if (publication.isSubscribed) {
                    document
                      .querySelector(".remote-audio")
                      .appendChild(publication.track.attach());
                  }
                }
            });

            participant.on("trackSubscribed", (track) => {
              if (track.kind === "video") {
                document.querySelector(".remote-video").innerHTML = "";
                document
                  .querySelector(".remote-video")
                  .appendChild(track.attach());
              } else {
                document
                  .querySelector(".remote-audio")
                  .appendChild(track.attach());
              }
            });
          });
          room.on("participantConnected", (participant) => {
            toast.success(
              `The ${isCustomer ? "doctor" : "customer"} just connected`
            );
            setUsersInCall((u) => u + 1);
            participant.tracks.forEach((publication) => {
              if (publication.kind === "video")
                if (publication.isSubscribed) {
                  document.querySelector(".remote-video").innerHTML = "";
                  document
                    .querySelector(".remote-video")
                    .appendChild(publication.track.attach());
                } else {
                  if (publication.isSubscribed) {
                    document
                      .querySelector(".remote-audio")
                      .appendChild(publication.track.attach());
                  }
                }
            });
            participant.on("trackSubscribed", (track) => {
              if (track.kind === "video") {
                document.querySelector(".remote-video").innerHTML = "";
                document
                  .querySelector(".remote-video")
                  .appendChild(track.attach());
              } else {
                document
                  .querySelector(".remote-audio")
                  .appendChild(track.attach());
              }
            });
          });
          room.on("participantDisconnected", (participant) => {
            toast.success(
              `The ${isCustomer ? "doctor" : "customer"} just disconnected`
            );
            setUsersInCall((u) => u - 1);
          });
        }
        const videoTrack = tracks[1];
        setLocalVideoStream(videoTrack);
        room.localParticipant.publishTrack(videoTrack);
        document.querySelector(".local-video").appendChild(videoTrack.attach());
        const audioTrack = tracks[0];
        setLocalAudioStream(audioTrack);
        room.localParticipant.publishTrack(audioTrack);
      };
      connectToTwilio();
    }
  }, [roomName, token]);

  const toggleCamera = async () => {
    if (useCamera) {
      localVideoStream.stop();
      room.localParticipant.unpublishTrack(localVideoStream);
      document.querySelector(".local-video").innerHTML = "";
      setLocalVideoStream(null);
    } else {
      const track = await createLocalTracks({
        video: true,
      });
      const videoTrack = track[0];
      setLocalVideoStream(videoTrack);
      room.localParticipant.publishTrack(videoTrack);
      document.querySelector(".local-video").appendChild(videoTrack.attach());
    }
    setUseCamera(!useCamera);
  };

  const toggleMicrophone = async () => {
    if (useMicrophone) {
      localAudioStream.stop();
      room.localParticipant.unpublishTrack(localAudioStream);
      setLocalAudioStream(null);
    } else {
      const track = await createLocalTracks({
        audio: true,
      });
      const audioTrack = track[0];
      setLocalAudioStream(audioTrack);
      room.localParticipant.publishTrack(audioTrack);
    }
    setUseMicrophone(!useMicrophone);
  };

  return (
    <>
      {!haveFetchedRoomInfo && <CircularProgress />}
      {haveFetchedRoomInfo && (
        <div
          className="container-video"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "3.5%",
              left: "3%",
              zIndex: "1",
              color: "white",
              border: "1px solid white",
              padding: "5px",
              paddingLeft: "15px",
              paddingRight: "15px",
              fontSize: "26px",
            }}
          >
            {(counter / 60).toFixed(0)} :{" "}
            {(counter % 60).toString().padStart(2, "0")}
          </div>
          <div className="videos">
            {usersInCall === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <h2>No one else is in the call</h2>
              </div>
            )}
            {!(usersInCall === 0) && <div className="remote-video"></div>}
            <div style={{ display: "none" }} className="remote-audio"></div>
            <div className="local-video"></div>
          </div>
          <div className="controls">
            <VideoControlButton
              sx={{
                background: useCamera ? "red" : "blue",
              }}
              onClick={toggleCamera}
            >
              <CameraAlt />
            </VideoControlButton>
            <VideoControlButton
              onClick={toggleMicrophone}
              sx={{
                background: useMicrophone ? "red" : "blue",
              }}
            >
              <Mic />
            </VideoControlButton>
            <VideoControlButton
              sx={{
                background: "blue",
              }}
              onClick={() => {
                if (useMicrophone) toggleMicrophone();
                if (useCamera) toggleCamera();
                if (room) room.disconnect();
                closeBackdrop();
              }}
            >
              <ExitToApp />
            </VideoControlButton>
          </div>
        </div>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Something went wrong"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Unable to join the meeting, try again later. If the issue persists,
            please contact us.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default VideoChatWidget;
