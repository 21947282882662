import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import ProductSlider from "../product/ProductSlider";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    zIndex: "10",
    padding: "6px",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: 'lightgray',
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

function CatProducts({ isLoading, products }) {
  const slider = useRef();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [index, setIndex] = useState(0);
  const beforeChange = (prev, next) => {
    setIndex(next);
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 5,
    className: "slider-container",
    slidesToScroll: 2,
    initialSlide: 0,
    arrows: false,
    swipe: false,
    beforeChange: beforeChange,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          className: "center",
          centerMode: true,
          swipe: true,
          centerPadding: "60px",
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          swipe: true,
        },
      },
    ],
  };
  const productList =
    products?.data.map((product) => ({
      title: product.product_title,
      images: product.product_other_images,
      discountedPrice: product.product_sale_price || 0,
      actualPrice: product.product_mrp || 0,
      size: product.size || [],
      color: product.color || [],
      ...product,
    })) || [];

  const handlePrevSlide = () => {
    slider.current.slickPrev();
  };

  const handleNextSlide = () => {
    slider.current.slickNext();
  };

  return (
    <section className="section">
      <div className="section-inner">
        <div className="sectionHeader">
          <h3>Cat Products</h3>
          <Button
            variant="contained"
            size="medium"
            style={{
              backgroundColor: "#09d1c7",
              marginRight: "8px",
              padding: "4px 11px",
            }}
          >
            <Link
              to={`/products?cid=${btoa(products?.category?.id)}`}
              style={{ textDecoration: "none", color: "white" }}
            >
              View All{" "}
            </Link>
          </Button>
        </div>
        {/* <div
          style={{
            borderBottom: "1px solid #e5e5e5",
            width: "100%",
            height: "0",
            marginBottom: "0.5rem",
          }}
        /> */}
        {mobileMatches ? (
          <div className="sliderrr">
            <Slider
              style={{ width: "100%" }}
              ref={(c) => (slider.current = c)}
              {...settings}
            >
              {productList.map((product) => (
                <ProductSlider key={product.id} data={product} from={true} />
              ))}
            </Slider>
            {/* {mobileMatches && (
                  <> */}
            <CustomIconButton
              className="arrowLeft"
              variant="contained"
              color="primary"
              disabled={index === 0}
              onClick={handlePrevSlide}
            >
              <ArrowBackIosNewIcon />
            </CustomIconButton>
            <CustomIconButton
              className="arrowRight"
              variant="contained"
              color="primary"
              disabled={index + 4 === productList.length}
              onClick={handleNextSlide}
            >
              <ArrowForwardIosIcon />
            </CustomIconButton>
            {/* </>
                )} */}
          </div>
        ) : (
          <div className="mobile-sliderr">
            <table>
              <tbody>
                <tr>
                  {productList.map((product) => (
                    <td>
                      <ProductSlider
                        key={product.id}
                        data={product}
                        from={true}
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
}

export default CatProducts;
