import React, { useEffect, useRef, useState } from "react";
import "./ProductSlider.css";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ImageSlider from "./ImageSlider";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ClearIcon from "@mui/icons-material/Clear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router";
import { addToCart, fetchItems, menuActions, updateCart } from "../../store";
import Login from "../login/Login";
import { toast } from "react-toastify";
import { checkStock } from "../../api";

const BootstrapButton = withStyles({
  root: {
    border: "1px solid",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

function ProductSlider({ data, type, from }) {
  const { isLoggedIn, cart, sessionlessCart } = useSelector(
    (state) => state.menu
  );
  const history = useHistory();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [hoverStatus, setHoverStatus] = useState(false);
  const [pickedColor, setPickedColor] = useState(null);
  const [loginBoxStatus, setLoginBoxStatus] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState();
  const [pickedSize, setPickedSize] = useState(null);
  const [colorPickerStatus, setColorPickerStatus] = useState(false);
  const [shakeStatus, setShakeStatus] = useState(false);
  const [sizeColorMap, setSizeColorMap] = useState({});
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [addToCartReq, setAddToCartReq] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  let interval = useRef();
  const dispatch = useDispatch();

  const handlePickedColor = (color) => {
    setPickedColor(color);
    setColorPickerStatus(false);
  };

  useEffect(() => {
    if (pickedSize) {
      setColors(sizeColorMap[pickedSize]);
      setPickedColor(sizeColorMap[pickedSize][0]);
    }
  }, [pickedSize]);

  useEffect(() => {
    setSelectedVariation(
      data?.variation_types?.find(
        (variation) =>
          variation.color_attribute === pickedColor &&
          variation.size_attribute === pickedSize
      )
    );
  }, [pickedColor, pickedSize]);

  const handleMoreColorsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleColorDialogClose = () => {
    setAnchorEl(null);
  };
  const addToBasket = async () => {
    if (!isLoggedIn) {
      const checkStockStatus = await checkStock({
        product_id: data.id,
        picked_color: pickedColor,
        picked_size: pickedSize,
      });
      if (!checkStockStatus.data.error)
        if (
          sessionlessCart.some(
            (item) =>
              item.id === data.id &&
              (item.picked_size === pickedSize || item.picked_size === "") &&
              (item.picked_color === pickedColor || item.picked_color === "")
          )
        ) {
          await dispatch(
            menuActions.incrementSessionlessCart({
              ...data,
              product_id: data.id,
              title: data.title,
              rating: data.rating,
              picked_size: pickedSize || "",
              color: pickedColor || "",
              pickedSize: pickedSize,
              picked_color: pickedColor || "",
              max_cart_val: checkStockStatus.data.data.quantity,
              ...selectedVariation,
            })
          );
        } else
          await dispatch(
            menuActions.setSessionlessCart({
              ...data,
              product_id: data.id,
              title: data.title,
              rating: data.rating,
              picked_size: pickedSize || "",
              color: pickedColor || "",
              pickedSize: pickedSize,
              picked_color: pickedColor || "",
              quantity: 1,
              max_cart_val: checkStockStatus.data.data.quantity,
              ...selectedVariation,
            })
          );
      toast.success("The item has been added to the cart successfully");
      return;
    }
    if (
      data.size !== undefined &&
      data.size.length > 0 &&
      pickedSize === null
    ) {
      return setShakeStatus(true);
    }
    if (
      cart.some(
        (item) =>
          item.product_id === data.id &&
          (item.picked_size === pickedSize || item.picked_size === "''") &&
          (item.picked_color === pickedColor || item.picked_color === "''")
      )
    ) {
      await updateCart(
        {
          ...data,
          product_id: data.id,
          picked_size: pickedSize || "",
          picked_color: pickedColor || "",
          quantity: 1,
        },
        "increase",
        dispatch
      );
    } else {
      await addToCart(
        {
          ...data,
          product_id: data.id,
          title: data.title,
          rating: data.rating,
          picked_size: pickedSize || "",
          color: pickedColor || "",
          pickedSize: pickedSize,
          picked_color: pickedColor || "",
          quantity: 1,
        },
        dispatch
      );
    }
    fetchItems()(dispatch);

    toast.success("The item has been added to the cart successfully");
  };

  useEffect(() => {
    if (data.product_type === 1) {
      const tempMap = {};
      data.variation_types.forEach((variation) => {
        if (tempMap[variation.size_attribute]) {
          tempMap[variation.size_attribute].push(variation.color_attribute);
        } else {
          tempMap[variation.size_attribute] = [variation.color_attribute];
        }
      });
      setSizeColorMap(tempMap);
      setSelectedVariation(data.variation_types[0]);
      setSizes(Object.keys(tempMap));
      setPickedSize(data.variation_types[0]?.size_attribute);
      setColors(tempMap[Object.keys(tempMap)[0]]);
    } else {
      setSelectedVariation(data);
    }
  }, []);

  useEffect(() => {
    if (shakeStatus) {
      let count = 0;
      interval.current = setInterval(() => {
        if (count < 1) {
          count = count + 1;
        } else {
          setShakeStatus(false);
          clearInterval(interval.current);
        }
      }, 1000);
    }

    return () => clearInterval(interval.current);
  }, [shakeStatus]);

  return (
    <>
      <div
        key={data.id}
        className={`productSlider ${
          from && !mobileMatches ? "productSliding" : ""
        }`}
        onMouseEnter={() => setHoverStatus(true)}
        onMouseLeave={() => setHoverStatus(false)}
      >
        {/* {!mobileMatches && type && type !== "listing" ? (
          <img
            className="productImage"
            src={
              data.product_type === "1"
                ? data.variation_image_URL +
                  "/" +
                  selectedVariation?.product_image
                : "https://api.pettofit.com/products-images/thumb-image/" +
                  selectedVariation?.product_image
            }
            alt=""
            onClick={() => history.push(`./detail/${btoa(data.id)}`)}
          />
        ) : ( */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(menuActions.addRecentlyVisitedProducts(data));
            history.push(`/detail/${btoa(data.id)}`);
          }}
        >
          <ImageSlider
            data={
              data.product_type === "1"
                ? [
                    data.variation_image_URL +
                      "/" +
                      selectedVariation?.product_image,
                  ]
                : [
                    "https://api.pettofit.com/products-images/thumb-image/" +
                      selectedVariation?.product_image,
                  ]
            }
            hover={hoverStatus}
            type={type}
          />
        </div>
        {/* )} */}
        {!(
          selectedVariation?.product_mrp ===
          selectedVariation?.product_sale_price
        ) && (
          <IconButton size="small" variant="contained" className="discountBox">
            {(
              100 -
                (selectedVariation?.product_sale_price /
                  selectedVariation?.product_mrp) *
                  100 || 0
            ).toFixed(0)}{" "}
            % OFF
          </IconButton>
        )}
        <div className="detailContainer">
          <div className="productTitleContainer">
            <h4
              className="title"
              onClick={() => {
                dispatch(menuActions.addRecentlyVisitedProducts(data));
                history.push(`/detail/${btoa(data.id)}`);
              }}
            >
              {!mobileMatches
                ? (data.title && data.title.length > 65
                    ? data.title.substring(0, 65) + "..."
                    : data.title) ||
                  (data.product_title && data.product_title.length > 65
                    ? data.product_title.substring(0, 65) + "..."
                    : data.product_title)
                : data.title || data.product_title}
            </h4>
          </div>

          {colors !== undefined &&
            colors.length > 0 &&
            colors.some((color) => color !== "") && (
              <div className="colorPicker colorPickerDemo">
                <div className="content">
                  {colors.slice(0, 3).map((color, index) =>
                    color !== "" ? (
                      <div
                        key={`color${index}`}
                        className="colorBox picking"
                        onClick={() => handlePickedColor(color)}
                        style={{
                          backgroundColor: `${color}`,
                          border: "0.5px solid 	#E0E0E0",
                        }}
                      ></div>
                    ) : (
                      <></>
                    )
                  )}
                  {colors.length > 3 && (
                    <div onClick={handleMoreColorsClick}>
                      {Boolean(anchorEl) ? (
                        <ClearIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <KeyboardArrowUpIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  )}
                  {colors.length > 3 && (
                    <Menu
                      id="colors-positioned-menu"
                      aria-labelledby="colors-positioned-button"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleColorDialogClose}
                      PaperProps={{
                        style: {
                          transform: "translateX(-40%) translateY(-90%)",
                        },
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div className="menucolorcontainer">
                        {colors.map((color, index) => {
                          return (
                            <div
                              key={`color${index}`}
                              className="colorBox picking menucolor"
                              onClick={() => handlePickedColor(color)}
                              style={{
                                backgroundColor: `${color}`,
                                border: "0.5px solid 	#E0E0E0",
                              }}
                            ></div>
                          );
                        })}
                      </div>
                    </Menu>
                  )}
                </div>
              </div>
            )}
          <div className="productSlider__ratingContainer">
            {[1, 2, 3, 4, 5].map((rating) =>
              rating <= data.rating ? (
                <StarIcon key={`star${rating}`} className="ratingIcon" />
              ) : (
                <StarBorderIcon key={`star${rating}`} className="ratingIcon" />
              )
            )}
          </div>
          {sizes !== undefined ? (
            <div
              className={`productSlider__sizeContainer ${
                shakeStatus && "shake"
              }`}
            >
              {sizes.map((size) => (
                <div
                  key={`size${size}`}
                  style={{
                    backgroundColor: pickedSize === size ? "#B9FFFB" : "",
                  }}
                  onClick={() => setPickedSize(size)}
                  className="sizeBox"
                >
                  {size}
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                height: "36px",
              }}
            ></div>
          )}

          <div className="productSlider__footer">
            <div className="rateCard">
              {!(
                selectedVariation?.product_mrp ===
                selectedVariation?.product_sale_price
              ) && (
                <p className="actualPrice">
                  <FontAwesomeIcon icon={faRupeeSign} className="icon" />
                  {selectedVariation?.product_mrp || 0}
                </p>
              )}
              <p className="discountPrice">
                <FontAwesomeIcon icon={faRupeeSign} className="icon" />
                {selectedVariation?.product_sale_price || 0}
              </p>
            </div>
            <BootstrapButton
              size={!mobileMatches ? "small" : "small"}
              style={{
                fontSize: !mobileMatches && "10px",
                backgroundColor:
                  addToCartReq ||
                  (selectedVariation && selectedVariation.out_of_stock == "1")
                    ? "#e6e6e6"
                    : "",
              }}
              disabled={
                addToCartReq ||
                (selectedVariation && selectedVariation.out_of_stock == "1")
              }
              variant="contained"
              color="primary"
              onClick={async () => {
                setAddToCartReq(true);
                await addToBasket();
                setAddToCartReq(false);
              }}
            >
              {selectedVariation && selectedVariation.out_of_stock == "1"
                ? "Out of Stock"
                : "Add To Cart"}
            </BootstrapButton>
          </div>
        </div>
      </div>
      <Login
        setLoginBoxStatus={setLoginBoxStatus}
        loginBoxStatus={loginBoxStatus}
      />
    </>
  );
}

export default ProductSlider;
