import { configureStore, current } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import ntc from "ntc";
import { headers } from "../api";
// const initialState = ;

// TODO: splitting legacy code

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    shop_by_brands: {},
    sessionlessCart: [],
    blogList: [],
    menuItems: [],
    productInformation: [],
    cart: [],
    user: {},
    isLoggedIn: false,
    addresses: [],
    cartId: 0,
    orders: [],
    singleOrder: {},
    recentlyVisitedProducts: [],
    blogLink: "https://api.pettofit.com/blog-banners/thumb/",
    productImageLink: "https://api.pettofit.com/products-images/thumb-image",
    fromApp: false,
    cartDetails: {},
  },
  reducers: {
    updateBrands: (state, action) => {
      const demoState = { ...state };
      demoState.shop_by_brands = action.payload.data;
      return demoState;
    },
    setSessionlessCart: (state, action) => {
      state.sessionlessCart = [...state.sessionlessCart, action.payload];
    },
    deleteFromSessionlessCart: (state, action) => {
      state.sessionlessCart = state.sessionlessCart.filter((item) => {
        if (item.id !== action.payload.id) {
          return item;
        } else {
          if (item.picked_color != "") {
            if (
              item.picked_size !== action.payload.picked_size &&
              item.picked_color !== action.payload.picked_color
            ) {
              return item;
            }
          } else {
            if (item.picked_size !== action.payload.picked_size) {
              return item;
            }
          }
        }
      });
    },
    updateCartDetails: (state, action) => {
      state.cartDetails = action.payload;
    },
    incrementSessionlessCart: (state, action) => {
      state.sessionlessCart = state.sessionlessCart.map((item) => {
        if (
          item.id === action.payload.id &&
          (item.picked_size === action.payload.picked_size ||
            item.picked_size == "''") &&
          (item.picked_color === action.payload.picked_color ||
            item.picked_size == "''")
        ) {
          item.quantity = item.quantity + 1;
        }
        return item;
      });
    },
    clearSessionlessCart: (state) => {
      state.sessionlessCart = [];
    },
    decrementSessionlessCart: (state, action) => {
      state.sessionlessCart = state.sessionlessCart.map((item) => {
        if (
          item.id === action.payload.id &&
          (item.picked_size === action.payload.picked_size ||
            item.picked_size == "''") &&
          (item.picked_color === action.payload.picked_color ||
            item.picked_size == "''")
        ) {
          item.quantity = item.quantity - 1;
        }
        return item;
      });
    },
    addRecentlyVisitedProducts: (state, action) => {
      if (
        !state.recentlyVisitedProducts.some(
          (product) => product.id === action.payload.id
        )
      ) {
        state.recentlyVisitedProducts.push(action.payload);
      }
    },
    addTocart: (state, action) => {
      try {
        state.fromApp = true;
        let theItem = action.payload;
        let existingItem = state.cart.find(
          (item) => item.product_sku === theItem.product_sku
        );
        if (!existingItem) {
          state.cart.push(theItem);
        } else {
          existingItem["quantity"] =
            parseInt(existingItem["quantity"]) + 1 + "";
        }
      } catch (err) {
        console.log("error in addingto Cart", err);
      }
    },
    updateCart: (state, action) => {
      const { type } = action.payload;
      if (type === "increase") {  
        let theItem = action.payload;
        let existingItem = state.cart.find(
          (item) => item.product_sku === theItem.product_sku
        );
        if (!existingItem && theItem.max_cart_val>0) {
          theItem["quantity"] = 1;
          state.cart.push(theItem);
        } else {
          if(theItem.max_cart_val>existingItem["quantity"]){
            existingItem["quantity"] =
            parseInt(existingItem["quantity"]) + 1 + "";
          }
        }
      } else {
        let existingItem = state.cart.find(
          (item) => item.product_sku === action.payload.product_sku
        );
        if (existingItem.quantity > 1) {
          existingItem.quantity--;
        } else {
          state.cart = state.cart.filter(
            (item) => item.product_sku !== action.payload.product_sku
          );
        }
      }
    },
    setMenuBar: (state, action) => {
      try {
        state.menuItems = action.payload;
      } catch (err) {}
    },
    setBlogList: (state, action) => {
      try {
        let demoData = { ...action.payload };
        delete demoData.url;
        let demoArr = Object.keys(demoData).map((item) => demoData[item]);
        for (var k in demoArr) {
          demoArr[k].link = `/blog/${demoArr[k].id}`;
          demoArr[k].title = demoArr[k].blog_title;
          demoArr[k].desc = demoArr[k].blog_text;
          demoArr[k].image = state.blogLink + demoArr[k].thumb_image;
        }
        state.blogList = demoArr;
      } catch (err) {
        console.log("action.payload :::: ", action.payload);
        console.log("error :in setBlogList", err);
      }
    },
    setOrders: (state, action) => {
      try {
        state.orders = action.payload.orderItems;
        if (action.payload.imageLink !== undefined) {
          state.productImageLink = action.payload.imageLink;
        }
      } catch (err) {
        console.log("error in set orders ::: ");
      }
    },
    setSingleOrder: (state, action) => {
      try {
        state.singleOrder = action.payload;
      } catch (err) {
        console.log("error in set Single order ::: ");
      }
    },
    setProductInfo: (state, action) => {
      try {
        state.productInformation = action.payload;
      } catch (err) {
        console.log("error in setting product info ::: ");
      }
    },
    deleteItemFromCart: (state, action) => {
      try {
        state.fromApp = true;
        deleteFromCart(action.payload);
      } catch (err) {
        console.log("Delete item from cart ::: ");
      }
    },
    removeFromCart: (state, action) => {
      try {
        state.fromApp = true;
        let theItem = action.payload.product_sku;
        console.log("prodcut_sku ::: ", action.payload.product_sku);
        let existingItem = state.cart.find(
          (item) => item.product_sku === action.payload.product_sku
        );
        if (existingItem.quantity > 1) {
          existingItem.quantity--;
        } else {
          state.cart = state.cart.filter(
            (item) => item.product_sku !== action.payload.product_sku
          );
        }
        window.localStorage.setItem("cart", JSON.stringify(state.cart));
      } catch {
        console.log("issue In cart Api :::");
      }
    },
    fetchCarts: (state, action) => {
      try {
        state.fromApp = false;
        let demoArr = [...action.payload.cartItems];
        for (var k in demoArr) {
          let temp = {};
          let item = demoArr[k];
          let name = String(ntc.name(item.picked_color)[1]);
          temp["name"] = name;
          temp["code"] = item.picked_color;
          item.pickedSize = item.picked_size || null;
          item.pickedColor = temp || null;
        }
        state.cart = demoArr;
        state.cartId = action.payload.cartId;
        state.productImageLink = action.payload.productImageLink;
      } catch (err) {
        console.log("error in fetchning carts ::: ");
      }
    },
    setAddr: (state, action) => {
      try {
        let theItem = action.payload;
        state.addresses = action.payload;
      } catch (err) {
        console.log("error in state ::");
      }
    },
    emptyCart: (state) => {
      console.log("cartEmptied ");
      state.cart = [];
      state.cartId = 0;
    },
    setCartId: (state, action) => {
      state.cartId = action.payload.cartId;
    },

    setUser: (state, action) => {
      try {
        state.isLoggedIn = true;
        state.user = action.payload.user;
        state.fromApp = false;
        if (
          window.localStorage.getItem("cart") !== undefined &&
          window.localStorage.getItem("cart") !== "[]"
        ) {
          state.fromApp = true;
          // state.cart = JSON.parse(window.localStorage.getItem('cart'))
        }
        window.localStorage.setItem("tokenId", action.payload.tokenId);
        headers.Authorization = "Bearer " + action.payload.user.token;
        window.localStorage.setItem(
          "user",
          JSON.stringify(action.payload.user)
        );
      } catch (err) {
        console.log("error in setting user ::: ");
      }
    },
    loggedIn: (state, action) => {
      try {
        state.isLoggedIn = true;
      } catch (err) {
        console.log("error in setting user ::: ");
      }
    },
    setFromResponse: (state, action) => {
      state.fromApp = action.payload;
    },
    logOut: (state, action) => {
      try {
        state.user = {};
        state.cart = [];
        state.isLoggedIn = false;
        window.localStorage.setItem("tokenId", "");
        window.localStorage.setItem("user", "");
        window.localStorage.setItem("cart", "");
        console.log("state User ::: ", state);
        return state;
      } catch (err) {
        console.log("error in setting user ::: ");
      }
    },
  },
});
export const menuActions = menuSlice.actions;

export const fetchUser = () => {
  return async (dispatch) => {
    if (localStorage.getItem("tokenId")) {
      dispatch(
        menuActions.setUser({
          user: JSON.parse(window.localStorage.getItem("user")),
          tokenId: window.localStorage.getItem("tokenId"),
        })
      );
    }
  };
};
export const fetchAddress = () => {
  return async (dispatch) => {
    if (window.localStorage.getItem("tokenId")) {
      let user = JSON.parse(window.localStorage.getItem("user"));
      let addrData = {
        userId: user.id,
      };
      const fetchAddress = await axios.post(
        "https://api.pettofit.com/api/v1/list-saved-address",
        addrData,
        { headers: headers }
      );
      if (fetchAddress.status === 200) {
        if (!fetchAddress.data.error) {
          dispatch(menuActions.setAddr(fetchAddress.data.data));
        }
      }
      // dispatch(menuActions.setUser({"user":JSON.parse(window.localStorage.getItem("user")),"tokenId":window.localStorage.getItem("tokenId")}))
    }
  };
};

export const fetchProductDetails = () => {
  return async (dispatch) => {
    const productQuantites = await axios.get(
      "https://api.pettofit.com/api/v1/product-quantities"
    );
    if (productQuantites.status === 200) {
      if (productQuantites.data.error === false) {
        dispatch(menuActions.setProductInfo(productQuantites.data.data));
      }
    }
  };
};

export const fetchItems = () => {
  console.log("fetching items");
  return async (dispatch) => {
    if (window.localStorage.getItem("tokenId")) {
      let user = JSON.parse(window.localStorage.getItem("user"));
      const fetchCart = axios.get(
        `https://api.pettofit.com/api/v1/cart?userId=${user.id}`,
        { headers: headers }
      );
      fetchCart.then((response) => {
        if (response.status === 200) {
          console.log("response.data.data ::: ", response.data.data);
          if (response.data.data.cartItems !== undefined) {
            dispatch(
              menuActions.fetchCarts({
                cartItems: response.data.data.cartItems,
                cartId: response.data.data.cartId,
                productImageLink: response.data.image_link,
                from_servers: true,
              })
            );
          } else {
            dispatch(menuActions.fetchCarts({ cartItems: [], cartId: 0 }));
          }
        }
      });
      fetchCart.catch((err) => {
        console.log("error :::", err);
      });
    }
  };
};

export const fetchOrders = () => {
  return async (dispatch) => {
    if (window.localStorage.getItem("tokenId")) {
      let user = JSON.parse(window.localStorage.getItem("user"));
      let addrData = { userId: user.id };
      const fetchOrders = await axios.get(
        `https://api.pettofit.com/api/v1/order?userId=${user.id}`,
        { headers: headers }
      );
      if (fetchOrders.status === 200) {
        if (!fetchOrders.data.error) {
          if (fetchOrders.data !== undefined) {
            console.log("fetchOrders :::: ", fetchOrders);
            dispatch(
              menuActions.setOrders({
                orderItems: fetchOrders.data,
                imageLink: fetchOrders.data.image_link,
              })
            );
          }
        }
      }
    }
  };
};

export const fetchSingleOrder = (userId, orderId) => {
  return async (dispatch) => {
    if (window.localStorage.getItem("tokenId")) {
      let user = JSON.parse(window.localStorage.getItem("user"));
      let addrData = { userId: user.id };
      const fetchOrders = await axios.get(
        `https://api.pettofit.com/api/v1/order-details?userId=${userId}&order_id=${orderId}`,
        { headers: headers }
      );
      if (fetchOrders.status === 200) {
        console.log("fetchOrders ::::::: ", fetchOrders);
        if (!fetchOrders.data.error) {
          console.log("inside fetchData 1");
          if (fetchOrders.data !== undefined) {
            console.log("inside fetchData 2");
            dispatch(menuActions.setSingleOrder(fetchOrders.data.data));
          }
        }
      }
    }
  };
};

export const fetchMenuBar = () => {
  return async (dispatch) => {
    let menuItems = await axios.get(
      "https://api.pettofit.com/api/v1/category-menu",
      { header: headers }
    );
    console.log("menuItems:::", menuItems);
    if (!menuItems.status === 200) {
      dispatch(menuActions.setMenuBar(menuItems.data.data.data));
    }
  };
};

export const fetchCartForUser = () => {
  return async (dispatch) => {
    if (window.localStorage.getItem("tokenId")) {
      let user = JSON.parse(window.localStorage.getItem("user"));
      const fetchCart = axios.get(
        `https://api.pettofit.com/api/v1/cart?userId=${user.id}`,
        { headers: headers }
      );
      fetchCart.then((response) => {
        console.log(response);
        if (response.status === 200) {
          if (response.data.data.cartItems !== undefined) {
            dispatch(
              menuActions.fetchCarts({
                cartItems: response.data.data.cartItems,
                cartId: response.data.data.cartId,
                productImageLink: response.data.image_link,
                from_servers: true,
              })
            );
          } else {
            dispatch(menuActions.fetchCarts({ cartItems: [], cartId: 0 }));
          }
          if (response.data.data.cartDetails) {
            dispatch(
              menuActions.updateCartDetails(response.data.data.cartDetails)
            );
          } else {
            dispatch(menuActions.updateCartDetails({}));
          }
        }
      });
      fetchCart.catch((err) => {
        console.log("error :::", err);
      });
      dispatch(menuActions.setFromResponse(false));
    }
  };
};

export const fetchBlogs = () => {
  return async (dispatch) => {
    if (window.localStorage.getItem("tokenId")) {
      let user = JSON.parse(window.localStorage.getItem("user"));
      const fetchBlogs = await axios.get(
        `https://api.pettofit.com/api/v1/blog-all`,
        { headers: headers }
      );
      if (fetchBlogs && fetchBlogs.status === 200 && !fetchBlogs.data.error) {
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>fetchBlogs", fetchBlogs);
        dispatch(menuActions.setBlogList(fetchBlogs.data.data));
        console.log("setBlogList ::::", fetchBlogs);
      }
    }
  };
};

export async function addToCart(item, dispatch) {
  console.log("item", item);
  if (window.localStorage.getItem("tokenId")) {
    let user = JSON.parse(window.localStorage.getItem("user"));
    console.log("item", item);
    const res = await axios.post(
      `https://api.pettofit.com/api/v1/cart`,
      { ...item, userId: user.id },
      { headers: headers }
    );
  }
}

export async function deleteFromCart(item, dispatch) {
  if (window.localStorage.getItem("tokenId")) {
    let user = JSON.parse(window.localStorage.getItem("user"));
    const fetchBlogs = await axios.post(
      `https://api.pettofit.com/api/v1/remove`,
      {
        userId: user.id,
        cartItemId: item.id,
      },
      { headers: headers }
    );
    if (fetchBlogs && fetchBlogs.status === 200 && !fetchBlogs.data.error) {
      fetchItems()(dispatch);
      return true;
    }
  }
}

export async function updateCart(data, type, dispatch) {
  let user = JSON.parse(window.localStorage.getItem("user"));
  if (window.localStorage.getItem("tokenId")) {
    const action = {
      ...data,
      type,
      action: type,
      userId: user.id,
    };
    const res = await axios.post(
      "https://api.pettofit.com/api/v1/update",
      action,
      { headers: headers }
    );
    if (res && res.status === 200 && !res.data.error) {
      dispatch(menuActions.updateCart(action));
    }
  }
}

export const store = configureStore({
  reducer: {
    menu: menuSlice.reducer,
  },
});

export default store;
