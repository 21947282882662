import axios from "axios";
import { toast } from "react-toastify";

export const headers = {
  Authorization: "Il9eRmkWQSO8WC0HGO3cwr5LmKvtJA90",
};

export const sendOtp = async (data) => {
  const otpResponse = await axios.post(
    "https://api.pettofit.com/api/v1/login-with-otp", //done
    data,
    { headers: headers }
  );
  console.log("otpResponse :::: ", otpResponse);
  return otpResponse.data;
};

export const LoginWithEmailPassword = async (email, password) => {
  let data = {
    //done
    email: email,
    password: password,
  };
  const LoginResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/login", data, {
        headers: headers,
      })
      .then((response) => {
        if (!response.data.error) {
          return resolve(response.data);
        } else {
          return resolve({
            error: "Could not log in. Please check your email and password.",
          });
        }
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return LoginResponse;
};

export const RegisterManually = async (data) => {
  const RegisterResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/register", data, {
        headers: headers, //done
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return RegisterResponse;
};

export const verifyOtp = async (data) => {
  console.log("data:::", data);
  const OtpResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/verify-login-with-otp", data, {
        headers: headers,
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return OtpResponse;
};

export const forgotPassOtp = async (data) => {
  const forOtpResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/forgot-password", data, {
        headers: headers,
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return forOtpResponse;
};

export const verifyforgotPassOtp = async (data) => {
  const VerifyForOtpResponse = new Promise(async (resolve) => {
    axios
      .post(
        "https://api.pettofit.com/api/v1/verify-forgot-password-otp",
        data,
        { headers: headers }
      )
      .then((response) => {
        console.log("executes");
        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return VerifyForOtpResponse;
};

export const resetPassword = async (data) => {
  const resetPasswordResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/reset-password", data, {
        headers: headers, //done
      })
      .then((response) => {
        return resolve(response.data);
      })

      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return resetPasswordResponse;
};

export const addToCartApi = async (data) => {
  const addToCartApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/add-buld-item", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the main Response", response.data);

        return resolve(response.data);
      })

      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return addToCartApiResponse;
};

export const updateToCartApi = async (data) => {
  const updateToCartApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/update", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the update to cart api response ::", response.data);

        return resolve(response.data);
      })

      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return updateToCartApiResponse;
};

export const RemoveFromApi = async (data) => {
  const RemoveFromApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/remove", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the remove api to cart api response ::", response.data);

        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return RemoveFromApiResponse;
};

export const SaveAddressApi = async (data) => {
  const SaveAddressApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/save-address", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the save address to cart api response ::", response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        return resolve({
          error: true,
          message: err.response?.data.message,
        });
      });
  });

  return SaveAddressApiResponse;
};

export const fetchAddressApi = async (data) => {
  const fetchAddressApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/list-saved-address", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the update to cart api response ::", response.data);

        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return fetchAddressApiResponse;
};

export const choosePaymentApi = async (data) => {
  const choosePayementApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/do-payment", data, {
        headers: headers,
      })
      .then((response) => {
        console.log(
          "the choose payment to cart api response ::",
          response.data
        );
        if (response.status === 200) {
          return resolve(response.data);
        } else {
          return resolve({
            error: true,
            message: "Server Issue Please try again Later",
          });
        }
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return choosePayementApiResponse;
};

export const ApplyCouponApi = async (data) => {
  const ApplyCouponApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/add-coupon", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the ApplyCouponApi response ::", response.data);

        return resolve(response.data);
      });
  });

  return ApplyCouponApiResponse;
};

export const RemoveCouponApi = async (data) => {
  const RemoveCouponApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/delete-coupon", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the RemoveCouponApi response ::", response.data);

        return resolve(response.data);
      });
  });
  return RemoveCouponApiResponse;
};

export const deleteAddressApi = async (data) => {
  const deleteAddressApiResponse = new Promise(async (resolve) => {
    axios
      .post("https://api.pettofit.com/api/v1/delete-address", data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the deleteAddressApi response ::", response.data);

        return resolve(response.data);
      });
  });

  return deleteAddressApiResponse;
};

export const shipToAddressApi = async (data) => {
  const shipToAddressApiResponse = new Promise(async (resolve) => {
    axios
      .post(`https://api.pettofit.com/api/v1/ship-to-address`, data, {
        headers: headers,
      })
      .then((response) => {
        console.log("the shipToAddressApi response ::", response.data);
        return resolve(response.data);
      });
  });

  return shipToAddressApiResponse;
};

export const paymentSuccessApi = async (data) => {
  const paymentSuccessApiResponse = new Promise(async (resolve) => {
    axios
      .post(`https://api.pettofit.com/api/v1/payment-callback`, data, {
        headers: headers,
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return paymentSuccessApiResponse;
};

export const singleProductApi = async (slug) => {
  const singleProductResponse = new Promise(async (resolve) => {
    axios
      .get(`https://api.pettofit.com/api/v1/get-product?slug=${slug}`, {
        headers: headers,
      })
      .then((response) => {
        console.log("the singleProductApi response ::", response.data);
        console.log("visiting here  LLLLLL", response.data.data);
        return resolve(response.data.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });
};

export const menuBarApi = async () => {
  const menuBarApiResponse = new Promise(async (resolve) => {
    axios
      .get(`https://api.pettofit.com/api/v1/category-menu`, {
        headers: headers,
      })
      .then((response) => {
        console.log("the paymentSuccessApi response ::", response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return menuBarApiResponse;
};

export const getBlogBySlugApi = async (id) => {
  const blogApiResponse = new Promise(async (resolve) => {
    axios
      .get(`https://api.pettofit.com/api/v1/get-blog?blog_id=${id}`, {
        headers: headers,
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });
};

export const getProductDetailsApi = async (id) => {
  const details = await axios.get(
    `https://api.pettofit.com/api/v1/get-product?slug=${id}`,
    {
      headers: headers,
    }
  );
  return details;
};

export const checkStock = async (data) => {
  const details = await axios.post(
    "https://api.pettofit.com/api/v1/check-item-stock",
    {
      ...data,
    }
  );
  return details;
};

export const UpdateProfile = async (data) => {
  const UpdateProfileResponse = await axios.post(
    "https://api.pettofit.com/api/v1/update-profile",
    data,
    {
      headers: headers, //done
    }
  );
  return UpdateProfileResponse.data; //for update the user profile
};

export const updatePasswordApi = async (data) => {
  const UpdateProfileResponse = await axios.post(
    "https://api.pettofit.com/api/v1/update-password",
    data,
    {
      headers: headers, //done
    }
  );
  return UpdateProfileResponse.data;
};

export const GetSubscription = async () => {
  const GetSubscriptionResponse = await axios.get(
    "https://api.pettofit.com/api/v1/get-subscriptions-plans",
    {
      headers: headers,
    }
  );
  return GetSubscriptionResponse.data; //for subscription plans
};

export const AddSubscription = async (data) => {
  const AddSubscriptionResponse = await axios.post(
    "https://api.pettofit.com/api/v1/add-subscription",
    data,
    {
      headers: headers,
    }
  );
  return AddSubscriptionResponse.data;
};

export const verifySubscriptionPayment = async (data) => {
  const paymentSuccessApiResponse = new Promise(async (resolve) => {
    axios
      .post(`https://api.pettofit.com/api/v1/subscription-payment`, data, {
        headers: headers,
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return resolve({
          error: true,
          message: "Server Issue Please try again Later",
        });
      });
  });

  return paymentSuccessApiResponse;
};

export const getUserSubscriptions = async () => {
  if (window.localStorage.getItem("tokenId")) {
    let user = JSON.parse(window.localStorage.getItem("user"));
    const subscriptionsData = await axios.get(
      `https://api.pettofit.com/api/v1/get-my-active-subscription?userId=${user.id}`,
      {
        headers: headers,
      }
    );
    return subscriptionsData.data;
  } else return false;
};

export const getUserAppointments = async () => {
  if (window.localStorage.getItem("tokenId")) {
    let user = JSON.parse(window.localStorage.getItem("user"));
    const appointmentData = await axios.post(
      `https://api.pettofit.com/api/v1/my-appointments`,
      {
        userId: user.id,
      },
      { headers }
    );
    return appointmentData.data;
  } else return false;
};

export const AllBlogs = async () => {
  const AllBlogsResponse = await axios.get(
    "https://api.pettofit.com/api/v1/blog-all",
    {
      headers: headers,
    }
  );
  return AllBlogsResponse.data;
};

export const singleBlog = async (id) => {
  const singleBlogResponse = await axios.get(
    `https://api.pettofit.com/api/v1/get-blog?blog_id=${id}`,

    {
      headers: headers,
    }
  );
  return singleBlogResponse.data;
};

export const privacyPolicy = async (id) => {
  const privacyPolicyResponse = await axios.get(
    `https://api.pettofit.com/api/v1/get-page?page_id=2`,

    {
      headers: headers,
    }
  );
  return privacyPolicyResponse.data;
};

export const returnPolicy = async (id) => {
  const returnPolicyResponse = await axios.get(
    `https://api.pettofit.com/api/v1/get-page?page_id=3`,

    {
      headers: headers,
    }
  );
  return returnPolicyResponse.data;
};

export const termOfUse = async (id) => {
  const termOfUseResponse = await axios.get(
    `https://api.pettofit.com/api/v1/get-page?page_id=4`,

    {
      headers: headers,
    }
  );
  return termOfUseResponse.data;
};

export const fetchContactUs = async (data) => {
  const contactResponse = await axios.post(
    `https://api.pettofit.com/api/v1/contact-us`,
    data,

    {
      headers: headers,
    }
  );
  return contactResponse.data;
};

export const fetchFaq = async (faqType) => {
  const faqResponse = await axios.get(
    `https://api.pettofit.com/api/v1/faq-master?faq_type=${faqType}`,

    {
      headers: headers,
    }
  );
  return faqResponse.data;
};

export const fetchOrderDetail = async (order_id, userId) => {
  const fetchOrderDetailResponse = await axios.get(
    `https://api.pettofit.com/api/v1/order-details?order_id=${order_id}&userId=${userId}`,

    {
      headers: headers,
    }
  );
  return fetchOrderDetailResponse.data;
};

export const fetchOrderStatus = async (order_id, userId) => {
  const orderResponse = await axios.post(
    `https://api.pettofit.com/api/v1/order-tracking`,
    { order_id, userId },
    {
      headers: headers,
    }
  );
  return orderResponse.data;
};

export const fetchVetFaq = async (faqType) => {
  const orderResponse = await axios.get(
    `https://api.pettofit.com/api/v1/faq-master?faq_type=${faqType}`,

    {
      headers: headers,
    }
  );
  return orderResponse.data;
};

//cancel orders

export const fetchCancelForm = async (d) => {
  const CancelResponse = await axios.post(
    `https://api.pettofit.com/api/v1/do-order-cancel`,
    d,
    {
      headers: headers,
    }
  );
  return CancelResponse.data;
};

export const fetchReview = async (data) => {
  const CancelResponse = await axios.post(
    `https://api.pettofit.com/api/v1/add-rating`,
    data,

    {
      headers: headers,
    }
  );
  return CancelResponse.data;
};

export const fetchMyReviews = async (userId) => {
  const fetchOrderDetailResponse = await axios.get(
    `https://api.pettofit.com/api/v1/get-user-rating?userId=${userId}`,
    {
      headers: headers,
    }
  );
  return fetchOrderDetailResponse.data;
};

export const fetchTwilioToken = async (email) => {
  const res = await axios.post(
    "https://api.pettofit.com/api/v1/chat/token",
    {
      email,
    },
    {
      headers: headers,
    }
  );
  return res.data;
};

export const getDoctorAppointments = async (id) => {
  const res = await axios.post(
    "https://api.pettofit.com/api/v1/my-meeting-appointments",
    {
      userId: id,
    },
    {
      headers: headers,
    }
  );
  return res.data;
};

export const headerSearch = async (keyword) => {
  const res = await axios.post(
    `https://api.pettofit.com/api/v1/search-products?keyword=${keyword}`,
    {},
    {
      headers: headers,
    }
  );
  return res.data;
};

export const fetchCatalog = async (iddata) => {
  let fetchingquery = "";
  if (iddata.cid) {
    fetchingquery = `category_id=${iddata.cid}`;
  } else if (iddata.sid) {
    fetchingquery = `sub_category_id=${iddata.sid}`;
  } else {
    fetchingquery = `p_category_id=${iddata.pid}`;
  }
  const res = await axios.get(
    `https://api.pettofit.com/api/v1/get-filters?${fetchingquery}`,
    {
      headers: headers,
    }
  );
  return res.data;
};

export const fetchOrderList = async (userId) => {
  const res = await axios.get(
    `https://api.pettofit.com/api/v1/order?userId=${userId}`,
    {
      headers: headers,
    }
  );
  return res.data;
};

export const myReviews = async (userId) => {
  const res = await axios.get(
    `https://api.pettofit.com/api/v1/get-user-rating?userId=${userId}&type=0`, //myreviews
    {
      headers: headers,
    }
  );
  return res.data;
};

// pincode //

export const fetchPincode = async (pincode) => {
  console.log(pincode);
  const res = await axios.get(
    `https://api.pettofit.com/api/v1/check-pincode?pincode=${pincode}`,
    {
      headers: headers,
    }
  );
  return res.data;
};

export const addBulkUserCart = async (data) => {
  const res = await axios.post(
    `https://api.pettofit.com/api/v1/add-bulk-item`,
    data,
    {
      headers: headers,
    }
  );
  return res.data;
};

export const fetchNewsLetter = async (email) => {
  const otpResponse = await axios.post(
    "https://api.pettofit.com/api/v1/newsletter", //done
    { email },
    { headers: headers }
  );
  console.log("otpResponse :::: ", otpResponse);
  return otpResponse.data;
};

export const raiseTicketApi = async (data) => {
  try {
    const ticketResponse = await axios.post(
      "https://api.pettofit.com/api/v1/ticket-add",
      data,
      {
        headers: headers,
      }
    );
    return ticketResponse.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Something went wrong");
    return null;
  }
};

export const fetchTicketList = async (userId) => {
  try {
    const ticketResponse = await axios.get(
      `https://api.pettofit.com/api/v1/user-tickets?userId=${userId}`,
      {
        headers: headers,
      }
    );
    return ticketResponse.data;
  } catch (error) {
    console.log("error", error);
    toast.error("Something went wrong");
    return null;
  }
};

export const aboutUs = async (id) => {
  const aboutUsResponse = await axios.get(
    `https://api.pettofit.com/api/v1/get-page?page_id=7`,

    {
      headers: headers,
    }
  );
  return aboutUsResponse.data;
};
