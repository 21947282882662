import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@mui/material/Paper";
import { Skeleton } from "@mui/material";
const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    zIndex: "10",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: 'lightgray',
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

function Blog({ isLoading, blog }) {
  const slider = useRef();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [index, setIndex] = useState(0);
  const beforeChange = (prev, next) => {
    setIndex(next);
  };
  const history = useHistory();

  var settings = {
    dots: false,
    infinite: false,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    beforeChange: beforeChange,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 800,
        settings: {
          className: "center",
          centerMode: true,
          centerPadding: "60px",
          infinite: true,
          swipe: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };
  const blogList =
    blog?.data.map((listing) => ({
      id: listing.id,
      link: "",
      title: listing.blog_title,
      desc: listing.blog_text,
      image: blog.image_link + "/" + listing.thumb_image,
    })) || [];

  const handlePrevSlide = () => {
    slider.current.slickPrev();
  };

  const handleNextSlide = () => {
    slider.current.slickNext();
  };

  const ViewBlog = (data) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
      <Paper key={`blog${data.id}`} className="blog__container" elevation={3}>
        <img src={data.image} onLoad={() => setImageLoaded(true)} alt="" />
        <div className="blog__detail">
          <h3 className="blog__title">{data.title}</h3>
          <p
            className="blog__desc"
            dangerouslySetInnerHTML={{ __html: data.desc }}
          ></p>
          <Link to={`/blog-single/${data.id}`} className="blog__link">
            Read more
          </Link>
        </div>
      </Paper>
    );
  };
  return (
    <section className="blog">
      <div className="section">
        <div className="section-inner">
          <div className="sectionHeader">
            <h3>Know your pet with Pettofit</h3>
            <Button
              variant="contained"
              size="medium"
              style={{
                backgroundColor: "#09d1c7",
                marginRight: "8px",
                padding: "4px 11px",
              }}
            >
              <Link
                to="/blog"
                style={{ textDecoration: "none", color: "white" }}
              >
                View All{" "}
              </Link>
            </Button>
          </div>
          {mobileMatches ? (
            <div className="sliderrr">
              <Slider
                style={{ width: "100%" }}
                ref={(c) => (slider.current = c)}
                {...settings}
              >
                {blogList.map((blog) => ViewBlog(blog))}
              </Slider>
              {/* {mobileMatches && (
                  <> */}
              <CustomIconButton
                className="arrowLeft"
                variant="contained"
                color="primary"
                disabled={index === 0}
                onClick={handlePrevSlide}
              >
                <ArrowBackIosNewIcon />
              </CustomIconButton>
              <CustomIconButton
                className="arrowRight"
                variant="contained"
                color="primary"
                disabled={index + 4 === blogList.length}
                onClick={handleNextSlide}
              >
                <ArrowForwardIosIcon />
              </CustomIconButton>
              {/* </>
                )} */}
            </div>
          ) : (
            <div className="mobile-sliderr">
              {blogList.map((blog) => ViewBlog(blog))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Blog;
