import React, { useRef, useState } from "react";
import "./Home.css";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Skeleton } from "@mui/material";
const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    zIndex: "10",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: 'lightgray',
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

function MainSlider({ slides }) {
  const slider = useRef();
  const [imageLoaded, setImageLoaded] = useState(false);
  const mobileMatches = useMediaQuery("(min-width:600px)");
  var settings = {
    // className: "center",
    // centerMode: true,
    // centerPadding: "60px",
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };
  const bannerList = mobileMatches
    ? slides.data.map((imageData, i) => ({
        id: i,
        image: slides.desktop_image_link + "/" + imageData.banner_image,
        link: imageData.banner_link,
      }))
    : slides.data.map((imageData, i) => ({
        id: i,
        image: slides.mobile_image_link + "/" + imageData.mob_banner_image,
        link: imageData.banner_link,
      }));
  const handlePrevSlide = () => {
    slider.current.slickPrev();
  };

  const handleNextSlide = () => {
    slider.current.slickNext();
  };
  return (
    <section className="section-full">
      <div className="sliderrr">
        <Slider ref={(c) => (slider.current = c)} {...settings}>
          {!imageLoaded && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={mobileMatches ? 465 : 300}
            />
          )}
          {bannerList.map((banner) => (
            <img
              onLoad={() => setImageLoaded(true)}
              key={`b${banner.id}`}
              src={banner.image}
              alt=""
              onClick={() => {
                window.open(banner.link, "_self");
              }}
              style={{
                display: imageLoaded ? "block" : "none",
                cursor: "pointer",
              }}
              className="mainBannerImage"
            />
          ))}
        </Slider>
        {mobileMatches && (
          <>
            <CustomIconButton
              className="arrowLeft"
              variant="contained"
              color="primary"
              onClick={handlePrevSlide}
            >
              <ArrowBackIosNewIcon />
            </CustomIconButton>
            <CustomIconButton
              className="arrowRight"
              variant="contained"
              color="primary"
              onClick={handleNextSlide}
            >
              <ArrowForwardIosIcon />
            </CustomIconButton>
          </>
        )}
      </div>
    </section>
  );
}

export default MainSlider;
