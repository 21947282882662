import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/core/styles";
import ProductSlider from "../product/ProductSlider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "react-redux";

const CustomIconButton = withStyles({
  root: {
    position: "absolute",
    padding: "5px",
    zIndex: "10",
    // border: '1px solid',
    backgroundColor: "#09D1C7",
    // borderColor: 'lightgray',
    boxShadow: "0 0 10px lightgray",
    fontWeight: "bold",
    color: "#fff",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(IconButton);

function RecentView() {
  const slider = useRef();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [index, setIndex] = useState(0);
  const recentlyVisitedProducts = useSelector(
    (state) => state.menu.recentlyVisitedProducts
  );
  const beforeChange = (prev, next) => {
    setIndex(next);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    className: "slider-container",
    slidesToScroll: 5,
    initialSlide: 0,
    arrows: false,
    swipe: false,
    beforeChange: beforeChange,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          className: "center",
          centerMode: true,
          centerPadding: "60px",
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          swipe: true,
        },
      },
    ],
  };
  const productList = recentlyVisitedProducts;
  const handlePrevSlide = () => {
    slider.current.slickPrev();
  };

  const handleNextSlide = () => {
    slider.current.slickNext();
  };

  return productList.length > 0 ? (
    <section className="section">
      <div className="section-inner">
        <div className="sectionHeader">
          <h3>Recently Viewed</h3>
        </div>
        {/* <div
          style={{
            borderBottom: "1px solid #e5e5e5",
            width: "100%",
            height: "0",
            marginBottom: "0.5rem",
          }}
        /> */}
        {productList.length === 0 && (
          <h3 style={{ textAlign: "center" }}>
            Your recently viewed products appear here
          </h3>
        )}
        {mobileMatches ? (
          <div className="sliderrr">
            <Slider
              style={{ width: "100%" }}
              ref={(c) => (slider.current = c)}
              {...settings}
            >
              {productList.map((product) => (
                <ProductSlider key={product.id} data={product} from={true} />
              ))}
            </Slider>
            {/* {mobileMatches && (
                  <> */}
            <CustomIconButton
              size="small"
              className="arrowLeft"
              variant="contained"
              color="primary"
              disabled={index === 0}
              onClick={handlePrevSlide}
            >
              <ArrowBackIosNewIcon />
            </CustomIconButton>
            <CustomIconButton
              size="small"
              className="arrowRight"
              variant="contained"
              color="primary"
              disabled={index + 4 === productList.length}
              onClick={handleNextSlide}
            >
              <ArrowForwardIosIcon />
            </CustomIconButton>
            {/* </>
                )} */}
          </div>
        ) : (
          <div className="mobile-sliderr">
            <table>
              <tbody>
                <tr>
                  {productList.map((product) => (
                    <td>
                      <ProductSlider
                        key={product.id}
                        data={product}
                        from={true}
                      />
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  ) : (
    ""
  );
}

export default RecentView;
