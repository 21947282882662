import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Link, useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Login from "../login/Login";
import StorefrontIcon from "@material-ui/icons/Storefront";
import StripeMenu from "./StripeMenu";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchCartForUser,
  fetchUser,
  menuActions,
  fetchAddress,
} from "../../store";
import { fetchMenu } from "../../services/http/menuService";
import { CircularProgress } from "@mui/material";
import { addBulkUserCart, headerSearch } from "../../api";

const BootstrapButton = withStyles({
  root: {
    border: "1px solid",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  curser: {
    cursor: "pointer",
  },
  customBadge: {
    backgroundColor: "#09D1C7",
    curser: "pointer",
  },
  customVetButton: {
    paddingTop: "10px",
    marginTop: "-5px",
    paddingBottom: "10px",
    marginBottom: "-5px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  iconButtonColor: {
    color: "#000",
  },
  fullList: {
    width: "calc(100% - 2px)",
  },
}));

function Header() {
  const { user, isLoggedIn, cart, sessionlessCart } = useSelector(
    (state) => state.menu
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const mobileMatches = useMediaQuery("(min-width:800px)");
  const [search, setSearch] = useState("");
  const [pageAct, setPageAct] = useState({
    page: "home",
  });
  const [profileOpen, setProfileOpen] = useState(false);
  const anchorRef = useRef(null);
  const searchRef = useRef(null);
  const [openSearchBoxStatus, setOpenSearchBoxStatus] = useState(false);
  const [loginBoxStatus, setLoginBoxStatus] = useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(null);
  const [menuData, setMenudata] = useState([]);
  const [isMenuLoading, setMenuLoading] = useState(true);
  const [inputOpen, setInputOpen] = useState(false);
  const [vetDashboardActive, setVetDashboardActive] = useState(false);
  const [mobileMenu, setmobileMenu] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const location = useLocation();
  const [searching, setSearching] = useState(false);
  const timer = useRef(null);
  const [searchResults, setSearchResults] = useState({});

  useEffect(() => {
    const unlisten = history.listen(() => {
      document.querySelector("#root").scrollTo(0, 0);
    });
    return unlisten;
  }, []);

  useEffect(() => {
    if (search != "") {
      setSearching(true);
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        headerSearch(search).then((res) => {
          setSearching(false);
          if (res.data.products.length == 0 && res.data.category.length == 0) {
            setIsSearchOpen(false);
          } else {
            setSearchResults(res.data);
          }
        });
      }, 700);
    } else {
      setIsSearchOpen(false);
    }
  }, [search]);

  useEffect(() => {
    setPageAct({
      page: location.pathname.split("/")[1] || "home",
    });
    {
      if (location.pathname == "/vet-dashboard") setVetDashboardActive(true);
      else setVetDashboardActive(false);
    }
    {
      if (location.pathname.includes("detail")) setmobileMenu(true);
      else setmobileMenu(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchUser()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    fetchCartForUser()(dispatch);
    fetchAddress()(dispatch);
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    (async () => {
      const menu = await fetchMenu();
      menu.data.shift();
      menu.data.forEach((c) => {
        c.child.shift();
      });
      setMenudata(menu.data);
      dispatch(menuActions.setMenuBar(menu.data));
      setMenuLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isLoggedIn && sessionlessCart.length > 0) {
        await addBulkUserCart({
          userId: user.id,
          itemsInBulk: JSON.stringify(sessionlessCart),
        });
        fetchCartForUser()(dispatch);
        dispatch(menuActions.clearSessionlessCart());
      }
    })();
  }, [isLoggedIn]);

  const handleToggle = () => {
    setLoginBoxStatus(true);
  };

  const handleLogout = () => {
    setProfileOpen(false);
    toast.info("Sucessfully logout");

    dispatch(menuActions.logOut());
  };

  const handleClose = (event) => {
    if (event === "profile") {
      history.push("/profile");
    }
    if (event === "MyOrder") {
      history.push("/orders");
    }
    if (event === "MyAppointment") {
      history.push("/appointment");
    }
    if (event === "vetDashboard") {
      history.push("/vet-dashboard");
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setProfileOpen(false);
  };

  const getItemQty = () => {
    let qty = 0;
    if (isLoggedIn) {
      qty = cart.length;
    } else {
      qty = sessionlessCart.length;
    }
    return qty;
  };

  return vetDashboardActive ? (
    <></>
  ) : (
    <>
      {mobileMatches ? (
        <div className="header">
          <div className="header__top">
            <div className="logo" onClick={() => history.push("/")}>
              <img src={require("../../image/logo-sm.png").default} alt="" />
              <img
                src={require("../../image/logo-text-sm.png").default}
                alt=""
              />
            </div>
            <div className="right">
              <div
                className="search"
                style={{
                  borderBottom: inputOpen ? "1px solid gray" : "",
                  borderRadius: 0,
                }}
              >
                {search.trim().length == 0 ? (
                  <SearchIcon
                    // onClick={() => {
                    //   setIsSearchOpen(false);
                    //   setInputOpen((open) => !open);}
                    // }
                    className="icon"
                  />
                ) : (
                  <CloseIcon
                    onClick={() => {
                      setSearch("");
                    }}
                    className="icon"
                    style={{ cursor: "pointer" }}
                  />
                )}
                {/* {inputOpen && ( */}
                <>
                  <input
                    ref={searchRef}
                    type="text"
                    value={search}
                    style={{
                      width: "450px",
                    }}
                    onChange={(e) => {
                      setIsSearchOpen(true);
                      setSearch(e.target.value);
                    }}
                    placeholder="Search Products"
                  />
                </>
                {/* )} */}
                <Popper
                  open={isSearchOpen}
                  anchorEl={searchRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: "1" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                      <Paper
                        sx={{
                          marginTop: "9px",
                          width: "550px",
                          height: "350px",
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={() => setIsSearchOpen(false)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            }}
                          >
                            {searching ? (
                              <CircularProgress />
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexDirection: "column",
                                  width: "100%",
                                  height: "100%",
                                  overflowY: "scroll",
                                  paddingLeft: "10px",
                                }}
                              >
                                {searchResults &&
                                  searchResults.products &&
                                  searchResults.products.length > 0 && (
                                    <>
                                      <h4
                                        style={{
                                          marginTop: "15px",
                                          marginBottom: "2px",
                                        }}
                                      >
                                        Products
                                      </h4>
                                      {searchResults &&
                                        searchResults.products &&
                                        searchResults.products.map((p) => (
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              border: "1px solid #f1f1f1",
                                              paddingTop: "10px",
                                              paddingBottom: "10px",
                                            }}
                                            onClick={() => {
                                              history.push(
                                                `/detail/${btoa(p.id)}`
                                              );
                                              setIsSearchOpen(false);
                                            }}
                                          >
                                            {p.product_title}
                                          </div>
                                        ))}
                                    </>
                                  )}
                                {searchResults &&
                                  searchResults.category &&
                                  searchResults.category.length > 0 && (
                                    <>
                                      <h4
                                        style={{
                                          marginTop: "10px",
                                          marginBottom: "2px",
                                        }}
                                      >
                                        Categories
                                      </h4>
                                      {searchResults &&
                                        searchResults.category &&
                                        searchResults.category.map((c) => {
                                          return (
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                border: "1px solid #f1f1f1",
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                              }}
                                              onClick={() => {
                                                history.push(
                                                  `/products?${c.type}=${btoa(
                                                    c.id
                                                  )}`
                                                );
                                                setIsSearchOpen(false);
                                              }}
                                            >
                                              {c.name}
                                            </div>
                                          );
                                        })}
                                    </>
                                  )}
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <div>
                <IconButton
                  style={{ padding: "3px" }}
                  className={classes.iconButtonColor}
                  onClick={() => history.push("/contact")}
                >
                  <SupportAgentIcon />
                </IconButton>
              </div>
              <div>
                {!isLoggedIn && (
                  <IconButton
                    size="small"
                    ref={anchorRef}
                    onClick={() => handleToggle()}
                    className={`phone ${classes.curser}`}
                  >
                    <PersonIcon />
                  </IconButton>
                )}
                {isLoggedIn && (
                  <IconButton
                    size="small"
                    ref={anchorRef}
                    onClick={() => setProfileOpen((open) => !open)}
                    className={`phone ${classes.curser}`}
                  >
                    {user.photo && (
                      <img
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                        }}
                        src={user.photo}
                      ></img>
                    )}
                    {!user.photo && <PersonIcon />}
                  </IconButton>
                )}
                {/* After Login */}
                <Popper
                  open={profileOpen}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: "1" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <Box>
                            <Stack
                              sx={{ p: 1, maxWidth: "200px" }}
                              direction="column"
                              alignItems="center"
                            >
                              {user.photo && (
                                <img
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "50%",
                                  }}
                                  src={user.photo}
                                ></img>
                              )}
                              {!user.photo && <Avatar />}
                              <h4
                                style={{ margin: "0px", textAlign: "center" }}
                              >
                                {user?.name}
                              </h4>
                            </Stack>
                            <Divider />
                            <MenuList
                              autoFocusItem={profileOpen}
                              id="menu-list-grow"
                            >
                              {user.role_type === "10" && (
                                <MenuItem
                                  onClick={() => handleClose("vetDashboard")}
                                >
                                  Vet Dashboard
                                </MenuItem>
                              )}
                              <MenuItem onClick={() => handleClose("profile")}>
                                Profile
                              </MenuItem>
                              <MenuItem onClick={() => handleClose("MyOrder")}>
                                My Orders
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleClose("MyAppointment")}
                              >
                                My Appointment
                              </MenuItem>
                              <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </MenuList>
                          </Box>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <div
                className={classes.curser}
                onClick={() => history.push("/cart")}
              >
                <Badge
                  badgeContent={getItemQty()}
                  color="primary"
                  classes={{ badge: classes.customBadge }}
                  className={classes.curser}
                >
                  <IconButton size="small">
                    <ShoppingCartIcon sx={{ color: "#000" }} />
                  </IconButton>
                </Badge>
              </div>
            </div>
          </div>
          <div className="header__bottom">
            <StripeMenu data={menuData} loading={isMenuLoading} />
            <BootstrapButton
              variant="contained"
              color="primary"
              className={classes.customVetButton}
              onClick={() => history.push("/vet")}
            >
              Vet Consultation
            </BootstrapButton>
          </div>
        </div>
      ) : (
        <>
          <div className="headerMobile">
            <IconButton
              className={classes.iconButtonColor}
              onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
            >
              {isMobileMenuActive ? <ArrowBackIcon /> : <MenuIcon />}
            </IconButton>
            <div className="logo" onClick={() => history.push("/")}>
              <img src={require("../../image/logo-sm.png").default} alt="" />
              <img
                src={require("../../image/logo-text-sm.png").default}
                alt=""
              />
            </div>
            <div className="buttonContainer">
              <IconButton
                className={classes.iconButtonColor}
                onClick={() => setOpenSearchBoxStatus(!openSearchBoxStatus)}
              >
                {openSearchBoxStatus ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
              <IconButton
                className={classes.iconButtonColor}
                onClick={() => history.push("/cart")}
              >
                <Badge
                  badgeContent={getItemQty()}
                  color="primary"
                  classes={{ badge: classes.customBadge }}
                  className={classes.curser}
                >
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </div>
            <SwipeableDrawer
              anchor={"top"}
              open={openSearchBoxStatus}
              disableSwipeToOpen={true}
              onClose={() => setOpenSearchBoxStatus(false)}
              onOpen={() => setOpenSearchBoxStatus(true)}
            >
              <div
                className={classes.fullList}
                role="presentation"
                // onClick={() => setOpenSearchBoxStatus(false)}
                // onKeyDown={() => setOpenSearchBoxStatus(false)}
              >
                <div className="search mobileSearch">
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setIsSearchOpen(true);
                    }}
                    placeholder="Search"
                    autoFocus
                  />
                  {search !== "" ? (
                    <CloseIcon className="icon" onClick={() => setSearch("")} />
                  ) : (
                    <SearchIcon className="icon" />
                  )}
                </div>
                {search !== "" && (
                  <Paper className="searchListDropdown" elevation={3}>
                    <List style={{ maxHeight: "300px", overflowY: "scroll" }}>
                      {isSearchOpen &&
                        searchResults &&
                        searchResults.products &&
                        searchResults.products.length > 0 && (
                          <>
                            <h4
                              style={{
                                marginLeft: "10px",
                                marginBottom: "5px",
                              }}
                            >
                              Products
                            </h4>
                            {searchResults.products.map((text, index) => (
                              <ListItemButton
                                key={text.product_title}
                                onClick={() => {
                                  history.push(`/detail/${btoa(text.id)}`);
                                  setIsSearchOpen(false);
                                  setOpenSearchBoxStatus(false);
                                }}
                                dense={true}
                                style={{ borderBottom: "1px solid #d9d9d9" }}
                              >
                                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                                <ListItemText
                                  sx={{ fontSize: "12" }}
                                  primary={text.product_title}
                                />
                              </ListItemButton>
                            ))}
                            <h4 style={{ marginLeft: "10px" }}>Categories</h4>
                            {searchResults.category.map((text, index) => (
                              <ListItemButton
                                key={text.product_title}
                                onClick={() => {
                                  history.push(
                                    `/products?${text.type}=${btoa(text.id)}`
                                  );
                                  setIsSearchOpen(false);
                                  setOpenSearchBoxStatus(false);
                                }}
                                dense={true}
                              >
                                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                                <ListItemText
                                  sx={{ fontSize: "12" }}
                                  primary={text.name}
                                />
                              </ListItemButton>
                            ))}
                          </>
                        )}
                    </List>
                  </Paper>
                )}
              </div>
            </SwipeableDrawer>
          </div>
          {!mobileMenu && (
            <div className="menu-mobile">
              <Link
                to="/"
                className={`icons ${pageAct.page === "home" ? "active" : ""}`}
              >
                <b></b>
                <b></b>
                <StorefrontIcon className="iconFont" />
              </Link>

              <Link
                to="/vet"
                className={`icons ${pageAct.page === "vet" ? "active" : ""}`}
              >
                <b></b>
                <b></b>
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.000000pt"
                  height="17.000000pt"
                  viewBox="0 0 367.000000 334.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,334.000000) scale(0.100000,-0.100000)"
                    fill={pageAct.page === "vet" ? "#fff" : "#000"}
                    stroke="none"
                  >
                    <path
                      d="M1143 3321 c-155 -58 -300 -237 -339 -420 -19 -90 -18 -277 2 -355
71 -278 298 -463 524 -427 256 42 430 289 430 614 0 194 -60 347 -185 473 -97
98 -155 125 -282 130 -80 4 -108 1 -150 -15z"
                    />
                    <path
                      d="M2247 3316 c-119 -50 -209 -136 -272 -260 -49 -98 -65 -162 -71 -296
-8 -146 9 -230 71 -357 76 -155 190 -249 340 -280 68 -13 83 -13 151 1 311 63
491 465 375 836 -45 142 -159 283 -277 341 -56 28 -77 33 -162 36 -86 3 -104
1 -155 -21z"
                    />
                    <path
                      d="M305 2433 c-257 -92 -375 -364 -260 -599 39 -79 120 -158 202 -197
64 -30 75 -32 178 -32 103 0 114 2 178 32 89 42 168 121 209 210 26 55 33 83
36 153 9 180 -82 330 -246 408 -65 31 -88 36 -161 39 -64 3 -99 -1 -136 -14z"
                    />
                    <path
                      d="M3125 2436 c-108 -34 -213 -119 -263 -214 -114 -214 -25 -481 195
-585 64 -30 75 -32 178 -32 103 0 114 2 178 32 89 42 168 121 209 210 58 124
48 285 -26 401 -56 89 -146 156 -249 187 -61 18 -167 18 -222 1z"
                    />
                    <path
                      d="M1680 2044 c-305 -44 -469 -120 -650 -303 -149 -149 -256 -338 -373
-651 -81 -217 -135 -394 -147 -482 -34 -241 118 -502 338 -579 87 -31 171 -28
367 13 266 56 395 71 615 72 225 0 307 -9 590 -65 260 -52 340 -51 445 0 133
66 235 198 275 357 36 139 25 220 -70 504 -137 409 -264 658 -422 821 -205
213 -485 321 -823 318 -66 -1 -131 -3 -145 -5z m217 -618 c27 -24 28 -27 33
-153 l5 -128 105 -5 c120 -7 135 -12 155 -51 25 -47 19 -104 -14 -141 -29 -33
-31 -33 -138 -38 l-108 -5 -5 -123 -5 -124 -33 -29 c-36 -33 -76 -37 -110 -13
-42 29 -52 65 -52 184 l0 110 -97 0 c-119 0 -155 16 -174 79 -14 47 -7 81 25
118 23 27 28 28 134 31 l110 4 4 125 3 125 33 29 c41 37 89 39 129 5z"
                    />
                  </g>
                </svg>
              </Link>

              <Link
                to="/blog"
                className={`icons ${pageAct.page === "blog" ? "active" : ""}`}
              >
                <b></b>
                <b></b>
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.000000pt"
                  height="18.000000pt"
                  viewBox="0 0 342.000000 342.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,342.000000) scale(0.100000,-0.100000)"
                    fill={pageAct.page === "blog" ? "#fff" : "#000"}
                    stroke="none"
                  >
                    <path
                      d="M207 3400 c-96 -37 -161 -109 -191 -210 -13 -44 -16 -106 -16 -337
l0 -283 1705 0 1705 0 0 295 c0 277 -1 298 -22 352 -29 77 -104 152 -181 181
l-58 22 -1447 0 c-1391 -1 -1449 -2 -1495 -20z m333 -415 c94 -48 63 -195 -40
-195 -31 0 -44 7 -69 35 -34 39 -39 71 -16 115 15 29 57 59 83 60 7 0 26 -7
42 -15z m400 0 c33 -17 60 -61 60 -99 0 -14 -14 -41 -31 -61 -25 -28 -38 -35
-69 -35 -31 0 -44 7 -69 35 -34 39 -39 71 -16 115 15 29 57 59 83 60 7 0 26
-7 42 -15z m400 0 c33 -17 60 -61 60 -99 0 -14 -14 -41 -31 -61 -24 -27 -39
-35 -65 -35 -98 0 -139 120 -63 184 35 30 58 32 99 11z"
                    />
                    <path
                      d="M2 1298 l3 -1063 28 -57 c30 -61 74 -105 140 -140 l42 -23 1470 -3
c1683 -3 1537 -10 1636 87 96 96 89 3 89 1208 l0 1053 -1705 0 -1705 0 2
-1062z m1555 837 c12 -9 27 -32 33 -53 13 -50 13 -784 -1 -834 -19 -70 -5 -68
-589 -68 -584 0 -570 -2 -589 68 -7 23 -11 187 -11 425 0 409 3 440 47 466 12
7 191 10 553 11 465 0 538 -2 557 -15z m1424 -404 c37 -38 39 -88 5 -128 l-24
-28 -541 -3 c-595 -3 -585 -4 -611 57 -15 38 -7 75 25 107 l24 24 546 0 547 0
29 -29z m0 -390 c37 -38 39 -88 5 -128 l-24 -28 -541 -3 c-595 -3 -585 -4
-611 57 -15 38 -7 75 25 107 l24 24 546 0 547 0 29 -29z m5 -394 c32 -37 32
-87 0 -124 l-24 -28 -1239 -3 c-919 -2 -1245 1 -1266 9 -28 12 -57 54 -57 84
0 27 30 76 53 85 13 5 556 8 1265 7 l1244 -2 24 -28z m0 -390 c32 -37 32 -87
0 -124 l-24 -28 -1239 -3 c-919 -2 -1245 1 -1266 9 -28 12 -57 54 -57 83 0 31
26 69 55 82 24 11 259 13 1268 11 l1239 -2 24 -28z"
                    />
                    <path d="M590 1710 l0 -320 425 0 425 0 -2 318 -3 317 -422 3 -423 2 0 -320z" />
                  </g>
                </svg>
              </Link>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  if (!isLoggedIn) setLoginBoxStatus(true);
                  else history.push("/profile");
                }}
                to="/profile"
                className={`icons ${
                  pageAct.page === "profile" ||
                  pageAct.page === "appointment" ||
                  pageAct.page === "order-detail" ||
                  pageAct.page === "orders" ||
                  pageAct.page === "reviews"
                    ? "active"
                    : ""
                }`}
              >
                <b></b>
                <b></b>
                <PersonIcon className="iconFont" />
              </Link>
            </div>
          )}
          {isMobileMenuActive && (
            <div className="mobielMenu">
              <List
                sx={{ width: "100%", bgcolor: "background.paper" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {menuData?.map((menu) =>
                  menu.name !== "Personalised" && menu.name !== "Small Pets" ? (
                    <>
                      <ListItemButton
                        sx={{
                          backgroundColor:
                            mobileMenuExpanded === menu.link
                              ? "#E7FDFD !important"
                              : "inherit",
                          color:
                            mobileMenuExpanded === menu.link
                              ? "#03D1C7"
                              : "inherit",
                        }}
                        onClick={() =>
                          mobileMenuExpanded === null ||
                          mobileMenuExpanded !== menu.link
                            ? setMobileMenuExpanded(menu.link)
                            : setMobileMenuExpanded(null)
                        }
                      >
                        <ListItemText
                          primary={
                            <span
                              style={{ fontWeight: "600", fontSize: "16px" }}
                            >
                              {menu.name}
                            </span>
                          }
                        />
                        {mobileMenuExpanded === menu.link ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        className="submenuBox"
                        in={mobileMenuExpanded === menu.link}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Grid container spacing={1}>
                          {menu.child.map((c, i) => (
                            <Grid key={i} item xs={6} md={6}>
                              <List
                                component="div"
                                disablePadding
                                subheader={
                                  <ListSubheader
                                    className="subHeader"
                                    component="div"
                                    id="nested-list-subheader"
                                  >
                                    {c.name}
                                  </ListSubheader>
                                }
                              >
                                {c.child.map((sc) => (
                                  <ListItemButton sx={{ pl: 2, pb: 0, pt: 0 }}>
                                    <ListItemText
                                      onClick={() => {
                                        history.push(
                                          `/products?sid=${btoa(sc.id)}`
                                        );
                                        setIsMobileMenuActive(false);
                                      }}
                                    >
                                      <span className="subMenu">
                                        {sc.name}{" "}
                                      </span>
                                    </ListItemText>
                                  </ListItemButton>
                                ))}
                              </List>
                            </Grid>
                          ))}
                        </Grid>
                      </Collapse>
                      <Divider />
                    </>
                  ) : menu.name === "Personalised" ? (
                    <>
                      <ListItemButton
                        sx={{
                          backgroundColor:
                            mobileMenuExpanded === "pers"
                              ? "#E7FDFD !important"
                              : "inherit",
                          color:
                            mobileMenuExpanded === "pers"
                              ? "#03D1C7"
                              : "inherit",
                        }}
                        onClick={() =>
                          mobileMenuExpanded === null ||
                          mobileMenuExpanded !== "pers"
                            ? setMobileMenuExpanded("pers")
                            : setMobileMenuExpanded(null)
                        }
                      >
                        <ListItemText
                          primary={
                            <span
                              style={{ fontWeight: "600", fontSize: "16px" }}
                            >
                              {menu.name}
                            </span>
                          }
                        />
                        {mobileMenuExpanded === "pers" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={mobileMenuExpanded === "pers"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {menu.child.map((c, i) => (
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText
                                onClick={() => {
                                  history.push(`/products?sid=${btoa(c.id)}`);
                                  setIsMobileMenuActive(false);
                                }}
                              >
                                <span className="subHeader">{c.name}</span>
                              </ListItemText>
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                      <Divider />
                    </>
                  ) : (
                    <>
                      <ListItemButton
                        sx={{
                          backgroundColor:
                            mobileMenuExpanded === "smallpets"
                              ? "#E7FDFD !important"
                              : "inherit",
                          color:
                            mobileMenuExpanded === "smallpets"
                              ? "#03D1C7"
                              : "inherit",
                        }}
                        onClick={() =>
                          mobileMenuExpanded === null ||
                          mobileMenuExpanded !== "smallpets"
                            ? setMobileMenuExpanded("smallpets")
                            : setMobileMenuExpanded(null)
                        }
                      >
                        <ListItemText
                          primary={
                            <span
                              style={{ fontWeight: "600", fontSize: "16px" }}
                            >
                              {menu.name}
                            </span>
                          }
                        />
                        {mobileMenuExpanded === "smallpets" ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={mobileMenuExpanded === "smallpets"}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {menu.child.map((c, i) => (
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText
                                onClick={() => {
                                  history.push(`/products?sid=${btoa(c.id)}`);
                                  setIsMobileMenuActive(false);
                                }}
                              >
                                <span className="subHeader">{c.name}</span>
                              </ListItemText>
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                      <Divider />
                    </>
                  )
                )}
                {user.role_type === "10" && (
                  <>
                    <ListItemButton onClick={() => handleClose("vetDashboard")}>
                      <ListItemText>
                        <strong>Vet Dashboard</strong>
                      </ListItemText>
                    </ListItemButton>
                    <Divider />
                  </>
                )}
              </List>
              {isLoggedIn && (
                <center>
                  <BootstrapButton
                    style={{ color: "white", marginTop: "20px" }}
                    onClick={handleLogout}
                  >
                    <LogoutIcon></LogoutIcon>Log Out
                  </BootstrapButton>
                </center>
              )}
            </div>
          )}
        </>
      )}
      {/* For Login */}
      <Login
        setLoginBoxStatus={setLoginBoxStatus}
        loginBoxStatus={loginBoxStatus}
      />
    </>
  );
}

export default Header;
