import { headers } from "../../api";
import { baseService } from "./baseService";

export async function fetchRoomInfo({ appointment_number, userId, type }) {
  const roomInfo = await baseService.post(
    "room/create",
    { appointment_number, userId, type },
    {
      headers: headers,
    }
  );
  return roomInfo?.data;
}

export async function fetchRoomToken({ roomName }) {
  const roomInfo = await baseService.post(
    `room/join-in-room?roomName=${roomName}`,
    {},
    {
      headers: headers,
    }
  );
  return roomInfo?.data;
}
