import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
// import GoogleMapReact from 'google-map-react';
import FAQS from "../FAQS";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { fetchContactUs } from "../../api";
import { Helmet } from "react-helmet";

const useStyles = makeStyles({
  paper: {
    marginTop: "50px",
    marginBottom: "50px",
  },
});
const BootstrapButton2 = withStyles({
  root: {
    width: "100%",
    border: "1px solid",
    color: "#fff",
    padding: "15px",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    marginBottom: "10px",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

const TextAreaField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

const CssTextField = withStyles({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginRight: "10px",
    "& label": {
      color: "#000",
    },
    "& label.Mui-focused": {
      color: "#09D1C7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "after",
    },
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "& .MuiOutlinedInput-input": {
        padding: "10px",
      },

      "& fieldset": {
        borderColor: "#000",
      },
      "&:hover fieldset": {
        borderColor: "#000",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#09D1C7",
      },
    },
  },
})(TextField);

function ContactUs() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [description, setDescription] = useState("");

  const handleContact = async () => {
    const data = {
      full_name: name,
      email: email,
      mobile: mobile,
      meta_description: description,
    };
    console.clear();

    console.log(data);
    const res = await fetchContactUs(data);
    console.log(res);

    if (!res.error) {
      toast.info("Your response has been recorded successfully");
      setName("");
      setMobile("");
      setEmail("");
      setDescription("");
    } else {
      toast.error(res.message);
    }
  };

  return (
    <>
      <div className="contactUs section">
        <Helmet>
          <title>
            Contact us
          </title>
        </Helmet>
        <center>
          <h1 style={{ marginTop: "40px" }}>Get in touch</h1>
          <h3>We'd love to talk about how we can help you.</h3>
        </center>
        <Grid container spacing={5}>
          <Grid item md={6} xs={12}>
            <div className="detailContainer">
              {/* <h4>CONTACT US</h4> */}
              {/* <img src={require("../../image/map.png").default} alt="" /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.028553442953!2d77.64237221473957!3d12.905885490899049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa1ad92f7a3287cf%3A0xc15a9d6d23f07d93!2sPettofit!5e0!3m2!1sen!2sin!4v1662398398436!5m2!1sen!2sin"
                width="100%"
                height="620"
                // style="border:0;"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </Grid>
          <Grid item md={6} xs={12} className="contactForm">
            <Paper sx={{ p: 5 }} className="formContainer" elevation={3}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleContact();
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <CssTextField
                      sx={{ width: "100%" }}
                      label="Full Name"
                      id="outlined-size-small"
                      type="text"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CssTextField
                      sx={{ width: "100%" }}
                      label="Mobile Number"
                      id="outlined-size-small"
                      type="number"
                      value={mobile}
                      onChange={(event) => setMobile(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CssTextField
                      sx={{ width: "100%" }}
                      label="Email"
                      id="outlined-size-small"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextAreaField
                      multiline
                      style={{ width: "100%", marginBottom: "15px" }}
                      minRows={10}
                      placeholder="Message"
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </Grid>
                </Grid>
                <BootstrapButton2 type="submit">Submit</BootstrapButton2>
              </form>
              <Divider />
              <div className="terms">
                By clicking on "submit" you agree to our{" "}
                <a href="/privacy-policy">Privacy Policy</a>.
              </div>
            </Paper>
          </Grid>
        </Grid>
        <div className="cms section" style={{ marginBottom: "15px" }}>
          <Paper
            className={classes.paper}
            elevation={3}
            style={{ margin: "15px" }}
          >
            <h2>Contact Info</h2>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <center>
                  <h4 style={{ margin: "10px 0px" }}>Call Us</h4>
                  <a href="tel: +91-7348807744" className="contactLink">
                    <PhoneIcon />
                    &nbsp;7348807744
                  </a>
                </center>
              </Grid> 
              <Grid item md={4} xs={12}>
                <center>
                  <h4 style={{ margin: "10px 0px" }}>Mail Us</h4>
                  <a href="mailto:hello@pettofit.com" className="contactLink">
                    <EmailIcon />
                    &nbsp;hello@pettofit.com
                  </a>
                </center>
              </Grid>
              <Grid item md={4} xs={12}>
                <center>
                  <h4 style={{ padding: "0px 20px", margin: "10px 0px" }}>
                    Address
                  </h4>
                </center>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "0px",
                    paddingTop: "5px",
                  }}
                >
                  PETTOFIT PRIVATE LIMITED <br></br>No. 1702, First Floor, 19th
                  main Sector-2, H.S.R. Layout, Bengaluru, Karnataka, 560102,
                  India
                </p>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
      {/* <FAQS /> */}
    </>
  );
}

export default ContactUs;
