import React, { useEffect, useState } from "react";
import "./Blog.css";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AllBlogs } from "../../api";
import { Helmet } from "react-helmet";
function Blog() {
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [blog, setBlogPage] = useState([]);
  const [blogImageUrl, setBlogImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const blogPage = await AllBlogs();
      setIsLoading(false);
      const data = Object.values(blogPage.data);
      setBlogImageUrl(data.pop());
      setBlogPage(data);
    })();
  }, []);

  const blogList =
    blog?.map((listing) => ({
      id: listing.id,
      link: "",
      title: listing.blog_title,
      desc: listing.blog_text,
      image: blogImageUrl + "/" + listing.thumb_image,
    })) || [];

  return (
    <div className="section">
      {!isLoading && (
        <>
          <Helmet>
            <title>
              Best Vet Care Online [2022]| Online Pet Consultation | Online Pet
              Clinic
            </title>
            <meta
              name="title"
              content="Best Vet Care Online [2022]| Online Pet Consultation | Online Pet"
            ></meta>
            <meta
              property="og:title"
              content="Best Vet Care Online [2022]| Online Pet Consultation | Online Pet"
            ></meta>
          </Helmet>
          <Grid container spacing={3} sx={{ p: 1 }}>
            <Grid item md={12} xs={12}>
              <div className="blog__header">
                <h4>Know your pet with Pettofit</h4>
              </div>
              <Grid container spacing={2}>
                {blogList.map((data) => (
                  <Grid key={data.id} item md={6} xs={12}>
                    <Paper key={`blog${data.id}`} className="blogContainer">
                      <img src={data.image} alt="" />
                      <div className="blog__detail">
                        <h3 className="blog__title">{data.title}</h3>
                        <p
                          className="blog__desc"
                          dangerouslySetInnerHTML={{ __html: data.desc }}
                        ></p>
                        <Link
                          to={`/blog-single/${data.id}`}
                          className="blog__link"
                        >
                          Read more
                        </Link>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              {/* <div className="pagination">
                <Pagination count={5} />
              </div> */}
            </Grid>
            {/* <Grid item md={3} xs={12}>
          <h4 className="title">Categories</h4>
          <Divider />
          <ul className="listContainer">
            <li>
              <Link to="/">
                <span>Fooding Blog</span>
                <span>(12)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Clothing Blog</span>
                <span>(12)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Toy Blog</span>
                <span>(12)</span>
              </Link>
            </li>
          </ul>
          <h4 className="title">Tags</h4>
          <Divider />
          <div className="tagContainer">
            <Chip label="Clickable" className="chip" />
            <Chip label="Clickable" className="chip" variant="outlined" />
          </div>
        </Grid> */}
          </Grid>
        </>
      )}
      {isLoading && (
        <>
          <Stack
            spacing={4}
            style={{ alignItems: "center" }}
            sx={{
              padding: "20px",
            }}
          >
            <Skeleton animation="wave" width={200} height={70} variant="text" />
            <Stack
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div className="product-loader-box">
                <Skeleton
                  variant="rectangular"
                  width={mobileMatches ? 650 : 200}
                  height={mobileMatches ? 500 : 100}
                />
                <Skeleton
                  variant="text"
                  width={mobileMatches ? 650 : 200}
                  height={50}
                />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={90} height={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton
                  variant="rectangular"
                  width={mobileMatches ? 650 : 200}
                  height={mobileMatches ? 500 : 100}
                />
                <Skeleton
                  variant="text"
                  width={mobileMatches ? 650 : 200}
                  height={50}
                />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={90} height={35} />
              </div>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div className="product-loader-box">
                <Skeleton
                  variant="rectangular"
                  width={mobileMatches ? 650 : 200}
                  height={mobileMatches ? 500 : 100}
                />
                <Skeleton
                  variant="text"
                  width={mobileMatches ? 650 : 200}
                  height={50}
                />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={90} height={35} />
              </div>
              <div className="product-loader-box">
                <Skeleton
                  variant="rectangular"
                  width={mobileMatches ? 650 : 200}
                  height={mobileMatches ? 500 : 100}
                />
                <Skeleton
                  variant="text"
                  width={mobileMatches ? 650 : 200}
                  height={50}
                />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={mobileMatches ? 650 : 200} />
                <Skeleton variant="text" width={90} height={35} />
              </div>
            </Stack>
            {/* banner3 */}
          </Stack>
        </>
      )}
    </div>
  );
}

export default Blog;
