import React, { useEffect, useState } from "react";
import "./Blog.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { singleBlog } from "../../api";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { Helmet } from "react-helmet";

const BootstrapButton2 = withStyles({
  root: {
    border: "1px solid",
    color: "#fff",
    backgroundColor: "#09D1C7",
    borderColor: "#09D1C7",
    fontWeight: "bold",
    width: "160px",
    marginBottom: "10px",
    fontFamily: ["Arial"].join(","),
    "&:hover": {
      backgroundColor: "#08bbb2",
      borderColor: "#09d3c9",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#09d3c9",
      borderColor: "#0acfc5",
    },
  },
})(Button);

function BlogSingle() {
  const [blogId, setBlogId] = useState({});
  const mobileMatches = useMediaQuery("(min-width:600px)");
  //const [blogImageUrl, setBlogImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const [metaInfo, setMetaInfo] = useState({});
  useEffect(() => {
    axios
      .get("https://api.pettofit.com/api/v1/get-blog?blog_id=" + params.id)
      .then((response) => {
        setMetaInfo(response.data.data);
      });
  }, []);

  useEffect(() => {
    (async () => {
      const res = await singleBlog(params.id);
      console.log(res);
      setBlogId(res.data);
      setIsLoading(false);
      console.log(res.data);
    })();
  }, []);

  return (
    <div className="section">
      <Helmet>
        <title>{metaInfo.meta_title}</title>
      </Helmet>
      <Grid
        container
        spacing={0}
        sx={{ p: 0 }}
        style={{ justifyContent: "center" }}
      >
        <Grid item md={12} xs={10} className="blogSection">
          {!isLoading && (
            <Grid container spacing={0} style={{ border: "none" }}>
              <Paper className="blogSingle">
                <img
                  src={blogId.url + "/" + blogId.thumb_image}
                  alt=""
                  className="blogsingleImg"
                />
                <div className="container">
                  {/* <h5 className="dat">26-Jan-2021</h5> */}
                  <h2 className="title">{blogId.blog_title}</h2>
                  <Divider />
                  <p
                    className="para"
                    dangerouslySetInnerHTML={{ __html: blogId.blog_text }}
                  ></p>
                  {/* <p
                  className="para"
                  dangerouslySetInnerHTML={{ __html: blogId.blog_text }}
                ></p> */}

                  {/* <span>
                      <strong>23-July-2021 08:21AM</strong>
                    </span> */}

                  {/* <form>
                  <h4 className="subTitle">Leave a Comment</h4>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="First Name"
                        id="outlined-size-small"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Last Name"
                        id="outlined-size-small"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Email"
                        id="outlined-size-small"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Subject"
                        id="outlined-size-small"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextareaAutosize
                        style={{ width: "99%" }}
                        minRows={4}
                        placeholder="Description"
                      />
                    </Grid>
                  </Grid>
                  <BootstrapButton2>Send</BootstrapButton2>
                </form> */}
                </div>
              </Paper>
            </Grid>
          )}
          {isLoading && (
            <>
              <Stack
                spacing={4}
                sx={{
                  padding: "1%",
                }}
              >
                {/* main slider */}
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={mobileMatches ? 550 : 200}
                />
                {/* shop by category */}
                {/* text and heading */}
                <Stack spacing={1}>
                  <Skeleton
                    animation="wave"
                    width={150}
                    height={30}
                    variant="text"
                  />
                  <Skeleton
                    animation="wave"
                    width={"100%"}
                    height={30}
                    variant="text"
                  />
                  <Skeleton
                    animation="wave"
                    width={"100%"}
                    height={30}
                    variant="text"
                  />
                  <Skeleton
                    animation="wave"
                    width={"100%"}
                    height={30}
                    variant="text"
                  />
                </Stack>
              </Stack>
            </>
          )}
        </Grid>
        {/* <Grid item md={3} xs={12}>
          <h4 className="title">Categories</h4>
          <Divider />
          <ul className="listContainer">
            <li>
              <Link to="/">
                <span>Fooding Blog</span>
                <span>(12)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Clothing Blog</span>
                <span>(12)</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Toy Blog</span>
                <span>(12)</span>
              </Link>
            </li>
          </ul>
          <h4 className="title">Tags</h4>
          <Divider />
          <div className="tagContainer">
            <Chip label="Clickable" className="chip" />
            <Chip label="Clickable" className="chip" variant="outlined" />
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default BlogSingle;
