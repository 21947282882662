import React, { useState, useEffect } from "react";
import "./Footer.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStateValue } from "../../StateProvider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewsLetter } from "../../api";
import { toast } from "react-toastify";
import Login from "../login/Login";

const useStyles = makeStyles((theme) => ({
  footerDiv: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    maxWidth: "300px",
    minWidth: "150px",
    // padding: '20px',
    flex: "1",
  },
  customLink: {
    textDecoration: "none",
    color: "#000",
    marginBottom: "10px",
    transition: "0.15s ease",
    "&:hover": {
      // transform: 'scale(1.05)',
      // color: '#09D1C7',
    },
  },
}));

function Footer() {
  const classes = useStyles();
  const mobileMatches = useMediaQuery("(min-width:600px)");
  const [{ page }] = useStateValue();
  const location = useLocation();
  const { user, isLoggedIn } = useSelector((state) => state.menu);
  const [loginBoxStatus, setLoginBoxStatus] = useState(false);
  const [vetDashboardActive, setVetDashboardActive] = useState(false);
  const [email, setEmail] = useState("");
  const history = useHistory();
  const { menuItems } = useSelector((state) => state.menu);

  useEffect(() => {
    if (location.pathname == "/vet-dashboard") setVetDashboardActive(true);
    else setVetDashboardActive(false);
  }, [location.pathname]);
  const handleNews = async () => {
    const res = await fetchNewsLetter(email);
    if (res.error) {
      toast.error(res.message);
    } else {
      toast.info("successfully subscribed");
    }
  };

  return vetDashboardActive ? (
    <></>
  ) : (
    <Box
      sx={{
        paddingBottom:
          page?.page === "product-detail" && mobileMatches ? "80px" : "",
        mt: mobileMatches ? 0 : 2,
      }}
    >
      <Grid
        container
        spacing={mobileMatches ? 8 : 2}
        className="footer"
        sx={{ marginTop: mobileMatches ? "0px" : "" }}
      >
        <Grid item md={4} sm={12} xs={12} sx={{ pt: "30px !important" }}>
          <Stack direction="column">
            <img
              className="logo"
              src={require("../../image/logo4.png").default}
              alt="logo"
            />
            <p></p>
            <div className="social">
              <a href="https://www.facebook.com/pettofit/">
                <FacebookIcon />
              </a>
              <a href="https://www.instagram.com/pettofit/">
                <InstagramIcon />
              </a>
              <a href="https://twitter.com/pettofit">
                <TwitterIcon />
              </a>
              <a href="https://www.linkedin.com/company/pettofit">
                <LinkedInIcon />
              </a>
              <a href="https://www.youtube.com/channel/UCJSnAXVgSyjnqJHdhoWDYiw">
                <YouTubeIcon></YouTubeIcon>
              </a>
            </div>
          </Stack>
        </Grid>
        <Grid
          item
          md={4}
          sm={12}
          xs={12}
          className="footerDivv"
          sx={{ flexWrap: "wrap", pt: "30px !important" }}
        >
          <div className={classes.footerDiv}>
            <h3>Explore</h3>
            <div
              style={{
                cursor: "pointer",
              }}
              className={classes.customLink}
              onClick={() => {
                if (!isLoggedIn) setLoginBoxStatus(true);
                else history.push("/profile");
              }}
            >
              My Account
            </div>
            <Link className={classes.customLink} to="/about">
              Our Story
            </Link>
            <Link className={classes.customLink} to="career">
              Careers
            </Link>
            <Link className={classes.customLink} to="/faq">
              FAQ's
            </Link>
            <Link className={classes.customLink} to="/contact">
              Contact Us
            </Link>
          </div>
          <div className={classes.footerDiv}>
            <h3>Online Shopping</h3>
            <Link
              className={classes.customLink}
              to={`/products?cid=${btoa(
                menuItems.find((m) => m.name === "Dogs")?.id
              )}`}
            >
              For Dogs
            </Link>
            <Link
              className={classes.customLink}
              to={`/products?cid=${btoa(
                menuItems.find((m) => m.name === "Cats")?.id
              )}`}
            >
              For Cats
            </Link>
            <Link
              className={classes.customLink}
              to={`products?cid=${btoa(
                menuItems.find((m) => m.name === "Small Pets")?.id
              )}`}
            >
              For Small Animals
            </Link>
            <Link
              className={classes.customLink}
              to={`products?cid=${btoa(
                menuItems.find((m) => m.name === "Personalised")?.id
              )}`}
            >
              Personalised
            </Link>
            <Link className={classes.customLink} to="/vet">
              Vet Consultation
            </Link>
            <Link className={classes.customLink} to="/blog">
              Blog
            </Link>
          </div>
        </Grid>
        <Grid item md={4} sm={12} xs={12} sx={{ pt: "30px !important" }}>
          <Stack
            direction="column"
            alignItems={`${!mobileMatches ? "center" : "flex-start"}`}
          >
            <h3>Sign up to our Newsletter</h3>
            <p>Happy Pet Parenting !</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNews();
              }}
            >
              <input
                type="text"
                placeholder="Enter Your Email Here"
                onChange={(event) => setEmail(event.target.value)}
              />
              {/* <BootstrapButton type="submit" size="small" variant="contained"><SendIcon style={{color: '#03D1C7ed'}}/></BootstrapButton> */}
              <IconButton type="submit" color="inherit">
                <SendIcon />
              </IconButton>
            </form>
          </Stack>
        </Grid>
      </Grid>
      <div style={{backgroundColor:"#18f5ea"}}>
        Pet Parents Common Searches- <a href="#">Dog Pet Food</a> | <a href="#">Dog Food</a> | <a href="#">Pedigree Dog Food</a> | <a href="#">Dry Dog Food</a> | <a href="#">Dog Accessories Online</a> | <a href="#">Cat Treat</a> | <a href="#">Cat Toys</a> | <a href="#">Online Cat Food</a> | <a href="#">Wet Cat Food</a> | <a href="#">Dry Cat Food</a> | <a href="#">Whiskas Cat Food</a> | <a href="#">Dog Biscuits</a> | <a href="#">Puppy Treats</a> | <a href="#">Dog Collars & Leashes</a> | <a href="#">Cat Litter & Accessories</a> | <a href="#">Cat Harnesses</a> | <a href="#">Sheba Cat Food</a> | <a href="#">Pet Items Online</a> | <a href="#">Vet Consultation</a> | <a href="#">Veterinary care</a> 
      </div>
      <div className="copyright">
        <p style={{ margin: "0px" }}>Pettofit.ltd, 2021. All Right Reserved</p>
        <div>
          <Link className={classes.customLink} to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link className={classes.customLink} to="/return-policy">
            Return Policy
          </Link>
          <Link className={classes.customLink} to="/terms&condition">
            Terms of Use
          </Link>
        </div>
      </div>
      <Login
        setLoginBoxStatus={setLoginBoxStatus}
        loginBoxStatus={loginBoxStatus}
      />
    </Box>
  );
}

export default Footer;
